// material ui
import { Typography } from '@mui/material';

//interfaces
import type { IGameDrawtimeOverviewReport } from '@/shared/interfaces';
import { retainDecimalValue } from '@/shared/constants';

interface IProps {
  gameDrawTimesReport: IGameDrawtimeOverviewReport[];
}

export const OverviewGameDrawtimesReport: React.FC<IProps> = ({ gameDrawTimesReport }) => {
  return (
    <>
      <div className="resultscontainer">
        <div className="ResultsRow">
          <div id="header-1" className="ResultsHeader">
            <Typography
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '1.15em',
                letterSpacing: '0.08em',
              }}>
              {' '}
              Draw Time{' '}
            </Typography>
          </div>
          <div id="header-2" className="ResultsHeader">
            <Typography
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '1.15em',
                letterSpacing: '0.08em',
              }}>
              {' '}
              Game Result{' '}
            </Typography>
          </div>
          <div id="header-3" className="ResultsHeader">
            <Typography
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '1.15em',
                letterSpacing: '0.08em',
              }}>
              {' '}
              Total Bets{' '}
            </Typography>
          </div>
          <div id="header-4" className="ResultsHeader">
            <Typography
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '1.15em',
                letterSpacing: '0.08em',
              }}>
              {' '}
              Total Sales{' '}
            </Typography>
          </div>
          <div id="header-5" className="ResultsHeader">
            <Typography
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '1.15em',
                letterSpacing: '0.08em',
              }}>
              {' '}
              Total Hits{' '}
            </Typography>
          </div>
        </div>
        {gameDrawTimesReport.map((gameDrawTime) => (
          <div className="ResultsRow">
            <div className="resultCell" style={{ backgroundColor: '#d9e3ed' }}>
              {gameDrawTime.drawTime.name}
            </div>

            {gameDrawTime.gameResult?.resultCombination && gameDrawTime.gameResult.resultCombination !== 'NULL' ? (
              <div className="resultCell" style={{ backgroundColor: '#EDEDED' }}>
                {gameDrawTime.gameResult.resultCombination.replace(/,/g, '')}
              </div>
            ) : (
              <div className="resultCellNoResult" style={{ backgroundColor: '#EDEDED' }}>
                No Result
              </div>
            )}

            <div className="resultCell" style={{ backgroundColor: '#EDEDED' }}>
              {gameDrawTime.noOfbets}
            </div>
            <div className="resultCell" style={{ backgroundColor: '#EDEDED' }}>
              {gameDrawTime.sales}
            </div>
            <div className="resultCell" style={{ backgroundColor: '#EDEDED' }}>
              {retainDecimalValue(gameDrawTime.hits, 2)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
