import type { GameTripleRepeatingPayout } from '@/generated/graphql';

import { mapGQLGameTypeData } from './game-type';
import type { IGameType } from './game-type';

export interface IGameTripleRepeatingPayout {
  id: string;
  payout: number;

  createdAt: string;
  updatedAt: string;

  gameType: IGameType;
}

export function mapGQLGameTripleRepeatingPayout(gameTripleRepeatingPayout?: GameTripleRepeatingPayout | null): IGameTripleRepeatingPayout {
  return {
    id: gameTripleRepeatingPayout?.id ?? '',
    payout: gameTripleRepeatingPayout?.payout ?? 0,
    updatedAt: gameTripleRepeatingPayout ? gameTripleRepeatingPayout.updatedAt ?? '' : '',
    createdAt: gameTripleRepeatingPayout ? gameTripleRepeatingPayout.createdAt ?? '' : '',

    gameType: mapGQLGameTypeData(gameTripleRepeatingPayout?.gameType),
  };
}
