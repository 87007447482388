import { useState, useContext } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

// material-ui
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// graphql
import { useConfirmUsherApplicantMutation, ConfirmUsherApplicantInput } from '@/generated/graphql';

// components
import type { IConfirmUsherApplicantInput } from '@/shared/interfaces';
import { ConfirmationModal, IModalConfirmationDetails } from '@/components/modal/confirmation';
import { FeedbackAlert, IAlertFeedBackDetails } from '@/components/feedback/alert';

// context api
import { UsherPageRefetchUsherCtx } from '@/contexts/user-page-refetch-usher';

// interface
import { IUsherApplicantTableData } from '@/shared/interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

const schema = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    username: yup.string(),
    address: yup.string(),
    uniqueCode: yup.string(),
    contactNumber: yup.string(),
  })
  .required();

interface Props {
  isConfirmUsherApplicantModalOpen: boolean;
  setIsConfirmUsherApplicantModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUsherApplicant: IUsherApplicantTableData | undefined;
  refetch: () => void;
}

export const ConfirmUsherApplicantModal: React.FC<Props> = ({
  isConfirmUsherApplicantModalOpen,
  setIsConfirmUsherApplicantModalOpen,
  selectedUsherApplicant,
  refetch,
}) => {
  const { setRefetchUsherData } = useContext(UsherPageRefetchUsherCtx);

  // graphql
  const [confirmGameUsherApplicant] = useConfirmUsherApplicantMutation();

  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Number',
    messages: [],
  });

  const [addUsherApplicantInputs, setConfirmUsherApplicantInputs] = useState<IConfirmUsherApplicantInput>();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmUsherApplicantInput>({
    resolver: yupResolver(schema),
  });

  const closeModal = () => {
    setIsConfirmUsherApplicantModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const onSubmit = async (dataInput: IConfirmUsherApplicantInput) => {
    setConfirmUsherApplicantInputs(dataInput);
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You are about to add a new usher.'],
    });
  };

  const confirmConfirm = () => {
    if (addUsherApplicantInputs && selectedUsherApplicant) {
      const { uniqueCode, firstName, lastName, email, username, contactNumber, address } = addUsherApplicantInputs;

      const input: ConfirmUsherApplicantInput = {
        id: selectedUsherApplicant.id,
        uniqueCode,
        firstName,
        lastName,
        email,
        username,
        contactNumber,
        address,
      };

      activateLoading();

      confirmGameUsherApplicant({
        variables: { input },
        onCompleted: () => {
          refetch();
          setRefetchUsherData(true);
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Usher added successfully',
            type: 'success',
          });
          setIsConfirmUsherApplicantModalOpen(false);
          setShowLoading(false);
          reset();
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isConfirmUsherApplicantModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div style={{ paddingBottom: '1em' }}>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} mb={4}>
              Accept Usher
            </Typography>

            {selectedUsherApplicant && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="First Name"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('firstName')}
                        defaultValue={selectedUsherApplicant.firstName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Last Name"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('lastName')}
                        defaultValue={selectedUsherApplicant.lastName}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="Email"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('email')}
                        defaultValue={selectedUsherApplicant.email}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Ushername"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('username')}
                        defaultValue={selectedUsherApplicant.username}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="Address"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('address')}
                        defaultValue={selectedUsherApplicant.address}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Contact Number"
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('contactNumber')}
                        defaultValue={selectedUsherApplicant.contactNumber}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="Agent Code"
                        sx={{
                          width: '100%',
                        }}
                        type="text"
                        size="small"
                        {...register('uniqueCode')}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                  mt={10}>
                  <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmConfirm}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
