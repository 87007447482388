import React from 'react';

// material ui
import { Button, Box } from '@mui/material';
import Modal from 'react-modal';

// components
import { CoordinatorUshersSalesTable, Data, HeadCell } from './ushers-sales-table';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

interface Props {
  ushersSales: Data[];
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  CUSRheadCells: HeadCell[];
}

export const CoordinatorUshersSalesBetsModal = ({ ushersSales, modalIsOpen, setModalIsOpen, CUSRheadCells }: Props) => {
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Modal">
        <Box sx={{ height: 15, background: '#87ADD4' }} />
        <Box sx={{ width: 1700, py: 3, pb: 3, pt: 1 }}>
          <CoordinatorUshersSalesTable rows={ushersSales} headCells={CUSRheadCells} />
        </Box>

        <Box justifyContent={'end'} display={'flex'} px={2} pb={3}>
          <Button variant="contained" size="small" type="submit" onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};
