import { useLoginMutation } from '@/generated/graphql';

export default function LoginService() {
  const [loginMutation, { loading, error }] = useLoginMutation();

  return {
    loginMutation,
    loading,
    error,
  };
}
