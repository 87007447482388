import { useState } from 'react';

import './styles/dashboard-layout.css';
import './styles/page-layout.css';

// components
import { TopNavbar, Sidebar } from './components';

export default function NewDashboard() {
  return (
    <>
      <div className="app-wrapper">
        <TopNavbar />
        <Sidebar />
      </div>
    </>
  );
}
