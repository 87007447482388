import type { TelegramReportType } from '@/generated/graphql';

export interface ITelegramReportType {
  id: string;
  name: string;
  description: string;

  createdAt: string;
  updatedAt: string;
}

export interface ITelegramReportTypeTable {
  id: string;
  name: string;
  description: string;
  createdAt: string;
}

export interface ITelegramReportTypeQueryVariable {
  first: number;
  after?: string;
  before?: string;
}

export interface ICreateTelegramReportTypeInput {
  name: string;
  description: string;
}

export function mapGQLTelegramReportType(notification?: TelegramReportType | null): ITelegramReportType {
  return {
    id: notification?.id ?? '',
    name: notification ? notification.name ?? '' : '',
    description: notification ? notification.description ?? '' : '',
    createdAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
  };
}
