import axios from 'axios';

interface UploadFileResponse {
  success: boolean;
  message: string;
  progress: number;
  uploadNextBlock: boolean;
  fileId?: number;
}

export class FileService {
  private file: File;
  private fileSessionId = 0;
  private fileName = '';
  private totalFileBlocks = 0;
  // private maxFileBlockSize: number = 20971520;
  private maxFileBlockSize = 3971520; // 3 MB
  private fileBlockCount = 0;
  private appApkVersion = '';
  private appName = '';

  private startBlock = 0;
  private endBlock = 0;

  constructor(file: File, appApkVersion: string, appName: string) {
    this.file = file;
    this.appApkVersion = appApkVersion;
    this.appName = appName;
    this.setTotalFileBlocks();
  }

  static getFileExtension(fileName: string): string {
    const fileNames: Array<string> = fileName.split('.');

    if (fileNames.length === 0) {
      return '';
    }

    return fileNames[fileNames.length - 1];
  }

  private setTotalFileBlocks() {
    this.totalFileBlocks = Math.ceil(this.file.size / this.maxFileBlockSize);
  }

  async createFileUploadSession(): Promise<{
    success: boolean;
    message?: string;
  }> {
    const response = await axios.post(
      (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL) +
        '/api/v1/appApkCreateFileUploadSession',
      this.getFileSessionDetails(),
    );

    if (response.data.success === false) {
      return {
        success: false,
        message: response.data.message,
      };
    }

    this.fileSessionId = response.data.fileSessionId;
    this.fileName = response.data.fileName;

    return {
      success: true,
    };
  }

  async uploadFile(): Promise<UploadFileResponse> {
    const uploadResponse = await axios.post(
      (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL) +
        '/api/v1/appApkUploadFile',
      this.getFileData(),
    );

    const responseJson = uploadResponse.data;

    if (responseJson.success === false) {
      return {
        success: false,
        message: responseJson.message,
        progress: 0,
        uploadNextBlock: false,
      };
    }

    const uploadFileResponse: UploadFileResponse = {
      success: true,
      message: '',
      progress: this.calculateUploadProgress(),
      uploadNextBlock: !this.isLastBlock(),
    };

    if (responseJson.fileId.length > 0) {
      console.log('PER UP');
      uploadFileResponse.fileId = responseJson.fileId;
      uploadFileResponse.message = 'Uploaded Successfully';
    }

    return uploadFileResponse;
  }

  private calculateUploadProgress(): number {
    if (this.totalFileBlocks === 1) {
      return 100;
    }

    return Math.round((this.fileBlockCount / this.totalFileBlocks) * 100);
  }

  private getFileSessionDetails(): FormData {
    const formData = new FormData();

    formData.append('fileName', this.file.name);
    formData.append('fileSize', this.file.size.toString());

    return formData;
  }

  private getFileData(): FormData {
    const formData = new FormData();

    formData.append('file', this.getFileBlock());
    formData.append('fileSessionId', this.fileSessionId.toString());
    formData.append('fileName', this.fileName);
    formData.append('isLastBlock', this.isLastBlock() ? '1' : '0');
    formData.append('appApkVersion', this.appApkVersion);
    formData.append('appName', this.appName);

    return formData;
  }

  private getFileBlock(): Blob {
    this.startBlock = this.endBlock;
    this.endBlock = this.endBlock + this.maxFileBlockSize;
    const blob = this.file.slice(this.startBlock, this.endBlock);

    this.fileBlockCount += 1;

    return blob;
  }

  private isLastBlock(): boolean {
    console.log(this.fileBlockCount, this.totalFileBlocks);
    return this.fileBlockCount === this.totalFileBlocks;
  }
}
