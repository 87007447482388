import type { GameConfig } from '@/generated/graphql';
export interface IGameConfig {
  id: string;
  combinationLimit: number;
  globalNumberLimit: number;
  isRepeating: boolean;
  isRumble: boolean;
  placeValue: number;
  minNumber: number;
  maxNumber: number;
  hasDoubleRepeatingPayout: boolean;
  hasTripleRepeatingPayout: boolean;

  updatedAt: string;
  createdAt: string;
}

export function mapGQLGameConfigData(gameConfig?: GameConfig | null): IGameConfig {
  return {
    id: gameConfig?.id ?? '',
    combinationLimit: gameConfig ? gameConfig.combinationLimit ?? 0 : 0,
    globalNumberLimit: gameConfig ? gameConfig.globalNumberLimit ?? 0 : 0,
    isRepeating: gameConfig ? gameConfig.isRepeating ?? false : false,
    isRumble: gameConfig ? gameConfig.isRumble ?? false : false,
    placeValue: gameConfig ? gameConfig.placeValue ?? 0 : 0,
    minNumber: gameConfig ? gameConfig.minNumber ?? 0 : 0,
    maxNumber: gameConfig ? gameConfig.maxNumber ?? 0 : 0,
    hasDoubleRepeatingPayout: gameConfig ? gameConfig.hasDoubleRepeatingPayout ?? false : false,
    hasTripleRepeatingPayout: gameConfig ? gameConfig.hasTripleRepeatingPayout ?? false : false,
    updatedAt: gameConfig ? gameConfig.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: gameConfig ? gameConfig.createdAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
