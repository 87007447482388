import { useState, useContext, useEffect } from 'react';

// interfaces
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import type { INumberCombInput, IGameDrawtimelimitTableData, IUpdateGameDrawtimeLimitInput, IGameDrawTime } from '@/shared/interfaces';

// material-ui
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Typography, Select } from '@mui/material';
import Modal from 'react-modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api

// graphql
import type { UpdateGameDrawtimeLimitInput } from '@/generated/graphql';
import { useLimitUpdateGameDrawtimeLimitMutation } from '@/generated/graphql';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isLimitModalOpen: boolean;
  setIsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameDrawtimeLimit: IGameDrawtimelimitTableData;
  refetchData: () => void;
  gameDrawTimes: IGameDrawTime[];
}

export const EditNumberSalesLimitModal: React.FC<Props> = ({
  isLimitModalOpen,
  setIsLimitModalOpen,
  gameDrawtimeLimit,
  refetchData,
  gameDrawTimes,
}) => {
  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const [updateGameDrawtimeLimit] = useLimitUpdateGameDrawtimeLimitMutation();

  const [limitInput, setLimitInput] = useState<IUpdateGameDrawtimeLimitInput>({
    limit: 0,
    startDate: '',
    endDate: '',
  });

  const [selectedGDTId, setSelectedGDTId] = useState<string>();

  const closeModal = () => {
    setIsLimitModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const handleLimitAmountChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (numValue.length > 0 || numValue === '') {
      setLimitInput({
        ...limitInput,
        limit: numValue === '' ? 0 : parseInt(numValue),
      });
    }
  };

  useEffect(() => {
    if (gameDrawtimeLimit) {
      setSelectedGDTId(gameDrawtimeLimit.gameDrawtimeId);
      setLimitInput({
        limit: gameDrawtimeLimit.limit,
        startDate: gameDrawtimeLimit.startDate,
        endDate: gameDrawtimeLimit.endDate,
      });
    }
  }, [gameDrawtimeLimit]);

  const clickSubmit = () => {
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to edit this limit'],
    });
  };

  const editLimit = () => {
    activateLoading();
    const { startDate, endDate, limit } = limitInput;

    const input: UpdateGameDrawtimeLimitInput = {
      id: gameDrawtimeLimit.id,
      startDate,
      endDate,
      limit: limit,
      gameDrawtimeId: selectedGDTId ? selectedGDTId : undefined,
    };
    console.log(input);

    updateGameDrawtimeLimit({
      variables: { input },
      onCompleted: () => {
        refetchData();
        setIsLimitModalOpen(false);
        setConfirmationModal({
          isOpen: false,
          title: 'Confirm Result',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'Limit edited successfully',
          type: 'success',
        });
        setShowLoading(false);
      },
    });
  };

  return (
    <>
      <Modal isOpen={isLimitModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 350, px: 5, pb: 3, pt: 1 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit Limit
            </Typography>

            <FormControl fullWidth sx={{ my: 4 }}>
              <InputLabel id="demo-simple-select-label">DrawTime</InputLabel>
              <Select
                label="DrawTime"
                className="bg-white"
                size="small"
                onChange={(e) => {
                  setSelectedGDTId(e.target.value);
                }}
                value={selectedGDTId ? selectedGDTId : ''}>
                {gameDrawTimes.map((gameDrawTime) => (
                  <MenuItem value={gameDrawTime.id}>{gameDrawTime.drawTime.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Limit"
                variant="outlined"
                size="small"
                value={limitInput?.limit}
                onChange={(e) => {
                  handleLimitAmountChange(e.target.value);
                }}
              />
            </FormControl>

            <TextField
              onChange={(e) => setLimitInput({ ...limitInput, startDate: e.target.value })}
              id="date"
              label="Start date"
              type="date"
              size="small"
              value={limitInput?.startDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              onChange={(e) => setLimitInput({ ...limitInput, endDate: e.target.value })}
              id="date"
              label="End date"
              type="date"
              size="small"
              value={limitInput?.endDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{
            display: 'flex',
            paddingBottom: '2em',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <Button variant="contained" onClick={clickSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={editLimit}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
