import type { Transaction } from '@/generated/graphql';
import { IBetTransaction, mapGQLBetTransaction } from './bet-transaction';
import { ITransactionLocation, mapGQLTransactionLocation } from './transaction-location';

import { mapGQLUsher } from './usher';
import type { IUsher } from './usher';

export interface ITransaction {
  id: string;
  notes: string;
  transactionCode: string;
  transactionNumber: number;
  createdAt: string;
  updatedAt: string;

  location: ITransactionLocation;

  usher: IUsher;
  betTransactions: IBetTransaction[];

  totalSales: number;
  hits: number;
}

export function mapGQLTransaction(transaction?: Transaction | null): ITransaction {
  return {
    id: transaction?.id ?? '',
    notes: transaction ? transaction.notes ?? '' : '',
    transactionCode: transaction ? transaction.transactionCode ?? '' : '',
    transactionNumber: transaction ? transaction.transactionNumber ?? 0 : 0,
    // createdAt: transaction ? transaction.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: transaction ? transaction.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: transaction
      ? new Date(transaction.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    usher: mapGQLUsher(transaction?.usher),
    location: mapGQLTransactionLocation(transaction?.location),
    betTransactions: transaction?.betTransactions
      ? transaction.betTransactions?.map((betTransaction) => mapGQLBetTransaction(betTransaction))
      : [],

    totalSales: transaction ? transaction.totalSales ?? 0 : 0,
    hits: transaction ? transaction.hits ?? 0 : 0,
  };
}
