import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';

// material ui
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';

// graphql
import { UpdateDrawAdminInput, useUpdateDrawAdminMutation } from '@/generated/graphql';

// interfaces
import type { IUpdateDrawAdminInput, IDrawAdminTable } from '@/shared/interfaces';
import { useEffect } from 'react';

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawAdmin: IDrawAdminTable | undefined;
}

export const EditDrawAdmin = ({ open, setOpen, refetchData, drawAdmin }: IUsherProps) => {
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      username: yup.string().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateDrawAdminInput>({
    resolver: yupResolver(schema),
  });

  const [updateDrawAdminMutation] = useUpdateDrawAdminMutation();

  const onSubmit = async (dataInput: IUpdateDrawAdminInput) => {
    const { firstName, lastName, username, email } = dataInput;

    if (drawAdmin) {
      const input: UpdateDrawAdminInput = {
        id: drawAdmin.id,
        firstName,
        lastName,
        username,
        email,
      };

      const { data } = await updateDrawAdminMutation({
        variables: {
          input,
        },
      });

      if (data) console.log(data);
      if (errors) console.log(errors);
      refetchData();
      handleClose();
      reset();
    }
  };

  useEffect(() => {
    if (drawAdmin) {
      reset({
        firstName: drawAdmin.firstName,
        lastName: drawAdmin.lastName,
        email: drawAdmin.email,
        username: drawAdmin.username,
      });
    }
  }, [drawAdmin]);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 400,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box sx={{}} p={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              variant="h4"
              component="h4">
              Edit Draw Admin
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={9}>
                <Grid container spacing={1}>
                  <TextField
                    required
                    label="Name"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('firstName')}
                  />
                  <TextField
                    required
                    label="Name"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('lastName')}
                  />
                  <TextField
                    required
                    label="Username"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('username')}
                  />
                  <TextField
                    required
                    label="Email"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('email')}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                }}
                mt={10}>
                <Button type="submit">
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                    }}
                    p={1}>
                    Submit
                  </Box>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
