import { useEffect } from 'react';
import Modal from 'react-modal';

// material ui
import { Box, Button, Divider, FormControl, Grid, Input, InputAdornment, InputLabel, Stack, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { IoMdAddCircle } from 'react-icons/io';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// graphql
import { UpdateCoordinatorCommissionInput, useUpdateCoordinatorCommissionMutation } from '@/generated/graphql';

import { ICoordinatorTable, IEditCoordinatorCommissionInput } from 'src/shared/interfaces';

interface Props {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  coordinator: ICoordinatorTable | undefined;
}

export const EditCoordinatorCommission = ({ refetchData, open, setOpen, coordinator }: Props) => {
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      commission: yup.number().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditCoordinatorCommissionInput>({
    resolver: yupResolver(schema),
  });

  const [updateCoordinatorCommissionMutation] = useUpdateCoordinatorCommissionMutation();

  const onSubmit = async (dataInput: IEditCoordinatorCommissionInput) => {
    console.log('🚀 ~ onSubmit ~ dataInput:', dataInput);
    const { commission } = dataInput;

    if (coordinator) {
      const input: UpdateCoordinatorCommissionInput = {
        coordinatorId: coordinator.id,
        commission: parseFloat(commission),
      };

      const { data } = await updateCoordinatorCommissionMutation({
        variables: {
          input,
        },
      });

      if (data) console.log(data);
      if (errors) console.log(errors);
      refetchData();
      handleClose();
      reset();
    }
  };

  const handleCommissionAmountInput = (value: string) => {
    console.log('🚀 ~ handleCommissionAmountInput ~ value:', value);
    if (value) {
      reset({
        commission: value,
      });
    } else {
      reset({
        commission: '',
      });
    }
  };

  useEffect(() => {
    if (coordinator) {
      reset({
        commission: coordinator.commission.toString(),
      });
    }
  }, [coordinator]);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'inherit',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
          },
          content: {
            width: '340px',
            overflow: 'unset',
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            overflow: 'unset',
            borderRadius: '5px',
          }}
          mt={9}>
          <Box sx={{ px: 5, py: 2 }} alignItems={'center'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="subtitle2" m={0}>
                Edit operator commission
              </Typography>
            </Stack>
          </Box>
          <Divider />

          <Box px={5} pt={2} pb={2}>
            <Typography gutterBottom variant="subtitle2" m={0}>
              Operator: {coordinator?.name}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid>
                <FormControl fullWidth sx={{ mt: 5 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">Commission</InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    {...register('commission')}
                    onChange={(text) => handleCommissionAmountInput(text.currentTarget.value.replace(/[^0-9.]/g, ''))}
                    endAdornment={<InputAdornment position="start">%</InputAdornment>}
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent={'end'} mt={5}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: 'grey',
                    fontSize: 10,
                    fontWeight: 600,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    textTransform: 'unset',
                  }}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    ml: 1,
                    fontSize: 10,
                    fontWeight: 600,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    textTransform: 'unset',
                  }}
                  type="submit"
                  size="small"
                  variant="contained">
                  Submit
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
