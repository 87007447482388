import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button, FormControl, Grid, Link, Skeleton, TextField } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { retainDecimalValue } from '@/shared/constants';

import { UsherWinnerBetsModal } from './usher-win-bets-modal';
import { IBet } from '@/shared/interfaces';

import '../../styles/reports.css';

export interface Data {
  [key: string]: string | number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';
export type Align = 'left' | 'right';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  hide: boolean;
  tbCellAlign: 'left' | 'right';
  className: string;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <>
            {!headCell.hide && (
              <TableCell
                className={headCell.className}
                style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
                key={headCell.id}
                align={'left'}
                padding={'normal'}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Sales Report
      </Typography>
      <FormControl sx={{ ml: 2, mb: 2, mt: 0, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="standard"
        />
      </FormControl>
    </Toolbar>
  );
}

interface Props {
  rows: Data[];
  headCells: HeadCell[];
  winnerBets: IBet[];
  USRtotalCount: number;
}

export const UsherSalesTable: React.FC<Props> = ({ rows, headCells, winnerBets, USRtotalCount }) => {
  const [searchedRows, setSearchedRows] = useState<Data[]>([]);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('calories');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [usherWinBets, setUsherWinBets] = useState<Data[]>([]);

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type ITableDataKey = keyof Data;
    const searchKeys: ITableDataKey[] = headCells.map((x) => x.id);

    const searchedResults = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedRows(searchedResults);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const showAllRow = () => {
    setRowsPerPage(rows.length);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const viewUsherHits = (usherId: string) => {
    const usherWinBets = winnerBets.filter((bet) => bet.transaction.usher.id === usherId);

    const ushersSalesReportTableData = usherWinBets.map((winnerBet) => {
      const { gameDrawTime, betDetail, transaction, numberCombination, amount, winAmount, createdAt } = winnerBet;

      const dynamicObject: Data = {
        agent: `${transaction.usher.firstName} ${transaction.usher.lastName}`,
        gameType: gameDrawTime.game.gameType.name,
        draw: gameDrawTime.drawTime.name,
        numberCombination: numberCombination.replace(/,/g, ''),
        transactionCode: transaction.transactionCode,
        amount: retainDecimalValue(amount, 2),
        payable: winAmount,
        createdAt: createdAt,
        betType: betDetail.betType.name,
      };

      return dynamicObject;
    });
    setUsherWinBets(ushersSalesReportTableData);

    setModalIsOpen(true);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={typeof orderBy === 'number' ? orderBy.toString() : orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(searchWord.length >= 1 ? searchedRows : rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      className="usher-table-row"
                      style={{ border: '0.07em solid #CFCFCF' }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}>
                      {headCells.map((headCell, index) => (
                        <>
                          {!headCell.hide && (
                            <>
                              {headCell.id === 'payableWinner' && row[headCell.id] !== 0 ? (
                                <TableCell key={index} align={headCell.tbCellAlign}>
                                  <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                      viewUsherHits(row.usherId.toString());
                                    }}>
                                    {row[headCell.id]}
                                  </Link>
                                </TableCell>
                              ) : (
                                <TableCell key={index} align={headCell.tbCellAlign} className={headCell.className}>
                                  {row[headCell.id]}
                                </TableCell>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent={'space-between'} display={'flex'}>
            {USRtotalCount === rows.length ? (
              <>
                <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                  <Button variant="contained" color="success" sx={{ ml: 2 }} size={'small'} onClick={showAllRow}>
                    Show All
                  </Button>
                </Grid>
                <Grid item md={10} justifyContent={'end'} display={'flex'}>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                  <Skeleton variant="rounded" width={90} height={35} sx={{ ml: 2 }} />
                </Grid>
                <Grid item md={10} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                  <Skeleton variant="rounded" width={250} height={15} sx={{ mr: 2 }} />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Box>
      <UsherWinnerBetsModal usherWinBets={usherWinBets} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}></UsherWinnerBetsModal>
    </>
  );
};
