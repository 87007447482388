import type { TransactionLocation } from '@/generated/graphql';

export interface ITransactionLocation {
  id: string;
  latitude: number;
  longitude: number;
  createdAt: string;
  updatedAt: string;
}

export function mapGQLTransactionLocation(transactionLocation?: TransactionLocation | null): ITransactionLocation {
  return {
    id: transactionLocation?.id ?? '',
    latitude: transactionLocation ? transactionLocation.latitude ?? 0 : 0,
    longitude: transactionLocation ? transactionLocation.longitude ?? 0 : 0,
    createdAt: transactionLocation ? transactionLocation.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: transactionLocation ? transactionLocation.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
