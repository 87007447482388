import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import type { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface IAlertFeedBackDetails {
  isOpen: boolean;
  message: string;
  type: 'error' | 'info' | 'success' | 'warning';
}

interface Props {
  successDetails: IAlertFeedBackDetails;
  setSuccessDetails: React.Dispatch<React.SetStateAction<IAlertFeedBackDetails>>;
}

export const FeedbackAlert: React.FC<Props> = ({ successDetails, setSuccessDetails }) => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessDetails({ ...successDetails, isOpen: false });
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={successDetails.isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={successDetails.type} sx={{ width: '100%' }}>
          {successDetails.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
