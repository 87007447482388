import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Box, Typography, TextField, FormControl, Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';

// material ui

// interfaces
import type { ICreateDrawTimeInput, IDrawTimeInput } from '@/shared/interfaces';

// validation schema
const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -40vh)',
  },
};

interface Props {
  drawTimeInputs: IDrawTimeInput[];
  setdrawTimeInput: React.Dispatch<React.SetStateAction<IDrawTimeInput[]>>;
}

export function ChildModal(props: Props) {
  const { setdrawTimeInput, drawTimeInputs } = props;

  const [open, setOpen] = React.useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateDrawTimeInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (dataInput: ICreateDrawTimeInput) => {
    const { startTime, endTime, cutOff, name } = dataInput;
    const date = new Date().toISOString().split('T');

    const input: IDrawTimeInput = {
      name: name,
      schedule: 'Everyday',
      startDateTime: new Date(date[0] + 'T' + updateTime(startTime) + ':00Z').toISOString(),
      endDateTime: new Date(date[0] + 'T' + updateTime(endTime) + ':00Z').toISOString(),
      cutOffDateTime: new Date(date[0] + 'T' + updateTime(cutOff) + ':00Z').toISOString(),
      startTime: new Date(date[0] + 'T' + updateTime(startTime) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
      endTime: new Date(date[0] + 'T' + updateTime(endTime) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
      cutOff: new Date(date[0] + 'T' + updateTime(cutOff) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
    console.log('addding');
    setdrawTimeInput([...drawTimeInputs, input]);
    setOpen(false);
    reset();
  };

  function updateTime(time: string) {
    const [hour, minute] = time.split(':');
    let newHour = parseInt(hour) - 8;
    if (newHour < 0) {
      newHour += 24;
    }
    return `${newHour.toString().length === 1 ? '0' + newHour.toString() : newHour.toString()}:${minute}`;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Add Draw Time
      </Button>
      <Modal isOpen={open} onRequestClose={handleClose} style={customStyles}>
        <Box sx={{ height: 15, background: '#87ADD4' }} />
        <Box sx={{ width: 600, p: 3 }}>
          <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} pt={2}>
            Add Draw Time
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <TextField
                error={errors.name?.message ? true : false}
                helperText={errors.name?.message ? errors.name?.message : null}
                {...register('name')}
                label="Name"
                variant="standard"
              />
            </FormControl>
            <Grid container spacing={3} mt={1}>
              <Grid item md={4}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" gutterBottom textAlign={'center'}>
                    Draw Time
                  </Typography>
                </Grid>
                <TextField
                  error={errors.endTime?.message ? true : false}
                  helperText={errors.endTime?.message ? errors.endTime?.message : null}
                  {...register('endTime')}
                  id="time"
                  label="End Time"
                  type="time"
                  defaultValue="12:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                />
              </Grid>
              <Grid item md={8} justifyContent={'end'}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" gutterBottom textAlign={'center'}>
                    Cut-off Schedule
                  </Typography>
                </Grid>
                <TextField
                  error={errors.startTime?.message ? true : false}
                  helperText={errors.startTime?.message ? errors.startTime?.message : null}
                  {...register('startTime')}
                  id="time"
                  label="Start Time"
                  type="time"
                  defaultValue="12:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                />
                <TextField
                  error={errors.cutOff?.message ? true : false}
                  helperText={errors.cutOff?.message ? errors.cutOff?.message : null}
                  {...register('cutOff')}
                  id="time"
                  label="Cutt-Off"
                  type="time"
                  defaultValue="12:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150, ml: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container mt={4} justifyContent={'end'}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
