//Time-Related Functions and Filter

// create new Date object with today's date
export const today = new Date(
  new Date().toLocaleString('en-US', {
    timeZone: 'Asia/Manila',
  }),
);

// DefaultDate is today.year/month/date
export const defaultDateFilter = {
  dateFrom: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
  dateTo: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
};

export const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();

export interface IDateFilter {
  date: string;
}
