import type { BetType } from '@/generated/graphql';

export interface IBetType {
  id: string;
  name: string;
  description: string;
  payout: number;
  createdAt: string;
  updatedAt: string;
}

export function mapGQLBetType(betType?: BetType | null): IBetType {
  return {
    id: betType?.id ?? '',
    name: betType ? betType.name ?? '' : '',
    description: betType ? betType.description ?? '' : '',
    payout: betType ? betType.payout ?? 0 : 0,
    createdAt: betType ? betType.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: betType ? betType.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
