import type { AppApk } from '@/generated/graphql';

export interface IAppApk {
  id: string;
  originalFileName: string;
  uniqueFileName: string;
  fileExtension: string;
  fileSize: number;
  appName: string;
  appVersion: string;
  createdAt: string;
  updatedAt: string;
  createdAtISOString: string;
}

export interface IAppApkTableData {
  id: string;
  originalFileName: string;
  fileSize: string;
  appName: string;
  appVersion: string;
  createdAt: string;
  createdAtISOString: string;
}

export interface IAppApkQueryVariable {
  first: number;
  after?: string;
  before?: string;
}

export function mapGQLAppApk(appApk?: AppApk | null): IAppApk {
  return {
    id: appApk?.id ?? '',
    originalFileName: appApk?.originalFileName ?? '',
    uniqueFileName: appApk?.uniqueFileName ?? '',
    fileExtension: appApk?.fileExtension ?? '',
    fileSize: appApk?.fileSize ?? 0,
    appVersion: appApk?.appVersion ?? '',
    appName: appApk?.appName ?? '',
    createdAt: appApk
      ? new Date(appApk.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: appApk ? appApk.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAtISOString: appApk ? appApk.createdAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
