import type { GameType } from '@/generated/graphql';

import { mapGQLGameConfigData } from '@/shared/interfaces';

import type { IGameConfig } from '@/shared/interfaces';
export interface IGameType {
  id: string;
  name: string;
  description: string;
  updatedAt: string;
  createdAt: string;

  gameConfig: IGameConfig;
  latestPayout: number;
}

export interface GameTypePhoto {
  id: string;
  image: string;
}

export interface IGamesSalesReportGameType {
  id: string;
  name: string;
}

export function mapGQLGameTypeData(gameType?: GameType | null): IGameType {
  return {
    id: gameType ? gameType.id ?? '' : '',
    name: gameType ? gameType.name ?? '' : '',
    description: gameType ? gameType.description ?? '' : '',
    updatedAt: gameType ? gameType.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: gameType ? gameType.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    latestPayout: gameType ? gameType.latestPayout ?? 0 : 0,

    gameConfig: mapGQLGameConfigData(gameType?.gameConfig),
  };
}

export function mapGQLGamesSalesReportGameType(gameType?: GameType | null): IGamesSalesReportGameType {
  return {
    id: gameType ? gameType.id ?? '' : '',
    name: gameType ? gameType.name ?? '' : '',
  };
}
