import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState, useTransition } from 'react';
import Modal from 'react-modal';

import { useEnableDisableUsherMutation, useTransactionQuery } from '@/generated/graphql';
import { IUpdateUsherInput, ITransaction, mapGQLTransaction, IBetTableData } from '@/shared/interfaces';

// context
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

interface IUsherProp {
  transactionId: string | undefined;
  bet: IBetTableData | undefined;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
}

export function TransactionDetails(props: IUsherProp) {
  const { transactionId, isOpen, openEvent, bet } = props;
  const [modalHeight, setModalHeight] = useState<number>(450);
  const [transaction, setTransaction] = useState<ITransaction>();
  const [skipTransactionQuery, setSkipTransactionQuery] = useState<boolean>(true);
  const [gameName, setGameName] = useState<string>('');
  const { gameCtx } = useGameDashboardCtx();

  const handleClose = () => openEvent(false);

  useTransactionQuery({
    variables: { id: transactionId ?? '' },
    skip: skipTransactionQuery,
    onCompleted(data) {
      const mapTransaction = mapGQLTransaction(data.transaction);
      setTimeout(() => {
        setTransaction(mapTransaction);
      }, 200);
    },
    onError(error) {
      console.log(error, '--->>2');
    },
  });

  useEffect(() => {
    if (transactionId !== undefined && transactionId !== '') {
      setSkipTransactionQuery(false);
    }
  }, [transactionId]);

  useEffect(() => {
    if (gameCtx.gameType.name.length > 0) {
      setGameName(gameCtx.gameType.name);
    }
  }, [gameCtx]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 270,
            height: { modalHeight },
          }}>
          <Box p={1} mt={5}>
            <Typography variant="body1" display="block" align="center" gutterBottom mt={3} mb={5}>
              Transaction Details
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                Date:
              </Typography>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                {transaction?.createdAt}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} mt={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                Longitude:
              </Typography>
              <Typography variant="body2" display="block" fontWeight={600} gutterBottom mb={0}>
                {transaction?.location.longitude}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                Latitude:
              </Typography>
              <Typography variant="body2" display="block" fontWeight={600} gutterBottom mb={0}>
                {transaction?.location.latitude}
              </Typography>
            </Stack>
            ----------------------------------------------
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                AGENT:
              </Typography>
              <Typography variant="body2" display="block" fontWeight={600} gutterBottom mb={0}>
                {bet?.agent.toUpperCase()}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                GAME:
              </Typography>
              <Typography variant="body2" display="block" fontWeight={600} gutterBottom mb={0}>
                {gameName}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" display="block" gutterBottom mb={0}>
                DRAW:
              </Typography>
              <Typography variant="body2" display="block" fontWeight={600} gutterBottom mb={0}>
                {bet?.draw}
              </Typography>
            </Stack>
            ----------------------------------------------
            <Stack direction="row" spacing={2} justifyContent={'space-between'} mb={2} mt={1}>
              <Typography variant="body2" gutterBottom mb={0}>
                Combi
              </Typography>
              <Typography variant="body2" gutterBottom mb={0}>
                Payout
              </Typography>
            </Stack>
            {transaction?.betTransactions
              .filter((x) => x.bet.isOriginalBet === true)
              .map((betTransaction) => {
                return (
                  <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                    <Typography variant="body2" fontWeight={600} gutterBottom mb={0}>
                      {betTransaction.bet.numberCombination.replace(/,/g, '')}
                      {betTransaction.bet.betDetail.betType.name === 'Rumble' ? 'R' : 'T'}
                      {betTransaction.bet.originalBetAmount}
                    </Typography>
                    <Typography variant="body2" fontWeight={600} gutterBottom mb={0}>
                      {betTransaction.bet.winAmount}
                    </Typography>
                  </Stack>
                );
              })}
            ----------------------------------------------
            <Typography variant="subtitle1" display="block" gutterBottom mb={0}>
              TOTAL: {transaction?.totalSales}
            </Typography>
            <Typography variant="body2" display="block" align="center" gutterBottom mb={0} mt={3}>
              {bet?.transactionCode}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
