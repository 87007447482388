import LinearProgress from '@mui/material/LinearProgress';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

// material ui
import { AiOutlineCloudUpload } from 'react-icons/ai';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { AiOutlineFile } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import { FeedbackAlert } from '@/components/feedback/alert';

// graphql
import { AppApkNameEnum } from '@/generated/graphql';

// validator
import { validateFileSize, validateFileType, FileService } from '@/services/file';
import type { LinearProgressProps } from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </>
  );
}

interface Props {
  formatBytes: (bytes: number, decimals?: number) => string;
  refetchData: () => void;
}

export const AppUpload: React.FC<Props> = ({ formatBytes, refetchData }) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFilename] = useState('');
  const [appApkVersion, setAppApkVersion] = useState<string>('');
  const [appName, setAppName] = useState<string>('');

  const [fileUploadPercentage, setFileUploadPercentage] = useState<number | undefined>(undefined);

  // alert
  const [alert, setAlert] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const handleFileUpload = async (files: File | null) => {
    console.log('FILESSS');
    console.log('🚀 ~ file: app-upload.tsx ~ line 70 ~ handleFileUpload ~ files', files);

    if (!files) {
      return;
    }

    const validFileSize = await validateFileSize(files.size);
    const validFileType = await validateFileType(FileService.getFileExtension(files.name));

    if (!validFileSize.isValid) {
      setAlert({
        ...alert,
        isOpen: true,
        message: validFileSize.errorMessage,
        type: 'warning',
      });

      return;
    }

    if (!validFileType.isValid) {
      setAlert({
        ...alert,
        isOpen: true,
        message: validFileType.errorMessage,
        type: 'warning',
      });
      return;
    }

    setFile(files);
    setFilename(files.name);
  };

  const submitFile = async () => {
    if (file && appApkVersion && appName) {
      const fileService = new FileService(file, appApkVersion, appName);
      const fileSessionDetails = await fileService.createFileUploadSession();

      if (fileSessionDetails.success === false) {
        setAlert({
          ...alert,
          isOpen: true,
          message: fileSessionDetails.message ?? 'Error uploading file',
          type: 'error',
        });
        return;
      }

      const uploadFile = async () =>
        fileService.uploadFile().then((response) => {
          if (response.success === false) {
            setAlert({
              ...alert,
              isOpen: true,
              message: response.message,
              type: 'error',
            });
            setFileUploadPercentage(undefined);
            return Promise.reject(false);
          }

          setFileUploadPercentage(response.progress);

          if (response.uploadNextBlock) {
            console.log('uploadNextBlock');
            uploadFile();
          } else {
            setFile(null);
            setFilename('');
            setAppApkVersion('');
            setFileUploadPercentage(undefined);

            setAlert({
              ...alert,
              isOpen: true,
              message: 'File successfully uploaded',
              type: 'success',
            });

            refetchData();

            return Promise.resolve(response);
          }
        });

      uploadFile().catch((error) => {
        console.log(error);
        setAlert({
          ...alert,
          isOpen: true,
          message: 'Error occurred uploading the file',
          type: 'error',
        });
      });
    }
  };

  const updateOrRemoveFile = () => {
    setFile(null);
    setFilename('');
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAppName(event.target.value);
  };

  return (
    <>
      <hr />
      <Box
        sx={{
          bgcolor: '#f5f8ff',
          height: '150px',
          border: 'solid 1px #bcc5df',
          borderRadius: '10px',
          mt: 5,
        }}>
        <FileUploader
          multiple={false}
          handleChange={handleFileUpload}
          name="file"
          // types={fileTypes}
          children={
            <>
              <Grid container p={2}>
                <Grid item md={1} />
                <Grid item md={10}>
                  <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={'column'}
                    mt={-3}
                    sx={{ height: '150px', color: '#424ca8' }}>
                    <IconButton aria-label="edit" sx={{ backgroundColor: '#a7caed' }}>
                      <AiOutlineCloudUpload size={25} />
                    </IconButton>

                    <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} mt={1}>
                      <Typography variant="body2" gutterBottom fontWeight={'bold'} color="#17416D">
                        Click to upload
                      </Typography>
                      <Typography variant="body2" gutterBottom ml={1} color="#17416D">
                        or drag and drop
                      </Typography>
                    </Stack>

                    <Typography
                      variant="body2"
                      gutterBottom
                      justifyContent={'center'}
                      display={'flex'}
                      fontWeight={'bold'}
                      color={'#8dbaf4'}>
                      APK (max. 100 MB)
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={1} justifyContent={'end'} alignItems={'end'} display={'flex'}>
                  <Button className="btnChooseFile " variant="contained" sx={{ mb: 2 }} endIcon={<AiOutlineFile color="grey" />}>
                    <Typography
                      style={{
                        color: 'grey',
                        fontSize: '0.8em',
                        paddingRight: '0.5em',
                        marginRight: '0.5em',
                        fontFamily: 'Inter',
                        fontWeight: '600',
                        borderRight: ' 0.2em solid grey ',
                        width: '7.8em',
                        height: '3em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      Upload File
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        />
      </Box>

      {fileName !== '' && (
        <Box
          sx={{
            height: '100px',
            border: 'solid 1px #bcc5df',
            borderRadius: '10px',
            mt: 3,
          }}>
          <Grid container>
            <Grid item md={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton aria-label="edit" color="primary" sx={{ backgroundColor: '#a7caed', m: 1 }}>
                <AiOutlineFile size={35} />
              </IconButton>
            </Grid>
            <Grid item md={9} justifyContent={'center'} display={'flex'}>
              <Grid md={8}>
                <Stack justifyContent={'center'} alignItems={'start'} direction={'column'} sx={{ height: '100px' }}>
                  <Typography variant="body2" gutterBottom ml={1}>
                    {fileName}
                  </Typography>
                  <Typography variant="body2" gutterBottom ml={1}>
                    {formatBytes(file?.size || 0)}
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={fileUploadPercentage ? fileUploadPercentage : 0} />
                  </Box>
                </Stack>
              </Grid>
              <Grid md={2} alignItems={'end'} display={'flex'}>
                <TextField
                  id="standard-basic"
                  sx={{ mx: 2, mb: 3 }}
                  label="App Version"
                  variant="standard"
                  value={appApkVersion}
                  onChange={(e) => {
                    setAppApkVersion(e.target.value);
                  }}
                />
              </Grid>
              <Grid md={2} alignItems={'end'} display={'flex'}>
                <FormControl variant="standard" sx={{ mx: 2, mb: 3, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">App Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={appName}
                    onChange={handleChange}
                    label="App Name">
                    <MenuItem value="stl mobile">Stl mobile</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={2} justifyContent={'between'} alignItems={'end'} display={'flex'}>
              <Stack
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                justifyContent={'space-between'}
                alignItems={'start'}
                direction={'row'}
                sx={{ height: '100px', ml: 3 }}>
                <IconButton aria-label="edit" style={{ marginTop: '0.3em' }} onClick={updateOrRemoveFile}>
                  <MdDelete size={20} />
                </IconButton>
                {appApkVersion?.length > 0 && appName.length > 0 && fileName !== '' && (
                  <Button
                    style={{ marginTop: '1.2em', padding: '0.6em 1em' }}
                    variant="contained"
                    size="small"
                    sx={{ mb: 1, ml: 3 }}
                    onClick={submitFile}>
                    Upload
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      <FeedbackAlert setSuccessDetails={setAlert} successDetails={alert} />
    </>
  );
};
