import { useState } from 'react';

// material ui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// graphql
import { GetTelegramReportsFilter } from '@/generated/graphql';

// interfaces
import type { ITelegramReportQueryVariable, ITelegramReportTypeTable } from '@/shared/interfaces';

interface IProps {
  reportQueryVariable: ITelegramReportQueryVariable;
  setReportQueryVariable: React.Dispatch<React.SetStateAction<ITelegramReportQueryVariable>>;
  tableDataReportTypes: ITelegramReportTypeTable[];
}

export const ReportsFilterSection = ({ reportQueryVariable, setReportQueryVariable, tableDataReportTypes }: IProps) => {
  const [reportsFilter, setReportsFilter] = useState<GetTelegramReportsFilter>({ reportTypeId: 'All' });

  const handelReportsFilter = (value: string | null) => {
    if (value) {
      setReportQueryVariable({
        ...reportQueryVariable,
        filterBy: { ...reportQueryVariable.filterBy, reportTypeId: value != 'All' ? value : undefined },
      });
    }

    setReportsFilter({ ...reportsFilter, reportTypeId: value });
  };

  return (
    <>
      <FormControl sx={{ minWidth: '200px' }} size="small">
        <InputLabel id="demo-select-small-label">Report Type</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={reportsFilter.reportTypeId}
          label="Report Type"
          onChange={(event) => {
            handelReportsFilter(event.target.value);
          }}>
          {tableDataReportTypes.map((reportType, reportTypeIndex) => (
            <MenuItem key={reportTypeIndex} value={reportType.id}>
              {reportType.name}
            </MenuItem>
          ))}
          <MenuItem value="All">All</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
