import { Box, Button, Grid, Tab, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CoordinatorTableUshers } from '../components/coordinator-table-ushers';
import {
  IDisabledUsherQueryVariable,
  IDisabledUsherTable,
  IUsher,
  IUsherQueryVariable,
  IUsherTable,
  mapGQLUsher,
  ICoordinator,
  mapGQLCoordinator,
} from '@/shared/interfaces';
import { useState } from 'react';
import { PageInfo, useDisabledUshersQuery, useTableUshersQuery, useCoordinatorQuery } from '@/generated/graphql';
import { CoordinatorAsignUsher } from '../components/assign-usher/modal-assign-ushers';
import { CoordinatorUnasignUsher } from '../components/coordinator-unasign-usher';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CoordinatorTableDisabledUshers } from '../components/coordinator-table-disabled-usher';

interface ICheckboxData {
  isChecked: boolean;
  usherId: string;
}

export function CoordinatorUshersPage() {
  const { coordinatorId } = useParams();

  const [tabValue, setTabValue] = useState('1');

  const [usherIds, setUsherIds] = useState<string[]>([]);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [getUsherLoading, setGetUsherLoading] = useState<boolean>(false);

  const [coordinator, setCoordinator] = useState<ICoordinator>();

  const [usherTableData, setUsherTableData] = useState<IUsherTable[]>([]);
  const [usherQueryVariable, setUsherQueryVariable] = useState<IUsherQueryVariable>({
    first: 25,
    filterBy: {
      coordinatorId: coordinatorId,
    },
  });

  const [disabledUsherTableData, setDisabledUsherTableData] = useState<IDisabledUsherTable[]>([]);
  const [disabledUsherQueryVariable, setDisabledUsherQueryVariable] = useState<IDisabledUsherQueryVariable>({
    first: 25,
    filterBy: {
      coordinatorId: coordinatorId,
    },
  });

  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [pageInfoDisabledUsher, setPageInfoDisabledUsher] = useState<PageInfo>();
  const [totalCountDisabledUsher, setTotalCountDisabledUsher] = useState<number>(0);

  const usherEvent = (usherId: string, opn: boolean, eventName: string) => {
    if (eventName == 'delete') {
      setUsherIds([...usherIds, usherId]);
      setDeleteOpen(opn);
    }
  };

  const [checkedData, setCheckedData] = useState<ICheckboxData[]>([]);

  const [checkedStates, setCheckedStates] = useState<boolean[]>([false]);

  const [checkedDisabledStates, setCheckedDisabledStates] = useState<boolean[]>([false]);

  const chackDataEventTable = (index: number, isCheck: boolean, usherId: string) => {
    let newData = [...checkedData];

    if (isCheck === true) {
      newData.push({
        isChecked: isCheck,
        usherId: usherId,
      });
    } else {
      newData = newData.filter((item) => item.usherId != usherId);
    }

    setCheckedData(newData);
  };

  useCoordinatorQuery({
    variables: { coordinatorId: coordinatorId ?? '' },
    onCompleted(data) {
      setCoordinator(mapGQLCoordinator(data.coordinator));
    },
  });

  const getUshers = useTableUshersQuery({
    variables: usherQueryVariable,
    onCompleted: (data) => {
      const mappedUshers: IUsherTable[] = [];
      data.ushers.nodes.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);

        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });
      setTimeout(() => {
        setUsherTableData(mappedUshers);
        setPageInfo(data.ushers.pageInfo);
        setTotalCount(data.ushers.totalCount);
      }, 100);
    },
  });

  const getDisabledUshers = useDisabledUshersQuery({
    variables: disabledUsherQueryVariable,
    onCompleted: (data) => {
      const mappedUshers: IDisabledUsherTable[] = [];
      data.disabledUshers.nodes.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);
        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          lastDisabledDatetime: mapResult.lastDisabledDatetime,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });
      setTimeout(() => {
        setDisabledUsherTableData(mappedUshers);
        setPageInfoDisabledUsher(data.disabledUshers.pageInfo);
        setTotalCountDisabledUsher(data.disabledUshers.totalCount);
      }, 100);
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const refetchData = () => {
    console.log('REFETCH');
    // coordinatorQuery.refetch({
    //   ...coordinatorsQueryVariable,
    // });

    getUshers.refetch({
      ...usherQueryVariable,
    });

    getDisabledUshers.refetch({
      ...disabledUsherQueryVariable,
    });

    setCheckedStates([false]);
    setCheckedDisabledStates([false]);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Active Ushers" value="1" />
              <Tab label="Disabled Ushers" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: '0em' }}>
            <Box>
              <Box
                sx={{
                  backgroundColor: '#87ADD4',
                }}
                p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        color: '#fff',
                      }}
                      variant="h4">
                      {coordinator?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          sx={{
                            width: '100%',
                          }}
                          onClick={() => {
                            const uniqueArray = checkedData.filter((value, index, self) => {
                              if (value.isChecked === true) {
                                return self.indexOf(value) === index;
                              }
                            });
                            setUsherIds(uniqueArray.map((u) => u.usherId));
                            setDeleteOpen(true);
                          }}>
                          {coordinator && !coordinator.isDefaultOperator && (
                            <Box
                              sx={{
                                backgroundColor: '#427FC1',
                                color: '#fff',
                                fontSize: '16px',
                                width: '100%',
                              }}
                              p={1}>
                              Remove Usher
                            </Box>
                          )}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <CoordinatorAsignUsher coordinator={coordinator} refetchData={refetchData} />
                        <CoordinatorUnasignUsher open={deleteOpen} setOpen={setDeleteOpen} refetchData={refetchData} usherIds={usherIds} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Tables */}
              <Box
                sx={{
                  backgroundColor: '#C5D8EC',
                  height: '100%',
                }}
                p={2}>
                <CoordinatorTableUshers
                  checkDataEvent={chackDataEventTable}
                  rows={usherTableData}
                  setUsherQueryVariable={setUsherQueryVariable}
                  usherQueryVariable={usherQueryVariable}
                  totalCount={totalCount}
                  pageInfo={pageInfo}
                  usherEvent={usherEvent}
                  getUsherLoading={getUsherLoading}
                  checkedStates={checkedStates}
                  setCheckedStates={setCheckedStates}
                  coordinator={coordinator}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="2" style={{ padding: '0em' }}>
            <Box>
              <Box
                sx={{
                  backgroundColor: '#87ADD4',
                }}
                p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        color: '#fff',
                      }}
                      variant="h4">
                      {coordinator?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            const uniqueArray = checkedData.filter((value, index, self) => {
                              if (value.isChecked === true) {
                                return self.indexOf(value) === index;
                              }
                            });
                            setUsherIds(uniqueArray.map((u) => u.usherId));
                            setDeleteOpen(true);
                          }}>
                          <Box
                            sx={{
                              backgroundColor: '#427FC1',
                              color: '#fff',
                              fontSize: '16px',
                              width: '100%',
                            }}
                            p={1}>
                            Remove Usher
                          </Box>
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <CoordinatorAsignUsher coordinator={coordinator} refetchData={refetchData} />
                        <CoordinatorUnasignUsher open={deleteOpen} setOpen={setDeleteOpen} refetchData={refetchData} usherIds={usherIds} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Tables */}
              <Box
                sx={{
                  backgroundColor: '#C5D8EC',
                  height: '100%',
                }}
                p={2}>
                <CoordinatorTableDisabledUshers
                  checkDataEvent={chackDataEventTable}
                  rows={disabledUsherTableData}
                  setUsherQueryVariable={setDisabledUsherQueryVariable}
                  usherQueryVariable={disabledUsherQueryVariable}
                  totalCount={totalCountDisabledUsher}
                  pageInfo={pageInfoDisabledUsher}
                  usherEvent={usherEvent}
                  getUsherLoading={getUsherLoading}
                  checkedStates={checkedDisabledStates}
                  setCheckedStates={setCheckedDisabledStates}
                  coordinator={coordinator}
                />
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
