import { useState } from 'react';

import { useEnableDisableGameDrawtimeMutation } from '@/generated/graphql';

// material ui
import { FormControlLabel, Switch, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// interfaces
import type { IGameDrawTime, IDrawTime } from '@/shared/interfaces';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

interface IProps {
  id: string;
  isDisabled: boolean;
  refetch: () => void;
}

export const GameDrawTimeStatus: React.FC<IProps> = ({ id, isDisabled, refetch }) => {
  const [enableDisableGameDrawtime] = useEnableDisableGameDrawtimeMutation();

  const [isDisabledGD, setIsDisabledGD] = useState<boolean | undefined>();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const addSched = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabledGD(!event.target.checked);

    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to edit this drawtime'],
    });
  };

  const confirmSched = () => {
    if (isDisabledGD === true || isDisabledGD === false) {
      enableDisableGameDrawtime({
        variables: {
          input: {
            disable: isDisabledGD,
            id,
          },
        },
        onCompleted(data) {
          refetch();
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Limit edited successfully',
            type: 'success',
          });
          setShowLoading(false);
        },
        onError(error) {
          setSuccessDetails({
            isOpen: true,
            message: 'Someting went wrong',
            type: 'error',
          });
        },
      });
    }
  };

  return (
    <>
      <FormControlLabel
        control={<Switch checked={!isDisabled} onChange={addSched} name="Disabled" />}
        label={isDisabled ? 'Disabled' : 'Enabled'}
      />
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmSched}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
