import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';
import React, { useState } from 'react';

import { AiFillEdit } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// interface
import type { PageInfo } from '@/generated/graphql';
import { ChangePageType } from '@/shared/enums';
import type { IGameResultTableData, IGameResultQueryVariable } from '@/shared/interfaces';
// enums
// graphql

// components
import { EditGameResultModal } from './edit-result-modal';

interface TablePageLabel {
  start: number;
  end: number;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof IGameResultTableData | '';
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'resultCombination',
    numeric: false,
    disablePadding: true,
    label: 'Combination',
  },
  {
    id: 'draw',
    numeric: false,
    disablePadding: true,
    label: 'Draw',
  },
  {
    id: 'drawDate',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IGameResultTableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IGameResultTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ pl: 2 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id === '' ? undefined : createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        GameResults
      </Typography>
      <FormControl sx={{ ml: 2, mb: 2, mt: 0, width: 300 }}>
        <TextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="standard"
        />
      </FormControl>
    </Toolbar>
  );
};

interface GameResultsTableTableProps {
  rows: readonly IGameResultTableData[];
  setGameResultQueryVariable: React.Dispatch<React.SetStateAction<IGameResultQueryVariable>>;
  gameResultQueryVariable: IGameResultQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;
  refetchData: () => void;
}

export function GameResultsTable(props: GameResultsTableTableProps) {
  const { rows, gameResultQueryVariable, setGameResultQueryVariable, totalCount, pageInfo, refetchData } = props;
  const [searchedGameResults, setSearchedGameResults] = useState<IGameResultTableData[]>([]);
  const [searchWord, setSearchWord] = useState('');

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IGameResultTableData>('drawDate');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage] = React.useState(5);

  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: gameResultQueryVariable.first,
  });

  const [isGameResultEditModalOpen, setIsGameResultEditModalOpen] = useState(false);
  const [selectedGameResult, setSelectedGameResult] = useState<IGameResultTableData>({
    id: '',
    resultCombination: '',
    draw: '',
    drawDate: '',
    gameDrawTimeId: '',
    originalResultCombination: '',
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IGameResultTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type IGameResultTableDataKey = keyof IGameResultTableData;
    const searchKeys: IGameResultTableDataKey[] = ['resultCombination', 'draw', 'drawDate'];

    const newGameResults = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedGameResults(newGameResults);
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setGameResultQueryVariable({
        ...gameResultQueryVariable,
        after: pageInfo.endCursor,
        before: undefined,
      });
      let end = pageLabel.end + gameResultQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start + gameResultQueryVariable.first, end });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setGameResultQueryVariable({
        ...gameResultQueryVariable,
        before: pageInfo.startCursor,
        after: undefined,
      });
      let end = pageLabel.end - gameResultQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start - gameResultQueryVariable.first, end });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setGameResultQueryVariable({
      ...gameResultQueryVariable,
      first: intValue,
    });
  };

  const clickEdit = (gameResult: IGameResultTableData) => {
    setIsGameResultEditModalOpen(true);
    setSelectedGameResult(gameResult);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%', mt: 0 }}>
        <Paper sx={{ width: '100%', mb: 2, background: '#C5D8EC' }}>
          <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                {(searchWord.length >= 1 ? searchedGameResults : rows).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ pl: 2 }}>
                        {row.resultCombination}
                      </TableCell>
                      <TableCell align="left">{row.draw}</TableCell>
                      <TableCell align="left">{row.drawDate}</TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="edit" color="primary" onClick={() => clickEdit(row)}>
                          <AiFillEdit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 1, justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
            <Typography variant="body2" mr={4} gutterBottom>
              Rows per page:
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
              <Select
                onChange={(e) => {
                  handleChangeRowsPerPage(e.target.value);
                }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={gameResultQueryVariable.first}
                label="Row">
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" mx={4} gutterBottom>
              {pageLabel.start} - {pageLabel.end} of {totalCount}
            </Typography>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.PREV)}
              disabled={pageInfo?.hasPreviousPage ? false : true}
              size={'small'}
              sx={{ mr: 2 }}
              aria-label="prev">
              <IoIosArrowBack />
            </IconButton>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.NEXT)}
              disabled={pageInfo?.hasNextPage ? false : true}
              size={'small'}
              aria-label="next">
              <IoIosArrowForward />
            </IconButton>
          </Box>
        </Paper>
      </Box>
      <EditGameResultModal
        isGameResultEditModalOpen={isGameResultEditModalOpen}
        setIsGameResultEditModalOpen={setIsGameResultEditModalOpen}
        selectedGameResult={selectedGameResult}
        refetchData={refetchData}
      />
    </>
  );
}
