import { useState } from 'react';

// graphql
import { useDeleteGameDrawTimeScheduleMutation } from '@/generated/graphql';

// material ui
import { Box, Button, IconButton, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// interfaces
import type { IGameDrawTimeSceduleTable } from '@/shared/interfaces';
import { MdDelete } from 'react-icons/md';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface IProps {
  id: string;
  setRefetchGameDrawtimeShedData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteGameDrawtimeSched: React.FC<IProps> = ({ id, setRefetchGameDrawtimeShedData }) => {
  const [deleteGameDrawtimeSched] = useDeleteGameDrawTimeScheduleMutation();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const clickDeleteBtn = () => {
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to delete this schedule'],
    });
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmDelete = () => {
    activateLoading();

    deleteGameDrawtimeSched({
      variables: { id },
      onCompleted(data) {
        setRefetchGameDrawtimeShedData(true);
        setConfirmationModal({
          isOpen: false,
          title: 'Confirm Result',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'Game drawtime schedule deleted successfully',
          type: 'success',
        });
        setShowLoading(false);
      },
    });
  };

  return (
    <>
      <IconButton aria-label="delete" color="error" onClick={clickDeleteBtn}>
        <MdDelete />
      </IconButton>

      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmDelete}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
