import { useState } from 'react';
import { useParams } from 'react-router-dom';

// material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Grid } from '@mui/material';

// components
import { NotificationTable } from './components/notification-table';
import { CreateNotification } from './components/create-notification';

// graphql
import { useNotificationsQuery } from '@/generated/graphql';
import type { PageInfo } from '@/generated/graphql';

// interfaces
import type { INotificationQueryVariable, INotification, INotificationTable } from '@/shared/interfaces';
import { mapGQLNotification } from '@/shared/interfaces';

export function NotificationsPage() {
  const [notificationQueryVariable, setNotificationQueryVariable] = useState<INotificationQueryVariable>({
    first: 25,
  });
  const [notificationsTable, setNotificationsTable] = useState<INotificationTable[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const getNotificationQuery = useNotificationsQuery({
    variables: notificationQueryVariable,
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      const mapResult: INotification[] = data.notificationsAdminQuery.nodes.map((x) => mapGQLNotification(x));

      setTimeout(() => {
        setNotificationsTable(
          mapResult.map((x) => ({
            id: x.id,
            title: x.title,
            subTitle: x.subTitle,
            message: x.message,
            views: x.views,
            createdAt: x.createdAt,
          })),
        );

        setPageInfo(data.notificationsAdminQuery.pageInfo);
        setTotalCount(data.notificationsAdminQuery.totalCount);
      }, 200);
    },
  });

  const refetchData = () => {
    getNotificationQuery.refetch({
      ...notificationQueryVariable,
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#C5D8EC',
          height: '100%',
        }}
        p={2}>
        <Grid justifyContent={'end'} display={'flex'}>
          <Button variant="contained" onClick={() => setCreateModalOpen(true)}>
            Create Notification
          </Button>
        </Grid>
        <NotificationTable
          rows={notificationsTable}
          setNotificationQueryVariable={setNotificationQueryVariable}
          notificationQueryVariable={notificationQueryVariable}
          totalCount={totalCount}
          pageInfo={pageInfo}
          getNotificationLoading={getNotificationQuery.loading}
        />
      </Box>
      <CreateNotification open={createModalOpen} setOpen={setCreateModalOpen} refetchData={refetchData} />
    </>
  );
}
