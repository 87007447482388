import { UsherApplicant } from '@/generated/graphql';

export interface IUsherApplicant {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  contactNumber: string;
  username: string;
  email: string;
  registrationCode: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUsherApplicantQueryVariable {
  first: number;
  after?: string;
  before?: string;
}

export interface IUsherApplicantTableData {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  contactNumber: string;
  username: string;
  email: string;
  registrationCode: string;
}

export interface IConfirmUsherApplicantInput {
  uniqueCode: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  username: string;
  email: string;
  address: string;
}

export function mapGQLUsherApplicant(userApplicant?: UsherApplicant | null): IUsherApplicant {
  return {
    id: userApplicant?.id ?? '',
    firstName: userApplicant ? userApplicant.firstName ?? '' : '',
    lastName: userApplicant ? userApplicant.lastName ?? '' : '',
    address: userApplicant ? userApplicant.address ?? '' : '',
    contactNumber: userApplicant ? userApplicant.contactNumber ?? '' : '',
    username: userApplicant ? userApplicant.username ?? '' : '',
    email: userApplicant ? userApplicant.email ?? '' : '',
    registrationCode: userApplicant ? userApplicant.registrationCode ?? '' : '',
    updatedAt: userApplicant ? userApplicant.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: userApplicant ? userApplicant.createdAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
