import type { UsherType } from '@/generated/graphql';

export interface IUsherType {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUsherTypeQueryVariable {
  first: number;
  before?: string;
  after?: string;
}

export function mapGQLUsherType(usherType?: UsherType | null): IUsherType {
  return {
    id: usherType?.id ?? '',
    name: usherType ? usherType.name ?? '' : '',
    description: usherType ? usherType.description ?? '' : '',
    createdAt: usherType ? usherType.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: usherType ? usherType.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
