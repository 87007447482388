import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';
import { useState, useRef, useEffect } from 'react';

// material ui
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

// graphql
import { useGetTelegramBotUpdatesMutation, useTelegramReportsQuery } from '@/generated/graphql';

// interfaces
import type {
  IGetTelegramBotUpdateInput,
  IGetTelegramBotUpdateResp,
  ITelegramReportQueryVariable,
  ITelegramReportTable,
} from '@/shared/interfaces';
import { mapGQLGetTelegramBotUpdateResp, mapGQLTelegramReport } from '@/shared/interfaces';

// icon
import { MdOutlinePostAdd } from 'react-icons/md';
import { TiGroup } from 'react-icons/ti';
import { BsChatDots } from 'react-icons/bs';
import { IoSearch } from 'react-icons/io5';

// components
import { ConfirmationModal } from './confirmation-modal';
import { SwitchBtn } from './switch-btn';

// services
import { sendMessageToTelegram } from '../../services/telegram-send-message';

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITelegramReport extends ITelegramReportTable {
  isSelected: boolean;
}

// Styled Paper component with scrollable content
const ScrollablePaper = styled(Paper)(({ theme }) => ({
  maxHeight: 500, // Adjust the height as needed
  marginTop: 10,
  marginRight: 10,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    // backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
}));

export const CreateReportDestination = ({ open, setOpen, refetchData }: IUsherProps) => {
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [botUpdates, setBotUpdates] = useState<IGetTelegramBotUpdateResp[]>([]);
  const [manualBotDetails, setManualBotDetails] = useState<IGetTelegramBotUpdateResp | null>(null);
  const [query, setQuery] = useState('idle');
  const [reportTypeQueryVariable, setReportQueryVariable] = useState<ITelegramReportQueryVariable>({
    first: 99999,
  });
  const [reportTypes, setReports] = useState<ITelegramReport[]>([]);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      token: yup.string().required(),

      botName: yup.string().notRequired(),
      botUsername: yup.string().notRequired(),
      groupChatName: yup.string().notRequired(),
      chatId: yup.string().notRequired(),
      topicId: yup.string().notRequired(),
      topicName: yup.string().notRequired(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IGetTelegramBotUpdateInput>({
    resolver: yupResolver(schema),
  });

  const getReports = useTelegramReportsQuery({
    variables: reportTypeQueryVariable,
    onCompleted(data) {
      const mappedData = data.telegramReports.nodes.map((x) => mapGQLTelegramReport(x));

      const mappedTableData: ITelegramReport[] = mappedData.map((x) => ({
        id: x.id,
        name: x.name,
        reportType: x.telegramReportType.name,
        description: x.description,
        createdAt: x.createdAt,
        isSelected: false,
      }));

      setTimeout(() => {
        setReports(mappedTableData);
      }, 100);
    },
  });

  const [getTelegramBotUpdatesMutation] = useGetTelegramBotUpdatesMutation();

  const onSubmit = async (dataInput: IGetTelegramBotUpdateInput) => {
    const { token } = dataInput;

    const input: IGetTelegramBotUpdateInput = {
      token,
    };

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (query === 'success') {
      setQuery('progress');
    } else if (query === 'idle') {
      setQuery('progress');
    } else if (query !== 'idle') {
      setQuery('idle');
      return;
    }

    // setQuery('progress');
    timerRef.current = setTimeout(() => {
      setQuery('success');
    }, 10000);

    console.log('🚀 ~ onSubmit ~ input:', input);

    const { data } = await getTelegramBotUpdatesMutation({
      variables: {
        input,
      },
    });

    if (data) {
      clearTimeout(timerRef.current);
      setQuery('success');

      const mappedData = data.getTelegramBotUpdates.map((x) => mapGQLGetTelegramBotUpdateResp(x));
      setBotUpdates(mappedData);
    }
    if (errors) console.log(errors);
    // refetchData();
    // handleClose();
    // reset();
  };

  const handleChooseReport = (index: number) => {
    setReports((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item)));
  };

  const handleChooseGroupTopic = (index: number) => {
    const lastIndexSelected = botUpdates.findIndex((x) => x.isSelected === true);

    if (lastIndexSelected !== -1) {
      setBotUpdates((prevItems) =>
        prevItems.map((item, i) => (i === lastIndexSelected ? { ...item, isSelected: !item.isSelected } : item)),
      );
    }

    setBotUpdates((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item)));
  };

  const handleCreateReportDestination = () => {
    let allowToContinue = false;

    const { token, botName, botUsername, groupChatName, chatId, topicId, topicName } = getValues();

    if (token.length > 0 && reportTypes.length > 0) {
      if (isManualInput && botName && botUsername && groupChatName && chatId) {
        let topicId2 = '';
        let topicName2 = '';
        let type2 = 'Chat group';

        if (topicId && topicName && topicId?.length > 0 && topicName.length) {
          topicId2 = topicId;
          topicName2 = topicName;
          type2 = 'Topic chat group';
        }

        setManualBotDetails({
          botName,
          botUsername,
          token,
          groupChatName,
          chatId,
          topicId: topicId2,
          topicName: topicName2,
          type: type2,
          isSelected: true,
        });
        allowToContinue = true;
      } else if (!isManualInput && botUpdates.find((x) => x.isSelected === true)) {
        allowToContinue = true;
      }

      if (allowToContinue) {
        handleClose();
        setTimeout(() => {
          setModalConfirmation(true);
        }, 200);
      }
    }
  };

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  const clickSendMessage = (data?: IGetTelegramBotUpdateResp) => {
    const { token, chatId, topicId } = getValues();

    if (data) {
      sendMessageToTelegram(data.token, data.chatId, data.topicId);
    } else if (!data && token && chatId) {
      sendMessageToTelegram(token, chatId, topicId);
    }
  };

  return (
    <>
      <ConfirmationModal
        refetchData={refetchData}
        open={modalConfirmation}
        setOpen={setModalConfirmation}
        setOpenCreate={setOpen}
        botUpdate={isManualInput && manualBotDetails ? manualBotDetails : botUpdates.find((x) => x.isSelected === true)}
        reportTypes={reportTypes.filter((x) => x.isSelected === true).map((y) => y)}
        setReports={setReports}
        setBotUpdates={setBotUpdates}
      />
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'inherit',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
          },
          content: {
            width: '880px',
            overflow: 'unset',
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            overflow: 'unset',
            borderRadius: '5px',
          }}
          mt={9}>
          <Box sx={{ px: 5, py: 2 }} alignItems={'center'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="subtitle2" m={0}>
                Create Report Destination
              </Typography>
              <MdOutlinePostAdd fontSize={18} />
            </Stack>
          </Box>
          <Divider />

          <Grid container>
            <Grid item md={6}>
              {!isManualInput && (
                <Box px={5} pt={2} pb={2}>
                  <Typography variant="caption" display="block" gutterBottom sx={{ fontWeight: 600 }}>
                    Retrieve the Chat ID
                  </Typography>

                  <Typography variant="caption" display="block" gutterBottom>
                    To ensure the bot is active and able to interact with the group, send a message in the group. This will help in fetching
                    the updates.
                  </Typography>

                  <Typography variant="caption" display="block" gutterBottom>
                    message:
                  </Typography>

                  <Typography variant="caption" display="block" gutterBottom>
                    /start @bot_username
                  </Typography>
                </Box>
              )}

              <Stack direction="row" justifyContent="end" alignItems="center">
                <SwitchBtn isManualInput={isManualInput} setIsManualInput={setIsManualInput} />
              </Stack>

              <Box px={5} pt={2} pb={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="token-search" shrink>
                        Token
                      </InputLabel>
                      <Input
                        id="token-search"
                        sx={{ fontSize: 11 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton type="submit">
                              <IoSearch />
                            </IconButton>
                          </InputAdornment>
                        }
                        {...register('token')}
                      />
                    </FormControl>
                    {isManualInput && (
                      <>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="botName" shrink>
                            Bot Name
                          </InputLabel>
                          <Input id="botName" sx={{ fontSize: 11 }} {...register('botName')} />
                        </FormControl>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="botUsername" shrink>
                            Bot Username
                          </InputLabel>
                          <Input id="botUsername" sx={{ fontSize: 11 }} {...register('botUsername')} />
                        </FormControl>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="groupChatName" shrink>
                            Group Chat Name
                          </InputLabel>
                          <Input id="groupChatName" sx={{ fontSize: 11 }} {...register('groupChatName')} />
                        </FormControl>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="chatId" shrink>
                            Chat ID
                          </InputLabel>
                          <Input id="chatId" sx={{ fontSize: 11 }} {...register('chatId')} />
                        </FormControl>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="topicId" shrink>
                            Topic ID
                          </InputLabel>
                          <Input id="topicId" sx={{ fontSize: 11 }} {...register('topicId')} />
                        </FormControl>
                        <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                          <InputLabel htmlFor="topicName" shrink>
                            Topic Name
                          </InputLabel>
                          <Input id="topicName" sx={{ fontSize: 11 }} {...register('topicName')} />
                        </FormControl>
                        <Stack direction={'row'} justifyContent={'end'}>
                          <Button
                            onClick={() => clickSendMessage()}
                            sx={{
                              ml: 1,
                              mt: 2,
                              fontSize: 10,
                              fontWeight: 600,
                              paddingLeft: '20px',
                              paddingRight: '20px',
                              paddingTop: '8px',
                              paddingBottom: '8px',
                              textTransform: 'unset',
                            }}
                            size="small"
                            variant="contained">
                            Send message
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Grid>
                </form>

                {!isManualInput && (
                  <>
                    {query === 'success' && (
                      <Typography variant="overline" display="block" mt={2}>
                        Choose update
                      </Typography>
                    )}

                    {query === 'success' ? (
                      botUpdates.map((update, index) => (
                        <Card
                          onClick={() => handleChooseGroupTopic(index)}
                          sx={{
                            backgroundColor: update.isSelected ? '#A5D6A7' : '#ffff',
                            minWidth: 275,
                            mt: 2,
                            py: 1,
                            px: 2,
                            cursor: 'pointer',
                            transition: '0.2s',
                            '&:hover': {
                              backgroundColor: '#d7d1d1', // Background color on hover
                              transform: 'scale(1.05)',
                              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                          }}>
                          <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'}>
                              <TiGroup fontSize={14} />
                              <Typography sx={{ fontSize: 12, fontWeight: 700 }} style={{ marginLeft: '3px' }} color="black">
                                {update.groupChatName}
                              </Typography>
                            </Stack>
                            {update.type === 'Topic chat group' && (
                              <Stack direction={'row'} alignItems={'center'}>
                                <BsChatDots fontSize={14} />
                                <Typography sx={{ fontSize: 12, fontWeight: 700 }} style={{ marginLeft: '3px' }} color="black">
                                  {update.topicName}
                                </Typography>
                              </Stack>
                            )}
                          </Stack>

                          <Stack justifyContent={'space-between'} direction={'row'} mt={1}>
                            <Typography sx={{ fontSize: 10 }}>Chat ID: {update.chatId}</Typography>
                            {update.type === 'Topic chat group' && (
                              <Typography sx={{ fontSize: 10, ml: 1 }}>Topic ID: {update.topicId}</Typography>
                            )}
                          </Stack>
                          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mt={1}>
                            <Typography sx={{ fontSize: 10 }}>Bot: {update.botName}</Typography>
                            <Stack direction={'row'} alignItems={'center'}>
                              <Typography sx={{ fontSize: 10, ml: 2 }}>Username: </Typography>
                              <Link sx={{ fontSize: 10 }}>@{update.botUsername}</Link>
                            </Stack>
                            <Chip
                              label="send messge"
                              size="small"
                              color="primary"
                              sx={{ fontSize: 10 }}
                              onClick={() => clickSendMessage(update)}
                            />
                          </Stack>
                        </Card>
                      ))
                    ) : (
                      <Fade
                        in={query === 'progress'}
                        style={{
                          transitionDelay: query === 'progress' ? '800ms' : '0ms',
                        }}
                        unmountOnExit>
                        <Stack direction={'row'} justifyContent={'center'}>
                          <CircularProgress sx={{ mt: 2 }} />
                        </Stack>
                      </Fade>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography variant="overline" display="block" mt={2}>
                Choose report
              </Typography>
              <ScrollablePaper>
                <List>
                  {reportTypes.length === 0 ? (
                    <ListItem disablePadding>
                      <ListItemText primary="No items available" />
                    </ListItem>
                  ) : (
                    reportTypes.map((reportType, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onClick={() => handleChooseReport(index)}
                            // onChange={handleToggle(value)}
                            checked={reportType.isSelected}
                            // inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }>
                        <Typography variant="caption" display="block">
                          {reportType.name}
                        </Typography>
                      </ListItem>
                    ))
                  )}
                </List>
              </ScrollablePaper>
            </Grid>

            <Grid container justifyContent={'end'} mb={2} mr={2}>
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{
                  background: 'grey',
                  fontSize: 10,
                  fontWeight: 600,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  textTransform: 'unset',
                }}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleCreateReportDestination}
                sx={{
                  ml: 1,
                  fontSize: 10,
                  fontWeight: 600,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  textTransform: 'unset',
                }}
                size="small"
                variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
