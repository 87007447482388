import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

//Component
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

import { useGameDashboardCtx } from '@/layouts/components/sidebar';

//graphql
import type { CreateGameDrawtimeLimitInput } from '@/generated/graphql';
import { useLimitCreateGameDrawtimeLimitMutation } from '@/generated/graphql';

// interfaces
import type { IGameDrawTime } from '@/shared/interfaces';

// material ui
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export interface IIndividualLimitInput {
  individualLimit: string | number;
  startDate: string;
  endDate: string;
}
interface IlimitsTableProps {
  refetchData: () => void;
  gameDrawTimes: IGameDrawTime[];
}

export const CreateGameDrawtimeLimit: React.FC<IlimitsTableProps> = ({ refetchData, gameDrawTimes }) => {
  //States
  const [isCompressed, setIsCompressed] = useState(false);

  const { gameCtx } = useGameDashboardCtx();

  const [selectedGDTId, setSelectedGDTId] = useState<string>();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  // mutation
  const [createGameDrawtimeLimit] = useLimitCreateGameDrawtimeLimitMutation();

  const [limitInput, setLimitInput] = useState<IIndividualLimitInput>({
    individualLimit: '',
    startDate: '',
    endDate: '',
  });

  //onSubmit Function
  const onSubmitForm = async () => {
    if (limitInput && limitInput.individualLimit && selectedGDTId) {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: ['You want to add this limit'],
      });
    }
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmAdd = () => {
    const { startDate, endDate, individualLimit } = limitInput;

    activateLoading();

    const input: CreateGameDrawtimeLimitInput = {
      gameDrawtimeId: selectedGDTId ?? '',
      startDate,
      endDate,
      limit: parseInt(individualLimit.toString()),
    };
    console.log(input);

    createGameDrawtimeLimit({
      variables: { input },
      onCompleted(data) {
        // update limitAmount to empty string
        setLimitInput({
          individualLimit: '',
          startDate: '',
          endDate: '',
        });
        setSelectedGDTId(undefined);
        refetchData();
        setConfirmationModal({
          isOpen: false,
          title: 'Confirm Result',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'Limit added successfully',
          type: 'success',
        });
        setShowLoading(false);
      },
    });
  };

  const handleLimitAmountChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (numValue.length > 0 || numValue === '') {
      setLimitInput({ ...limitInput, individualLimit: numValue === '' ? '' : parseInt(numValue) });
    }
  };

  useEffect(() => {
    if (gameCtx.gameType.gameConfig.combinationLimit * gameCtx.gameType.gameConfig.placeValue > 6) {
      setIsCompressed(true);
    } else {
      setIsCompressed(false);
    }
  }, [gameCtx]);

  return (
    <>
      <div className="createindividuallimit">
        <p className="createindividuallimit-headertext" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          Create Individual Limits
        </p>
        <div className="createindividuallimit-wrapper" style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
          <div className="createindividuallimit-column1">
            <div
              className="createindividuallimit-content1"
              style={{ display: 'grid', gridTemplateColumns: isCompressed ? '2fr 1fr' : '1fr 1fr' }}>
              <div className="createindividuallimit-combination">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">DrawTime</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="game-drawtime-limit-dt-select"
                    // value={null}
                    // onChange={}
                    label="DrawTime"
                    className="bg-white"
                    size="small"
                    onChange={(e) => {
                      setSelectedGDTId(e.target.value);
                    }}
                    value={selectedGDTId ? selectedGDTId : ''}>
                    {gameDrawTimes.map((gameDrawTime) => (
                      <MenuItem value={gameDrawTime.id}>{gameDrawTime.drawTime.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="createindividuallimit-limit">
                <TextField
                  label="Limit"
                  variant="outlined"
                  value={limitInput?.individualLimit}
                  onChange={(e) => {
                    handleLimitAmountChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="createindividuallimit-content2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <TextField
                onChange={(e) => setLimitInput({ ...limitInput, startDate: e.target.value })}
                id="date"
                label="Start date"
                type="date"
                size="small"
                value={limitInput?.startDate}
                sx={{ width: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                onChange={(e) => setLimitInput({ ...limitInput, endDate: e.target.value })}
                id="date"
                label="End date"
                type="date"
                size="small"
                value={limitInput?.endDate}
                sx={{ width: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          {/* column-1 */}
          <div className="createindividuallimit-column2">
            <button onClick={onSubmitForm} className="createindividuallimit-submit">
              {' '}
              Set Limit{' '}
            </button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
