import { useState, useEffect } from 'react';
import { NavLink, Outlet, useOutletContext, useNavigate } from 'react-router-dom';
import moment from 'moment';

// material ui
import { GiChart, GiHamburgerMenu, GiStack, GiThreeBurningBalls } from 'react-icons/gi';
import { MdDashboard } from 'react-icons/md';
import { FaGamepad, FaUsers } from 'react-icons/fa';
import { BsArrowsCollapse } from 'react-icons/bs';
import { ImStatsBars } from 'react-icons/im';
import { AiFillSetting } from 'react-icons/ai';
import { IconType } from 'react-icons';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { FaTelegramPlane } from 'react-icons/fa';

// components
import { GameTypeDashboardLayout } from '../../components/gametype-carousel/gametype-carousel';

import { IGame, mapGQLGame } from '@/shared/interfaces';
import { Clock, ClockVariantTypes } from '@/components/clock/clock';
import { Box } from '@mui/material';
interface ISidebarLink {
  title: string;
  icon: IconType;
  path: string;
}

interface ISidebar {
  headings: string;
  tabcontent: ISidebarLink[];
}

type GameContextType = { gameCtx: IGame };

export const Sidebar: React.FC = () => {
  const routepath = location.pathname.split('/');
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [gameCtx, setGameCtx] = useState<IGame>(mapGQLGame());

  const [today, setToday] = useState(moment().format('Do MMM YY |[\n] hh:mm:ss A'));

  const gameTypeDashboardTabContents: ISidebarLink[] = [
    {
      title: 'Bets',
      icon: GiStack,
      path: 'bets',
    },
    {
      title: 'Results',
      icon: GiThreeBurningBalls,
      path: 'game-results',
    },
    {
      title: 'Limits',
      icon: BsArrowsCollapse,
      path: 'game-limits',
    },
    {
      title: 'Operation Stats',
      icon: ImStatsBars,
      path: 'operation-stats',
    },
    {
      title: 'Reports',
      icon: GiChart,
      path: 'reports',
    },
    {
      title: 'Operators Report',
      icon: GiChart,
      path: 'operators-report',
    },
    {
      title: 'Server Config',
      icon: AiFillSetting,
      path: 'server-config',
    },
  ];

  const sidebardata: ISidebar[] = [
    {
      headings: 'Management Dashboards',
      tabcontent: [
        {
          title: 'Overview',
          icon: MdDashboard,
          path: 'overview',
        },
        {
          title: 'Usher',
          icon: FaUsers,
          path: 'usher',
        },
        {
          title: 'Operator',
          icon: FaUsers,
          path: 'coordinators',
        },
        {
          title: 'Draw Admin',
          icon: FaUsers,
          path: 'draw-admins',
        },
      ],
    },
    {
      headings: 'Game-Type Dashboard',
      tabcontent: gameTypeDashboardTabContents,
    },
    {
      headings: 'Admin Dashboards',
      tabcontent: [
        {
          title: 'Games',
          icon: FaGamepad,
          path: 'game-types',
        },
        {
          title: 'Notifications',
          icon: IoMdNotificationsOutline,
          path: 'notifications',
        },
        {
          title: 'Telegram Report',
          icon: FaTelegramPlane,
          path: 'telegram-report',
        },
      ],
    },
  ];

  // useEffect(() => {
  //   setInterval(() => setToday(moment().format('Do MMM YY |[\n] hh:mm:ss A')), 1000);
  // }, [today]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 600) {
        setIsCollapsed(true);
      }
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      console.log('InnerWidthTest', getWindowSize().innerWidth);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      navigate('/dashboard/game-types');
    }
  });

  return (
    <>
      <div className="content-container">
        <div
          className="sidebarnew"
          // style={{
          //   width: isCollapsed ? ' calc(100vw / 18)' : 'calc(100vw / 6)',
          // }}>
        >
          <div className="sidebar-responsive">
            <div className="sidebar-collapse" style={{ display: isCollapsed ? 'initial' : 'grid' }}>
              <Clock variant={ClockVariantTypes.FULL} isCollapsed={isCollapsed} />
              <div
                className="collapse-button"
                onClick={() => setIsCollapsed((prevValue) => !prevValue)}
                style={{
                  paddingBottom: isCollapsed ? '0.5em' : '0',
                  paddingTop: isCollapsed ? '0.5em' : '0',
                }}>
                <GiHamburgerMenu color="#DADADA" size="2.8rem" />
              </div>
            </div>
            <ul className="sidebar-link-list" style={{ display: isCollapsed ? 'none' : 'initial', paddingTop: '2em' }}>
              {sidebardata.map((OuterItem, index) => (
                <div key={index}>
                  <li className="sidebar-headings">
                    <span className="hide-menu">{OuterItem.headings}</span>
                  </li>
                  {OuterItem.tabcontent.map((innerElement, index2) => (
                    <li key={index2} className={`sidebar-item sub-item ${routepath[2] === innerElement.path ? 'active' : ''}`}>
                      <NavLink to={innerElement.path} className="sidebar-link">
                        <innerElement.icon className="icon mr-1" />
                        <span className="hide-menu">{innerElement.title}</span>
                      </NavLink>
                    </li>
                  ))}
                </div>
              ))}
              {/* map */}
            </ul>
          </div>
        </div>
        {/* Sidebar */}
        {/* <div
          className="page-content"
          style={{
            paddingLeft: isCollapsed ? ' 2.5vw' : '11.5vw',
          }}> */}
        <Box
          className="page-content"
          p={1}
          sx={{
            paddingLeft: isCollapsed ? ' 6vw' : '11.5vw',
          }}>
          {gameTypeDashboardTabContents.filter((item) => item.path === routepath[2] && item.path != '').length > 0 && (
            <GameTypeDashboardLayout gameCtx={gameCtx} setGameCtx={setGameCtx} />
          )}
          {routepath.includes('usher-bets') && <GameTypeDashboardLayout gameCtx={gameCtx} setGameCtx={setGameCtx} />}

          <Outlet context={{ gameCtx }} />
        </Box>
      </div>
    </>
  );
};

export function useGameDashboardCtx() {
  return useOutletContext<GameContextType>();
}
