import type {
  Usher,
  UsherSalesReport,
  UsherSalesReportFilterField,
  GameDrawTimeSales,
  UsherGameSales,
  UshersFilterField,
  DisabledUshersFilterField,
  InactiveUshersFilterField,
} from '@/generated/graphql';
import { UsherTransactionAccessEnum } from '@/generated/graphql';

import { mapGQLBet } from './bet';
import { mapGQLUsherType } from './usher-type';

import type { IBet } from './bet';

import type { IUsherType } from './usher-type';
import { IUser, mapGQLUser } from './user';

import { mapGQLCoordinator, ICoordinator } from './coordinator';

export interface IUsher {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  contactNumber: string;
  createdAt: string;
  updatedAt: string;
  usherType: IUsherType;
  uniqueCode: string;
  transactionAccess: UsherTransactionAccessEnum;
  showUsherNameOnReceipt: boolean;
  immuneToDisable: boolean;
  user: IUser;
  DTlastTransaction: string;
  lastDisabledDatetime: string;
  coordinator: ICoordinator;
}

export interface IUsherSalesReport {
  usher: IUsher;
  hits: number;
  totalSales: number;
  totalBets: number;
  totalWinner: number;
  payableWinner: number;
  date: string;
  usherGameSales: IUsherGameSales[];
  winnerBets: IBet[];
  user: IUser;
}

export function mapGQLUsherSalesReport(usher?: UsherSalesReport | null): IUsherSalesReport {
  return {
    usher: mapGQLUsher(usher?.usher),
    hits: usher?.hits ?? 0,
    totalSales: usher ? usher.totalSales ?? 0 : 0,
    totalBets: usher ? usher.totalBets ?? 0 : 0,
    totalWinner: usher ? usher.totalWinner ?? 0 : 0,
    payableWinner: usher ? usher.payableWinner ?? 0 : 0,
    date: usher ? usher.date ?? new Date().toISOString() : new Date().toISOString(),
    usherGameSales: usher?.usherGameSales ? usher.usherGameSales.map((usherGameSale) => mapGQLUsherGameSales(usherGameSale)) : [],
    winnerBets: usher?.winnerBets ? usher.winnerBets.map((bet) => mapGQLBet(bet)) : [],
    user: mapGQLUser(usher?.usher.user),
  };
}

export interface IUsherGameSales {
  name: string;
  gameDrawTimeSales: IGameDrawTimeSales[];
}

function mapGQLUsherGameSales(usherGameSales: UsherGameSales | null): IUsherGameSales {
  return {
    name: usherGameSales ? usherGameSales.name : '',
    gameDrawTimeSales: usherGameSales?.gameDrawTimeSales
      ? usherGameSales.gameDrawTimeSales?.map((gameDrawTimeSale) => mapGQLGameDrawTimeSales(gameDrawTimeSale))
      : [],
  };
}

export interface IGameDrawTimeSales {
  drawName: string;
  sales: number;
  hits: number;
  payableWinner: number;
}

function mapGQLGameDrawTimeSales(gameDrawTimeSale?: GameDrawTimeSales | null): IGameDrawTimeSales {
  return {
    drawName: gameDrawTimeSale ? gameDrawTimeSale.drawName : '',
    sales: gameDrawTimeSale?.sales ? gameDrawTimeSale.sales : 0,
    hits: gameDrawTimeSale?.hits ? gameDrawTimeSale.hits : 0,
    payableWinner: gameDrawTimeSale?.payableWinner ? gameDrawTimeSale.payableWinner : 0,
  };
}
export interface IUsherReportQueryVariable {
  filterBy?: UsherSalesReportFilterField;
  first: number;
  after?: string;
  before?: string;
}

export interface IUsherQueryVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: UshersFilterField;
}

export interface IDisabledUsherQueryVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: DisabledUshersFilterField;
}

export interface IInactiveUshserQueryVariable {
  filterBy: InactiveUshersFilterField;
}

export interface IUsherTable {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  usherType: string;
  uniqueCode: string;
  address: string;
  username: string;
  email: string;
  isDisabled: boolean;
  immuneToDisable: boolean;

  createdAt: string;
  DTlastTransaction: string;
  coordinator: ICoordinator;
  transactionAccess: UsherTransactionAccessEnum;
  showUsherNameOnReceipt: boolean;
}

export interface IDisabledUsherTable {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  usherType: string;
  uniqueCode: string;
  address: string;
  username: string;
  email: string;
  isDisabled: boolean;
  createdAt: string;
  DTlastTransaction: string;
  lastDisabledDatetime: string;
  transactionAccess: UsherTransactionAccessEnum;
  coordinator: ICoordinator;
  showUsherNameOnReceipt: boolean;
  immuneToDisable: boolean;
}

export interface IUsherSelect {
  id: string;
  firstName: string;
}

export interface IAddUsherInput {
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  contactNumber: string;
  username: string;
  password: string;
  coordinatorId: string;
  uniqueCode: string;
}

export interface IUpdateUsherInput {
  id: string;
  firstName?: string;
  lastName?: string;
  contactNumber?: string;
  username?: string;
  email?: string;
  address?: string;
  uniqueCode?: string;
  coordinatorId?: string;
}

export interface IUpdateUsherPassword {
  newPassword: string;
  confirmPassword: string;
}

export interface IUpdateTransactionAccessInput {
  id: string;
  transactionAccess: UsherTransactionAccessEnum;
}

export function mapGQLUsher(usher?: Usher | null): IUsher {
  let showUsherNameOnReceipt = true;
  if (usher?.showUsherNameOnReceipt === false) {
    showUsherNameOnReceipt = false;
  }
  return {
    id: usher?.id ?? '',
    firstName: usher ? usher.firstName ?? '' : '',
    lastName: usher ? usher.lastName ?? '' : '',
    address: usher ? usher.address ?? '' : '',
    contactNumber: usher ? usher.contactNumber ?? '' : 'No Contact Number',
    uniqueCode: usher ? usher.uniqueCode ?? '' : '',
    coordinator: mapGQLCoordinator(usher?.coordinator),
    transactionAccess: usher ? usher.transactionAccess ?? UsherTransactionAccessEnum.AppAccess : UsherTransactionAccessEnum.AppAccess,
    showUsherNameOnReceipt,
    immuneToDisable: usher ? usher.immuneToDisable ?? false : false,

    // createdAt: usher ? usher.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: usher ? usher.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    usherType: mapGQLUsherType(usher?.usherType),
    user: mapGQLUser(usher?.user),
    createdAt: usher
      ? new Date(usher.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    DTlastTransaction:
      usher && usher.DTlastTransaction
        ? new Date(usher.DTlastTransaction).toLocaleString('en-US', {
            timeZone: 'Asia/Manila',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour12: true,
            minute: '2-digit',
            hour: '2-digit',
          }) ?? ''
        : '',
    lastDisabledDatetime:
      usher && usher.user && usher.user.lastDisabledDatetime
        ? new Date(usher.user.lastDisabledDatetime).toLocaleString('en-US', {
            timeZone: 'Asia/Manila',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour12: true,
            minute: '2-digit',
            hour: '2-digit',
          }) ?? ''
        : '',
  };
}
