import { ITransaction, mapGQLTransaction } from './transaction';
import type { BetTransaction } from '@/generated/graphql';
import { IBet, mapGQLBet } from './bet';

export interface IBetTransaction {
  id: string;
  createdAt: string;
  updatedAt: string;

  transaction: ITransaction;
  bet: IBet;
}

export function mapGQLBetTransaction(betTransaction?: BetTransaction | null): IBetTransaction {
  return {
    id: betTransaction?.id ?? '',
    createdAt: betTransaction ? betTransaction.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: betTransaction ? betTransaction.updatedAt ?? new Date().toISOString() : new Date().toISOString(),

    transaction: mapGQLTransaction(betTransaction?.transaction),
    bet: mapGQLBet(betTransaction?.bet),
  };
}
