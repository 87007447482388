import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';

// graphql
import { PageInfo } from '@/generated/graphql';

// material ui
import { Grid, Button, Skeleton } from '@mui/material';

// interface
import { IBet } from '@/shared/interfaces';

// components
import { TableComponent, Data, HeadCell } from './table';

// services
import { winnerBetsExportToPDF, generateDataForCSVExport } from '../services';

interface Props {
  winnerBets: IBet[];
  USRpageInfo: PageInfo | undefined;
  resetData: boolean;
  dateRangeLabel: string;
  USRtotalCount: number;
}

export const WinnerBetsTab: React.FC<Props> = ({ winnerBets, USRpageInfo, resetData, dateRangeLabel, USRtotalCount }) => {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

  const [headCells, setHeadCells] = useState<HeadCell[]>([
    {
      id: 'createdAt',
      label: 'Date',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'agent',
      label: 'Agent',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'gameType',
      label: 'Game Type',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'draw',
      label: 'Draw',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'numberCombination',
      label: 'No.',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'transactionCode',
      label: 'Transaction Code',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'betType',
      label: 'Type',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'amount',
      label: 'Amount',
      numeric: false,
      tbCellAlign: 'right',
      hide: false,
    },
    {
      id: 'payable',
      label: 'Payable',
      numeric: false,
      tbCellAlign: 'right',
      hide: false,
    },
  ]);

  const csvLink: any = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const [tableData, setTableData] = useState<Data[]>([]);

  const [CSVData, setCSVData] = useState<any[]>([]);

  // this will watch USRpageInfo change to check if all data is arrived
  useEffect(() => {
    if (USRpageInfo && !USRpageInfo.hasNextPage) {
      setTimeout(() => {
        const ushersSalesReportTableData = winnerBets.map((winnerBet) => {
          const { gameDrawTime, betDetail, transaction, numberCombination, amount, winAmount, createdAt } = winnerBet;

          const dynamicObject: Data = {
            agent: `${transaction.usher.firstName} ${transaction.usher.lastName}`,
            gameType: gameDrawTime.game.gameType.name,
            draw: gameDrawTime.drawTime.name,
            numberCombination: numberCombination.replace(/,/g, ''),
            transactionCode: transaction.transactionCode,
            amount: amount.toFixed(2),
            payable: winAmount.toFixed(2),
            createdAt: createdAt.replace(/,/g, ''),
            betType: betDetail.betType.name,
          };

          return dynamicObject;
        });
        console.log(ushersSalesReportTableData);
        setTableData(ushersSalesReportTableData);
        setCSVData(generateDataForCSVExport(ushersSalesReportTableData, headCells, 'STL Winner Bets Report'));
      }, 10);
    }
  }, [USRpageInfo]);

  useEffect(() => {
    if (resetData) {
      setTableData([]);
    }
  }, [resetData]);

  const clickExportToCSV = async () => {
    csvLink.current.link.click();
  };

  return (
    <>
      <CSVLink data={CSVData} filename={`stl-winner-bets-report-${date}.csv`} className="hidden" ref={csvLink} target="_blank" />
      <Grid container mb={3} alignItems={'center'} display={'flex'} justifyContent={'end'}>
        {USRpageInfo && !USRpageInfo.hasNextPage ? (
          <>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              onClick={() => {
                winnerBetsExportToPDF(tableData, headCells, dateRangeLabel);
              }}>
              Export to PDF
            </Button>
            <Button onClick={clickExportToCSV} variant="contained" color="success" sx={{ ml: 2 }}>
              Export to CSV
            </Button>
          </>
        ) : (
          <>
            <Skeleton variant="rounded" width={150} height={40} />
            <Skeleton variant="rounded" width={150} height={40} sx={{ ml: 2 }} />
          </>
        )}
      </Grid>
      <TableComponent rows={tableData} headCells={headCells}></TableComponent>
    </>
  );
};
