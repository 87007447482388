import { useEffect } from 'react';

// material ui
import { Button, Grid, TextField } from '@mui/material';

// interface
import { ICoordinatorReportQueryVariable } from '@/shared/interfaces';

export interface IDataFilter {
  dateFrom: string;
  dateTo: string;
}

interface Props {
  dataFilter: IDataFilter;
  setDataFilter: React.Dispatch<React.SetStateAction<IDataFilter>>;
  setCSRQuerySkipQuery: React.Dispatch<React.SetStateAction<boolean>>;
  CSRQueryVariable?: ICoordinatorReportQueryVariable;
  setCSRQueryVariable: React.Dispatch<React.SetStateAction<ICoordinatorReportQueryVariable | undefined>>;
  setResetData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataFilter: React.FC<Props> = ({
  dataFilter,
  setDataFilter,
  setCSRQuerySkipQuery,
  CSRQueryVariable,
  setCSRQueryVariable,
  setResetData,
}) => {
  const todayDateStr = new Date().toLocaleString('en-US', { timeZone: 'Asia/Manila' });

  const dateArr = todayDateStr.split(',')[0].split('/');
  const dateStr = `${dateArr[2]}-${dateArr[0].length <= 1 ? `0${dateArr[0]}` : dateArr[0]}-${
    dateArr[1].length <= 1 ? `0${dateArr[1]}` : dateArr[1]
  }`;

  const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();

  useEffect(() => {
    setDataFilter({
      dateFrom: dateStr,
      dateTo: dateStr,
    });
    setCSRQueryVariable({
      ...CSRQueryVariable,
      filterBy: { ...CSRQueryVariable?.filterBy, dateFrom: toUTC(dateStr, '12:00:00 AM'), dateTo: toUTC(dateStr, '11:59:59 PM') },
    });
    setTimeout(() => {
      setCSRQuerySkipQuery(false);
    }, 70);
  }, []);

  const filter = () => {
    const dateFrom = toUTC(dataFilter.dateFrom, '12:00:00 AM');
    const dateTo = toUTC(dataFilter.dateTo, '11:59:59 PM');
    if (CSRQueryVariable?.filterBy?.dateFrom !== dateFrom || CSRQueryVariable?.filterBy?.dateFrom !== dateFrom) {
      setResetData(true);
      setCSRQueryVariable({
        ...CSRQueryVariable,
        filterBy: {
          ...CSRQueryVariable?.filterBy,
          dateFrom,
          dateTo,
        },
      });
    }
  };

  return (
    <>
      <Grid item md={5} justifyContent={'start'} display={'flex'}>
        <TextField
          onChange={(e) => setDataFilter({ ...dataFilter, dateFrom: e.target.value })}
          id="date"
          label="Start Date"
          type="date"
          size="small"
          value={dataFilter?.dateFrom}
          sx={{ ml: 3 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          onChange={(e) => setDataFilter({ ...dataFilter, dateTo: e.target.value })}
          id="date"
          label="End Date"
          type="date"
          size="small"
          value={dataFilter?.dateTo}
          sx={{ mx: 3 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" size="medium" type="submit" onClick={filter}>
          Filter
        </Button>
      </Grid>
    </>
  );
};
