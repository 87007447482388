import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Input,
} from '@mui/material';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { alpha, styled } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';

import React, { SetStateAction, useEffect, useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineSearch } from 'react-icons/ai';
import { IGameDrawTimeSceduleTable, IGameDrawTimeSceduleVariable } from '@/shared/interfaces';
import { PageInfo } from '@/generated/graphql';
import { RandomKeyString } from '../../services';
import { ChangePageType } from '@/shared/enums';
import { TableBodySkeleton } from '@/components/table/table-body-skeleton';

// components
import { DeleteGameDrawtimeSched } from './delete-game-drawtime-sched';

type Order = 'asc' | 'desc';

interface TablePageLabel {
  start: number;
  end: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IGameDrawTimeSceduleTable) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IGameDrawTimeSceduleTable | '';
  label: string;
  numeric: boolean;
  className: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'drawTimeName',
    numeric: false,
    disablePadding: true,
    label: 'Draw Time Name',
    className: 'draw-time-name-column',
  },
  {
    id: 'startDateTime',
    numeric: false,
    disablePadding: true,
    label: 'Changes Apply On',
    className: 'start-date-time-column',
  },
  {
    id: 'endDateTime',
    numeric: false,
    disablePadding: true,
    label: 'Changes Revert Back On',
    className: 'end-date-time-column',
  },
  {
    id: 'drawTime',
    numeric: false,
    disablePadding: true,
    label: 'Draw Time',
    className: 'draw-time-column',
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start Time',
    className: 'start-time-column',
  },
  {
    id: 'cutOffTime',
    numeric: false,
    disablePadding: true,
    label: 'Cut Off Time',
    className: 'cut-off-time-column',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
    className: 'cut-off-time-column',
  },
];

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledSelect = styled(Select)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans,Roboto',
    fontWeight: 600,
    fontSize: 15,
    pt: 15,
    pb: 15,
    pl: 15,
  },
  '& .MuiTableCell-hover	': {
    backgroundColor: '#C2DAF0',
    border: '0.08em solid #C2DAF0',
  },
  // '&:nth-of-type(odd)': {
  //   backgroundColor: '#E8F0F7',
  //   fontFamily: 'Poppins',
  // },
  // '&:nth-of-type(even)': {
  //   backgroundColor: '#E0EAF5',
  //   fontFamily: 'Poppins',
  // },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IGameDrawTimeSceduleTable) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={RandomKeyString()}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
            className={headCell.className}
            sx={{ pl: 2, py: 1 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id === '' ? undefined : createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={RandomKeyString()} align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  const [searchWord, setSearchWord] = useState<string>();

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Scheduled Draw Time Changes
      </Typography>

      {/* <FormControl sx={{ m: 1, width: '30ch' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-search">Search</InputLabel>
        <Input
          id="standard-adornment-search"
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
          onKeyDown={(event) => event.key === 'Enter' && searchHandler(searchWord ? searchWord : '')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => searchHandler(searchWord ? searchWord : '')}
              >
                <AiOutlineSearch />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl> */}

      {/* <FormControl sx={{ ml: 2, mb: 2, mt: 1, width: 300 }}>
          <StyledTextField
            onChange={(e) => {
              searchHandler(e.target.value);
            }}
            id="filled-search"
            label="Search field"
            type="search"
            variant="outlined"
            size="small"
          />
        </FormControl> */}
    </Toolbar>
  );
};

interface lGameDrawTimeScheduleProps {
  rows: readonly IGameDrawTimeSceduleTable[];
  setGameDrawTimeScheduleQueryVariable: React.Dispatch<SetStateAction<IGameDrawTimeSceduleVariable>>;
  gameDrawTimeScheduleQueryVariable: IGameDrawTimeSceduleVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;

  setRefetchGameDrawtimeShedData: React.Dispatch<React.SetStateAction<boolean>>;
  getGameDrawTimeScheduleLoading: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

export function GameDrawTimeSchedsTable(props: lGameDrawTimeScheduleProps) {
  const {
    rows,
    gameDrawTimeScheduleQueryVariable,
    setGameDrawTimeScheduleQueryVariable,
    totalCount,
    pageInfo,
    setRefetchGameDrawtimeShedData,
    getGameDrawTimeScheduleLoading,
  } = props;
  const [searchGameDrawTimeSchedule, setSearchGameDrawTimeSchedule] = useState<IGameDrawTimeSceduleTable[]>([]);
  const [search, setSearch] = useState('');

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IGameDrawTimeSceduleTable>('drawTimeName');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = useState(0);
  const [dense] = useState(true);
  const [rowsPerPage] = useState(5);
  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: gameDrawTimeScheduleQueryVariable.first,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IGameDrawTimeSceduleTable) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearch(value);

    console.log(value, '==');

    setGameDrawTimeScheduleQueryVariable({
      ...gameDrawTimeScheduleQueryVariable,
      filterBy: { ...gameDrawTimeScheduleQueryVariable.filterBy }, //, search: value },
    });

    // type IUsherKey = keyof IGameDrawTimeSceduleTable;
    // const searchKeys: IUsherKey[] = ['uniqueCode', 'fullName', 'usherType', 'contactNumber', 'username'];

    // const newUsher = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    // setSearchUsher(newUsher);
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setGameDrawTimeScheduleQueryVariable({
        ...gameDrawTimeScheduleQueryVariable,
        after: pageInfo.endCursor,
        before: undefined,
      });
      let end = pageLabel.end + gameDrawTimeScheduleQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start + gameDrawTimeScheduleQueryVariable.first, end });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setGameDrawTimeScheduleQueryVariable({
        ...gameDrawTimeScheduleQueryVariable,
        before: pageInfo.startCursor,
        after: undefined,
      });
      let end = pageLabel.end - gameDrawTimeScheduleQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start - gameDrawTimeScheduleQueryVariable.first, end });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setGameDrawTimeScheduleQueryVariable({
      ...gameDrawTimeScheduleQueryVariable,
      first: intValue,
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        backgroundColor: '#ffffff',
      }}>
      <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
      <TableContainer>
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          {getGameDrawTimeScheduleLoading ? (
            <TableBodySkeleton row={10} column={5} />
          ) : (
            <TableBody>
              {rows.map((row, index) => (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    sx={{
                      pl: 2,
                    }}>
                    {row.drawTimeName}
                  </TableCell>
                  <TableCell align="left">{row.startDateTime}</TableCell>
                  <TableCell align="left">{row.endDateTime}</TableCell>
                  <TableCell align="left">{row.drawTime}</TableCell>
                  <TableCell align="left">{row.startTime}</TableCell>
                  <TableCell align="left">{row.cutOffTime}</TableCell>
                  <TableCell align="right">
                    <DeleteGameDrawtimeSched id={row.id} setRefetchGameDrawtimeShedData={setRefetchGameDrawtimeShedData} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box
        sx={{
          backgroundColor: '#6591BD',
          color: '#FFFFFF',
          mt: 1,
          justifyContent: 'end',
          alignItems: 'center',
          display: 'flex',
        }}>
        <Typography variant="body2" mr={4} gutterBottom>
          Rows per page:
        </Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
          <Select
            onChange={(e) => {
              handleChangeRowsPerPage(e.target.value);
            }}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={gameDrawTimeScheduleQueryVariable.first}
            label="Row">
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" mx={4} gutterBottom>
          {pageLabel.start} - {pageLabel.end} of {totalCount}
        </Typography>
        <IconButton
          onClick={() => handleChangePage(ChangePageType.PREV)}
          disabled={pageInfo?.hasPreviousPage ? false : true}
          size={'small'}
          sx={{ mr: 2 }}
          aria-label="prev">
          <IoIosArrowBack />
        </IconButton>
        <IconButton
          onClick={() => handleChangePage(ChangePageType.NEXT)}
          disabled={pageInfo?.hasNextPage ? false : true}
          size={'small'}
          aria-label="next">
          <IoIosArrowForward />
        </IconButton>
      </Box>
    </Paper>
  );
}
