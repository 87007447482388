import { useState, useEffect } from 'react';

import { useGameDrawTimeSchedulesQuery, PageInfo } from '@/generated/graphql';

// material ui
import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// interfaces
import {
  IGameDrawTimeSchedule,
  mapGQLGameDrawtimeSched,
  IGameDrawTimeSceduleTable,
  IGameDrawTimeSceduleVariable,
} from '@/shared/interfaces';

// components
import { GameDrawTimeSchedsTable } from './game-drawtime-scheds-table';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

interface IProps {
  refetchGameDrawtimeShedData: boolean;
  setRefetchGameDrawtimeShedData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GameDrawTimeScheds: React.FC<IProps> = ({ refetchGameDrawtimeShedData, setRefetchGameDrawtimeShedData }) => {
  // context
  const { gameCtx } = useGameDashboardCtx();

  const [gameDrawTimeSchedules, setGameDrawTimeSchedules] = useState<IGameDrawTimeSceduleTable[]>([]);
  const [gameDrawTimeScheduleQueryVariable, setGameDrawTimeScheduleQueryVariable] = useState<IGameDrawTimeSceduleVariable>({
    first: 25,
  });
  const [getGameDrawTimeScheduleLoading, setGetGameDrawTimeScheduleLoading] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const getGameDrawTimeSchedules = useGameDrawTimeSchedulesQuery({
    variables: gameDrawTimeScheduleQueryVariable,
    skip: gameDrawTimeScheduleQueryVariable.filterBy?.gameId ? false : true,
    onCompleted: (data) => {
      const mappedGameDrawTimeSchedules: IGameDrawTimeSceduleTable[] = [];
      data.gameDrawTimeSchedules.nodes.map((result) => {
        const mapResult: IGameDrawTimeSchedule = mapGQLGameDrawtimeSched(result);

        mappedGameDrawTimeSchedules.push({
          id: mapResult.id,
          drawTimeName: mapResult.gameDrawTime.drawTime.name,
          startDateTime: mapResult.startDateTime,
          endDateTime: mapResult.endDateTime,
          drawTime: mapResult.drawEndTime,
          startTime: mapResult.drawStartTime,
          cutOffTime: mapResult.drawCutOffTime,
        });
      });
      setTimeout(() => {
        setGameDrawTimeSchedules(mappedGameDrawTimeSchedules);
        setPageInfo(data.gameDrawTimeSchedules.pageInfo);
        setTotalCount(data.gameDrawTimeSchedules.totalCount);
      }, 100);
    },
  });

  useEffect(() => {
    setGetGameDrawTimeScheduleLoading(getGameDrawTimeSchedules.loading);
  }, [getGameDrawTimeSchedules.loading]);

  useEffect(() => {
    if (refetchGameDrawtimeShedData) {
      getGameDrawTimeSchedules.refetch();
      setRefetchGameDrawtimeShedData(false);
    }
  }, [refetchGameDrawtimeShedData]);

  useEffect(() => {
    if (gameCtx.id !== '') {
      setGameDrawTimeScheduleQueryVariable({
        ...gameDrawTimeScheduleQueryVariable,
        filterBy: { ...gameDrawTimeScheduleQueryVariable.filterBy, gameId: gameCtx.id },
      });
    }
  }, [gameCtx]);

  return (
    <>
      <Grid item md={12} justifyContent={'center'} display={'flex'} mb={4}>
        <Grid item md={11} sx={{ background: '#A2BCD8' }}>
          <GameDrawTimeSchedsTable
            rows={gameDrawTimeSchedules}
            setGameDrawTimeScheduleQueryVariable={setGameDrawTimeScheduleQueryVariable}
            gameDrawTimeScheduleQueryVariable={gameDrawTimeScheduleQueryVariable}
            totalCount={totalCount}
            pageInfo={pageInfo}
            getGameDrawTimeScheduleLoading={getGameDrawTimeScheduleLoading}
            setRefetchGameDrawtimeShedData={setRefetchGameDrawtimeShedData}
          />
        </Grid>
      </Grid>
    </>
  );
};
