import { Box, Button, Typography } from '@mui/material';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import Modal from 'react-modal';

// interfaces
import type { IModalDetails } from '@/shared/interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface IProps {
  modalDetails: IModalDetails;
  setModalDetails: React.Dispatch<React.SetStateAction<IModalDetails>>;
  confirm: () => void;
}

export const ConfirmationModal: React.FC<IProps> = ({ modalDetails, setModalDetails, confirm }) => {
  function closeModal() {
    setModalDetails({ isOpen: false });
  }

  return (
    <>
      <Modal isOpen={modalDetails.isOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <Box sx={{ width: 400, p: 3, background: '#fff' }}>
          <Box justifyContent={'center'} display={'flex'} mb={3}>
            <AiOutlineExclamationCircle size={90} color={'#facea8'} />
          </Box>
          <Typography variant="h5" gutterBottom component="div" fontSize={30} fontWeight={600} textAlign={'center'}>
            Are you sure?
          </Typography>
          <Typography variant="body2" gutterBottom textAlign={'center'}>
            You want to continue!
          </Typography>
          <Box justifyContent={'center'} display={'flex'} mt={3}>
            <Button variant="contained" sx={{ mr: 3 }} onClick={confirm}>
              Save
            </Button>
            <Button variant="contained" color="error" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
