import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// graphql
import type { CreateGameDrawTimeInput } from '@/generated/graphql';
import { useServerConfigCreateGameDrawTimeMutation } from '@/generated/graphql';

// interfaces
import type { IDrawTimeInput, ICreateDrawTimeInput, IGame, IModalDetails } from '@/shared/interfaces';

// material ui

// components
import { ConfirmationModal } from '../components/confirmation-modal';

// validation schema
const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

interface IProps {
  gameCtx: IGame;
  gameDrawTimeRefetch: () => void;
}

export const AddDrawTime: React.FC<IProps> = ({ gameCtx, gameDrawTimeRefetch }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: {},
  } = useForm<ICreateDrawTimeInput>({
    resolver: yupResolver(schema),
  });

  const [createGameDrawTimeMutation, {}] = useServerConfigCreateGameDrawTimeMutation();

  const [modalDetails, setModalDetails] = useState<IModalDetails>({ isOpen: false });
  const [drawTimeInputs, setDrawTimeInputs] = useState<IDrawTimeInput>();

  const onSubmit = async (dataInput: ICreateDrawTimeInput) => {
    const { startTime, endTime, cutOff, name } = dataInput;
    const date = new Date().toISOString().split('T');

    const input: IDrawTimeInput = {
      name: name,
      schedule: 'Everyday',
      startDateTime: new Date(date[0] + 'T' + updateTime(startTime) + ':00Z').toISOString(),
      endDateTime: new Date(date[0] + 'T' + updateTime(endTime) + ':00Z').toISOString(),
      cutOffDateTime: new Date(date[0] + 'T' + updateTime(cutOff) + ':00Z').toISOString(),
      startTime: new Date(date[0] + 'T' + updateTime(startTime) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
      endTime: new Date(date[0] + 'T' + updateTime(endTime) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
      cutOff: new Date(date[0] + 'T' + updateTime(cutOff) + ':00Z').toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
    console.log('addding');
    setDrawTimeInputs(input);
    setModalDetails({ isOpen: true, action: 'add draw time' });
    reset();
  };

  function updateTime(time: string) {
    const [hour, minute] = time.split(':');
    let newHour = parseInt(hour) - 8;
    if (newHour < 0) {
      newHour += 24;
    }
    return `${newHour.toString().length === 1 ? '0' + newHour.toString() : newHour.toString()}:${minute}`;
  }

  const confirmEdit = () => {
    if (modalDetails.action === 'add draw time' && drawTimeInputs) {
      if (gameCtx.gameType.id) {
        const { name, startDateTime, endDateTime, cutOffDateTime } = drawTimeInputs;

        const input: CreateGameDrawTimeInput = {
          gameId: gameCtx.id,
          name: name,
          startDateTime,
          endDateTime,
          cutOff: cutOffDateTime,
        };

        createGameDrawTimeMutation({
          variables: { input },
          onCompleted: () => {
            reset();
            gameDrawTimeRefetch();
          },
        });
      }
    }
    setModalDetails({ ...modalDetails, isOpen: false });
  };

  return (
    <>
      <Grid item md={12} justifyContent={'center'} display={'flex'} mb={4}>
        <Grid item xl={7} lg={10} sx={{ background: '#A2BCD8', pb: 3, pt: 4 }}>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <Grid container spacing={3}>
            <Grid item md={2} alignItems={'center'} display={'flex'} justifyContent={'end'}>
              <TextField
                sx={{ ml: 3 }}
                size="small"
                id="standard-basic"
                variant="standard"
                label="Name"
                {...register('name')}
                // value={gameDrawTime.drawTime.name}
                // onChange = {(e) => setGameDrawTimes(gameDrawTimes.map(dt =>
                //     dt.drawTime.id === gameDrawTime.drawTime.id ?
                //     {...dt, drawTime: {...dt.drawTime, name: e.target.value}}: dt))}
              />
            </Grid>
            <Grid item md={7} justifyContent={'center'} display={'flex'}>
              <TextField
                {...register('endTime')}
                size="small"
                label="Draw Time"
                type="time"
                defaultValue="23:00"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <TextField
                {...register('startTime')}
                size="small"
                label="Start Time"
                type="time"
                defaultValue="00:00"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                sx={{ ml: 6 }}
              />
              <TextField
                {...register('cutOff')}
                size="small"
                label="Cutt-Off"
                type="time"
                defaultValue="22:55"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                sx={{ ml: 6 }}
              />
            </Grid>
            <Grid item md={2} alignItems={'center'} display={'flex'} justifyContent={'end'} pl={0}>
              <Button sx={{ ml: 3 }} variant="contained" size="small" type="submit" disabled={true}>
                Add
              </Button>
            </Grid>
          </Grid>
          {/* </form> */}
        </Grid>
      </Grid>
      <ConfirmationModal modalDetails={modalDetails} setModalDetails={setModalDetails} confirm={confirmEdit} />
    </>
  );
};
