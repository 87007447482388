// interfaces

// material-ui
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState, useContext, useRef } from 'react';
import Modal from 'react-modal';

// context api

// graphql
import { CombinationInput } from '@/components/Input/combination-input';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import { ConfirmationModal } from '@/components/modal/confirmation';
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import type { CreateGameResultInput } from '@/generated/graphql';
import { useCreateGameResultMutation } from '@/generated/graphql';

// components
import type { IGameResult, IGameDrawTime, INumberCombInput } from '@/shared/interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  noGameResults: IGameResult[];
  noGameResult: IGameResult | undefined;
  setNoGameResult: React.Dispatch<React.SetStateAction<IGameResult | undefined>>;
  refetchData: () => void;
  gameDrawTimes: IGameDrawTime[];
}

export const AddGameResultModal: React.FC<Props> = ({ noGameResults, noGameResult, setNoGameResult, refetchData, gameDrawTimes }) => {
  const { gameCtx } = useGameDashboardCtx();
  const inputRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<string>('');
  const [gameDrawTime, setGameDrawTime] = useState<IGameDrawTime>();
  const [showLoading, setShowLoading] = useState(false);

  const [createGameResult] = useCreateGameResultMutation();
  const [modalIsOpen, setIsOpen] = useState(false);

  // for confirmation modal
  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Result',
    messages: [],
  });

  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  // combination input
  const [numberCombInputs, setNumberCombInputs] = useState<INumberCombInput[]>([]);

  const onSubmit = async () => {
    const { combinationLimit, placeValue } = gameCtx.gameType.gameConfig;
    const inputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    if (inputCombination.replace(/,/g, '').length === combinationLimit * placeValue && (noGameResult || gameDrawTime)) {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: [
          'You entered result combination: ' + inputCombination.replace(/,/g, ''),
          'Drawtime: ' +
            (date && date !== ''
              ? gameDrawTime
                ? gameDrawTime.drawTime.name
                : ''
              : noGameResult
              ? noGameResult.gameDrawTime.drawTime.name
              : ''),
        ],
      });
    }
  };

  const cofirmedEnterResult = async () => {
    const inputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    const { combinationLimit, placeValue } = gameCtx.gameType.gameConfig;

    if (inputCombination.replace(/,/g, '').length === combinationLimit * placeValue && (noGameResult || gameDrawTime)) {
      const input: CreateGameResultInput = {
        resultCombination: inputCombination,
        id: date && date !== '' ? undefined : noGameResult?.id ?? '',
        gameDrawTimeId: date && date !== '' ? (gameDrawTime ? gameDrawTime.id : '') : noGameResult ? noGameResult.gameDrawTime.id : '',
        date: date && date !== '' ? date : undefined,
      };
      initializeLoading();

      createGameResult({
        variables: { input },
        onCompleted: () => {
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Result added successfully',
            type: 'success',
          });
          refetchData();
          setIsOpen(false);
          setShowLoading(false);
        },
      });
    }
    // setConfirmationModal({
    //   ...confirmationModal,
    //   isOpen: true,
    //   messages: ['You are about to add a new game result.'],
    // });
  };

  // modal details

  function openModal() {
    setIsOpen(true);
    setTimeout(() => {
      inputRef.current?.focus();
      console.log('input focus');
    }, 100);

    const emtyCombi: INumberCombInput[] = [];
    for (let i = 0; i < gameCtx.gameType.gameConfig.combinationLimit; i++) {
      emtyCombi.push({
        value: '',
        id: i,
        isFocused: false,
      });
    }
    setNumberCombInputs(emtyCombi);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const selectDate = (date: string) => {
    console.log(date);
    setDate(date);
    date === '' ? setGameDrawTime(undefined) : setNoGameResult(undefined);
    inputRef.current?.focus();
  };

  const selectNoGameResult = (result: IGameResult) => {
    setNoGameResult(result);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const initializeLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading((prevShowLoading) => {
        console.log(prevShowLoading, '======');
        if (prevShowLoading === true) {
          return false;
        }
        return prevShowLoading;
      });
    }, 15000);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
        </Grid>
        <Grid item xs={10} justifyContent={'end'} display={'flex'}>
          <Button sx={{ mr: 3 }} variant="contained" size="small" onClick={() => openModal()}>
            Add Result
          </Button>
        </Grid>
      </Grid>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Create Result
            </Typography>

            <Typography variant="body2" display="block" gutterBottom textAlign={'center'} mt={4}>
              Select Draw Time
            </Typography>

            {/* no results or game drawtime */}
            {date && date !== '' ? (
              <Grid container justifyContent={'center'}>
                <Grid item md={12} justifyContent={'center'} display={'flex'}>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <Select
                      size="small"
                      sx={{ fontSize: '.9rem' }}
                      value={gameDrawTime ? gameDrawTime.id : ''}
                      // onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {gameDrawTimes.map((gameDT) => (
                        <MenuItem
                          value={gameDT.id}
                          sx={{ fontSize: '.9rem' }}
                          onClick={() => {
                            setGameDrawTime(gameDT);
                          }}>
                          {gameDT.drawTime.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent={'center'}>
                <Grid item md={12} justifyContent={'center'} display={'flex'}>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <Select
                      size="small"
                      sx={{ fontSize: '.9rem' }}
                      value={noGameResult ? noGameResult.id : ''}
                      // onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {noGameResults.map((noResult) => (
                        <MenuItem
                          key={noResult.id}
                          value={noResult.id}
                          sx={{ fontSize: '.9rem' }}
                          onClick={() => {
                            selectNoGameResult(noResult);
                          }}>
                          {`${noResult.date} ${noResult.gameDrawTime.drawTime.name} ${noResult.gameDrawTime.game.gameType.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {/* end */}

            {/* date for custome date date result */}
            <Grid container justifyContent={'center'} mt={2}>
              <TextField
                id="outlined-basic"
                type={'date'}
                focused
                label="Date"
                variant="outlined"
                size={'small'}
                value={date}
                onChange={(e) => selectDate(e.target.value)}
              />
            </Grid>
            <Grid container justifyContent={'center'}>
              <Typography variant="caption" display="block" gutterBottom>
                If game result is not entered in previous date, select date
              </Typography>
            </Grid>
            {/* end */}

            <Grid container justifyContent={'center'} mt={6}>
              <CombinationInput
                perCombinationLength={gameCtx.gameType.gameConfig.placeValue}
                combinationLength={gameCtx.gameType.gameConfig.combinationLimit}
                setNumberCombInputs={setNumberCombInputs}
                numberCombInputs={numberCombInputs}
              />
            </Grid>

            <Grid container justifyContent={'center'} mt={10}>
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={
                  numberCombInputs.map((numberCombi) => numberCombi.value).join('').length <
                    gameCtx.gameType.gameConfig.combinationLimit * gameCtx.gameType.gameConfig.placeValue &&
                  (!gameDrawTime || !noGameResult)
                    ? true
                    : false
                }>
                Submit
              </Button>
            </Grid>
          </Box>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={cofirmedEnterResult}
        confirmText={'Submit'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
