import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';

// interfaces
import Modal from 'react-modal';
import { CombinationInput } from '@/components/Input/combination-input';

import type { UpdateGameHotNumberInput } from '@/generated/graphql';
import { useUpdateGameHotNumberMutation } from '@/generated/graphql';
import type { INumberCombInput, IGameHotNumberTableData } from '@/shared/interfaces';
import type { IGameHotNumberInput } from './create-game-hot-number';

import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// material-ui

// context api

// graphql

// components

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isGameHotNumberModalOpen: boolean;
  setIsGameHotNumberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameHotNumberData: IGameHotNumberTableData;
  refetchData: () => void;
}

export const EditGameHotNumberModal: React.FC<Props> = ({
  isGameHotNumberModalOpen,
  setIsGameHotNumberModalOpen,
  gameHotNumberData,
  refetchData,
}) => {
  const { gameCtx } = useGameDashboardCtx();

  const [updateGameHotNumber] = useUpdateGameHotNumberMutation();

  const [numberCombInputs, setNumberCombInputs] = useState<INumberCombInput[]>([]);
  const [gameHotNumberInput, setGameHotNumberInput] = useState<IGameHotNumberInput>({
    payout: '',
    startDate: '',
    endDate: '',
    salesLimit: '',
  });

  const closeModal = () => {
    setIsGameHotNumberModalOpen(false);
  };

  const handleGameHotNumberAmountChange = (value: string, type: 'payout' | 'salesLimit') => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (type === 'payout' && (numValue.length > 0 || numValue === '')) {
      setGameHotNumberInput({ ...gameHotNumberInput, payout: numValue === '' ? '' : parseInt(numValue) });
    } else if (type === 'salesLimit' && (numValue.length > 0 || numValue === '')) {
      setGameHotNumberInput({ ...gameHotNumberInput, salesLimit: numValue === '' ? '' : parseInt(numValue) });
    }
  };

  useEffect(() => {
    if (gameHotNumberData) {
      const emtyCombi: INumberCombInput[] = [];
      const numberCombinationList = gameHotNumberData.numberCombinationOriginal.split(',');

      numberCombinationList.forEach((combination, index) => {
        emtyCombi.push({
          value: combination,
          id: index,
          isFocused: false,
        });
      });
      setNumberCombInputs(emtyCombi);
      setGameHotNumberInput({
        payout: gameHotNumberData.payout,
        salesLimit: gameHotNumberData.salesLimit,
        startDate: gameHotNumberData.startDate,
        endDate: gameHotNumberData.endDate,
      });
    }
  }, [gameHotNumberData]);

  const editGameHotNumber = () => {
    const { combinationLimit, placeValue } = gameCtx.gameType.gameConfig;

    const InputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    if (gameCtx && InputCombination.replace(/,/g, '').length === combinationLimit * placeValue) {
      const { startDate, endDate, payout, salesLimit } = gameHotNumberInput;

      const input: UpdateGameHotNumberInput = {
        id: gameHotNumberData.id,
        gameId: gameCtx.id,
        startDate,
        endDate,
        payout: parseInt(payout.toString()),
        salesLimit: parseInt(salesLimit.toString()),
        numberCombination: InputCombination,
      };
      console.log(input);

      updateGameHotNumber({
        variables: { input },
        onCompleted: () => {
          refetchData();
          setIsGameHotNumberModalOpen(false);
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isGameHotNumberModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div style={{ paddingBottom: '1em' }}>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit GameHotNumber
            </Typography>

            <div className="createindividuallimit-limit" style={{ marginTop: '3em' }}>
              <TextField
                label="GameHotNumber"
                variant="outlined"
                value={gameHotNumberInput?.payout}
                onChange={(e) => {
                  handleGameHotNumberAmountChange(e.target.value, 'payout');
                }}
              />
            </div>

            <div className="createindividuallimit-limit" style={{ marginTop: '3em' }}>
              <TextField
                label="Sales Limit"
                variant="outlined"
                value={gameHotNumberInput?.salesLimit}
                onChange={(e) => {
                  handleGameHotNumberAmountChange(e.target.value, 'salesLimit');
                }}
              />
            </div>

            <TextField
              onChange={(e) => setGameHotNumberInput({ ...gameHotNumberInput, startDate: e.target.value })}
              id="date"
              label="Start date"
              type="date"
              size="small"
              value={gameHotNumberInput?.startDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              onChange={(e) => setGameHotNumberInput({ ...gameHotNumberInput, endDate: e.target.value })}
              id="date"
              label="End date"
              type="date"
              size="small"
              value={gameHotNumberInput?.endDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Grid container justifyContent={'center'} mt={6}>
              <CombinationInput
                perCombinationLength={gameCtx.gameType.gameConfig.placeValue}
                combinationLength={gameCtx.gameType.gameConfig.combinationLimit}
                setNumberCombInputs={setNumberCombInputs}
                numberCombInputs={numberCombInputs}
              />
            </Grid>
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{ display: 'flex', paddingBottom: '2em', justifyContent: 'center', alignContent: 'center' }}>
          <Button variant="contained" onClick={editGameHotNumber}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};
