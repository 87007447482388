import { Data, HeadCell } from '../components/table';

export function generateDataForCSVExport(datas: Data[], headCells: HeadCell[], title: string) {
  let totalHits = 0;
  let totalPayable = 0;
  const tableData: any = [[title]];

  const filteredHeadCells = headCells.filter((headCell) => headCell.hide === false);

  tableData.push(filteredHeadCells.map((headCell) => headCell.label.toUpperCase()));

  datas.forEach((x) => {
    totalPayable += parseFloat(x['payable'] as string);
    totalHits += parseFloat(x['amount'] as string);

    tableData.push(filteredHeadCells.map((headCell) => x[headCell.id]));
  });
  return tableData;
}
