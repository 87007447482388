import React, { useContext } from 'react';
import { createBrowserRouter, Outlet, useLocation, Navigate } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';

// route components
import { Login } from './pages/auth/login';
import PageLayout from './layouts/page-layout.page';
import { NewOverview } from './pages/dashboard/newOverview/newOverview';
import { Ushers } from './pages/dashboard/usher/views/ushers.page';
import { Bets } from './pages/dashboard/dashboard-by-gametype/bets/bets.page';
import { GameResults } from './pages/dashboard/dashboard-by-gametype/game-results/game-results.page';
import { Gamelimits } from './pages/dashboard/dashboard-by-gametype/game-limits/game-limits.page';
import { OpStats } from './pages/dashboard/dashboard-by-gametype/operation-stats/opstats.page';
import { Reports } from './pages/dashboard/dashboard-by-gametype/reports/report.page';
import { ServerConfig } from './pages/dashboard/dashboard-by-gametype/server-config/server-config.page';
import { GamesPage } from './pages/dashboard/games/views/games.page';
import { Development } from './pages/dashboard/development/development.page';
import { CoordinatorsPage } from './pages/dashboard/coordinators/coordinators.page';
import { CoordinatorsReportPage } from './pages/dashboard/dashboard-by-gametype/coordinators-report/coordinators-report.page';
import { DrawAdminsPage } from './pages/dashboard/draw-admin/draw-admin.page';
import { CoordinatorPage } from './pages/dashboard/coordinator/coordinator.page';
import { NotificationsPage } from './pages/dashboard/notifications/notification.page';
import { TelegramReportsPage } from './pages/dashboard/telegram-report/telegram-report.page';
// error page
import { NotFound } from '@/pages/error/4xx/notfound';

import { AuthProvider, AuthContext } from '@/contexts/auth';
// Pages being currently developed on:
import { ButtonAuditPage } from '@/pages/design-audit/button-audit';
import { Limits } from '@/pages/dashboard/dashboard-by-gametype/limits/limits.page';
import { CoordinatorUshersPage } from './pages/dashboard/coordinator-ushers/views/coordinator-ushers.page';
import { UsherBets } from './pages/dashboard/dashboard-by-gametype/usher-bets/usher-bets.page';

interface Props {
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrivateRoutes: React.FC = () => {
  const { authenticated } = useContext(AuthContext);

  const location = useLocation();

  if (!authenticated) {
    console.error('UnAuthenticated');
    // we have to "remember" the location from where the redirect happened to the Login page.
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export const Routes: React.FC<Props> = ({ setAuthenticated }) => {
  const router = createBrowserRouter([
    {
      element: <AuthProvider />,
      errorElement: <NotFound />,
      children: [
        {
          path: '/',
          element: <Login setAuthenticated={setAuthenticated} />,
        },
        {
          element: <PrivateRoutes />,
          children: [
            {
              path: '/dashboard',
              element: <PageLayout />,
              children: [
                {
                  path: 'overview',
                  element: <NewOverview />,
                },
                {
                  path: 'usher',
                  element: <Ushers />,
                },
                {
                  path: 'usher-bets/:usherId/:usherName',
                  element: <UsherBets />,
                },
                {
                  path: 'coordinators',
                  element: <CoordinatorsPage />,
                },
                {
                  path: 'coordinator-ushers/:coordinatorId',
                  element: <CoordinatorUshersPage />,
                },
                {
                  path: 'draw-admins',
                  element: <DrawAdminsPage />,
                },
                {
                  path: 'bets',
                  element: <Bets />,
                },
                {
                  path: 'game-results',
                  element: <GameResults />,
                },
                {
                  path: 'game-limits',
                  element: <Gamelimits />,
                },
                {
                  path: 'operation-stats',
                  element: <OpStats />,
                },
                {
                  path: 'reports',
                  element: <Reports />,
                },
                {
                  path: 'server-config',
                  element: <ServerConfig />,
                },
                {
                  path: 'game-types',
                  element: <GamesPage />,
                },
                {
                  path: 'developing',
                  element: <Limits />,
                },
                {
                  path: 'development',
                  element: <Development />,
                },
                {
                  path: 'operators-report',
                  element: <CoordinatorsReportPage />,
                },
                {
                  path: 'coordinator/:coordinatorId',
                  element: <CoordinatorPage />,
                },
                {
                  path: 'notifications',
                  element: <NotificationsPage />,
                },
                {
                  path: 'telegram-report',
                  element: <TelegramReportsPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
