import { useState } from 'react';

// graphql
import { useUsherApplicantsQuery, PageInfo } from '@/generated/graphql';

// interfaces
import { IUsherApplicant, IUsherApplicantTableData, mapGQLUsherApplicant, IUsherApplicantQueryVariable } from '@/shared/interfaces';

// components
import { UsherApplicantTable } from './usher-applicant-table';
// import { AddUsherApplicants } from './add-registration-code';

// material ui
import { Box, Grid, Typography } from '@mui/material';

export const UsherApplicants: React.FC = () => {
  const [userApplicantQueryVariable, setUsherApplicantQueryVariable] = useState<IUsherApplicantQueryVariable>({ first: 25 });
  const [userApplicantTableData, setUsherApplicantTableData] = useState<IUsherApplicantTableData[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const getCodes = useUsherApplicantsQuery({
    variables: userApplicantQueryVariable,
    onCompleted(data) {
      const tableData: IUsherApplicantTableData[] = [];

      const mappedData: IUsherApplicant[] = data.usherApplicants.nodes.map((x) => mapGQLUsherApplicant(x));

      mappedData.forEach((userApplicant) => {
        tableData.push({
          id: userApplicant.id,
          firstName: userApplicant.firstName,
          lastName: userApplicant.lastName,
          address: userApplicant.address,
          contactNumber: userApplicant.contactNumber,
          username: userApplicant.username,
          email: userApplicant.email,
          registrationCode: userApplicant.registrationCode,
        });
      });

      setTimeout(() => {
        setUsherApplicantTableData(tableData);
        setPageInfo(data.usherApplicants.pageInfo);
        setTotalCount(data.usherApplicants.totalCount);
      }, 100);
    },
  });

  const refetch = () => {
    getCodes.refetch();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#87ADD4',
        }}
        p={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography
              sx={{
                color: '#fff',
              }}
              variant="h4"></Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <AddUsherApplicants refetch={refetch} /> */}
          </Grid>
        </Grid>
      </Box>
      <UsherApplicantTable
        rows={userApplicantTableData}
        setUsherApplicantQueryVariable={setUsherApplicantQueryVariable}
        userApplicantQueryVariable={userApplicantQueryVariable}
        totalCount={totalCount}
        pageInfo={pageInfo}
        refetch={refetch}
      />
    </>
  );
};
