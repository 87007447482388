import type { DrawTimeStatus, DrawTime } from '@/generated/graphql';

export interface IDrawTime {
  id: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  cutOff: string;
  startTime: string;
  endTime: string;
  cutOffTime: string;
  status: DrawTimeStatus | string;

  createdAt: string;
  updatedAt: string;
}

export interface ICreateDrawTimeInput {
  name: string;
  startTime: string;
  endTime: string;
  cutOff: string;
}

export interface IDrawOverviewDrawTimeResult {
  id: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  cutOffTime: string;
}

export function mapGQLDrawTime(drawTime?: DrawTime | null): IDrawTime {
  const dtObjCutOffDateTime: Date | null = drawTime ? new Date(drawTime.cutOff) ?? null : null;
  const dtObjStartDateTime: Date | null = drawTime ? new Date(drawTime.startDateTime) ?? null : null;
  const dtObjEndDateTime: Date | null = drawTime ? new Date(drawTime.endDateTime) ?? null : null;

  return {
    id: drawTime?.id ?? '',
    name: drawTime ? drawTime.name ?? '' : '',
    startDateTime: drawTime ? drawTime.startDateTime ?? '' : '',
    endDateTime: drawTime ? drawTime.endDateTime ?? '' : '',
    cutOff: drawTime ? drawTime.cutOff ?? '' : '',

    cutOffTime: dtObjCutOffDateTime
      ? (dtObjCutOffDateTime.getHours().toString().length == 1
          ? '0' + dtObjCutOffDateTime.getHours().toString()
          : dtObjCutOffDateTime.getHours().toString()) +
          ':' +
          (dtObjCutOffDateTime.getMinutes().toString().length == 1
            ? '0' + dtObjCutOffDateTime.getMinutes().toString()
            : dtObjCutOffDateTime.getMinutes().toString()) ?? ''
      : '',

    startTime: dtObjStartDateTime
      ? (dtObjStartDateTime.getHours().toString().length == 1
          ? '0' + dtObjStartDateTime.getHours().toString()
          : dtObjStartDateTime.getHours().toString()) +
          ':' +
          (dtObjStartDateTime.getMinutes().toString().length == 1
            ? '0' + dtObjStartDateTime.getMinutes().toString()
            : dtObjStartDateTime.getMinutes().toString()) ?? ''
      : '',

    endTime: dtObjEndDateTime
      ? (dtObjEndDateTime.getHours().toString().length == 1
          ? '0' + dtObjEndDateTime.getHours().toString()
          : dtObjEndDateTime.getHours().toString()) +
          ':' +
          (dtObjEndDateTime.getMinutes().toString().length == 1
            ? '0' + dtObjEndDateTime.getMinutes().toString()
            : dtObjEndDateTime.getMinutes().toString()) ?? ''
      : '',

    status: drawTime ? drawTime.status ?? '' : '',
    createdAt: drawTime ? drawTime.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: drawTime ? drawTime.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}

export function mapGQLDrawOverviewDrawTimeResult(drawTimes?: DrawTime | null): IDrawOverviewDrawTimeResult {
  return {
    id: drawTimes?.id ?? '',
    name: drawTimes ? drawTimes.name ?? '' : '',
    startDateTime: drawTimes ? drawTimes.startDateTime ?? new Date().toISOString() : new Date().toISOString(),
    endDateTime: drawTimes ? drawTimes.endDateTime ?? new Date().toISOString() : new Date().toISOString(),
    cutOffTime: drawTimes ? drawTimes.cutOff ?? new Date().toISOString() : new Date().toISOString(),
  };
}
