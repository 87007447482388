import Modal from 'react-modal';
import { Backdrop, Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { ConfirmationModal, IModalConfirmationDetails } from '@/components/modal/confirmation';
import { useState } from 'react';
import { CreateGameHotNumberInput, useCreateGameHotNumberMutation } from '@/generated/graphql';
import { ICreateGameHotNumber } from '@/shared/interfaces';
import { useGameDashboardCtx } from '@/layouts/components';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FeedbackAlert, IAlertFeedBackDetails } from '@/components/feedback/alert';

interface IToggle {
  id: string;
  numberCombination: string;
}

interface Props {
  isAddHotNumbersModalOpen: boolean;
  setIsAddHotNumbersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedHotNumbers: IToggle[];
  refetchData: () => void;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

const schema = yup
  .object({
    payout: yup.number().required(),
    salesLimit: yup.number().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  })
  .required();

export function AddHotNumbers(props: Props) {
  const { isAddHotNumbersModalOpen, setIsAddHotNumbersModalOpen, selectedHotNumbers, refetchData } = props;

  const { gameCtx } = useGameDashboardCtx();

  const [createGameHotNumber] = useCreateGameHotNumberMutation();

  const closeModal = () => {
    setIsAddHotNumbersModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Number',
    messages: [],
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateGameHotNumber>({
    resolver: yupResolver(schema),
  });

  const [addHotNumberInputs, setAddHotNumberInputs] = useState<ICreateGameHotNumber>();

  const onSubmit = async (dataInput: ICreateGameHotNumber) => {
    setAddHotNumberInputs(dataInput);
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You are about to add a new hot number.'],
    });
  };

  const confirmAdd = () => {
    if (addHotNumberInputs) {
      const { payout, salesLimit, startDate, endDate } = addHotNumberInputs;

      const input: CreateGameHotNumberInput[] = [];

      selectedHotNumbers.map((item) => {
        input.push({
          numberCombination: item.numberCombination,
          endDate,
          payout,
          salesLimit,
          startDate,
          gameId: gameCtx.id,
        });
      });

      console.log(input);

      activateLoading();

      input.map((i) => {
        createGameHotNumber({
          variables: { input: i },
          onCompleted: () => {
            setConfirmationModal({
              isOpen: false,
              title: 'Confirm Result',
              messages: [],
            });
            setSuccessDetails({
              isOpen: true,
              message: 'Hot number added successfully',
              type: 'success',
            });
            setIsAddHotNumbersModalOpen(false);
            setShowLoading(false);
            reset();
            refetchData();
          },
        });
      });
    }
  };

  return (
    <>
      <Modal isOpen={isAddHotNumbersModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <Box sx={{ height: 15, background: '#87ADD4' }} />
        <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
          <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} mb={4}>
            Add To Hot Numbers
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid justifyContent={'center'} display={'flex'}>
              <Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2} width={250}>
                <TextField label="Payout" size="small" variant="outlined" fullWidth {...register('payout')} />
                <TextField label="Sales Limit" size="small" variant="outlined" fullWidth {...register('salesLimit')} />
                <TextField
                  label="Start Date"
                  variant="outlined"
                  size="small"
                  type={'date'}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('startDate')}
                />
                <TextField
                  label="End Date"
                  variant="outlined"
                  size="small"
                  type={'date'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  {...register('endDate')}
                />
                <Box>
                  <Typography variant="body1">
                    Number Combinations:
                    {selectedHotNumbers.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: 'inline',
                          }}
                          ml={3}>
                          {item.numberCombination}
                        </Box>
                      );
                    })}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <div
              className="gameedit-submit"
              style={{ display: 'flex', paddingBottom: '2em', justifyContent: 'center', alignContent: 'center' }}>
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
}
