import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
// import * as yup from 'yup';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// material ui

// graphql
import { UpdateDrawTimeInput } from '@/generated/graphql';
import { useServerConfigGameDrawTimesQuery, useServerConfigUpdateDrawTimeMutation } from '@/generated/graphql';

// interface
import { IGameDrawTime, IModalDetails } from '@/shared/interfaces';
import { mapGQLGameDrawTime } from '@/shared/interfaces';

// components
import {
  DrawTime,
  ConfirmationModal,
  GamePayout,
  AddDrawTime,
  GameDrawTimeStatus,
  AddGameDrawtimeSched,
  GameDrawTimeScheds,
  ShowUsherNameOnReceipt,
} from './components';

// validation schema
// const schema = yup
//   .object({
//     payout: yup.number().required(),
//   })
//   .required();

// interface IGameConfigInput {
//   payout: number;
// }

export const ServerConfig: React.FC = () => {
  const { gameCtx } = useGameDashboardCtx();
  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);
  const [drawTimeId, setDrawTimeId] = useState<string>();

  const [modalDetails, setModalDetails] = React.useState<IModalDetails>({ isOpen: false });

  const [refetchGameDrawtimeShedData, setRefetchGameDrawtimeShedData] = React.useState<boolean>(false);

  const [updateDrawTimeMutation] = useServerConfigUpdateDrawTimeMutation();

  // GRAPHQL QUERIES
  const getGameDrawTimes = useServerConfigGameDrawTimesQuery({
    skip: gameCtx.id === '' ? true : false,
    variables: { filterBy: { gameId: gameCtx.id } },
    onCompleted: (data) => {
      const mappedDatas: IGameDrawTime[] = data.gameDrawTimes.nodes.map((gameDrawTime) => mapGQLGameDrawTime(gameDrawTime));

      setTimeout(() => {
        setGameDrawTimes(orderBy(mappedDatas, ['drawTime.endTime'], ['asc']));
      }, 100);
    },
  });

  // END

  const editDrawTime = async () => {
    const drawTimeInput = gameDrawTimes.find((gameDrawTime) => gameDrawTime.drawTime.id === drawTimeId);
    if (drawTimeInput) {
      const { drawTime } = drawTimeInput;
      const date = new Date().toISOString().split('T');

      const input: UpdateDrawTimeInput = {
        id: drawTime.id,
        startDateTime: new Date(date[0] + 'T' + updateTime(drawTime.startTime) + ':00Z').toISOString(),
        endDateTime: new Date(date[0] + 'T' + updateTime(drawTime.endTime) + ':00Z').toISOString(),
        cutOff: new Date(date[0] + 'T' + updateTime(drawTime.cutOffTime) + ':00Z').toISOString(),
        name: drawTime.name,
      };

      const { data } = await updateDrawTimeMutation({ variables: { input } });

      if (data) {
        getGameDrawTimes.refetch();
      }
    }
  };

  function updateTime(time: string) {
    const [hour, minute] = time.split(':');
    let newHour = parseInt(hour) - 8;
    if (newHour < 0) {
      newHour += 24;
    }
    return `${newHour.toString().length === 1 ? '0' + newHour.toString() : newHour.toString()}:${minute}`;
  }

  useEffect(() => {
    if (gameCtx.id !== '') {
      console.log('refetching game draw times');
      getGameDrawTimes.refetch();
    }
  }, [gameCtx]);

  // modal functions

  const confirm = () => {
    if (modalDetails.action === 'server config update draw time') {
      editDrawTime();
    }
    setModalDetails({ ...modalDetails, isOpen: false });
  };

  const openModal = (action: string, drawTimeId?: string) => {
    setModalDetails({ isOpen: true, action });
    if (drawTimeId) {
      setDrawTimeId(drawTimeId);
    }
  };

  const gameDrawTimeRefetch = () => {
    getGameDrawTimes.refetch();
  };

  const refetch = () => {
    getGameDrawTimes.refetch();
  };

  return (
    <>
      <Grid container mt={0}>
        <Grid item md={12}>
          <Box sx={{ height: 50, background: '#87ADD4' }} justifyContent={'start'} alignItems={'center'} display={'flex'}>
            <Typography variant="h5" gutterBottom component="div" color="#FFF" fontWeight="700" textAlign={'start'} mb={0} ml={4}>
              Server Config
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ background: '#C5D8EC' }}>
        <Grid item md={12} mt={10}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Payout Multiplier
          </Typography>
        </Grid>
        <GamePayout gameCtx={gameCtx} gameDrawTimeRefetch={gameDrawTimeRefetch} />

        <Grid item md={12} mt={10}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Add Daily Cut-Off Schedule
          </Typography>
        </Grid>

        <AddDrawTime gameCtx={gameCtx} gameDrawTimeRefetch={gameDrawTimeRefetch} />

        <Grid item md={12} mt={4}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Daily Cut-Off Schedule
          </Typography>
        </Grid>

        <Grid item md={12} justifyContent={'center'} display={'flex'} mb={4}>
          <Grid item xl={8} lg={11} sx={{ background: '#A2BCD8', pb: 3 }}>
            {gameDrawTimes.map((gameDrawTime) => (
              <Box key={gameDrawTime.id} mt={3}>
                <Grid container spacing={3}>
                  <Grid item md={2} alignItems={'center'} display={'flex'} justifyContent={'end'}>
                    <TextField
                      sx={{ ml: 3 }}
                      size="small"
                      id="standard-basic"
                      variant="standard"
                      value={gameDrawTime.drawTime.name}
                      onChange={(e) =>
                        setGameDrawTimes(
                          gameDrawTimes.map((dt) =>
                            dt.drawTime.id === gameDrawTime.drawTime.id
                              ? { ...dt, drawTime: { ...dt.drawTime, name: e.target.value } }
                              : dt,
                          ),
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={6} justifyContent={'center'} display={'flex'}>
                    <DrawTime gameDrawTime={gameDrawTime} setGameDrawTimes={setGameDrawTimes} gameDrawTimes={gameDrawTimes} />
                  </Grid>
                  <Grid item md={1} alignItems={'center'} display={'flex'} justifyContent={'start'}>
                    <Box>
                      <Typography variant="overline" display="block" gutterBottom>
                        {gameDrawTime.status}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={1} alignItems={'center'} display={'flex'} justifyContent={'start'}>
                    <Button
                      sx={{ ml: 3 }}
                      variant="contained"
                      size="small"
                      type="submit"
                      onClick={() => openModal('server config update draw time', gameDrawTime.drawTime.id)}>
                      Edit
                    </Button>
                  </Grid>
                  <Grid item md={2} alignItems={'center'} display={'flex'} justifyContent={'end'}>
                    <GameDrawTimeStatus isDisabled={gameDrawTime.isDisabled} id={gameDrawTime.id} refetch={refetch} />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Grid item md={12} mt={4}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Add Game Drawtime Schedule
          </Typography>
        </Grid>

        <Grid item md={12} mt={4}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Show Usher name in receipt
          </Typography>
        </Grid>

        <ShowUsherNameOnReceipt />

        <Grid item md={12} mt={4}>
          <Typography variant="h6" gutterBottom component="div" textAlign={'center'} fontWeight={'bold'} color={'#567DA6'}>
            Add Game Drawtime Schedule
          </Typography>
        </Grid>

        <AddGameDrawtimeSched
          gameDrawTimes={gameDrawTimes}
          gameDrawTimeRefetch={gameDrawTimeRefetch}
          setRefetchGameDrawtimeShedData={setRefetchGameDrawtimeShedData}
        />

        <GameDrawTimeScheds
          refetchGameDrawtimeShedData={refetchGameDrawtimeShedData}
          setRefetchGameDrawtimeShedData={setRefetchGameDrawtimeShedData}
        />
        <ConfirmationModal modalDetails={modalDetails} setModalDetails={setModalDetails} confirm={confirm} />
      </Grid>
    </>
  );
};
