import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { orderBy } from 'lodash';
import { CSVLink } from 'react-csv';

import '../../styles/reports.css';

// graphql
import { useReportGameDrawTimesQuery } from '@/generated/graphql';

// material ui
import { Grid, Button, Skeleton } from '@mui/material';

// interface
import { ICoordinatorSalesReport, mapGQLGameDrawTime, IGameDrawTime, IUsherSalesReport } from '@/shared/interfaces';
import { CoordinatorSalesReportComponentRef } from '../../coordinators-report.page';

// components
import { CoordinatorSalesTable, Data, HeadCell, Align } from './coordinator-sales-table';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// services
import { coordinatorSaleReportExportToPDF, generateDataForCSVExport } from '../../services';

interface IGameDrawTime2 extends HeadCell {
  endTime: string;
  gameName: string;
}

interface Props {
  dateRangeLabel: string;
  CSRtotalCount: number;
  ushersSalesReport: IUsherSalesReport[];
}

export const CoordinatorsSalesReportSection = forwardRef<CoordinatorSalesReportComponentRef, Props>(
  ({ CSRtotalCount, dateRangeLabel, ushersSalesReport }, ref) => {
    useImperativeHandle(ref, () => ({
      populateTable: (coordinatorsSalesReport) => {
        setTimeout(() => {
          const coordinatorsSalesReportTableData = coordinatorsSalesReport.map((coordinatorReport) => {
            const { coordinator, coordinatorGameSales, date } = coordinatorReport;

            const dynamicObject: Data = {};

            coordinatorGameSales.forEach((gameSales) => {
              gameSales.gameDrawTimeSales.forEach((gameDrawTimeSales) => {
                dynamicObject[`${gameSales.name}${gameDrawTimeSales.drawName}`.replace(/ /g, '')] = gameDrawTimeSales.sales;
              });
            });

            dynamicObject.date = date;
            dynamicObject.agentName = coordinator.name;
            dynamicObject.totalSales = coordinatorReport.totalSales;
            dynamicObject.payableWinner = coordinatorReport.payableWinner;
            dynamicObject.totalBets = coordinatorReport.totalBets;
            dynamicObject.hits = coordinatorReport.hits;
            dynamicObject.coordinatorId = coordinatorReport.coordinator.id;

            return dynamicObject;
          });
          setTableData(coordinatorsSalesReportTableData);
          setCSVData(generateDataForCSVExport(coordinatorsSalesReportTableData, headCells, 'STL Operators Summary Report'));
        }, 100);
      },
    }));

    const { gameCtx } = useGameDashboardCtx();
    const [CSVData, setCSVData] = useState<any[]>([]);
    const csvLink: any = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
    const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);
    const [headCells, setHeadCells] = useState<HeadCell[]>([]);
    const [CUSRheadCells, setCUSRHeadCells] = useState<HeadCell[]>([]);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

    const [tableData, setTableData] = useState<Data[]>([]);

    const right: Align = 'right' as Align;

    const createHeadCell = (gameDrawTimesPrams: IGameDrawTime[], filterGameName?: string) => {
      let newHeadCell: HeadCell[] = [
        {
          id: 'coordinatorId',
          label: 'Coordinator ID',
          numeric: false,
          tbCellAlign: 'left',
          hide: true,
          className: 'coordinatorIDColumn',
        },
        {
          id: 'date',
          label: 'Date',
          numeric: false,
          tbCellAlign: 'left',
          hide: false,
          className: 'agentNameColumn',
        },
        {
          id: 'agentName',
          label: 'Operator',
          numeric: false,
          tbCellAlign: 'left',
          hide: false,
          className: 'agentNameColumn',
        },
      ];

      const gameDrawTimes2: IGameDrawTime2[] = gameDrawTimesPrams.map((gameDrawTime) => {
        return {
          id: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`.replace(/ /g, ''),
          label: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`,
          numeric: true,
          endTime: gameDrawTime.drawTime.endTime,
          gameName: gameDrawTime.game.gameType.name,
          tbCellAlign: right,
          hide: false,
          className: 'GDTColumns',
        };
      });
      const gameNames = Array.from(new Set(gameDrawTimes2.map((x) => x.gameName)));

      gameNames.forEach((game) => {
        if (filterGameName && game.replace(/ /g, '') === filterGameName) {
          newHeadCell = newHeadCell.concat(
            orderBy(
              gameDrawTimes2.filter((x) => x.gameName === game),
              ['endTime'],
              'asc',
            ),
          );
        } else if (!filterGameName) {
          newHeadCell = newHeadCell.concat(
            orderBy(
              gameDrawTimes2.filter((x) => x.gameName === game),
              ['endTime'],
              'asc',
            ),
          );
        }
      });

      newHeadCell = newHeadCell.concat([
        {
          id: 'totalSales',
          label: 'Total Sales',
          numeric: true,
          tbCellAlign: right,
          hide: false,
          className: 'totSalesColumn',
        },
        {
          id: 'payableWinner',
          label: 'Total Win',
          numeric: true,
          tbCellAlign: right,
          hide: false,
          className: 'payableWinnerColumn',
        },
      ]);

      setHeadCells(newHeadCell);
    };

    const createCoordinatorUshersHeadCell = (gameDrawTimesPrams: IGameDrawTime[], filterGameName?: string) => {
      let newHeadCell: HeadCell[] = [
        {
          id: 'usherId',
          label: 'Usher ID',
          numeric: false,
          tbCellAlign: 'left',
          hide: true,
          className: 'usherIDColumn',
        },
        {
          id: 'agentCode',
          label: 'Agent #',
          numeric: false,
          tbCellAlign: 'left',
          hide: false,
          className: 'agentNumColumn',
        },
        {
          id: 'agentName',
          label: 'Agent Name',
          numeric: false,
          tbCellAlign: 'left',
          hide: false,
          className: 'agentNameColumn',
        },
      ];

      const gameDrawTimes2: IGameDrawTime2[] = gameDrawTimesPrams.map((gameDrawTime) => {
        return {
          id: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`.replace(/ /g, ''),
          label: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`,
          numeric: true,
          endTime: gameDrawTime.drawTime.endTime,
          gameName: gameDrawTime.game.gameType.name,
          tbCellAlign: right,
          hide: false,
          className: 'GDTColumns',
        };
      });
      const gameNames = Array.from(new Set(gameDrawTimes2.map((x) => x.gameName)));

      gameNames.forEach((game) => {
        if (filterGameName && game.replace(/ /g, '') === filterGameName) {
          newHeadCell = newHeadCell.concat(
            orderBy(
              gameDrawTimes2.filter((x) => x.gameName === game),
              ['endTime'],
              'asc',
            ),
          );
        } else if (!filterGameName) {
          newHeadCell = newHeadCell.concat(
            orderBy(
              gameDrawTimes2.filter((x) => x.gameName === game),
              ['endTime'],
              'asc',
            ),
          );
        }
      });

      newHeadCell = newHeadCell.concat([
        {
          id: 'totalSales',
          label: 'Total Sales',
          numeric: true,
          tbCellAlign: right,
          hide: false,
          className: 'totSalesColumn',
        },
        {
          id: 'payableWinner',
          label: 'Total Win',
          numeric: true,
          tbCellAlign: right,
          hide: false,
          className: 'payableWinnerColumn',
        },
      ]);

      setCUSRHeadCells(newHeadCell);
    };

    // QUERIES
    useReportGameDrawTimesQuery({
      onCompleted(data) {
        const mappedGDT = data.gameDrawTimes.nodes.map((x) => mapGQLGameDrawTime(x));
        setGameDrawTimes(mappedGDT);
        createHeadCell(mappedGDT);
        createCoordinatorUshersHeadCell(mappedGDT);
      },
    });

    useEffect(() => {
      if (gameCtx.id.length > 0) {
        const gameName = gameCtx.gameType.name.replace(/ /g, '');
        createHeadCell(gameDrawTimes, gameName);
        createCoordinatorUshersHeadCell(gameDrawTimes, gameName);
      } else if (gameCtx.id === '') {
        createHeadCell(gameDrawTimes);
        createCoordinatorUshersHeadCell(gameDrawTimes);
      }
    }, [gameCtx]);

    const clickExportToCSV = async () => {
      csvLink.current.link.click();
    };

    return (
      <>
        <CSVLink data={CSVData} filename={`stl-operators-summary-report-${date}.csv`} className="hidden" ref={csvLink} target="_blank" />
        <Grid container mb={3}>
          <Grid item md={12} alignItems={'center'} display={'flex'} justifyContent={'end'}>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              onClick={() => {
                coordinatorSaleReportExportToPDF(tableData, headCells, dateRangeLabel, 'STL Operators Summary Report');
              }}>
              Export to PDF
            </Button>
            <Button onClick={clickExportToCSV} variant="contained" color="success" sx={{ ml: 2 }}>
              Export to CSV
            </Button>
            {/* <Skeleton variant="rounded" width={150} height={40} />
              <Skeleton variant="rounded" width={150} height={40} sx={{ ml: 2 }} /> */}
          </Grid>
        </Grid>
        <CoordinatorSalesTable
          rows={tableData}
          headCells={headCells}
          CSRtotalCount={CSRtotalCount}
          ushersSales={ushersSalesReport}
          CUSRheadCells={CUSRheadCells}
        />
      </>
    );
  },
);
