import { Cookies } from 'react-cookie';
import { LocalStorageKey } from '@/shared/constants';
import type { IUserAuth } from '@/shared/interfaces';

export function storeUserAuth(user: IUserAuth) {
  new Cookies().set(LocalStorageKey.TOKEN, user.token, { path: '/' });
  new Cookies().set(LocalStorageKey.USER_TYPE, user.userType, { path: '/' });
}

export function updateUserToken(token: string) {
  new Cookies().set(LocalStorageKey.TOKEN, token, { path: '/' });
}

export function deleteUserAuth() {
  new Cookies().remove(LocalStorageKey.TOKEN, { path: '/' });
  new Cookies().remove(LocalStorageKey.USER_TYPE, { path: '/' });
}

export function getUserAuth(): IUserAuth | null {
  const token = new Cookies().get(LocalStorageKey.TOKEN);
  const userType = new Cookies().get(LocalStorageKey.USER_TYPE);

  return token && userType ? { token, userType } : null;
}
