import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// graphql
import type { CreateGameDrawTimeInput } from '@/generated/graphql';
import { useCreateGameDrawTimeScheduleMutation } from '@/generated/graphql';

// interfaces
import type { IDrawTimeInput, ICreateDrawTimeInput, IGameDrawTime, IModalDetails } from '@/shared/interfaces';

// material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

// validation schema
const schema = yup
  .object({
    gameDrawTimeId: yup.string().required(),
  })
  .required();

interface CreateGameDrawtimeSchedInput {
  gameDrawTimeId: string;
  drawStartTime: string;
  drawEndTime: string;
  drawCutOffTime: string;
  startDateTime: string;
  endDateTime: string;
}
interface IProps {
  gameDrawTimes: IGameDrawTime[];
  gameDrawTimeRefetch: () => void;
  setRefetchGameDrawtimeShedData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddGameDrawtimeSched: React.FC<IProps> = ({ gameDrawTimes, gameDrawTimeRefetch, setRefetchGameDrawtimeShedData }) => {
  // create default value for datetime schedule range

  const dateToday = new Date();

  const dateList = dateToday
    .toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
    })
    .split(',')[0]
    .split('/');

  const date = `${dateList[2]}-${dateList[0]}-${dateList[1]}`;

  const time = dateToday.toLocaleString('en-US', {
    timeZone: 'Asia/Manila',
    hour12: false,
    minute: '2-digit',
    hour: '2-digit',
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: {},
  } = useForm<CreateGameDrawtimeSchedInput>({
    resolver: yupResolver(schema),
  });

  const [createGameDrawTimeSchedMutation] = useCreateGameDrawTimeScheduleMutation();

  const [drawTimeInputs, setDrawTimeInputs] = useState<CreateGameDrawtimeSchedInput>();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });

  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const onSubmit = async (dataInput: CreateGameDrawtimeSchedInput) => {
    console.log(dataInput);

    const { gameDrawTimeId, startDateTime, endDateTime, drawStartTime, drawEndTime, drawCutOffTime } = dataInput;

    // const { startTime, endTime, cutOff, name } = dataInput;
    const date = new Date().toISOString().split('T');

    const startDateTimeList = startDateTime.split('T');
    const endDateTimeList = endDateTime.split('T');

    console.log(new Date(startDateTimeList[0] + 'T' + updateTime(startDateTimeList[1]) + ':00Z').toISOString());

    setDrawTimeInputs({
      gameDrawTimeId,
      drawStartTime: new Date(date[0] + 'T' + updateTime(drawStartTime) + ':00Z').toISOString(),
      drawEndTime: new Date(date[0] + 'T' + updateTime(drawEndTime) + ':00Z').toISOString(),
      drawCutOffTime: new Date(date[0] + 'T' + updateTime(drawCutOffTime) + ':00Z').toISOString(),
      startDateTime: new Date(startDateTime).toISOString(),
      endDateTime: new Date(endDateTime).toISOString(),
    });

    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to add this schedule'],
    });
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmAdd = () => {
    if (drawTimeInputs) {
      activateLoading();

      createGameDrawTimeSchedMutation({
        variables: { input: drawTimeInputs },
        onCompleted(data) {
          setDrawTimeInputs(undefined);
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Schedule added successfully',
            type: 'success',
          });
          setShowLoading(false);
          setRefetchGameDrawtimeShedData(true);
        },
      });
    }
  };

  function updateTime(time: string) {
    const [hour, minute] = time.split(':');
    let newHour = parseInt(hour) - 8;
    if (newHour < 0) {
      newHour += 24;
    }
    return `${newHour.toString().length === 1 ? '0' + newHour.toString() : newHour.toString()}:${minute}`;
  }

  return (
    <>
      <Grid item md={12} justifyContent={'center'} display={'flex'} mb={4}>
        <Grid item xl={8} lg={11} sx={{ background: '#A2BCD8', pb: 3, pt: 4 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={2} alignItems={'center'} display={'flex'} justifyContent={'end'}>
                <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small">
                  <InputLabel id="demo-select-small">DrawTime</InputLabel>
                  <Select labelId="demo-select-small" id="demo-select-small" {...register('gameDrawTimeId')}>
                    {gameDrawTimes.map((gameDrawTime) => (
                      <MenuItem value={gameDrawTime.id}>{gameDrawTime.drawTime.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <TextField
                  size="small"
                  id="datetime-local"
                  label="Start Datetime"
                  type="datetime-local"
                  defaultValue={`${date}T${time}`}
                  {...register('startDateTime')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  size="small"
                  id="datetime-local"
                  label="End Datetime"
                  type="datetime-local"
                  defaultValue={`${date}T${time}`}
                  {...register('endDateTime')}
                  sx={{ mt: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                <TextField
                  {...register('drawEndTime')}
                  size="small"
                  label="Draw Time"
                  type="time"
                  defaultValue="00:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  {...register('drawStartTime')}
                  size="small"
                  label="Start Time"
                  type="time"
                  defaultValue="00:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ ml: 1 }}
                />
                <TextField
                  {...register('drawCutOffTime')}
                  size="small"
                  label="Cutt-Off"
                  type="time"
                  defaultValue="00:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ ml: 1 }}
                />
              </Grid>
              <Grid item md={1} alignItems={'center'} display={'flex'} justifyContent={'end'} pl={0}>
                <Button sx={{ ml: 3 }} variant="contained" size="small" type="submit">
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
