import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';

// material ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';

// graphql
import { useTelegramReportGameDrawTimesQuery, useTriggerManualReportMutation } from '@/generated/graphql';

// interfaces
import type { ICreateTelegramReportTypeInput, ITelegramReportTypeTable, IGameDrawTime } from '@/shared/interfaces';
import { mapGQLGameDrawTime } from '@/shared/interfaces';

// icon
import { MdOutlinePostAdd } from 'react-icons/md';

// Styled Paper component with scrollable content
const ScrollablePaper = styled(Paper)(({ theme }) => ({
  maxHeight: 500, // Adjust the height as needed
  marginTop: 10,
  marginRight: 10,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    // backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
}));

interface ITelegramReportTypeExt extends ITelegramReportTypeTable {
  isSelected: boolean;
}

interface IUsherProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reportTypes: ITelegramReportTypeTable[];
}

export const ModalTriggerManualReport = ({ open, setOpen, reportTypes }: IUsherProps) => {
  const [triggerManualReportMutation] = useTriggerManualReportMutation();

  const [reportTypeOptions, setReportTypeOptions] = useState<ITelegramReportTypeExt[]>([]);
  const [date, setDate] = useState<string>('');
  const [gameDrawtime, setGameDrawtime] = useState<string>('None');
  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);

  const handleClose = () => setOpen(false);

  const handleChooseReport = (index: number) => {
    setReportTypeOptions((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item)));
  };

  const submit = () => {
    const reportTypeIds = reportTypeOptions.filter((x) => x.isSelected === true).map((y) => y.id);
    console.log(date, reportTypeIds);

    if (reportTypeIds.length > 0 && date != '') {
      triggerManualReportMutation({
        variables: {
          input: {
            date,
            reportTypeIds,
            gameDrawtTimeId: gameDrawtime,
          },
        },
        onCompleted(data) {
          setDate('');
          setGameDrawtime('None');
          setReportTypeOptions((prevItems) => prevItems.map((item) => (item.isSelected === true ? { ...item, isSelected: false } : item)));
          setOpen(false);
        },
      });
    }
  };

  useTelegramReportGameDrawTimesQuery({
    variables: {
      first: 9999,
    },
    onCompleted(data) {
      const mappedData = data.gameDrawTimes.nodes.map((x) => mapGQLGameDrawTime(x));

      setTimeout(() => {
        const sortedGameDrawTimes = orderBy(mappedData, ['drawTime.endTime'], ['asc']);
        setGameDrawTimes(sortedGameDrawTimes);
      }, 200);
    },
  });

  useEffect(() => {
    console.log('UPDTT');
    setReportTypeOptions(reportTypes.map((x) => ({ ...x, isSelected: false })));
  }, [reportTypes]);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'inherit',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
          },
          content: {
            width: '440px',
            overflow: 'unset',
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            overflow: 'unset',
            borderRadius: '5px',
          }}
          mt={9}>
          <Box sx={{ px: 5, py: 2 }} alignItems={'center'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="subtitle2" m={0}>
                Trigger Manual Report
              </Typography>
              <MdOutlinePostAdd fontSize={18} />
            </Stack>
          </Box>
          <Divider />

          <Box px={5} pt={2} pb={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <FormControl sx={{ mt: 5, minWidth: 200 }} variant="standard">
                <TextField
                  label="Report date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormControl>
            </Stack>

            <Grid container justifyContent={'center'}>
              <Grid item xs={10}>
                <ScrollablePaper>
                  <List>
                    {reportTypes.length === 0 ? (
                      <ListItem disablePadding>
                        <ListItemText primary="No items available" />
                      </ListItem>
                    ) : (
                      reportTypeOptions.map((reportType, index) => (
                        <ListItem
                          key={index}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              onClick={() => handleChooseReport(index)}
                              // onChange={handleToggle(value)}
                              checked={reportType.isSelected}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          }>
                          <Typography variant="caption" display="block">
                            {reportType.name}
                          </Typography>
                        </ListItem>
                      ))
                    )}
                  </List>
                </ScrollablePaper>
              </Grid>
            </Grid>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <FormControl sx={{ width: '80%', mt: 3 }} size="small">
                <InputLabel id="demo-select-small-label">Game drawtime</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={gameDrawtime}
                  label="Game drawtime"
                  onChange={(event) => {
                    setGameDrawtime(event.target.value);
                  }}>
                  {gameDrawTimes.map((itemGDT, index) => (
                    <MenuItem key={index} value={itemGDT.id}>
                      {itemGDT.game.gameType.name} {itemGDT.drawTime.name}
                    </MenuItem>
                  ))}
                  <MenuItem key={999} value={'None'}>
                    None
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Box>

          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} mt={2} pb={3} mr={2}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              sx={{
                background: 'grey',
                fontSize: 10,
                fontWeight: 600,
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '8px',
                paddingBottom: '8px',
                textTransform: 'unset',
              }}
              onClick={handleClose}>
              Cancel
            </Button>
            <Button
              sx={{
                ml: 1,
                fontSize: 10,
                fontWeight: 600,
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '8px',
                paddingBottom: '8px',
                textTransform: 'unset',
              }}
              type="submit"
              size="small"
              variant="contained"
              onClick={submit}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
