import { IGameDrawTime, mapGQLGameDrawTime } from './game-drawtime';
import { NumberCombinationSales, NumberCombinationSalesFilter, NumberCombinationSalesSortField, SortDirection } from '@/generated/graphql';

export interface INumberCombinationSales {
  id: string;
  numberCombination: string;
  sales: number;
  numberOfBets: number;
  date: string;
}

export interface IOpstatsTableData {
  id: string;

  numberCombination: string;
  numberCombinationOriginal: string;
  sales: number;
  numberOfBets: number;
}
export interface INumberCombinationSalesQueryVariable {
  filterBy: NumberCombinationSalesFilter;
}
export function mapGQLNumberCombinationSales(numberCombinationSales?: NumberCombinationSales | null): INumberCombinationSales {
  return {
    id: numberCombinationSales?.id ? numberCombinationSales.id : '',
    numberCombination: numberCombinationSales?.numberCombination ? numberCombinationSales.numberCombination : '',
    sales: numberCombinationSales?.sales ? numberCombinationSales.sales : 0,
    numberOfBets: numberCombinationSales?.numberOfBets ? numberCombinationSales.numberOfBets : 0,
    date: numberCombinationSales?.date ? numberCombinationSales.date : '',
  };
}
export function mapGQLOpstatsTableData(opstatsTableData?: NumberCombinationSales | null): IOpstatsTableData {
  return {
    id: opstatsTableData?.id ? opstatsTableData.id : '',
    numberCombination: opstatsTableData?.numberCombination ? opstatsTableData.numberCombination.replace(/,/g, '') : '',
    numberCombinationOriginal: opstatsTableData?.numberCombination ? opstatsTableData.numberCombination : '',
    sales: opstatsTableData?.sales ? opstatsTableData.sales : 0,
    numberOfBets: opstatsTableData?.numberOfBets ? opstatsTableData.numberOfBets : 0,
  };
}
