import { Buffer } from 'buffer';
import { Box, FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useState } from 'react';

// graphql
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import type { PageInfo } from '@/generated/graphql';
import { useGamesQuery } from '@/generated/graphql';

// images
import { ChangePageType } from '@/shared/enums';
import type { IGameType, IQueryPaginationParams, GameTypePhoto, IGame } from '@/shared/interfaces';
import { mapGQLGame } from '@/shared/interfaces';
import ball20 from '../../../../assets/images/ball20.png';

// interface

// enums

// components
import { CreateGame, GameTypeCard } from '../components';

// material ui

export function GamesPage() {
  const [games, setGames] = useState<IGame[]>([]);
  const [imagePerGameType, setImagePerGameType] = useState<GameTypePhoto[]>([]);
  const [searchedGame, setSearchedGame] = useState<IGame[]>([]);
  const [queryGameParams, setQueryGameParams] = useState<IQueryPaginationParams>({ first: 8 });

  // pagination
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const GetGameTypePhoto = (id: string) =>
    axios.get(
      (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL) +
        '/api/v1/get-game-type-photo/' +
        id,
      {
        responseType: 'arraybuffer',
      },
    );

  const getGames = useGamesQuery({
    variables: queryGameParams,
    onCompleted: (data) => {
      console.log('Receive Date from server');
      const resGames: IGame[] = data.games.nodes.map((game) => mapGQLGame(game));
      // setPagePagination({...pagePagination, hasNextPage: data.games.pageInfo.hasNextPage})
      setPageInfo(data.games.pageInfo);
      setTotalCount(data.games.totalCount);

      setTimeout(() => {
        setGames(resGames);
        setPageInfo(data.games.pageInfo);
      }, 100);

      const resImagePerGameType: GameTypePhoto[] = [];
      resGames.map((game, index) => {
        GetGameTypePhoto(game?.gameType?.id ?? '')
          .then((res) => {
            if (game?.gameType?.id) {
              if (res.headers['content-type'] === 'image/jpeg') {
                const imageBase64 = Buffer.from(res.data, 'binary').toString('base64');
                resImagePerGameType.push({ id: game.gameType.id, image: `data:image/jpeg;base64,${imageBase64}` });
              } else {
                resImagePerGameType.push({ id: game?.gameType?.id, image: ball20 });
              }
            }
            if (index === resGames.length - 1) {
              setImagePerGameType(resImagePerGameType);
            }
          })
          .catch((err) => {
            console.error(err, 'EEEERRR');
          });
      });
    },
  });

  const refetchDataGames = () => {
    const { refetch } = getGames;

    refetch().then((res) => {
      if (!res.loading && !res.error && res.data?.games) {
        setGames(res.data.games.nodes.map((game) => mapGQLGame(game)));
      }
    });
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setQueryGameParams({
        first: queryGameParams.first,
        after: pageInfo.endCursor,
      });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setQueryGameParams({
        first: queryGameParams.first,
        before: pageInfo.startCursor,
      });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setQueryGameParams({
      first: intValue,
    });
  };

  const searchHandler = (value: string) => {
    type IGameTypeKey = keyof IGameType;
    const searchKeys: IGameTypeKey[] = ['name'];

    const newGames = games.filter((game) =>
      searchKeys.some((searchKey) => game.gameType && game.gameType[searchKey]?.toString().includes(value)),
    );
    setSearchedGame(newGames);
  };

  return (
    <>
      <Card>
        <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} px={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" ml={2} mt={1} component="div">
              Search Game Type
            </Typography>
            <FormControl fullWidth sx={{ ml: 2, mb: 2, mt: 0 }}>
              <TextField
                onChange={(e) => {
                  searchHandler(e.target.value);
                }}
                id="filled-search"
                label="Search field"
                type="search"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <CreateGame refetchDataGames={refetchDataGames} />
          </Grid>
        </Grid>
      </Card>
      <Box sx={{ background: '#C5D8EC', p: 3, mt: 0.4 }}>
        <Grid container spacing={4} justifyContent={'start'}>
          {(searchedGame.length >= 1 ? searchedGame : games)
            // .slice(pagePagination.from, pagePagination.to)
            .map((game) => (
              <Grid item xl={2} lg={3}>
                <GameTypeCard game={game} gameTypePhoto={imagePerGameType} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 1, justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
        <Typography variant="body2" mr={4} gutterBottom>
          Rows per page:
        </Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
          <Select
            onChange={(e) => {
              handleChangeRowsPerPage(e.target.value);
            }}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={queryGameParams.first}
            label="Row">
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={24}>24</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" mx={4} gutterBottom>
          1 - 8 of {totalCount}
        </Typography>
        <IconButton
          onClick={() => handleChangePage(ChangePageType.PREV)}
          disabled={pageInfo?.hasPreviousPage ? false : true}
          size={'small'}
          sx={{ mr: 2 }}
          aria-label="prev">
          <IoIosArrowBack />
        </IconButton>
        <IconButton
          onClick={() => handleChangePage(ChangePageType.NEXT)}
          disabled={pageInfo?.hasNextPage ? false : true}
          size={'small'}
          aria-label="next">
          <IoIosArrowForward />
        </IconButton>
      </Box>
    </>
  );
}
