import type { GameDoubleRepeatingPayout } from '@/generated/graphql';

import { mapGQLGameTypeData } from './game-type';
import type { IGameType } from './game-type';

export interface IGameDoubleRepeatingPayout {
  id: string;
  payout: number;

  createdAt: string;
  updatedAt: string;

  gameType: IGameType;
}

export function mapGQLGameDoubleRepeatingPayout(gameDoubleRepeatingPayout?: GameDoubleRepeatingPayout | null): IGameDoubleRepeatingPayout {
  return {
    id: gameDoubleRepeatingPayout?.id ?? '',
    payout: gameDoubleRepeatingPayout?.payout ?? 0,
    updatedAt: gameDoubleRepeatingPayout ? gameDoubleRepeatingPayout.updatedAt ?? '' : '',
    createdAt: gameDoubleRepeatingPayout ? gameDoubleRepeatingPayout.createdAt ?? '' : '',

    gameType: mapGQLGameTypeData(gameDoubleRepeatingPayout?.gameType),
  };
}
