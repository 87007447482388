import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';

// material ui
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';

// graphql
import { UpdateCoordinatorInput, useUpdateCoordinatorMutation } from '@/generated/graphql';

// interfaces
import type { IEditCoordinatorInput, ICoordinatorTable } from '@/shared/interfaces';
import { useEffect } from 'react';

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  coordinator: ICoordinatorTable | undefined;
}

export const EditCoordinator = ({ open, setOpen, refetchData, coordinator }: IUsherProps) => {
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      name: yup.string().required(),
      area: yup.string().required(),
      description: yup.string().notRequired(),
      email: yup.string().required(),
      username: yup.string().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditCoordinatorInput>({
    resolver: yupResolver(schema),
  });

  const [updateCoordinatorMutation] = useUpdateCoordinatorMutation();

  const onSubmit = async (dataInput: IEditCoordinatorInput) => {
    const { name, description, username, email, area } = dataInput;

    if (coordinator) {
      const input: UpdateCoordinatorInput = {
        id: coordinator.id,
        name,
        area,
        description,
        username,
        email,
      };

      const { data } = await updateCoordinatorMutation({
        variables: {
          input,
        },
      });

      if (data) console.log(data);
      if (errors) console.log(errors);
      refetchData();
      handleClose();
      reset();
    }
  };

  useEffect(() => {
    if (coordinator) {
      reset({
        name: coordinator.name,
        area: coordinator.area,
        description: coordinator.description,
        email: coordinator.email,
        username: coordinator.username,
      });
    }
  }, [coordinator]);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 400,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box sx={{}} p={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              variant="h4"
              component="h4">
              Edit Operators
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={9}>
                <Grid container spacing={1}>
                  <TextField
                    required
                    label="Name"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('name')}
                  />
                  <TextField
                    required
                    label="Area"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('area')}
                  />
                  <TextField
                    label="Description"
                    multiline
                    rows={4}
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    {...register('description')}
                  />
                  <TextField
                    required
                    label="Username"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('username')}
                  />
                  <TextField
                    required
                    label="Email"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('email')}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                }}
                mt={10}>
                <Button type="submit">
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                    }}
                    p={1}>
                    Submit
                  </Box>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
