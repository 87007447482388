import { IGetTelegramBotUpdateResp } from '@/shared/interfaces';
import axios from 'axios';

interface ITelegramPayload {
  chat_id: string;
  text: string;
  reply_to_message_id?: string;
}

export async function sendMessageToTelegram(token: string, chat_id: string, topicId?: string): Promise<boolean> {
  try {
    const url = `https://api.telegram.org/bot${token}/sendMessage`;

    let payload: ITelegramPayload = {
      chat_id,
      text: 'Test message',
    };

    if (topicId && topicId.length > 0) {
      payload = {
        chat_id,
        text: 'Test message',
        reply_to_message_id: topicId,
      };
    }

    const { status } = await axios.post(url, payload);

    if (status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}
