import { IUpdateUsherInput, IUpdateUsherPassword, IUsherTable } from '@/shared/interfaces';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import Modal from 'react-modal';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ChangeEvent, useEffect, useState } from 'react';
import { useUpdateUsherPasswordMutation } from '@/generated/graphql';

interface IUsherProp {
  datas: IUsherTable | undefined;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
  refetchData: () => void;
}

export function ChangePasswordUsher(props: IUsherProp) {
  const { datas, isOpen, openEvent, refetchData } = props;

  const handleClose = () => openEvent(false);

  const schema = yup
    .object({
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[A-Za-z0-9!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        )
        .required(),
      confirmPassword: yup.string().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateUsherPassword>({
    resolver: yupResolver(schema),
  });

  // const [newPassword, setNewPassword] = useState<string>('');
  // const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [updateUsherPasswordMutation] = useUpdateUsherPasswordMutation();

  const onSubmit = async (dataInput: IUpdateUsherPassword) => {
    console.log('trigger submit');
    const { newPassword, confirmPassword } = dataInput;
    const input: IUpdateUsherPassword = {
      newPassword,
      confirmPassword,
    };

    if (input.confirmPassword != input.newPassword) {
      setErrorMessage('Password Not Match!');
    } else {
      console.log('success');
      const { data } = await updateUsherPasswordMutation({
        variables: {
          input: {
            usherId: datas?.id ?? '',
            password: input.newPassword,
          },
        },
      });

      if (data) console.log(data);
      //  if (errors) alert(errors);
      refetchData();
      handleClose();
      reset();
      setErrorMessage('');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          position: 'absolute',
          margin: '0.1% auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: 0,
          marginRight: '-50%',
          transform: 'translate(-50%, -48vh)',
          border: '0px',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        },
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: 450,
        }}>
        <Divider
          sx={{
            height: '25px',
            backgroundColor: '#87ADD4',
          }}
        />
        <Box sx={{}} p={5}>
          <Typography
            sx={{
              textAlign: 'center',
            }}
            id="modal-modal-title"
            variant="h5"
            component="h5">
            Change Usher Password
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={9}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="New Password"
                    type={'password'}
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    error={errorMessage !== ''}
                    helperText={errorMessage !== '' ? errorMessage : ' '}
                    {...register('newPassword')}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Confirm Password"
                    type={'password'}
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('confirmPassword')}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
              mt={10}>
              <Button type="submit">
                <Box
                  sx={{
                    backgroundColor: '#427FC1',
                    color: '#fff',
                  }}
                  p={1}>
                  Change Password
                </Box>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
