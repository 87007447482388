import type { TelegramReport, GetTelegramReportsFilter } from '@/generated/graphql';
import { ITelegramReportType, mapGQLTelegramReportType } from './telegram-report-type';

export interface ITelegramReport {
  id: string;
  name: string;
  description: string;

  telegramReportType: ITelegramReportType;

  createdAt: string;
  updatedAt: string;
}

export interface ITelegramReportTable {
  id: string;
  name: string;
  reportType: string;
  description: string;
  createdAt: string;
}

export interface ITelegramReportQueryVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: GetTelegramReportsFilter;
}

export interface ICreateTelegramReportInput {
  name: string;
  description: string;
  telegramReportTypeId: string;
}

export function mapGQLTelegramReport(notification?: TelegramReport | null): ITelegramReport {
  return {
    id: notification?.id ?? '',
    name: notification ? notification.name ?? '' : '',
    description: notification ? notification.description ?? '' : '',
    telegramReportType: mapGQLTelegramReportType(notification?.telegramReportType),
    createdAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
  };
}
