import React, { useEffect, useState } from 'react';

// graphql
import { PageInfo } from '@/generated/graphql';

// material ui
import { Grid, Button, Box, Typography } from '@mui/material';
import Modal from 'react-modal';

// interface
import { IBet } from '@/shared/interfaces';

// components
import { TableComponent, Data, HeadCell } from '../table';

// services
import { winnerBetsExportToPDF } from '../../services/export-to-pdf';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

interface Props {
  usherWinBets: Data[];
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UsherWinnerBetsModal: React.FC<Props> = ({ usherWinBets, modalIsOpen, setModalIsOpen }) => {
  const [headCells, setHeadCells] = useState<HeadCell[]>([
    {
      id: 'createdAt',
      label: 'Date',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'gameType',
      label: 'Game Type',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'draw',
      label: 'Draw',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'numberCombination',
      label: 'No.',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'transactionCode',
      label: 'Transaction Code',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'betType',
      label: 'Type',
      numeric: false,
      tbCellAlign: 'left',
      hide: false,
    },
    {
      id: 'amount',
      label: 'Amount',
      numeric: false,
      tbCellAlign: 'right',
      hide: false,
    },
    {
      id: 'payable',
      label: 'Payable',
      numeric: false,
      tbCellAlign: 'right',
      hide: false,
    },
  ]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Modal">
        <Box sx={{ height: 15, background: '#87ADD4' }} />
        <Box sx={{ width: 1200, py: 3, pb: 3, pt: 1 }}>
          <TableComponent rows={usherWinBets} headCells={headCells}></TableComponent>
        </Box>

        <Box justifyContent={'end'} display={'flex'} px={2} pb={3}>
          <Button variant="contained" size="small" type="submit" onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};
