import type { Bet, BetFilterField, GreedySoldOutBet } from '@/generated/graphql';

import { mapGQLBetDetail } from './bet-detail';
import { mapGQLGameDrawTime } from './game-drawtime';
import { mapGQLTransaction } from './transaction';
import { mapGQLBetType } from './bet-type';

import type { BetStatusEnum } from '../enums/bet-status';

import type { IBetType } from './bet-type';

import type { IBetDetail } from './bet-detail';

import type { IGameDrawTime } from './game-drawtime';

import type { ITransaction } from './transaction';

export interface IBet {
  id: string;
  notes: string;
  amount: number;
  originalBetAmount: number;
  isWinner: boolean;
  numberCombination: string;
  createdAt: string;
  updatedAt: string;
  gameDrawTime: IGameDrawTime;
  betDetail: IBetDetail;
  transaction: ITransaction;
  winAmount: number;
  betAmountBeforeGreedy: number;
  returnBetAmount: number;
  isOriginalBet: boolean;
}

export interface IGreedySoldoutBet {
  id: string;
  amount: number;
  originalBetAmount: number;
  numberCombination: string;
  createdAt: string;
  updatedAt: string;
  gameDrawTime: IGameDrawTime;
  betType: IBetType;
  transaction: ITransaction;
}

export interface IBetTableData {
  id: string;
  transactionCode: string;
  transactionId: string;
  numberCombination: string;
  betType: string;
  betAmountBeforeGreedy: number;
  amount: number;
  returnBetAmount: number;
  originalBetAmount: number;
  draw: string;
  agent: string;
  time: string;
  status: BetStatusEnum;
}

export interface IBetQueryVariable {
  filterBy?: BetFilterField;
  first: number;
  after?: string;
  before?: string;
}

export function mapGQLBet(bet?: Bet | null): IBet {
  return {
    id: bet?.id ?? '',
    notes: bet ? bet.notes ?? '' : '',
    amount: bet ? bet.amount ?? 0 : 0,
    originalBetAmount: bet ? bet.originalBetAmount ?? 0 : 0,
    isWinner: bet ? bet.isWinner ?? false : false,
    isOriginalBet: bet ? bet.isOriginalBet ?? false : false,
    numberCombination: bet ? bet.numberCombination ?? '' : '',
    createdAt: bet
      ? new Date(bet.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: bet
      ? new Date(bet.updatedAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
        }) ?? ''
      : '',
    gameDrawTime: mapGQLGameDrawTime(bet?.gameDrawTime),
    betDetail: mapGQLBetDetail(bet?.betDetail),
    transaction: mapGQLTransaction(bet?.transaction),
    winAmount: bet ? bet.winAmount ?? 0 : 0,
    betAmountBeforeGreedy: bet ? bet.betAmountBeforeGreedy ?? 0 : 0,
    returnBetAmount: bet ? bet.returnBetAmount ?? 0 : 0,
  };
}

export function mapGQLGreedySoldOutBet(bet?: GreedySoldOutBet | null): IGreedySoldoutBet {
  return {
    id: bet?.id ?? '',
    amount: bet ? bet.amount ?? 0 : 0,
    originalBetAmount: bet ? bet.originalBetAmount ?? 0 : 0,
    numberCombination: bet ? bet.numberCombination ?? '' : '',
    createdAt: bet
      ? new Date(bet.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: bet
      ? new Date(bet.updatedAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
        }) ?? ''
      : '',
    gameDrawTime: mapGQLGameDrawTime(bet?.gameDrawTime),
    betType: mapGQLBetType(bet?.betType),
    transaction: mapGQLTransaction(bet?.transaction),
  };
}
