import { Box, Button, Typography } from '@mui/material';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import Modal from 'react-modal';

// graphql
import { useDeleteReportDestinationMutation } from '@/generated/graphql';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export interface IModalConfirmationDetails {
  isOpen: boolean;
  title: string;
  messages: string[];
}

interface IProps {
  modalDetails: IModalConfirmationDetails;
  setModalDetails: React.Dispatch<React.SetStateAction<IModalConfirmationDetails>>;
  id: string | null;
  confirmText: string;
  refetchData: () => void;
}

export const ConfirmationModal: React.FC<IProps> = ({ modalDetails, setModalDetails, id, confirmText, refetchData }) => {
  const [deleteReportDestinationMutation] = useDeleteReportDestinationMutation();

  function closeModal() {
    setModalDetails({ ...modalDetails, isOpen: false });
  }

  const confirm = async () => {
    if (id) {
      const { data } = await deleteReportDestinationMutation({ variables: { deleteReportDestinationId: id } });

      if (data) {
        console.log('🚀 ~ confirm ~ data:', data);
      }

      refetchData();
      closeModal();
    }
  };

  return (
    <>
      <Modal isOpen={modalDetails.isOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <Box sx={{ width: 500, py: 5, background: '#fff' }}>
          <Box justifyContent={'center'} display={'flex'} mb={3}>
            <AiOutlineExclamationCircle size={90} color={'#facea8'} />
          </Box>
          <Typography variant="h5" gutterBottom component="div" fontSize={30} fontWeight={600} textAlign={'center'}>
            {modalDetails.title}
          </Typography>
          {modalDetails.messages.map((message) => (
            <Typography variant="body1" gutterBottom textAlign={'center'}>
              {message}
            </Typography>
          ))}
          <Box justifyContent={'center'} display={'flex'} mt={5}>
            <Button variant="contained" sx={{ mr: 3, background: '#427FC1' }} onClick={confirm}>
              {confirmText}
            </Button>
            <Button variant="contained" sx={{ mr: 3, background: '#B44F4F' }} onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
