import type { DrawAdmin } from '@/generated/graphql';

import { IUser, mapGQLUser } from './user';

export interface IDrawAdmin {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  user: IUser;
}

export interface IDrawAdminQueryVariable {
  first: number;
  after?: string;
  before?: string;
}

export interface IDrawAdminTable {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  createdAt: string;
}

export interface IAddDrawAdminInput {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
}

export interface IUpdateDrawAdminInput {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
}

export interface IUpdateDrawAdminPassword {
  newPassword: string;
  confirmPassword: string;
}

export function mapGQLDrawAdmin(drawAdmin?: DrawAdmin | null): IDrawAdmin {
  return {
    id: drawAdmin?.id ?? '',
    firstName: drawAdmin ? drawAdmin.firstName ?? '' : '',
    lastName: drawAdmin ? drawAdmin.lastName ?? '' : '',
    updatedAt: drawAdmin ? drawAdmin.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    user: mapGQLUser(drawAdmin?.user),
    createdAt: drawAdmin
      ? new Date(drawAdmin.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
  };
}
