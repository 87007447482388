import { yupResolver } from '@hookform/resolvers/yup';

import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';
import { useCreateRegistrationCodeMutation } from '@/generated/graphql';

import type { ICreateRegistrationCodeInput } from '@/shared/interfaces';

// material ui
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

//Component
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

interface IProps {
  refetch: () => void;
}

export const AddRegistrationCodes: React.FC<IProps> = ({ refetch }) => {
  const [createRegistrationCode] = useCreateRegistrationCodeMutation();

  const [numberOfCodes, seNumberOfCodes] = useState<number | undefined>();

  const [open, setOpen] = React.useState(false);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      numberOfCodes: yup.number().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateRegistrationCodeInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (dataInput: ICreateRegistrationCodeInput) => {
    seNumberOfCodes(dataInput.numberOfCodes);
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to add code'],
    });
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmAdd = () => {
    activateLoading();

    if (numberOfCodes) {
      createRegistrationCode({
        variables: { numberOfCodes },
        onCompleted(data) {
          refetch();
          setOpen(false);
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Codes added successfully',
            type: 'success',
          });
          setShowLoading(false);
          reset();
        },
      });
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          width: '100%',
        }}>
        <Box
          sx={{
            backgroundColor: '#427FC1',
            color: '#fff',
            fontSize: '16px',
            width: '100%',
          }}
          p={1}>
          Add Codes
        </Box>
      </Button>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 300,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box sx={{}} p={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              id="modal-modal-title"
              variant="h6"
              component="h6">
              Add Registration Codes
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={2}>
                <TextField
                  required
                  label="Number of Codes"
                  sx={{
                    width: '100%',
                  }}
                  size="small"
                  {...register('numberOfCodes')}
                />
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                }}
                mt={5}>
                <Button type="submit">
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                    }}
                    p={1}>
                    Submit
                  </Box>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
