import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

// material-ui
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// graphql
import { useCreateGameHotNumberMutation, CreateGameHotNumberInput } from '@/generated/graphql';

// components
import type { ICreateGameHotNumber } from '@/shared/interfaces';
import { ConfirmationModal, IModalConfirmationDetails } from '@/components/modal/confirmation';
import { FeedbackAlert, IAlertFeedBackDetails } from '@/components/feedback/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

const schema = yup
  .object({
    payout: yup.number().required(),
    salesLimit: yup.number().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  })
  .required();

interface Props {
  isAddHotNumberModalOpen: boolean;
  setIsAddHotNumberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedHotNumber: string;
}

export const AddHotNumberModal: React.FC<Props> = ({ isAddHotNumberModalOpen, setIsAddHotNumberModalOpen, selectedHotNumber }) => {
  const { gameCtx } = useGameDashboardCtx();

  // graphql
  const [createGameHotNumber] = useCreateGameHotNumberMutation();

  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Number',
    messages: [],
  });

  const [addHotNumberInputs, setAddHotNumberInputs] = useState<ICreateGameHotNumber>();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateGameHotNumber>({
    resolver: yupResolver(schema),
  });

  const closeModal = () => {
    setIsAddHotNumberModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const onSubmit = async (dataInput: ICreateGameHotNumber) => {
    setAddHotNumberInputs(dataInput);
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You are about to add a new hot number.'],
    });
  };

  const confirmAdd = () => {
    if (addHotNumberInputs) {
      const { payout, salesLimit, startDate, endDate } = addHotNumberInputs;

      const input: CreateGameHotNumberInput = {
        numberCombination: selectedHotNumber,
        payout,
        salesLimit,
        startDate,
        endDate,
        gameId: gameCtx.id,
      };

      activateLoading();

      createGameHotNumber({
        variables: { input },
        onCompleted: () => {
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Hot number added successfully',
            type: 'success',
          });
          setIsAddHotNumberModalOpen(false);
          setShowLoading(false);
          reset();
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isAddHotNumberModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div style={{ paddingBottom: '1em' }}>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} mb={4}>
              Add To Hot Number
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid justifyContent={'center'} display={'flex'}>
                <Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2} width={250}>
                  <TextField label="Payout" size="small" variant="outlined" fullWidth {...register('payout')} />
                  <TextField label="Sales Limit" size="small" variant="outlined" fullWidth {...register('salesLimit')} />
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    size="small"
                    type={'date'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('startDate')}
                  />
                  <TextField
                    label="End Date"
                    variant="outlined"
                    size="small"
                    type={'date'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    {...register('endDate')}
                  />
                  <Typography variant="h6" gutterBottom>
                    Number Combination: {selectedHotNumber.replace(/,/g, '')}
                  </Typography>
                </Stack>
              </Grid>
              <div
                className="gameedit-submit"
                style={{ display: 'flex', paddingBottom: '2em', justifyContent: 'center', alignContent: 'center' }}>
                <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
