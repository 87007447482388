import { useUnassignUsherCoordinatorMutation } from '@/generated/graphql';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { SetStateAction } from 'react';

import Modal from 'react-modal';

interface IUsherProps {
  usherIds: string[];
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  refetchData: () => void;
}

export function CoordinatorUnasignUsher(props: IUsherProps) {
  const { usherIds, open, setOpen, refetchData } = props;

  const [removeUsher] = useUnassignUsherCoordinatorMutation();

  const removeUsherOnClick = async () => {
    const { data } = await removeUsher({
      variables: {
        usherIds: usherIds,
      },
    });

    console.log(data);

    refetchData();
    handleClose();
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 450,
            height: 350,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box p={1} mt={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              id="modal-modal-title"
              variant="h3"
              component="h3">
              Remove usher?
            </Typography>
          </Box>

          <Box m={9}>
            <Grid container spacing={1}>
              <Grid item alignItems={'center'} alignContent="center" xs={6}>
                <Button
                  sx={{
                    width: '100%',
                  }}
                  onClick={removeUsherOnClick}>
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                      width: '100%',
                    }}
                    p={1}>
                    Yes
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                  }}
                  onClick={handleClose}>
                  <Box
                    sx={{
                      backgroundColor: '#8E0A0E',
                      color: '#fff',
                      width: '100%',
                    }}
                    p={1}>
                    No
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
