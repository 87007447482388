import type { SystemRole } from '@/generated/graphql';

export interface ISystemRole {
  id: string;
  name: string;
  description: string;

  createdAt: string;
  updatedAt: string;
}

export function mapGQLSystemRole(systemRole?: SystemRole | null): ISystemRole {
  return {
    id: systemRole?.id ?? '',
    name: systemRole ? systemRole.name ?? '' : '',
    description: systemRole ? systemRole.description ?? '' : '',

    createdAt: systemRole ? systemRole.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: systemRole ? systemRole.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
  };
}
