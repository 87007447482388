import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

// material-ui
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// graphql
import { useCreateIndividualLimitMutation, CreateGameSalesNumberLimitInput } from '@/generated/graphql';

// components
import type { ICreateGameNumberSalesLimit } from '@/shared/interfaces';
import { ConfirmationModal, IModalConfirmationDetails } from '@/components/modal/confirmation';
import { FeedbackAlert, IAlertFeedBackDetails } from '@/components/feedback/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

const schema = yup
  .object({
    limit: yup.number().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  })
  .required();

interface Props {
  isAddNumberSalesLimitModalOpen: boolean;
  setIsAddNumberSalesLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNumberSalesLimit: string;
}

export const AddNumberSalesLimitModal: React.FC<Props> = ({
  isAddNumberSalesLimitModalOpen,
  setIsAddNumberSalesLimitModalOpen,
  selectedNumberSalesLimit,
}) => {
  const { gameCtx } = useGameDashboardCtx();

  // graphql
  const [createGameNumberSalesLimit] = useCreateIndividualLimitMutation();

  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Result',
    messages: [],
  });

  const [addNumberSalesLimitInputs, setAddNumberSalesLimitInputs] = useState<ICreateGameNumberSalesLimit>();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateGameNumberSalesLimit>({
    resolver: yupResolver(schema),
  });

  const closeModal = () => {
    setIsAddNumberSalesLimitModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const onSubmit = async (dataInput: ICreateGameNumberSalesLimit) => {
    setAddNumberSalesLimitInputs(dataInput);
    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You are about to add a new hot number.'],
    });
  };

  const confirmAdd = () => {
    if (addNumberSalesLimitInputs) {
      const { limit, startDate, endDate } = addNumberSalesLimitInputs;

      const input: CreateGameSalesNumberLimitInput = {
        numberCombination: selectedNumberSalesLimit,
        limit,
        startDate,
        endDate,
        gameId: gameCtx.id,
      };

      activateLoading();

      createGameNumberSalesLimit({
        variables: { input },
        onCompleted: () => {
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Number',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Hot number added successfully',
            type: 'success',
          });
          setIsAddNumberSalesLimitModalOpen(false);
          setShowLoading(false);
          reset();
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isAddNumberSalesLimitModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div style={{ paddingBottom: '1em' }}>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} mb={4}>
              Add To Sales Number Limit
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid justifyContent={'center'} display={'flex'}>
                <Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2} width={250}>
                  <TextField label="Sales Limit" size="small" variant="outlined" fullWidth {...register('limit')} />
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    size="small"
                    type={'date'}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('startDate')}
                  />
                  <TextField
                    label="End Date"
                    variant="outlined"
                    size="small"
                    type={'date'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    {...register('endDate')}
                  />
                  <Typography variant="h6" gutterBottom>
                    Number Combination: {selectedNumberSalesLimit.replace(/,/g, '')}
                  </Typography>
                </Stack>
              </Grid>
              <div
                className="gameedit-submit"
                style={{ display: 'flex', paddingBottom: '2em', justifyContent: 'center', alignContent: 'center' }}>
                <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
