import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TablePagination, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// interface
import { PageInfo, NumberCombinationSalesSortField, SortDirection } from '@/generated/graphql';
import { ChangePageType } from '@/shared/enums';
import type { IOpstatsTableData, INumberCombinationSalesQueryVariable } from '@/shared/interfaces';
import { retainDecimalValue } from '@/shared/constants';

// child components
import { AddHotNumberModal } from '../opstats-table/add-hot-number';
import { AddNumberSalesLimitModal } from '../opstats-table/add-number-sales-limit';
import { AddHotNumbers } from '../opstats-table/add-hot-numbers';

interface TablePageLabel {
  start: number;
  end: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IOpstatsTableData | '';
  label: string;
  numeric: boolean;
}
const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFFFFF',
    color: '#6B6B6B',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  '&:active tableCell.Mui-active': {
    color: '#FFFFFF',
  },
}));
const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: 15,
    pt: 20,
    pb: 20,
    pl: 15,
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#E8F0F7',
    fontFamily: 'Poppins',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#E0EAF5',
    fontFamily: 'Poppins',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'numberCombination',
    numeric: false,
    disablePadding: true,
    label: 'Number Combination',
  },
  {
    id: 'sales',
    numeric: false,
    disablePadding: true,
    label: 'Total Sales',
  },
  {
    id: 'numberOfBets',
    numeric: false,
    disablePadding: true,
    label: 'Total Bets',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IOpstatsTableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOpStatsQueryVariable: React.Dispatch<React.SetStateAction<INumberCombinationSalesQueryVariable>>;
  opStatsQueryVariable: INumberCombinationSalesQueryVariable;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;

  const createSortHandler = (property: keyof IOpstatsTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox" align={'left'}>
          <TableSortLabel></TableSortLabel>
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            width={10}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ pl: 2 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id === '' ? undefined : createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {/* <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">

      </Typography> */}
      {/* <FormControl sx={{ ml: 2, mb: 2, mt: 0, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="standard"
        />
      </FormControl> */}
    </Toolbar>
  );
};

interface NumberCombinationTableProps {
  rows: readonly IOpstatsTableData[];
  setOpStatsQueryVariable: React.Dispatch<React.SetStateAction<INumberCombinationSalesQueryVariable>>;
  opStatsQueryVariable: INumberCombinationSalesQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;
}

interface IToggle {
  id: string;
  numberCombination: string;
}

export function LowestNumberCombinationTable(props: NumberCombinationTableProps) {
  const { rows, opStatsQueryVariable, setOpStatsQueryVariable, totalCount, pageInfo } = props;
  const [searchedBets, setSearchedBets] = useState<IOpstatsTableData[]>([]);
  const [searchWord, setSearchWord] = useState('');
  const [optSelectedOption, setOptSelectedOption] = useState('');

  const [isAddHotNumbersModalOpen, setIsAddHotNumbersModalOpen] = useState<boolean>(false);

  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [toggleData, setToggleData] = useState<IToggle[]>([]);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IOpstatsTableData>('sales');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [dense] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // options modal
  const [isAddHotNumberModalOpen, setIsAddHotNumberModalOpen] = useState(false);
  const [isAddNumberSalesLimitModalOpen, setIsAddNumberSalesLimitModalOpen] = useState(false);
  const [numberCombination, setNumberCombination] = useState('');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IOpstatsTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type IOpstatsTableDataKey = keyof IOpstatsTableData;
    const searchKeys: IOpstatsTableDataKey[] = ['numberCombination', 'sales', 'numberOfBets'];

    const newBets = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedBets(newBets);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const clickOption = (type: string, numberCombination: string) => {
    if (type === 'hotNumber') {
      setIsAddHotNumberModalOpen(true);
    } else if (type === 'gameSalesNumberLimit') {
      setIsAddNumberSalesLimitModalOpen(true);
    }
    setNumberCombination(numberCombination);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // useEffect(() => {
  //   console.log(toggleData);
  // }, [toggleData]);

  const refetchData = () => {
    setToggleData([]);
    console.log(toggleData);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Button
          disabled={selectedCount > 0 ? false : true}
          onClick={() => {
            setIsAddHotNumbersModalOpen(true);
          }}>
          <Box
            sx={{
              backgroundColor: selectedCount > 0 ? '#427FC1' : '#427FA3',
              color: '#fff',
            }}
            p={1}>
            Add {selectedCount} Selected To Hot Number
          </Box>
        </Button>
        <Paper sx={{ width: '100%', mb: 2, background: '#C5D8EC' }}>
          <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                setOpStatsQueryVariable={setOpStatsQueryVariable}
                opStatsQueryVariable={opStatsQueryVariable}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}>
                        <StyledTableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            onChange={(event) => {
                              // console.log(isItemSelected);
                              if (event.target.checked === true) {
                                setSelectedCount(selectedCount + 1);
                                setToggleData((oldVal) => [...oldVal, { id: row.id, numberCombination: row.numberCombinationOriginal }]);
                              } else {
                                setSelectedCount(selectedCount - 1);
                                setToggleData(toggleData.filter((item) => item.id !== row.id));
                              }
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                        <StyledTableCell align="left">{row.numberCombination}</StyledTableCell>
                        <StyledTableCell align="left">{retainDecimalValue(row.sales, 2)}</StyledTableCell>
                        <StyledTableCell align="left">{row.numberOfBets}</StyledTableCell>
                        <StyledTableCell align="right">
                          <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small" sx={{ fontSize: '.7rem !important' }}>
                              Option
                            </InputLabel>
                            <Select
                              labelId="demo-select-small"
                              id="select-small"
                              value={optSelectedOption}
                              label="Age"
                              onChange={(e) => clickOption(e.target.value, row.numberCombinationOriginal)}>
                              <MenuItem value={'hotNumber'}>Add To Hot Number</MenuItem>
                              <MenuItem value={'gameSalesNumberLimit'}>Add To Game Sales Number Limit</MenuItem>
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <AddHotNumbers
        isAddHotNumbersModalOpen={isAddHotNumbersModalOpen}
        selectedHotNumbers={toggleData}
        setIsAddHotNumbersModalOpen={setIsAddHotNumbersModalOpen}
        refetchData={refetchData}
      />
      <AddHotNumberModal
        isAddHotNumberModalOpen={isAddHotNumberModalOpen}
        setIsAddHotNumberModalOpen={setIsAddHotNumberModalOpen}
        selectedHotNumber={numberCombination}
      />
      <AddNumberSalesLimitModal
        isAddNumberSalesLimitModalOpen={isAddNumberSalesLimitModalOpen}
        setIsAddNumberSalesLimitModalOpen={setIsAddNumberSalesLimitModalOpen}
        selectedNumberSalesLimit={numberCombination}
      />
    </>
  );
}
