import { useState } from 'react';
import { useParams } from 'react-router-dom';

// material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// components
import { MonthDailyCollectionTable, HeadCell } from './components/month-daily-collections-table';
import { TableCollectionHeader } from './components/table-collection-header';

// graphql
import { useCoordinatorDailyCollectionQuery, useCoordinatorQuery } from '@/generated/graphql';

// interfaces
import type { ICoordinatorDailyCollectionVariable, ICoordinatorCollectionReport, ICoordinator } from '@/shared/interfaces';
import { mapGQLCoordiCollectionReport, mapGQLCoordinator } from '@/shared/interfaces';
import {} from '@/shared/interfaces';

export function CoordinatorPage() {
  const { coordinatorId } = useParams();
  const [monthStr, dayStr, yearStr] = new Date().toLocaleString('en-US', { timeZone: 'Asia/Manila' }).split(',')[0].split('/');

  const headCells: HeadCell[] = [
    {
      id: 'day',
      label: 'Day',
      numeric: false,
      tbCellAlign: 'center',
      hide: false,
      className: 'coordinatorIDColumn',
    },
    {
      id: 'grossSales',
      label: 'Gross',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'agentNameColumn',
    },
    {
      id: 'commissionAmount',
      label: 'Commission',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'agentNameColumn',
    },

    {
      id: 'hitsAmount',
      label: 'Payout',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'coordinatorIDColumn',
    },
    {
      id: 'netAmount',
      label: 'Net Amount',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'agentNameColumn',
    },
    {
      id: 'payment',
      label: 'Payments',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'agentNameColumn',
    },
    {
      id: 'runningBalance',
      label: 'Outstanding Balance',
      numeric: true,
      tbCellAlign: 'right',
      hide: false,
      className: 'agentNameColumn',
    },
  ];

  const [coordinator, setCoordinator] = useState<ICoordinator>();

  const [coordinatorCollectionReport, setCoordinatorCollectionReport] = useState<ICoordinatorCollectionReport>({
    forwardBalance: 0,
    collections: [],
  });
  const [CDCQueryVariable, setCDCQueryVariable] = useState<ICoordinatorDailyCollectionVariable>({
    filterBy: {
      coordinatorId: coordinatorId || '',
      year: parseInt(yearStr),
      month: parseInt(monthStr),
    },
  });

  const coordinatorDailyCollection = useCoordinatorDailyCollectionQuery({
    variables: CDCQueryVariable,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const mappedData = mapGQLCoordiCollectionReport(data.coordinatorDailyCollection);
      setCoordinatorCollectionReport(mappedData);
    },
  });

  useCoordinatorQuery({
    variables: { coordinatorId: coordinatorId ?? '' },
    onCompleted(data) {
      setCoordinator(mapGQLCoordinator(data.coordinator));
    },
  });

  const refetchData = () => {
    coordinatorDailyCollection.refetch({
      ...CDCQueryVariable,
    });
  };

  return (
    <>
      <TableCollectionHeader
        CDCQueryVariable={CDCQueryVariable}
        forwardBalance={coordinatorCollectionReport.forwardBalance}
        refetchData={refetchData}
        coordinator={coordinator}
      />
      <MonthDailyCollectionTable
        rows={coordinatorCollectionReport.collections}
        CSRtotalCount={coordinatorCollectionReport.collections.length}
        headCells={headCells}
        CDCQueryVariable={CDCQueryVariable}
        setCDCQueryVariable={setCDCQueryVariable}
        coordinator={coordinator}
        forwardBalance={coordinatorCollectionReport.forwardBalance}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={coordinatorDailyCollection.loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
