import type { User } from '@/generated/graphql';

import { mapGQLSystemRole } from './system-role';
import type { ISystemRole } from './system-role';

export interface IUser {
  id: string;
  username: string;
  email: string;

  isDisabled: boolean;

  createdAt: string;
  updatedAt: string;

  role: ISystemRole;
}

export function mapGQLUser(user?: User | null): IUser {
  return {
    id: user?.id ?? '',
    username: user ? user.username ?? '' : '',
    email: user ? user.email ?? '' : '',
    createdAt: user ? user.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: user ? user.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    role: mapGQLSystemRole(user?.role),
    isDisabled: user ? user?.isDisabled ?? false : false,
  };
}

export interface ICurrentUserLogin {
  id: string;
  username: string;
  role: string;
}
