import { createContext, FC } from 'react';
import { useOutlet } from 'react-router-dom';
import { getUserAuth } from '@/services/storage/user-auth.service';

interface IAuthContext {
  authenticated: boolean;
}

const initialValue = {
  authenticated: false,
};

export const AuthContext = createContext<IAuthContext>(initialValue);

export const AuthProvider: FC = () => {
  const outlet = useOutlet();
  const userAuth = getUserAuth();

  let authenticated = false;

  if (userAuth) {
    authenticated = true;
  }

  const value = {
    authenticated,
  };

  return <AuthContext.Provider value={value}>{outlet}</AuthContext.Provider>;
};
