import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';

// material ui
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';

// graphql
import { CreateDrawAdminInput, useCreateDrawAdminMutation } from '@/generated/graphql';

// interfaces
import type { IAddDrawAdminInput } from '@/shared/interfaces';

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddDrawAdmin = ({ open, setOpen, refetchData }: IUsherProps) => {
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      username: yup.string().required(),
      password: yup.string().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddDrawAdminInput>({
    resolver: yupResolver(schema),
  });

  const [createDrawAdminMutation] = useCreateDrawAdminMutation();

  const onSubmit = async (dataInput: IAddDrawAdminInput) => {
    const { firstName, lastName, username, password, email } = dataInput;

    const input: CreateDrawAdminInput = {
      firstName,
      lastName,
      username,
      password,
      email,
    };

    const { data } = await createDrawAdminMutation({
      variables: {
        input,
      },
    });

    if (data) console.log(data);
    if (errors) console.log(errors);
    refetchData();
    handleClose();
    reset();
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 400,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box sx={{}} p={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              variant="h4"
              component="h4">
              Add Draw Admin
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={9}>
                <Grid container spacing={1}>
                  <TextField
                    required
                    label="First Name"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('firstName')}
                  />
                  <TextField
                    required
                    label="Last Name"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('lastName')}
                  />
                  <TextField
                    required
                    label="Username"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('username')}
                  />
                  <TextField
                    required
                    label="Email"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('email')}
                  />
                  <TextField
                    required
                    label="Password"
                    type="password"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    size="small"
                    {...register('password')}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                }}
                mt={10}>
                <Button type="submit">
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                    }}
                    p={1}>
                    Submit
                  </Box>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
