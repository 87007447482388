import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  OutlinedInput,
  InputLabel,
  Skeleton,
  InputAdornment,
  Input,
  Grid,
  TablePagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { alpha, styled } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';

import React, { useEffect, useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineSearch } from 'react-icons/ai';

import type { PageInfo } from '@/generated/graphql';

import { ChangePageType } from '@/shared/enums';

import type { IInactiveUshserQueryVariable, IUsherTable, IUpdateUsherInput } from '@/shared/interfaces';

import { RandomKeyString } from '../../services';

import type { SetStateAction } from 'react';

import '../../styles/ushers.css';

// components
import { TableBodySkeleton } from '@/components/table/table-body-skeleton';

type Order = 'asc' | 'desc';

interface TablePageLabel {
  start: number;
  end: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IUsherTable) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IUsherTable;
  label: string;
  numeric: boolean;
  className: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'uniqueCode',
    numeric: false,
    disablePadding: true,
    label: 'Agent Code',
    className: 'agent-code-column',
  },
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Full Name',
    className: 'namecolumn',
  },
  {
    id: 'contactNumber',
    numeric: false,
    disablePadding: true,
    label: 'Contact Number',
    className: 'contactnum-column',
  },
  {
    id: 'usherType',
    numeric: false,
    disablePadding: true,
    label: 'Agent Type',
    className: 'agent-type-column',
  },
  {
    id: 'coordinator',
    numeric: false,
    disablePadding: true,
    label: 'Coordinator',
    className: 'agent-type-column',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Created On',
    className: 'agent-type-column',
  },
  {
    id: 'DTlastTransaction',
    numeric: false,
    disablePadding: true,
    label: 'Last Transaction',
    className: 'agent-type-column',
  },
  {
    id: 'transactionAccess',
    numeric: false,
    disablePadding: true,
    label: 'Bet Access',
    className: 'agent-type-column',
  },
  // {
  //   id: 'username',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Username',
  //   className: 'agentusername-column',
  // },
];

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledSelect = styled(Select)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans,Roboto',
    fontWeight: 600,
    fontSize: 15,
    pt: 15,
    pb: 15,
    pl: 15,
  },
  '& .MuiTableCell-hover	': {
    backgroundColor: '#C2DAF0',
    border: '0.08em solid #C2DAF0',
  },
  // '&:nth-of-type(odd)': {
  //   backgroundColor: '#E8F0F7',
  //   fontFamily: 'Poppins',
  // },
  // '&:nth-of-type(even)': {
  //   backgroundColor: '#E0EAF5',
  //   fontFamily: 'Poppins',
  // },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IUsherTable) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={RandomKeyString()}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
            className={headCell.className}
            sx={{ pl: 2, py: 1 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={RandomKeyString()} align="right" width={140}></TableCell>
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  const [searchWord, setSearchWord] = useState<string>();

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Ushers
      </Typography>

      <FormControl sx={{ m: 1, width: '30ch' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-search">Search</InputLabel>
        <Input
          id="standard-adornment-search"
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
          onKeyDown={(event) => event.key === 'Enter' && searchHandler(searchWord ? searchWord : '')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(e) => searchHandler(searchWord ? searchWord : '')}
                // onMouseDown={handleMouseDownPassword}
              >
                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                <AiOutlineSearch />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {/* <FormControl sx={{ ml: 2, mb: 2, mt: 1, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="outlined"
          size="small"
        />
      </FormControl> */}
    </Toolbar>
  );
};
interface lUshersProps {
  rows: readonly IUsherTable[];
  setUsherQueryVariable: React.Dispatch<SetStateAction<IInactiveUshserQueryVariable>>;
  usherQueryVariable: IInactiveUshserQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;

  usherEvent: (data: IUsherTable, open: boolean, eventName: string) => void;
  getUsherLoading: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

export function TableUshers(props: lUshersProps) {
  const navigate = useNavigate();

  const { rows, usherQueryVariable, setUsherQueryVariable, totalCount, pageInfo, usherEvent, getUsherLoading } = props;
  const [searchUsher, setSearchUsher] = useState<IUsherTable[]>([]);
  const [search, setSearch] = useState('');

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IUsherTable>('firstName');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUsherTable) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearch(value);

    console.log(value, '==');

    setUsherQueryVariable({ ...usherQueryVariable, filterBy: { ...usherQueryVariable.filterBy, search: value } });

    // type IUsherKey = keyof IUsherTable;
    // const searchKeys: IUsherKey[] = ['uniqueCode', 'fullName', 'usherType', 'contactNumber', 'username'];

    // const newUsher = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    // setSearchUsher(newUsher);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const goToUsherBetPage = (id: string, usherName: string) => {
    navigate(`/dashboard/usher-bets/${id}/${usherName}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const showAllRow = () => {
    setRowsPerPage(rows.length);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#ffffff',
        }}>
        <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
        <TableContainer>
          <Table
            sx={{
              minWidth: 850,
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {getUsherLoading ? (
              <TableBodySkeleton row={10} column={9} />
            ) : (
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <StyledTableRow
                      sx={{
                        className: 'ushers-table-row',
                        backgroundColor: row.isDisabled === true ? '#dedede' : 'initial',
                        opacity: row.isDisabled === true ? '0.75' : 'inherit',
                        borderBottom: 1.1,
                        borderBottomColor: '#9F9F9F',
                      }}
                      // hover
                      role="checkbox"
                      tabIndex={-1}
                      key={RandomKeyString()}>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{
                          pl: 2,
                        }}>
                        {row.uniqueCode}
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: '600', color: '#6b6b6b' }}>
                        {row.firstName} {row.lastName}
                      </TableCell>
                      <TableCell align="left">{row.contactNumber}</TableCell>
                      <TableCell align="left">{row.usherType}</TableCell>
                      <TableCell align="left">{row.coordinator.name}</TableCell>
                      <TableCell align="left" style={{ fontWeight: '600', color: '#6b6b6b' }}>
                        {row.createdAt}
                      </TableCell>
                      <TableCell align="left">{row.DTlastTransaction}</TableCell>
                      <TableCell align="left">{row.transactionAccess.replace('_ACCESS', ' ').replace(/_/g, ' ')}</TableCell>
                      {/* <TableCell align="left">{row.username}</TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          paddingBottom: '0.7%',
                        }}>
                        <FormControl sx={{ width: '100%', marginTop: '-9%' }}>
                          <InputLabel id="select-label">Options</InputLabel>
                          <StyledSelect
                            input={<OutlinedInput sx={{ top: 17, fontSize: '4px' }} label="Options" />}
                            variant="outlined"
                            size="small">
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                goToUsherBetPage(row.id, `${row.firstName} ${row.lastName}`);
                              }}>
                              View Bets
                            </MenuItem>
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'edit');
                              }}>
                              Edit
                            </MenuItem>
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'enableDisable');
                              }}>
                              {row.isDisabled ? <>Enable</> : <>Disable</>}
                            </MenuItem>
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'disableImmunity');
                              }}>
                              {row.immuneToDisable ? <>Remove immunity to auto disable</> : <>Set immune to auto disable</>}
                            </MenuItem>

                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'changePassword');
                              }}>
                              Change Password
                            </MenuItem>
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'updateTransactionAccess');
                              }}>
                              Update Transaction Access
                            </MenuItem>
                            <MenuItem
                              key={RandomKeyString()}
                              onClick={() => {
                                const data: IUsherTable = {
                                  ...row,
                                };
                                usherEvent(data, true, 'showUsherNameOnReceipt');
                              }}>
                              {row.showUsherNameOnReceipt ? 'Hide' : 'Show'} name on receipt
                            </MenuItem>
                          </StyledSelect>
                        </FormControl>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Grid container justifyContent={'space-between'} display={'flex'}>
          {!getUsherLoading ? (
            <>
              <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                <Button variant="contained" color="success" sx={{ ml: 2 }} size={'small'} onClick={showAllRow}>
                  Show All
                </Button>
              </Grid>
              <Grid item md={10} justifyContent={'end'} display={'flex'}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                <Skeleton variant="rounded" width={90} height={35} sx={{ ml: 2 }} />
              </Grid>
              <Grid item md={10} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                <Skeleton variant="rounded" width={250} height={15} sx={{ mr: 2 }} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Box>
  );
}
