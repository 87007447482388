import type { GameHotNumber, GameHotNumberFilterField } from '@/generated/graphql';

import { mapGQLGame } from './game';

import type { IGame } from './game';

export interface IGameHotNumber {
  id: string;
  numberCombination: string;
  payout: number;
  salesLimit: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;

  game: IGame;
  sales: number;
}
export interface IGameHotNumberTableData {
  id: string;
  numberCombination: string;
  numberCombinationOriginal: string;
  payout: number;
  salesLimit: number;
  sales: number;
  startDate: string;
  endDate: string;
}

export interface IGameHotNumberQueryVariable {
  filterBy?: GameHotNumberFilterField;
  first: number;
  after?: string;
  before?: string;
}

export interface ICreateGameHotNumber {
  numberCombination: string;
  payout: number;
  salesLimit: number;
  startDate: string;
  endDate: string;
}

export function mapGameHotNumberData(gameHotNumber?: GameHotNumber | null) {
  return {
    id: gameHotNumber?.id ?? '',
    numberCombination: gameHotNumber ? gameHotNumber.numberCombination ?? '' : '',
    payout: gameHotNumber ? gameHotNumber.payout ?? 0 : 0,
    salesLimit: gameHotNumber ? gameHotNumber.salesLimit ?? 0 : 0,
    startDate: gameHotNumber ? gameHotNumber.startDate ?? '' : '',
    endDate: gameHotNumber ? gameHotNumber.endDate ?? '' : '',
    updatedAt: gameHotNumber ? gameHotNumber.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: gameHotNumber ? gameHotNumber.createdAt ?? new Date().toISOString() : new Date().toISOString(),

    game: mapGQLGame(gameHotNumber?.game),
    sales: gameHotNumber ? gameHotNumber.sales ?? 0 : 0,
  };
}
