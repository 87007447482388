// material ui
import { Box, Grid, Tab, Typography } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';

import { useEffect, useState } from 'react';

import { useTableUshersQuery, useDisabledUshersQuery, UsherTransactionAccessEnum } from '@/generated/graphql';
import type { PageInfo } from '@/generated/graphql';

import { mapGQLUsher } from '@/shared/interfaces';
import type { IUsher, IUsherQueryVariable, IUsherTable, IDisabledUsherTable, IDisabledUsherQueryVariable } from '@/shared/interfaces';

// components
import {
  AddUshers,
  TableUshers,
  EditUshers,
  ChangePasswordUsher,
  EnableDisableUsher,
  TableDisabledUshers,
  UpdateTransactionAccess,
  UsherApplicants,
  RegistrationCodes,
  ShowUsherNameOnReceiptConfirmation,
  UsherDisableImmunity,
} from '../components/';
import { InactiveUshers } from '../components/inactive-ushers';

// context api
import { UsherPageRefetchUsherCtx } from '@/contexts/user-page-refetch-usher';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

export function Ushers() {
  const [tabValue, setTabValue] = useState('1');
  const [usherTableData, setUsherTableData] = useState<IUsherTable[]>([]);
  const [usherQueryVariable, setUsherQueryVariable] = useState<IUsherQueryVariable>({
    first: 25,
    filterBy: {
      isDisabled: false,
    },
  });
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refetchUsherData, setRefetchUsherData] = useState(false);

  // disabled usher query variable

  const [disabledUsherTableData, setDisabledUsherTableData] = useState<IDisabledUsherTable[]>([]);
  const [disabledUsherQueryVariable, setDisabledUsherQueryVariable] = useState<IDisabledUsherQueryVariable>({
    first: 25,
  });
  const [pageInfoDisabledUsher, setPageInfoDisabledUsher] = useState<PageInfo>();
  const [totalCountDisabledUsher, setTotalCountDisabledUsher] = useState<number>(0);

  const [editDatas, setEditData] = useState<IUsherTable>();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [selectedUsher, setSelectedUsher] = useState<IUsherTable>();
  const [disableOpen, setDisableOpen] = useState<boolean>(false);
  const [showUsherNameOnReceiptConfirmationOpen, setShowUsherNameOnReceiptConfirmationOpen] = useState<boolean>(false);
  const [showUsherDisableImmunityConfirmationOpen, setShowUsherDisableImmunityConfirmationOpen] = useState<boolean>(false);
  const [changePasswordDatas, setChangePasswordData] = useState<IUsherTable>();
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [getUsherLoading, setGetUsherLoading] = useState<boolean>(false);
  const [updateTransactionAccessOpen, setUpdateTransactionAccessOpen] = useState<boolean>(false);
  const [updateTransactionAccessData, setUpdateTransactionAccessData] = useState<IUsherTable>();

  const getUshers = useTableUshersQuery({
    variables: usherQueryVariable,
    onCompleted: (data) => {
      const mappedUshers: IUsherTable[] = [];
      data.ushers.nodes.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);

        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });
      setTimeout(() => {
        setUsherTableData(mappedUshers);
        setPageInfo(data.ushers.pageInfo);
        setTotalCount(data.ushers.totalCount);
      }, 100);
    },
  });

  const getDisabledUshers = useDisabledUshersQuery({
    variables: disabledUsherQueryVariable,
    onCompleted: (data) => {
      const mappedUshers: IDisabledUsherTable[] = [];
      data.disabledUshers.nodes.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);

        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          lastDisabledDatetime: mapResult.lastDisabledDatetime,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });
      setTimeout(() => {
        setDisabledUsherTableData(mappedUshers);
        setPageInfoDisabledUsher(data.disabledUshers.pageInfo);
        setTotalCountDisabledUsher(data.disabledUshers.totalCount);
      }, 100);
    },
  });

  const usherEvent = (data: IUsherTable, opn: boolean, eventName: string) => {
    if (eventName == 'edit') {
      setEditData(data);
      setEditOpen(opn);
    }
    if (eventName == 'enableDisable') {
      setSelectedUsher(data);
      setDisableOpen(opn);
    }
    if (eventName == 'changePassword') {
      setChangePasswordData(data);
      setChangePasswordOpen(opn);
    }
    if (eventName == 'updateTransactionAccess') {
      console.log('PASS', opn);
      setUpdateTransactionAccessData(data);
      setUpdateTransactionAccessOpen(opn);
    }
    if (eventName == 'showUsherNameOnReceipt') {
      setSelectedUsher(data);
      setShowUsherNameOnReceiptConfirmationOpen(opn);
    }

    if (eventName == 'disableImmunity') {
      setSelectedUsher(data);
      setShowUsherDisableImmunityConfirmationOpen(opn);
    }
  };

  const openEditEvent = (opn: boolean) => setEditOpen(opn);
  const openEnableDisableEvent = (opn: boolean) => setDisableOpen(opn);
  const openShowUsherNameOnReceiptEvent = (opn: boolean) => setShowUsherNameOnReceiptConfirmationOpen(opn);
  const openShowUsherDisableImmunityEvent = (opn: boolean) => setShowUsherDisableImmunityConfirmationOpen(opn);
  const openChangePassword = (opn: boolean) => setChangePasswordOpen(opn);
  const openUpdateTransactionAccess = (opn: boolean) => setUpdateTransactionAccessOpen(opn);

  const refetchData = () => {
    getUshers.refetch({
      ...usherQueryVariable,
    });
    getDisabledUshers.refetch({
      ...disabledUsherQueryVariable,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setGetUsherLoading(getUshers.loading);
  }, [getUshers.loading]);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Active Ushers" value="1" />
              <Tab label="Usher Applicants" value="2" />
              <Tab label="Registration Codes" value="3" />
              <Tab label="Disabled Ushers" value="4" />
              <Tab label="Inactive Ushers" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: '0em' }}>
            <Box>
              <Box
                sx={{
                  backgroundColor: '#87ADD4',
                }}
                p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={10}></Grid>
                  <Grid item xs={2}>
                    <AddUshers refetchData={refetchData} />
                    <EditUshers datas={editDatas} isOpen={editOpen} openEvent={openEditEvent} refetchData={refetchData} />
                    <ChangePasswordUsher
                      datas={changePasswordDatas}
                      isOpen={changePasswordOpen}
                      openEvent={openChangePassword}
                      refetchData={refetchData}
                    />
                    <EnableDisableUsher
                      datas={selectedUsher}
                      isOpen={disableOpen}
                      openEvent={openEnableDisableEvent}
                      refetchData={refetchData}
                    />
                    <UpdateTransactionAccess
                      id={updateTransactionAccessData ? updateTransactionAccessData.id : ''}
                      usherTransactionAccess={
                        updateTransactionAccessData ? updateTransactionAccessData.transactionAccess : UsherTransactionAccessEnum.AppAccess
                      }
                      isOpen={updateTransactionAccessOpen}
                      openEvent={openUpdateTransactionAccess}
                      refetchData={refetchData}
                    />
                    <ShowUsherNameOnReceiptConfirmation
                      datas={selectedUsher}
                      isOpen={showUsherNameOnReceiptConfirmationOpen}
                      openEvent={openShowUsherNameOnReceiptEvent}
                      refetchData={refetchData}
                    />
                    <UsherDisableImmunity
                      datas={selectedUsher}
                      isOpen={showUsherDisableImmunityConfirmationOpen}
                      openEvent={openShowUsherDisableImmunityEvent}
                      refetchData={refetchData}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Tables */}
              <Box
                sx={{
                  backgroundColor: '#C5D8EC',
                  height: '100%',
                }}
                p={2}>
                <TableUshers
                  rows={usherTableData}
                  setUsherQueryVariable={setUsherQueryVariable}
                  usherQueryVariable={usherQueryVariable}
                  totalCount={totalCount}
                  pageInfo={pageInfo}
                  usherEvent={usherEvent}
                  getUsherLoading={getUsherLoading}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="2" style={{ padding: '0em' }}>
            <UsherPageRefetchUsherCtx.Provider
              value={{
                refetchUsherData: refetchUsherData,
                setRefetchUsherData: setRefetchUsherData,
              }}>
              <UsherApplicants></UsherApplicants>
            </UsherPageRefetchUsherCtx.Provider>
          </TabPanel>
          <TabPanel value="3" style={{ padding: '0em' }}>
            <RegistrationCodes />
          </TabPanel>
          <TabPanel value="4" style={{ padding: '0em' }}>
            <Box>
              <Box
                sx={{
                  backgroundColor: '#87ADD4',
                }}
                p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        color: '#fff',
                      }}
                      variant="h4">
                      Usher
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <AddUshers refetchData={refetchData} />
                    <EditUshers datas={editDatas} isOpen={editOpen} openEvent={openEditEvent} refetchData={refetchData} />
                    <ChangePasswordUsher
                      datas={changePasswordDatas}
                      isOpen={changePasswordOpen}
                      openEvent={openChangePassword}
                      refetchData={refetchData}
                    />
                    <EnableDisableUsher
                      datas={selectedUsher}
                      isOpen={disableOpen}
                      openEvent={openEnableDisableEvent}
                      refetchData={refetchData}
                    />
                    <ShowUsherNameOnReceiptConfirmation
                      datas={selectedUsher}
                      isOpen={showUsherNameOnReceiptConfirmationOpen}
                      openEvent={openShowUsherNameOnReceiptEvent}
                      refetchData={refetchData}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Tables */}
              <Box
                sx={{
                  backgroundColor: '#C5D8EC',
                  height: '100%',
                }}
                p={2}>
                <TableDisabledUshers
                  rows={disabledUsherTableData}
                  setUsherQueryVariable={setDisabledUsherQueryVariable}
                  usherQueryVariable={disabledUsherQueryVariable}
                  totalCount={totalCountDisabledUsher}
                  pageInfo={pageInfoDisabledUsher}
                  usherEvent={usherEvent}
                  getUsherLoading={getUsherLoading}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="5" style={{ padding: '0em' }}>
            <InactiveUshers />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
