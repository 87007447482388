import React, { useEffect, useState } from 'react';

// material ui
import { visuallyHidden } from '@mui/utils';
import {
  Paper,
  styled,
  Box,
  Button,
  FormControl,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Stack,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from '@mui/material';
import { FaFilePdf } from 'react-icons/fa';

// services
import { coordinatorDailyCollectionExportToPDF } from '../services/export-to-pdf';

// interfaces
import { ICoordinator, ICoordinatorDailyCollection, ICoordinatorDailyCollectionVariable } from '@/shared/interfaces';

interface IMonth {
  name: string;
  number: number;
}

interface ICollectionFilter {
  year: string;
  month: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';
export type Align = 'left' | 'right';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell {
  id: keyof ICoordinatorDailyCollection;
  label: string;
  numeric: boolean;
  hide: boolean;
  tbCellAlign: 'left' | 'right' | 'center';
  className: string;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICoordinatorDailyCollection) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof ICoordinatorDailyCollection) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <>
            {!headCell.hide && (
              <TableCell
                className={headCell.className}
                style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
                key={headCell.id}
                align={'center'}
                padding={'normal'}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'desc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

interface EnhancedTableToolbarProps {
  searchHandler: (value: string) => void;
  CDCQueryVariable: ICoordinatorDailyCollectionVariable;
  setCDCQueryVariable: React.Dispatch<React.SetStateAction<ICoordinatorDailyCollectionVariable>>;
  headCells: HeadCell[];
  rows: ICoordinatorDailyCollection[];
  coordinator: ICoordinator | undefined;
  forwardBalance: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const [monthStr, dayStr, yearStr] = new Date().toLocaleString('en-US', { timeZone: 'Asia/Manila' }).split(',')[0].split('/');

  const { searchHandler, CDCQueryVariable, setCDCQueryVariable, rows, headCells, coordinator, forwardBalance } = props;

  const monthsArray: IMonth[] = Array.from({ length: 12 }, (_, i) => {
    const monthNumber = i + 1; // Months are zero-indexed, so add 1 to get the month number
    const monthName = new Date(2000, i).toLocaleString('en-US', { month: 'long' });
    return { name: monthName, number: monthNumber };
  });

  const [filter, setFilter] = useState<ICollectionFilter>({
    year: yearStr,
    month: parseInt(monthStr),
  });

  const handleYearInput = (value: string) => {
    setFilter({ ...filter, year: value });
  };

  const clickFilterBtn = () => {
    setCDCQueryVariable({
      ...CDCQueryVariable,
      filterBy: { ...CDCQueryVariable.filterBy, month: filter.month, year: parseInt(filter.year) },
    });
  };

  headCells[0].label = `MONTH OF ${monthsArray[CDCQueryVariable.filterBy.month - 1].name.toLocaleUpperCase()} ${
    CDCQueryVariable.filterBy.year
  }`;

  return (
    <Box
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        py: 2,
      }}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack direction="row" spacing={3} alignItems={'center'}>
          <FormControl sx={{ mt: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small" sx={{ fontSize: '.7rem !important' }}>
              Month
            </InputLabel>
            <Select labelId="demo-select-small" id="select-small" label="Month" value={filter.month}>
              {monthsArray.map((month) => (
                <MenuItem value={month.number} onClick={() => setFilter({ ...filter, month: month.number })}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 0, width: 120 }} size="small" variant="standard">
            <InputLabel htmlFor="standard-adornment-amount" shrink>
              Year
            </InputLabel>
            <Input
              id="standard-adornment-amount"
              value={filter.year}
              onChange={(text) => handleYearInput(text.currentTarget.value.replace(/[^0-9]/g, ''))}
            />
          </FormControl>
          <Button
            sx={{
              ml: 1,
              fontSize: 12,
              fontWeight: 600,
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '7px',
              paddingBottom: '7px',
              textTransform: 'unset',
            }}
            size="small"
            variant="contained"
            onClick={clickFilterBtn}>
            Filter
          </Button>
        </Stack>
        <Stack direction="row" spacing={3} alignItems={'center'}>
          <Button
            sx={{
              ml: 1,
              fontSize: 12,
              fontWeight: 600,
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '7px',
              paddingBottom: '7px',
            }}
            size="small"
            variant="contained"
            startIcon={<FaFilePdf />}
            onClick={() =>
              coordinatorDailyCollectionExportToPDF(rows, headCells, coordinator, 'OPERATORS DAILY COLLECTION REPORT', forwardBalance)
            }>
            Export To PDF
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

interface Props {
  rows: ICoordinatorDailyCollection[];
  headCells: HeadCell[];
  CSRtotalCount: number;
  CDCQueryVariable: ICoordinatorDailyCollectionVariable;
  setCDCQueryVariable: React.Dispatch<React.SetStateAction<ICoordinatorDailyCollectionVariable>>;
  coordinator: ICoordinator | undefined;
  forwardBalance: number;
}

export const MonthDailyCollectionTable: React.FC<Props> = ({
  rows,
  headCells,
  CSRtotalCount,
  CDCQueryVariable,
  setCDCQueryVariable,
  coordinator,
  forwardBalance,
}) => {
  const [searchedRows, setSearchedRows] = useState<ICoordinatorDailyCollection[]>([]);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [tableOrderBy, setOrderBy] = useState<keyof ICoordinatorDailyCollection>('day');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type ITableDataKey = keyof ICoordinatorDailyCollection;
    const searchKeys: ITableDataKey[] = headCells.map((x) => x.id);

    const searchedResults = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedRows(searchedResults);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICoordinatorDailyCollection) => {
    const isAsc = tableOrderBy === property && order === 'desc';
    setOrder(isAsc ? 'desc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const showAllRow = () => {
    setRowsPerPage(rows.length);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            searchHandler={searchHandler}
            CDCQueryVariable={CDCQueryVariable}
            setCDCQueryVariable={setCDCQueryVariable}
            rows={rows}
            headCells={headCells}
            coordinator={coordinator}
            forwardBalance={forwardBalance}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
              <EnhancedTableHead order={order} orderBy={typeof tableOrderBy} onRequestSort={handleRequestSort} headCells={headCells} />
              <TableBody>
                {stableSort(searchWord.length >= 1 ? searchedRows : rows, getComparator(order, tableOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      className="coordinator-table-row"
                      style={{ border: '0.07em solid #CFCFCF' }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}>
                      <TableCell key={index} align="center">
                        {row.day}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.grossSales}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.commissionAmount}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.hitsAmount}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.netAmount}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.payment}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {row.runningBalance}
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent={'space-between'} display={'flex'}>
            {CSRtotalCount === rows.length ? (
              <>
                <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                  {/* <Button variant="contained" color="success" sx={{ ml: 2 }} size={'small'} onClick={showAllRow}>
                    Show All
                  </Button> */}
                </Grid>
                <Grid item md={10} justifyContent={'end'} display={'flex'}>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={2} justifyContent={'start'} alignContent={'center'} alignItems={'center'} display={'flex'}>
                  <Skeleton variant="rounded" width={90} height={35} sx={{ ml: 2 }} />
                </Grid>
                <Grid item md={10} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                  <Skeleton variant="rounded" width={250} height={15} sx={{ mr: 2 }} />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
