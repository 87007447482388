import { useState, useEffect } from 'react';

// graphql
import { useCoordinatorsQuery, PageInfo } from '@/generated/graphql';

// material ui
import { Box, Button, Grid } from '@mui/material';

// interfaces
import { ICoordinator, ICoordinatorQueryVariable, mapGQLCoordinator, ICoordinatorTable } from '@/shared/interfaces';

// components
import { TableCoordinators } from './components/table-coordinator';
import { AddCoordinator } from './components/add-coordinator';
import { EditCoordinator } from './components/edit-coordinator';

export const CoordinatorsPage = () => {
  const [coordinators, setCoordinators] = useState<ICoordinatorTable[]>([]);
  const [coordinatorsQueryVariable, setCoordinatorsQueryVariable] = useState<ICoordinatorQueryVariable>({
    first: 25,
  });

  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [getCoordinatorLoading, setGetCoordinatorLoading] = useState<boolean>(false);
  const [addOpen, setAddOpen] = useState(false);

  // edit coordinator
  const [editOpen, setEditOpen] = useState(false);
  const [editCoordinatorData, setEditCoordinatorData] = useState<ICoordinatorTable>();

  // QUERIES
  const coordinatorQuery = useCoordinatorsQuery({
    variables: coordinatorsQueryVariable,
    onCompleted(data) {
      const mapCoordinators: ICoordinator[] = data.coordinators.nodes.map((coordinator) => mapGQLCoordinator(coordinator));
      console.log('🚀 ~ onCompleted ~ mapCoordinators:', mapCoordinators);

      setTimeout(() => {
        setCoordinators(
          mapCoordinators.map((coordinator) => ({
            id: coordinator.id,
            name: coordinator.name,
            area: coordinator.area,
            username: coordinator.user.username,
            email: coordinator.user.email,
            description: coordinator.description,
            noOfUshers: coordinator.noOfUshers,
            commission: coordinator.commission,
          })),
        );
        setPageInfo(data.coordinators.pageInfo);
        setTotalCount(data.coordinators.totalCount);
      }, 100);
    },
  });

  // END QUERIES

  const coordinatorEvent = (data: ICoordinatorTable, eventName: string) => {
    console.log('🚀 ~ file: coordinators.page.tsx:124 ~ coordinatorEvent ~ eventName:', eventName);
    if (eventName == 'edit') {
      setEditCoordinatorData(data);
      setEditOpen(true);
    }
  };

  const refetchData = () => {
    coordinatorQuery.refetch({
      ...coordinatorsQueryVariable,
    });
  };

  useEffect(() => {
    setGetCoordinatorLoading(coordinatorQuery.loading);
  }, [coordinatorQuery.loading]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#C5D8EC',
          height: '100%',
        }}
        p={2}>
        <Grid justifyContent={'end'} display={'flex'}>
          <Button variant="contained" onClick={() => setAddOpen(true)}>
            Add Operator
          </Button>
        </Grid>
        <TableCoordinators
          rows={coordinators}
          setCoordinatorQueryVariable={setCoordinatorsQueryVariable}
          coordinatorQueryVariable={coordinatorsQueryVariable}
          totalCount={totalCount}
          pageInfo={pageInfo}
          coordinatorEvent={coordinatorEvent}
          getCoordinatorLoading={getCoordinatorLoading}
          refetchData={refetchData}
        />
      </Box>
      <AddCoordinator open={addOpen} setOpen={setAddOpen} refetchData={refetchData} />
      <EditCoordinator open={editOpen} setOpen={setEditOpen} refetchData={refetchData} coordinator={editCoordinatorData} />
    </>
  );
};
