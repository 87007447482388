import { useState, useEffect, useRef } from 'react';

// graphql
import { useCoordinatorsSalesReportQuery } from '@/generated/graphql';

// material ui
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// components
import { DataFilter, CoordinatorsSalesReportSection } from './components';

// types
import type { IDataFilter } from './components';
import type { ICoordinatorReportQueryVariable, ICoordinatorSalesReport, IUsherSalesReport } from '@/shared/interfaces';
import type { PageInfo } from '@/generated/graphql';

// data mappers
import { mapGQLCoordinatorSalesReport } from '@/shared/interfaces';

export type CoordinatorSalesReportComponentRef = {
  populateTable: (coordinatorsSalesReport: ICoordinatorSalesReport[]) => void;
};

export const CoordinatorsReportPage = () => {
  const coordinatorSalesReportComponentRef = useRef<CoordinatorSalesReportComponentRef>(null);
  const { gameCtx } = useGameDashboardCtx();

  // useState
  const [CSRQuerySkipQuery, setCSRQuerySkipQuery] = useState(true);
  const [dataFilter, setDataFilter] = useState<IDataFilter>({
    dateFrom: '',
    dateTo: '',
  });
  const [ushersSalesReport, setUshersSalesReport] = useState<IUsherSalesReport[]>([]);
  const [coordinatorsSalesReport, setCoordinatorsSalesReport] = useState<ICoordinatorSalesReport[]>([]);
  const [CSRQueryVariable, setCSRQueryVariable] = useState<ICoordinatorReportQueryVariable>();
  // other useState
  const [resetData, setResetData] = useState(false);
  const [dateRangeLabel, setDateRangeLabel] = useState('');

  // ************************ QUERIES ************************

  const { loading } = useCoordinatorsSalesReportQuery({
    variables: CSRQueryVariable,
    fetchPolicy: 'cache-and-network',
    skip: CSRQuerySkipQuery,
    onCompleted(data) {
      const mappedData: ICoordinatorSalesReport[] = data.coordinatorsSalesReport.map((x) => mapGQLCoordinatorSalesReport(x));
      const coordinatorsUshersSalesReport = mappedData
        .filter((x) => x.ushersSalesReport.length > 0)
        .map((result) => result.ushersSalesReport);
      let tempUshersSales: IUsherSalesReport[] = [];
      if (coordinatorsUshersSalesReport) {
        coordinatorsUshersSalesReport.forEach((usherSalesReport) => {
          tempUshersSales = [...tempUshersSales, ...usherSalesReport];
        });
      }

      coordinatorSalesReportComponentRef.current?.populateTable(mappedData);

      setDateRangeLabel(dataFilter.dateFrom === dataFilter.dateTo ? dataFilter.dateFrom : `${dataFilter.dateFrom} to ${dataFilter.dateTo}`);
      setCoordinatorsSalesReport(mappedData);
      setUshersSalesReport(tempUshersSales);
    },
  });

  // USE_EFFECT ------------------------------ Watching gameCTX ------------------------------
  useEffect(() => {
    if (gameCtx.id.length > 0) {
      setResetData(true);
      setCSRQueryVariable({
        ...CSRQueryVariable,
        filterBy: { ...CSRQueryVariable?.filterBy, gameId: gameCtx.id },
      });
    } else if (gameCtx.id === '') {
      setResetData(true);
      setTimeout(() => {
        setCSRQueryVariable({
          ...CSRQueryVariable,
          filterBy: { ...CSRQueryVariable?.filterBy, gameId: undefined },
        });
      }, 50);
    }
  }, [gameCtx]);

  return (
    <>
      <Box sx={{ background: 'white' }} py={0}>
        <DataFilter
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          setCSRQuerySkipQuery={setCSRQuerySkipQuery}
          CSRQueryVariable={CSRQueryVariable}
          setCSRQueryVariable={setCSRQueryVariable}
          setResetData={setResetData}></DataFilter>
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <CoordinatorsSalesReportSection
          ref={coordinatorSalesReportComponentRef}
          CSRtotalCount={coordinatorsSalesReport.length}
          dateRangeLabel={dateRangeLabel}
          ushersSalesReport={ushersSalesReport}
        />
      </Box>

      {/* Loading section */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
