import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';

// material ui
import { Box, Button, Divider, FormControl, Grid, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material';

// graphql
import { CreateNotificationInput, useCreateNotificationMutation } from '@/generated/graphql';

// interfaces
import type { ICreateNotificationInput } from '@/shared/interfaces';

import { MdNotificationAdd } from 'react-icons/md';

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateNotification = ({ open, setOpen, refetchData }: IUsherProps) => {
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      title: yup.string().required(),
      subTitle: yup.string().required(),
      message: yup.string().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateNotificationInput>({
    resolver: yupResolver(schema),
  });

  const [createNotificationMutation] = useCreateNotificationMutation();

  const onSubmit = async (dataInput: ICreateNotificationInput) => {
    const { title, subTitle, message } = dataInput;

    const input: ICreateNotificationInput = {
      title,
      subTitle,
      message,
    };
    console.log('🚀 ~ onSubmit ~ input:', input);

    const { data } = await createNotificationMutation({
      variables: {
        input,
      },
    });

    if (data) console.log(data);
    if (errors) console.log(errors);
    refetchData();
    handleClose();
    reset();
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'inherit',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
          },
          content: {
            width: '340px',
            overflow: 'unset',
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            overflow: 'unset',
            borderRadius: '5px',
          }}
          mt={9}>
          <Box sx={{ px: 5, py: 2 }} alignItems={'center'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="subtitle2" m={0}>
                Create notification
              </Typography>
              <MdNotificationAdd fontSize={18} />
            </Stack>
          </Box>
          <Divider />

          <Box px={5} pt={2} pb={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid>
                <FormControl fullWidth sx={{ mt: 5 }} variant="standard">
                  <TextField label="Title" InputLabelProps={{ shrink: true }} variant="standard" {...register('title')} />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
                  <TextField label="Subtitle" InputLabelProps={{ shrink: true }} variant="standard" {...register('subTitle')} />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
                  <TextField
                    id="standard-multiline-static"
                    label="Message"
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    {...register('message')}
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent={'end'} mt={5}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: 'grey',
                    fontSize: 10,
                    fontWeight: 600,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    textTransform: 'unset',
                  }}
                  onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    ml: 1,
                    fontSize: 10,
                    fontWeight: 600,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    textTransform: 'unset',
                  }}
                  type="submit"
                  size="small"
                  variant="contained">
                  Create
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
