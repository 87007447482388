import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as yup from 'yup';
import type { CreateUsherInput, PageInfo } from '@/generated/graphql';
import { useCreateUsherMutation, useSelectCoordinatorsQuery, useSelectUsherTypesQuery } from '@/generated/graphql';

import type { IAddUsherInput, ICoordinatorQueryVariable, ICoordinator, IUsherType, IUsherTypeQueryVariable } from '@/shared/interfaces';
import { mapGQLCoordinator, mapGQLUsherType } from '@/shared/interfaces';
import { RandomKeyString } from '../services';

interface IUsherProps {
  refetchData: () => void;
}

export function AddUshers(props: IUsherProps) {
  const { refetchData } = props;

  const [devicePageInfo, setDevicePageInfo] = useState<PageInfo>();
  const [totalDeviceCount, setTotalDeviceCount] = useState<number>(0);

  const [coordinators, setCoordinators] = useState<ICoordinator[]>([]);
  const [coordinatorQueryVariable] = useState<ICoordinatorQueryVariable>({
    first: 2,
  });

  const [totalCoordinatorCount, setTotalCoordinatorCount] = useState<number>(0);
  const [coordinatorPageInfo, setCoordinatorPageInfo] = useState<PageInfo>();

  const selectCoordinators = useSelectCoordinatorsQuery({
    variables: coordinatorQueryVariable,
    onCompleted: (data) => {
      const mappedCoordinators: ICoordinator[] = data.coordinators.nodes.map((coordinator) => mapGQLCoordinator(coordinator));
      setCoordinators(mappedCoordinators);
      setTotalCoordinatorCount(data.coordinators.totalCount);
      setCoordinatorPageInfo(data.coordinators.pageInfo);
    },
  });

  useEffect(() => {
    if (coordinatorPageInfo && coordinatorPageInfo.hasNextPage && coordinatorPageInfo.endCursor) {
      selectCoordinators
        .fetchMore({
          variables: { first: 10, after: coordinatorPageInfo.endCursor },
        })
        .then((data) => {
          setCoordinatorPageInfo(data.data.coordinators.pageInfo);
          const mappedCoordinators: ICoordinator[] = data.data.coordinators.nodes.map((coordinator) => mapGQLCoordinator(coordinator));

          setCoordinators([...coordinators, ...mappedCoordinators]);
        });
    }
  }, [coordinatorPageInfo]);

  const [usherTypes, setUsherTypes] = useState<IUsherType[]>([]);
  const [usherTypeQueryVariable] = useState<IUsherTypeQueryVariable>({
    first: 2,
  });

  const [, setTotalUsherTypeCount] = useState<number>(0);
  const [usherTypePageInfo, setUsherTypePageInfo] = useState<PageInfo>();

  const selectUsherTypes = useSelectUsherTypesQuery({
    variables: usherTypeQueryVariable,
    onCompleted: (data) => {
      const mappedUsherTypes: IUsherType[] = data.usherTypes.nodes.map((usherType) => mapGQLUsherType(usherType));
      setUsherTypes(mappedUsherTypes);
      setTotalUsherTypeCount(data.usherTypes.totalCount);
      setUsherTypePageInfo(data.usherTypes.pageInfo);
    },
  });

  useEffect(() => {
    if (usherTypePageInfo && usherTypePageInfo.hasNextPage && usherTypePageInfo.endCursor) {
      selectUsherTypes
        .fetchMore({
          variables: { first: 10, after: usherTypePageInfo.endCursor },
        })
        .then((data) => {
          setUsherTypePageInfo(data.data.usherTypes.pageInfo);
          const mappedUsherTypes: IUsherType[] = data.data.usherTypes.nodes.map((usherType) => mapGQLUsherType(usherType));

          setUsherTypes([...usherTypes, ...mappedUsherTypes]);
        });
    }
  }, [usherTypePageInfo]);

  const [deviceValue, setDeviceValue] = useState('');
  const [coordinatorValue, setCoordinatorValue] = useState('');
  const [usherTypeValue, setUsherTypeValue] = useState<string | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    selectCoordinators.refetch({
      first: totalCoordinatorCount,
    });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      address: yup.string().required(),
      username: yup.string().required(),
      password: yup.string().required(),
      uniqueCode: yup.string().required(),
      contactNumber: yup.string().required(),
      coordinatorId: yup.string().notRequired(),
      email: yup.string().email().required(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddUsherInput>({
    resolver: yupResolver(schema),
  });

  const [createUsherMutation] = useCreateUsherMutation();

  const onSubmit = async (dataInput: IAddUsherInput) => {
    if (usherTypeValue) {
      const { address, contactNumber, firstName, lastName, username, password, email, uniqueCode, coordinatorId } = dataInput;

      const input: CreateUsherInput = {
        address,
        contactNumber,
        firstName,
        lastName,
        username,
        password,
        email,
        uniqueCode,
        usherTypeId: usherTypeValue,
        coordinatorId,
      };

      const { data } = await createUsherMutation({
        variables: {
          input,
        },
      });

      if (data) console.log(data);
      if (errors) console.log(errors);
      refetchData();
      handleClose();
      reset();
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          width: '100%',
        }}>
        <Box
          sx={{
            backgroundColor: '#427FC1',
            color: '#fff',
            fontSize: '16px',
            width: '100%',
          }}
          p={1}>
          Add Usher
        </Box>
      </Button>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 650,
          }}>
          <Divider
            sx={{
              height: '25px',
              backgroundColor: '#87ADD4',
            }}
          />
          <Box sx={{}} p={5}>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              id="modal-modal-title"
              variant="h3"
              component="h3">
              Add Agent
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={9}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="First Name"
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('firstName')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Last Name"
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('lastName')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Address"
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('address')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Email"
                      sx={{
                        width: '100%',
                      }}
                      type="email"
                      size="small"
                      {...register('email')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Username"
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('username')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Password"
                      sx={{
                        width: '100%',
                      }}
                      type="password"
                      helperText="Minimum of 8 characters."
                      size="small"
                      {...register('password')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Contact Number"
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('contactNumber')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="select-label">Coordinator</InputLabel>
                      <Select
                        labelId="select-label"
                        input={<OutlinedInput label="Coordinator" />}
                        sx={{
                          width: '100%',
                        }}
                        size="small"
                        {...register('coordinatorId')}
                        onChange={(event) => {
                          setCoordinatorValue(event.target.value);
                        }}
                        value={coordinatorValue}
                        MenuProps={{
                          style: {
                            maxHeight: '300px',
                          },
                        }}>
                        {coordinators.map((coordinator) => (
                          <MenuItem key={RandomKeyString()} value={coordinator.id}>
                            {coordinator.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Agent Code"
                      sx={{
                        width: '100%',
                      }}
                      type="text"
                      size="small"
                      {...register('uniqueCode')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* <TextField
                      required
                      label="Agent Code"
                      sx={{
                        width: '100%',
                      }}
                      type="text"
                      size="small"
                      {...register('uniqueCode')}
                    /> */}
                    <InputLabel id="select-label">Agent Type *:</InputLabel>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={(event) => {
                          setUsherTypeValue(event.target.value);
                        }}
                        value={usherTypeValue}>
                        {usherTypes.map((usherType) => (
                          <FormControlLabel key={RandomKeyString()} value={usherType.id} control={<Radio />} label={usherType.name} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                }}
                mt={10}>
                <Button type="submit">
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                    }}
                    p={1}>
                    Register Agent
                  </Box>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
