import type {
  Coordinator,
  CoordinatorsFilterField,
  CoordinatorGameDrawTimeSales,
  CoordinatorGameSales,
  CoordinatorSalesReport,
  CoordinatorSalesReportFilterField,
  CoordinatorDailyCollectionFilterField,
  CoordinatorDailyCollection,
  CoordCollectionReport,
} from '@/generated/graphql';
import { IUser, mapGQLUser } from './user';
import { IUsherSalesReport, mapGQLUsherSalesReport } from './usher';

export interface ICoordinator {
  id: string;
  name: string;
  area: string;
  description: string;

  user: IUser;
  noOfUshers: number;

  isDefaultOperator: boolean;
  commission: number;
}

export interface ICoordinatorSalesReport {
  coordinator: ICoordinator;
  totalSales: number;
  totalBets: number;
  totalWinner: number;
  payableWinner: number;
  hits: number;
  date: string;
  coordinatorGameSales: ICoordinatorGameSales[];
  ushersSalesReport: IUsherSalesReport[];
}

export interface ICoordinatorGameSales {
  name: string;
  gameDrawTimeSales: ICoordinatorGameDrawTimeSales[];
  totalSales: number;
}

export interface ICoordinatorGameDrawTimeSales {
  drawName: string;
  endDateTime: string;
  sales: number;
  hits: number;
  payableWinner: number;
}

export interface ICoordinatorReportQueryVariable {
  filterBy?: CoordinatorSalesReportFilterField;
}

export interface ICoordinatorDailyCollectionVariable {
  filterBy: CoordinatorDailyCollectionFilterField;
}

export interface ICoordinatorTable {
  id: string;
  name: string;
  area: string;
  username: string;
  email: string;
  description: string;
  noOfUshers: number;
  commission: number;
}
export interface ICoordinatorDailyCollection {
  day: number;
  grossSales: number;
  commissionAmount: number;
  hitsAmount: number;
  netAmount: number;
  payment: number;
  runningBalance: number;
}

export interface ICoordinatorCollectionReport {
  forwardBalance: number;
  collections: ICoordinatorDailyCollection[];
}

export interface IAddCoordinatorInput {
  name: string;
  area: string;
  description: string;
  email: string;
  username: string;
  password: string;
  commission: number;
}

export interface IEditCoordinatorInput {
  name: string;
  area: string;
  description: string;
  email: string;
  username: string;
}

export interface ICoordinatorQueryVariable {
  first: number;
  before?: string;
  after?: string;
  filterBy?: CoordinatorsFilterField;
}

export function mapGQLCoordinator(coordinator?: Coordinator | null): ICoordinator {
  return {
    id: coordinator?.id ?? '',
    name: coordinator ? coordinator.name ?? '' : '',
    area: coordinator ? coordinator.area ?? '' : '',
    description: coordinator ? coordinator.description ?? '' : '',

    user: mapGQLUser(coordinator?.user),
    noOfUshers: coordinator ? coordinator.noOfUshers ?? 0 : 0,

    isDefaultOperator: coordinator ? coordinator.isDefaultOperator ?? false : false,
    commission: coordinator ? coordinator.commission ?? 0 : 0,
  };
}

export function mapGQLCoordinatorSalesReport(coordinatorSalesReport?: CoordinatorSalesReport | null): ICoordinatorSalesReport {
  return {
    coordinator: mapGQLCoordinator(coordinatorSalesReport?.coordinator),
    hits: coordinatorSalesReport?.hits ?? 0,
    totalSales: coordinatorSalesReport ? coordinatorSalesReport.totalSales ?? 0 : 0,
    totalBets: coordinatorSalesReport ? coordinatorSalesReport.totalBets ?? 0 : 0,
    totalWinner: coordinatorSalesReport ? coordinatorSalesReport.totalWinner ?? 0 : 0,
    payableWinner: coordinatorSalesReport ? coordinatorSalesReport.payableWinner ?? 0 : 0,
    date: coordinatorSalesReport ? coordinatorSalesReport.date ?? new Date().toISOString() : new Date().toISOString(),
    coordinatorGameSales: coordinatorSalesReport?.coordinatorGameSales
      ? coordinatorSalesReport.coordinatorGameSales.map((coordinatorGameSale) => mapGQLCoordinatorGameSales(coordinatorGameSale))
      : [],
    ushersSalesReport: coordinatorSalesReport?.ushersSalesReport
      ? coordinatorSalesReport.ushersSalesReport.map((usherSalesReport) => mapGQLUsherSalesReport(usherSalesReport))
      : [],
  };
}

function mapGQLCoordinatorGameSales(coordinatorGameSales: CoordinatorGameSales | null): ICoordinatorGameSales {
  return {
    name: coordinatorGameSales ? coordinatorGameSales.name : '',
    gameDrawTimeSales: coordinatorGameSales?.gameDrawTimeSales
      ? coordinatorGameSales.gameDrawTimeSales?.map((gameDrawTimeSale) => mapGQLCoordinatorGameDrawTimeSales(gameDrawTimeSale))
      : [],
    totalSales: coordinatorGameSales ? coordinatorGameSales.totalSales : 0,
  };
}

function mapGQLCoordinatorGameDrawTimeSales(
  coordinatorGameDrawTimeSales?: CoordinatorGameDrawTimeSales | null,
): ICoordinatorGameDrawTimeSales {
  return {
    drawName: coordinatorGameDrawTimeSales ? coordinatorGameDrawTimeSales.drawName : '',
    sales: coordinatorGameDrawTimeSales?.sales ? coordinatorGameDrawTimeSales.sales : 0,
    hits: coordinatorGameDrawTimeSales?.hits ? coordinatorGameDrawTimeSales.hits : 0,
    payableWinner: coordinatorGameDrawTimeSales?.payableWinner ? coordinatorGameDrawTimeSales.payableWinner : 0,
    endDateTime: coordinatorGameDrawTimeSales?.endDateTime ? coordinatorGameDrawTimeSales.endDateTime : '',
  };
}

function mapGQLCoordinatorDailyCollection(coordinatorDailyCollection?: CoordinatorDailyCollection): ICoordinatorDailyCollection {
  return {
    day: coordinatorDailyCollection?.day || 0,
    grossSales: coordinatorDailyCollection?.grossSales || 0,
    commissionAmount: coordinatorDailyCollection?.commissionAmount || 0,
    hitsAmount: coordinatorDailyCollection?.hitsAmount || 0,
    netAmount: coordinatorDailyCollection?.netAmount || 0,
    payment: coordinatorDailyCollection?.payment || 0,
    runningBalance: coordinatorDailyCollection?.runningBalance || 0,
  };
}

export function mapGQLCoordiCollectionReport(coordiCollectionReport?: CoordCollectionReport): ICoordinatorCollectionReport {
  return {
    forwardBalance: coordiCollectionReport ? coordiCollectionReport.forwardBalance : 0,
    collections: coordiCollectionReport?.collections
      ? coordiCollectionReport.collections?.map((collection) => mapGQLCoordinatorDailyCollection(collection))
      : [],
  };
}
