import type { RegistrationCode } from '@/generated/graphql';

import { IUser, mapGQLUser } from './user';

export interface IRegistrationCode {
  id: string;
  code: string;

  updatedAt: string;
  createdAt: string;

  user: IUser;
}

export interface IRegistrationCodeQueryVariable {
  first: number;
  after?: string;
  before?: string;
}

export interface IRegistrationCodeTableData {
  id: string;
  code: string;
  createdAt: string;
}

export interface ICreateRegistrationCodeInput {
  numberOfCodes: number;
}

export function mapGQLRegistrationCode(registrationCode?: RegistrationCode | null): IRegistrationCode {
  return {
    id: registrationCode?.id ?? '',
    code: registrationCode ? registrationCode.code ?? '' : '',
    updatedAt: registrationCode ? registrationCode.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: registrationCode
      ? new Date(registrationCode.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    user: mapGQLUser(registrationCode?.user),
  };
}
