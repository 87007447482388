import { createContext } from 'react';

interface UsherPageRefetchUsherContextProps {
  refetchUsherData: boolean;
  setRefetchUsherData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UsherPageRefetchUsherCtx = createContext<UsherPageRefetchUsherContextProps>({
  refetchUsherData: false,
  setRefetchUsherData: () => {},
});
