import { useState } from 'react';

// material ui
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

// graphql
import {
  TelegramReportDestinationsFilter,
  useTelegramGroupChatsQuery,
  useTelegramTopicsQuery,
  useTelegramBotsQuery,
} from '@/generated/graphql';

// interfaces
import type { ITelegramReportDestinationQueryVariable, ITelegramReportTypeTable } from '@/shared/interfaces';

interface IProps {
  queryVariable: ITelegramReportDestinationQueryVariable;
  setQueryVariable: React.Dispatch<React.SetStateAction<ITelegramReportDestinationQueryVariable>>;
  tableDataReportTypes: ITelegramReportTypeTable[];
}

export const ReportDestinationsFilterSection = ({ queryVariable, setQueryVariable, tableDataReportTypes }: IProps) => {
  const [reportsFilter, setReportsFilter] = useState<TelegramReportDestinationsFilter>({
    reportTypeId: 'All',
    groupChatName: 'All',
    topicName: 'All',
    botName: 'All',
  });
  const [telegramGroupChats, setTelegramGroupChats] = useState<string[]>([]);
  const [telegramTopics, setTelegramTopics] = useState<string[]>([]);
  const [telegramBots, setTelegramBots] = useState<string[]>([]);

  useTelegramGroupChatsQuery({
    onCompleted(data) {
      setTelegramGroupChats(data.telegramGroupChats);
    },
  });

  useTelegramTopicsQuery({
    onCompleted(data) {
      setTelegramTopics(data.telegramTopics);
    },
  });

  useTelegramBotsQuery({
    onCompleted(data) {
      setTelegramBots(data.telegramBots);
    },
  });

  const handelReportsFilter = (value: string | null, whichFilter: string) => {
    if (value) {
      if (whichFilter === 'groupChatName') {
        setQueryVariable({
          ...queryVariable,
          filterBy: { ...queryVariable.filterBy, groupChatName: value != 'All' ? value : undefined },
        });
        setReportsFilter({ ...reportsFilter, groupChatName: value });
      } else if (whichFilter === 'topicName') {
        setQueryVariable({
          ...queryVariable,
          filterBy: { ...queryVariable.filterBy, topicName: value != 'All' ? value : undefined },
        });
        setReportsFilter({ ...reportsFilter, topicName: value });
      } else if (whichFilter === 'botName') {
        setQueryVariable({
          ...queryVariable,
          filterBy: { ...queryVariable.filterBy, botName: value != 'All' ? value : undefined },
        });
        setReportsFilter({ ...reportsFilter, botName: value });
      } else if (whichFilter === 'reportTypeId') {
        setQueryVariable({
          ...queryVariable,
          filterBy: { ...queryVariable.filterBy, reportTypeId: value != 'All' ? value : undefined },
        });
        setReportsFilter({ ...reportsFilter, reportTypeId: value });
      }
    }
  };

  return (
    <>
      <Stack justifyContent={'start'} display={'flex'} direction={'row'} spacing={2}>
        <FormControl sx={{ minWidth: '200px' }} size="small">
          <InputLabel id="demo-select-small-label">Chat Group</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={reportsFilter.groupChatName}
            label="Chat Group"
            onChange={(event) => {
              handelReportsFilter(event.target.value, 'groupChatName');
            }}>
            {telegramGroupChats.map((chat, reportTypeIndex) => (
              <MenuItem key={reportTypeIndex} value={chat}>
                {chat}
              </MenuItem>
            ))}
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: '200px' }} size="small">
          <InputLabel id="demo-select-small-label">Topic</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={reportsFilter.topicName}
            label="Topic"
            onChange={(event) => {
              handelReportsFilter(event.target.value, 'topicName');
            }}>
            {telegramTopics.map((topic, reportTypeIndex) => (
              <MenuItem key={reportTypeIndex} value={topic}>
                {topic}
              </MenuItem>
            ))}
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: '200px' }} size="small">
          <InputLabel id="demo-select-small-label">Telegram Bot</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={reportsFilter.botName}
            label="Telegram Bot"
            onChange={(event) => {
              handelReportsFilter(event.target.value, 'botName');
            }}>
            {telegramBots.map((telegramBot, reportTypeIndex) => (
              <MenuItem key={reportTypeIndex} value={telegramBot}>
                {telegramBot}
              </MenuItem>
            ))}
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: '200px' }} size="small">
          <InputLabel id="demo-select-small-label">Report Type</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={reportsFilter.reportTypeId}
            label="Report Type"
            onChange={(event) => {
              handelReportsFilter(event.target.value, 'reportTypeId');
            }}>
            {tableDataReportTypes.map((reportType, reportTypeIndex) => (
              <MenuItem key={reportTypeIndex} value={reportType.id}>
                {reportType.name}
              </MenuItem>
            ))}
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};
