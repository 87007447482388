// material ui
import { Typography } from '@mui/material';

//interfaces
import type { GameReport } from '@/shared/interfaces';

interface IProps {
  gameReport: GameReport | undefined;
}

export const OverviewGameReport: React.FC<IProps> = ({ gameReport }) => {
  return (
    <>
      <div className="gametypedata-Col1">
        <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', color: '#444', fontWeight: '500' }}>
          Total Sales: {gameReport ? gameReport.sales : 0}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', color: '#444', fontWeight: '500' }}>
          Total Bets: {gameReport ? gameReport.noOfbets : 0}
        </Typography>
      </div>
      <div className="gametypedata-Col2">
        <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', color: '#444', fontWeight: '500' }}>
          Total Hits: {gameReport ? Math.floor(gameReport.hits) : 0}
        </Typography>
      </div>
    </>
  );
};
