import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';

interface IProps {
  row: number;
  column: number;
}

export const TableBodySkeleton: React.FC<IProps> = ({ row, column }) => {
  return (
    <>
      <TableBody>
        {[...Array(row)].map((_) => (
          <TableRow>
            {[...Array(column)].map((_) => (
              <TableCell>
                <Skeleton variant="rectangular" sx={{ my: 1, mx: 1 }} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};
