import type { TDocumentDefinitions } from 'pdfmake/interfaces';

import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Data, HeadCell } from '../components/table';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function winnerBetsExportToPDF(datas: Data[], headCells: HeadCell[], dateRangeLabel: string) {
  let totalHits = 0;
  let totalPayable = 0;
  const tableData: any = [];

  tableData.push(headCells.map((headCell) => ({ text: headCell.label.toUpperCase(), style: 'tableHeader' })));

  datas.forEach((x) => {
    totalPayable += parseFloat(x['payable'] as string);
    totalHits += parseFloat(x['amount'] as string);

    tableData.push(
      headCells.map((headCell) => {
        return {
          text: x[headCell.id],
          alignment: headCell.numeric ? 'right' : 'left',
          fontSize: 8,
        };
      }),
    );
  });

  const documentDefinition: TDocumentDefinitions = {
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins: [50, 50, 50, 50],
    content: [
      {
        text: [
          {
            text: '\n\nSTL BETS WINNER REPORT\n\n',
            alignment: 'center',
            fontSize: 12,
          },
        ],
      },
      {
        text: [
          {
            text: `${dateRangeLabel}\n\n`,
            alignment: 'center',
            fontSize: 10,
          },
        ],
      },
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: headCells.map((x) => (x.id === 'agent' ? '*' : 'auto')),
          body: tableData,
        },
        layout: 'lightHorizontalLines',
      },
      {
        style: 'tableExample1',
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [false, false, false, false],
                stack: [
                  {
                    alignment: 'justify',
                    columns: [
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL HITS: ${totalHits.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`WINNINGS: ${totalPayable.toFixed(2)}`],
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    styles: {
      tableExample: {
        margin: [0, 2, 0, 7],
      },
      tableExample1: {
        margin: [0, 15, 0, 15],
        fontSize: 10,
        bold: true,
        fillColor: '#dfdfdf',
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: '#343434',
        fillColor: '#dfdfdf',
      },
      tableHeader1: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'right',
      },
      tableHeader2: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'left',
      },
      tableHeader3: {
        bold: true,
        fontSize: 9,
        color: '#343434',
        margin: [0, 0, 0, 1000],
      },
      tableHeader4: {
        bold: true,
        fontSize: 9,
        alignment: 'right',
        color: '#343434',
      },
    },
  };
  pdfMake.createPdf(documentDefinition).print();
}

export function usherSaleReportExportToPDF(datas: Data[], headCells: HeadCell[], dateRangeLabel: string, title: string) {
  let totalBets = 0;
  let totalSales = 0;
  let totalHits = 0;
  let totalPayable = 0;
  const tableData: any = [];
  const headCellsFinal = headCells.filter((headCell) => headCell.hide === false);

  tableData.push(headCellsFinal.map((headCell) => ({ text: headCell.label.toUpperCase(), style: 'tableHeader' })));

  datas.forEach((x) => {
    totalBets += x['totalBets'] as number;
    totalSales += x['totalSales'] as number;
    totalPayable += x['payableWinner'] as number;
    totalHits += x['hits'] as number;

    tableData.push(
      headCellsFinal.map((headCell) => {
        return {
          text: x[headCell.id],
          alignment: headCell.numeric ? 'right' : 'left',
          fontSize: 8,
        };
      }),
    );
  });

  const documentDefinition: TDocumentDefinitions = {
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins: [50, 50, 50, 50],
    footer: function (currentPage, pageCount) {
      return { text: `Page ${currentPage}`, fontSize: 8, alignment: 'right', margin: [0, 25, 15, 0] };
    },
    content: [
      {
        text: [
          {
            text: `\n\n${title}\n\n`,
            alignment: 'center',
            fontSize: 12,
          },
        ],
      },
      {
        text: [
          {
            text: `${dateRangeLabel}\n\n`,
            alignment: 'center',
            fontSize: 10,
          },
        ],
      },
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: headCellsFinal.map((x) => (x.id === 'agentName' ? '*' : 'auto')),
          body: tableData,
        },
        layout: 'lightHorizontalLines',
      },
      {
        style: 'tableExample1',
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [false, false, false, false],
                stack: [
                  {
                    alignment: 'justify',
                    columns: [
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL BETS: ${totalBets.toFixed(0)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL SALES: ${totalSales.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`TOTAL HITS: ${totalHits.toFixed(2)}`],
                      },
                      {
                        margin: 5,
                        bold: true,
                        fontSize: 8,
                        alignment: 'center',
                        text: [`WINNINGS: ${totalPayable.toFixed(2)}`],
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    styles: {
      tableExample: {
        margin: [0, 2, 0, 7],
      },
      tableExample1: {
        margin: [0, 15, 0, 15],
        fontSize: 10,
        bold: true,
        fillColor: '#dfdfdf',
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: '#343434',
        fillColor: '#dfdfdf',
      },
      tableHeader1: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'right',
      },
      tableHeader2: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'left',
      },
      tableHeader3: {
        bold: true,
        fontSize: 9,
        color: '#343434',
        margin: [0, 0, 0, 1000],
      },
      tableHeader4: {
        bold: true,
        fontSize: 9,
        alignment: 'right',
        color: '#343434',
      },
    },
  };
  pdfMake.createPdf(documentDefinition).print();
}
