import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
  useUshersNoCoordinatorQuery,
  PageInfo,
  UshersNoCoordinatorFilterField,
  useAssignUsherCoordinatorMutation,
} from '@/generated/graphql';

import { mapGQLUsher, type IUsherTable, IUsher, ICoordinator } from '@/shared/interfaces';
import { CoordinatorTableAsignUsher } from './ushers-table';

interface IUsherProps {
  coordinator: ICoordinator | undefined;
  refetchData: () => void;
}

interface ICheckboxData {
  isChecked: boolean;
  usherId: string;
}

interface IUshersNoCoordinatorQueryVariables {
  first: number;
  filterBy?: UshersNoCoordinatorFilterField;
}

interface IAssignUsherCoordinatorInput {
  usherIds: string[];
  coordinatorId: string;
}

export function CoordinatorAsignUsher(props: IUsherProps) {
  const { coordinator, refetchData } = props;

  const [coordinatorId, setCoordinatorId] = useState<string>('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setCoordinatorId(coordinator && coordinator.id ? coordinator.id : '');
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [ushersNoCoordinatorQueryVariables, setUshersNoCoordinatorQueryVariables] = useState<IUshersNoCoordinatorQueryVariables>({
    first: 25,
  });
  const [usherNoCoordinatorTableData, setUsherNoCoordinatorTableData] = useState<IUsherTable[]>([]);

  const getUnasignUshers = useUshersNoCoordinatorQuery({
    variables: ushersNoCoordinatorQueryVariables,
    onCompleted: (data) => {
      const mappedUshers: IUsherTable[] = [];
      data.ushersNoCoordinator.nodes.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);

        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });
      setTimeout(() => {
        setUsherNoCoordinatorTableData((ushers) => [...ushers, ...mappedUshers]);
        setPageInfo(data.ushersNoCoordinator.pageInfo);
        setTotalCount(data.ushersNoCoordinator.totalCount);
      }, 100);
    },
  });

  const [assignUsherCoordinationMutation] = useAssignUsherCoordinatorMutation();

  const [checkedData, setCheckedData] = useState<ICheckboxData[]>([]);

  const asignUsherClickEvent = async () => {
    const uniqueArray = checkedData.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    const input: IAssignUsherCoordinatorInput = {
      usherIds: uniqueArray.map((c) => c.usherId),
      coordinatorId: coordinatorId,
    };

    assignUsherCoordinationMutation({
      variables: {
        input,
      },
      onCompleted() {
        refetchData();
      },
    });

    setCheckedData([
      {
        isChecked: false,
        usherId: '',
      },
    ]);
    handleClose();
  };

  const checkDataEvent = (index: number, isCheck: boolean, usherId: string) => {
    // setCheckedData([
    //   ...checkedData,
    //   {
    //     isChecked: isCheck,
    //     usherId: usherId,
    //   },
    // ]);

    let newData = [...checkedData];

    if (isCheck === true) {
      newData.push({
        isChecked: isCheck,
        usherId: usherId,
      });
    } else {
      newData = newData.filter((item) => item.usherId != usherId);
    }

    setCheckedData(newData);
  };

  const [getUsherLoading, setGetUsherLoading] = useState<boolean>(false);

  useEffect(() => {
    if (pageInfo && pageInfo.hasNextPage) {
      getUnasignUshers.fetchMore({
        variables: { ...ushersNoCoordinatorQueryVariables, after: pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
    }
  }, [pageInfo]);

  useEffect(() => {
    setGetUsherLoading(getUnasignUshers.loading);
  }, [getUnasignUshers.loading]);

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          width: '100%',
        }}>
        <Box
          sx={{
            backgroundColor: '#427FC1',
            color: '#fff',
            fontSize: '16px',
            width: '100%',
          }}
          p={1}>
          Add Usher
        </Box>
      </Button>

      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            width: 1480,
            height: 720,
          }}>
          <Box>
            <CoordinatorTableAsignUsher
              checkDataEvent={checkDataEvent}
              rows={usherNoCoordinatorTableData}
              setUsherQueryVariable={setUshersNoCoordinatorQueryVariables}
              usherQueryVariable={ushersNoCoordinatorQueryVariables}
              totalCount={totalCount}
              pageInfo={pageInfo}
              getUsherLoading={getUsherLoading}
            />
          </Box>

          <Box
            sx={{
              textAlign: 'center',
            }}
            mt={1}>
            <Button onClick={asignUsherClickEvent}>
              <Box
                sx={{
                  backgroundColor: '#427FC1',
                  color: '#fff',
                }}
                p={1}>
                Assign Agent
              </Box>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
