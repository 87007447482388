import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { orderBy } from 'lodash';

// graphql
import { useOpStatsGameDrawTimesQuery } from '@/generated/graphql';

// interfaces
import {
  INumberCombinationSoldOutQueryVariable,
  INumberCombinationSalesQueryVariable,
  IGameDrawTimeQueryVariable,
  IGameDrawTime,
  mapGQLGameDrawTime,
} from '@/shared/interfaces';

// material ui
import { MenuItem, Select, TextField } from '@mui/material';

// context
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

import { defaultDateFilter, IDateFilter } from '../service/time';

interface Props {
  opStatsNumberSalesQueryVariable: INumberCombinationSalesQueryVariable;
  opStatsSoldOutQueryVariable: INumberCombinationSoldOutQueryVariable;
  setopStatsNumberSalesQueryVariable: React.Dispatch<React.SetStateAction<INumberCombinationSalesQueryVariable>>;
  setOpStatsSoldOutQueryVariable: React.Dispatch<React.SetStateAction<INumberCombinationSoldOutQueryVariable>>;
  lowestNumberQueryVariable: INumberCombinationSalesQueryVariable;
  setLowestNumberQueryVariable: React.Dispatch<React.SetStateAction<INumberCombinationSalesQueryVariable>>;
}

export const Filter: FC<Props> = ({
  setopStatsNumberSalesQueryVariable,
  setOpStatsSoldOutQueryVariable,
  opStatsNumberSalesQueryVariable,
  opStatsSoldOutQueryVariable,
  lowestNumberQueryVariable,
  setLowestNumberQueryVariable,
}) => {
  const { gameCtx } = useGameDashboardCtx();

  const [gameDrawTimeQueryVariable, setGameDrawTimeQueryVariable] = useState<IGameDrawTimeQueryVariable>({
    first: 25,
  });
  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);
  const [selectedGDT, setSelectedGDT] = useState<IGameDrawTime>();
  const [numberCombinationFilter, setNumberCombinationFilter] = useState<string>('');

  const [dateFilter, setDateFilter] = useState<IDateFilter>({
    date: defaultDateFilter.dateFrom,
  });

  // QUERY
  useOpStatsGameDrawTimesQuery({
    variables: gameDrawTimeQueryVariable,
    skip: gameDrawTimeQueryVariable.filterBy?.gameId ? false : true,
    onCompleted: (data) => {
      const mappedGDTData: IGameDrawTime[] = data.gameDrawTimes.nodes.map((x) => mapGQLGameDrawTime(x));
      setTimeout(() => {
        const sortedGameDrawTimes = orderBy(mappedGDTData, ['drawTime.endTime'], ['asc']);

        if (gameDrawTimeQueryVariable.filterBy?.gameId) {
          setopStatsNumberSalesQueryVariable({
            ...opStatsNumberSalesQueryVariable,
            filterBy: { ...opStatsNumberSalesQueryVariable.filterBy, gameDrawTimeId: sortedGameDrawTimes[0].id },
          });
          setOpStatsSoldOutQueryVariable({
            ...opStatsSoldOutQueryVariable,
            filterBy: { ...opStatsSoldOutQueryVariable.filterBy, gameDrawTimeId: sortedGameDrawTimes[0].id },
          });
          setLowestNumberQueryVariable({
            ...lowestNumberQueryVariable,
            filterBy: { ...lowestNumberQueryVariable.filterBy, gameDrawTimeId: sortedGameDrawTimes[0].id },
          });
        }
        setSelectedGDT(sortedGameDrawTimes[0]);
        setGameDrawTimes(sortedGameDrawTimes);
      }, 100);
    },
  });

  // date filter
  const filterData = (value: string) => {
    setDateFilter({ date: value });
    if (value.length > 0) {
      setopStatsNumberSalesQueryVariable({
        ...opStatsNumberSalesQueryVariable,
        filterBy: {
          ...opStatsNumberSalesQueryVariable.filterBy,
          date: value,
        },
      });

      setOpStatsSoldOutQueryVariable({
        ...opStatsSoldOutQueryVariable,
        filterBy: {
          ...opStatsSoldOutQueryVariable.filterBy,
          date: value,
        },
      });

      setLowestNumberQueryVariable({
        ...lowestNumberQueryVariable,
        filterBy: {
          ...lowestNumberQueryVariable.filterBy,
          date: value,
        },
      });
    }
  };

  // game drawtime filter
  const filterDataGDT = (value: string) => {
    const gameDrawTimeResult = gameDrawTimes.find((x) => x.id === value);

    if (gameDrawTimeResult) {
      setSelectedGDT(gameDrawTimeResult);
      setopStatsNumberSalesQueryVariable({
        ...opStatsNumberSalesQueryVariable,
        filterBy: { ...opStatsNumberSalesQueryVariable.filterBy, gameDrawTimeId: value },
      });
      setOpStatsSoldOutQueryVariable({
        ...opStatsSoldOutQueryVariable,
        filterBy: { ...opStatsSoldOutQueryVariable.filterBy, gameDrawTimeId: value },
      });
      setLowestNumberQueryVariable({
        ...lowestNumberQueryVariable,
        filterBy: { ...lowestNumberQueryVariable.filterBy, gameDrawTimeId: value },
      });
    }
  };

  const filterDataByNumberCombination = (key: string) => {
    if (key === 'Enter') {
      setopStatsNumberSalesQueryVariable({
        ...opStatsNumberSalesQueryVariable,
        filterBy: { ...opStatsNumberSalesQueryVariable.filterBy, numberCombination: numberCombinationFilter.split('').join(',') },
      });
      setLowestNumberQueryVariable({
        ...lowestNumberQueryVariable,
        filterBy: { ...lowestNumberQueryVariable.filterBy, numberCombination: numberCombinationFilter.split('').join(',') },
      });
    }
  };

  useEffect(() => {
    if (gameCtx.id.length > 1) {
      setGameDrawTimeQueryVariable({
        ...gameDrawTimeQueryVariable,
        filterBy: { ...gameDrawTimeQueryVariable.filterBy, gameId: gameCtx.id },
      });
    }
  }, [gameCtx]);

  return (
    <div className="opstats-filter-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="opstats-gamedrawtime-placeholder" style={{ display: 'flex', alignItems: 'center' }}>
        <label
          style={{
            fontWeight: 500,
            color: '#fff',
            fontSize: '1em',
            fontFamily: 'inter',
            marginRight: '0.5em',
          }}>
          DrawTime:{' '}
        </label>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard outlined-size-small"
          // value={null}
          // onChange={}
          label="DrawTime"
          className="bg-white"
          size="small"
          onChange={(e) => {
            filterDataGDT(e.target.value);
          }}
          value={selectedGDT ? selectedGDT.id : ''}>
          {gameDrawTimes.map((gameDrawTime) => (
            <MenuItem value={gameDrawTime.id}>{gameDrawTime.drawTime.name}</MenuItem>
          ))}
        </Select>
        <label
          style={{
            fontWeight: 500,
            color: '#fff',
            fontSize: '1em',
            fontFamily: 'inter',
            marginRight: '0.5em',
            marginLeft: '1em',
          }}>
          Combination:{' '}
        </label>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          onChange={(event) => setNumberCombinationFilter(event.target.value)}
          onKeyDown={(event) => filterDataByNumberCombination(event.key)}
        />
      </div>

      <div className="overview-date-placeholder" style={{ display: 'flex', alignItems: 'center' }}>
        <label
          style={{
            fontWeight: 500,
            color: '#fff',
            fontSize: '1em',
            fontFamily: 'inter',
            marginRight: '0.5em',
          }}>
          Date:{' '}
        </label>
        <TextField
          className="bg-white"
          id="outlined-size-small"
          size="small"
          type={'date'}
          onChange={(e) => {
            filterData(e.target.value);
          }}
          value={dateFilter.date}
        />
      </div>
    </div>
  );
};
