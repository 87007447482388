import { useState, useEffect } from 'react';

// material ui
import { TextField, Typography } from '@mui/material';

//interfaces
import type { IGamesOverviewReportQueryVariable } from '@/shared/interfaces';

interface IProps {
  gamesReportQueryVariable: IGamesOverviewReportQueryVariable;
  setGamesReportQueryVariable: React.Dispatch<React.SetStateAction<IGamesOverviewReportQueryVariable>>;
}

export const OverviewSummaryHeader: React.FC<IProps> = ({ gamesReportQueryVariable, setGamesReportQueryVariable }) => {
  const todayDate = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
    }),
  );

  const [dateFilter, setDataFilter] = useState(
    `${todayDate.getFullYear()}-${('0' + (todayDate.getMonth() + 1)).slice(-2)}-${('0' + todayDate.getDate()).slice(-2)}`,
  );

  //Time Handler Functions
  const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();

  const filterData = (value: string) => {
    setDataFilter(value);
    if (value.length > 0 && dateFilter.length > 0) {
      setGamesReportQueryVariable({
        ...gamesReportQueryVariable,
        filterBy: {
          ...gamesReportQueryVariable.filterBy,
          dateFrom: toUTC(value, '12:00:00 AM'),
          dateTo: toUTC(value, '11:59:59 PM'),
        },
      });
    }
  };

  // initialize default value to filter for query report data
  useEffect(() => {
    setGamesReportQueryVariable({
      ...gamesReportQueryVariable,
      filterBy: {
        dateFrom: toUTC(
          `${todayDate.getFullYear()}-${('0' + (todayDate.getMonth() + 1)).slice(-2)}-${('0' + todayDate.getDate()).slice(-2)}`,
          '12:00:00 AM',
        ),
        dateTo: toUTC(
          `${todayDate.getFullYear()}-${('0' + (todayDate.getMonth() + 1)).slice(-2)}-${('0' + todayDate.getDate()).slice(-2)}`,
          '11:59:59 PM',
        ),
      },
    });
  }, []);

  return (
    <>
      <div
        className="summary-header"
        style={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <div className="overview-summary-header">
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
            SUMMARY
          </Typography>
        </div>

        <div className="overview-date-placeholder" style={{ display: 'flex', alignItems: 'center' }}>
          <label
            style={{
              fontWeight: 500,
              color: '#444',
              fontSize: '1em',
              fontFamily: 'inter',
              marginRight: '0.5em',
            }}>
            Date:{' '}
          </label>
          <TextField
            className="bg-white"
            id="outlined-size-small"
            size="small"
            type={'date'}
            onChange={(e) => {
              filterData(e.target.value);
            }}
            defaultValue={dateFilter}
          />
        </div>
      </div>
    </>
  );
};
