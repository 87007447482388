import { useState, useEffect } from 'react';

// graphql
import { useUsherRegistrationCodesQuery, PageInfo } from '@/generated/graphql';

// interfaces
import { IRegistrationCode, IRegistrationCodeTableData, mapGQLRegistrationCode, IRegistrationCodeQueryVariable } from '@/shared/interfaces';

// components
import { CodesTable } from './codes-table';
import { AddRegistrationCodes } from './add-registration-code';

// material ui
import { Box, Button, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// services
import { printRegistrationCode } from '../../services/print-registration-code';

export const RegistrationCodes: React.FC = () => {
  const [registrationCodeQueryVariable, setRegistrationCodeQueryVariable] = useState<IRegistrationCodeQueryVariable>({ first: 25 });
  const [registrationCodeTableData, setRegistrationCodeTableData] = useState<IRegistrationCodeTableData[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [showLoading, setShowLoading] = useState(false);

  const getCodesQuery = useUsherRegistrationCodesQuery({
    variables: registrationCodeQueryVariable,
    onCompleted(data) {
      const tableData: IRegistrationCodeTableData[] = [];

      const mappedData: IRegistrationCode[] = data.registrationCodes.nodes.map((x) => mapGQLRegistrationCode(x));

      mappedData.forEach((registrationCode) => {
        tableData.push({
          id: registrationCode.id,
          code: registrationCode.code,
          createdAt: registrationCode.createdAt,
        });
      });

      setTimeout(() => {
        setRegistrationCodeTableData((registrationCodeTableData) => [...registrationCodeTableData, ...tableData]);
        setPageInfo(data.registrationCodes.pageInfo);
        setTotalCount(data.registrationCodes.totalCount);
      }, 100);
    },
  });

  // USE_EFFECT ------------------------------ this will watch pageInfo change to re-query more data if it detected data has next page ------------------------------

  useEffect(() => {
    if (pageInfo && pageInfo.hasNextPage && pageInfo.endCursor) {
      getCodesQuery.fetchMore({
        variables: { ...registrationCodeQueryVariable, after: pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
    } else if (pageInfo && !pageInfo.hasNextPage) {
      setShowLoading(false);
    }
  }, [pageInfo]);

  const refetch = () => {
    getCodesQuery.refetch();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#87ADD4',
        }}
        p={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography
              sx={{
                color: '#fff',
              }}
              variant="h4"></Typography>
          </Grid>
          <Grid item xs={2}>
            <AddRegistrationCodes refetch={refetch} />
          </Grid>
        </Grid>
      </Box>
      <Grid item md={12} alignItems={'center'} display={'flex'} justifyContent={'end'} mt={3}>
        <Button
          variant="contained"
          size="medium"
          type="submit"
          color="success"
          sx={{ mr: 2 }}
          onClick={() => {
            printRegistrationCode(registrationCodeTableData);
          }}>
          Print Codes
        </Button>
      </Grid>

      <CodesTable
        rows={registrationCodeTableData}
        setRegistrationCodeQueryVariable={setRegistrationCodeQueryVariable}
        registrationCodeQueryVariable={registrationCodeQueryVariable}
        totalCount={totalCount}
        pageInfo={pageInfo}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
