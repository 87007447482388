// interfaces

// material-ui
import { Box, Button, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';

// context api
import { CombinationInput } from '@/components/Input/combination-input';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import { ConfirmationModal } from '@/components/modal/confirmation';
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// graphql
import type { UpdateGameResultInput } from '@/generated/graphql';
import { useUpdateGameResultMutation } from '@/generated/graphql';

// components
import type { INumberCombInput, IGameResultTableData } from '@/shared/interfaces';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isGameResultEditModalOpen: boolean;
  setIsGameResultEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGameResult: IGameResultTableData;
  refetchData: () => void;
}

export const EditGameResultModal: React.FC<Props> = ({
  isGameResultEditModalOpen,
  setIsGameResultEditModalOpen,
  selectedGameResult,
  refetchData,
}) => {
  const { gameCtx } = useGameDashboardCtx();
  const [showLoading, setShowLoading] = useState(false);

  const [updateGameResult] = useUpdateGameResultMutation();

  const [numberCombInputs, setNumberCombInputs] = useState<INumberCombInput[]>([]);

  const [modalConfirmationEdit, setModalConfirmationEdit] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure?',
    messages: ['You want to continue edit'],
  });

  const closeModal = () => {
    setIsGameResultEditModalOpen(false);
  };

  useEffect(() => {
    if (selectedGameResult) {
      const emtyCombi: INumberCombInput[] = [];
      const numberCombinationList = selectedGameResult.originalResultCombination.split(',');

      numberCombinationList.forEach((combination, index) => {
        emtyCombi.push({
          value: combination,
          id: index,
          isFocused: false,
        });
      });
      setNumberCombInputs(emtyCombi);
    }
  }, [selectedGameResult]);

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const editGameResult = () => {
    <input type="date" />;
    const { combinationLimit, placeValue } = gameCtx.gameType.gameConfig;

    const InputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    if (gameCtx && InputCombination.replace(/,/g, '').length === combinationLimit * placeValue) {
      const input: UpdateGameResultInput = {
        gameDrawTimeId: selectedGameResult.gameDrawTimeId,
        resultCombination: InputCombination,
        id: selectedGameResult.id,
      };
      setModalConfirmationEdit({ ...modalConfirmationEdit, isOpen: false });
      setIsGameResultEditModalOpen(false);
      activateLoading();

      updateGameResult({
        variables: { input },
        onCompleted: () => {
          refetchData();
          setShowLoading(false);
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isGameResultEditModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div style={{ paddingBottom: '1em' }}>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit Result
            </Typography>

            <Grid container justifyContent={'center'} mt={6}>
              <CombinationInput
                perCombinationLength={gameCtx.gameType.gameConfig.placeValue}
                combinationLength={gameCtx.gameType.gameConfig.combinationLimit}
                setNumberCombInputs={setNumberCombInputs}
                numberCombInputs={numberCombInputs}
              />
            </Grid>
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{ display: 'flex', paddingBottom: '2em', justifyContent: 'center', alignContent: 'center' }}>
          <Button variant="contained" onClick={() => setModalConfirmationEdit({ ...modalConfirmationEdit, isOpen: true })}>
            Submit
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={modalConfirmationEdit}
        setModalDetails={setModalConfirmationEdit}
        confirm={editGameResult}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
