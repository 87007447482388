import { Button, TextField, Grid } from '@mui/material';
import React, { useState } from 'react';

// material ui

// interfaces
import type {
  CreateGamePayoutInput,
  CreateGameTripleRepeatingPayoutInput,
  CreateGameDoubleRepeatingPayoutInput,
} from '@/generated/graphql';
import {
  useServerConfigCreateGameDoubleRepeatingPayoutMutation,
  useServerConfigCreateGamePayoutMutation,
  useServerConfigCreateGameTripleRepeatingPayoutMutation,
  useServerConfigGetLatestGameDoubleRepeatingPayoutQuery,
  useServerConfigGetLatestGameTripleRepeatingPayoutQuery,
  useServerConfigGameTypeQuery,
} from '@/generated/graphql';
import type { IModalDetails, IGameDoubleRepeatingPayout, IGameTripleRepeatingPayout, IGame } from '@/shared/interfaces';
import { mapGQLGameDoubleRepeatingPayout, mapGQLGameTripleRepeatingPayout, mapGQLGameTypeData } from '@/shared/interfaces';

// components
import { ConfirmationModal } from '../components/confirmation-modal';

// graphql

interface IProps {
  gameCtx: IGame;
  gameDrawTimeRefetch: () => void;
}

export const GamePayout: React.FC<IProps> = ({ gameCtx }) => {
  const [createGamePayoutMutation] = useServerConfigCreateGamePayoutMutation();
  const [createGameDoubleRepeatingPayoutMutation] = useServerConfigCreateGameDoubleRepeatingPayoutMutation();
  const [createGameTripleRepeatingPayoutMutation] = useServerConfigCreateGameTripleRepeatingPayoutMutation();

  const [doubleRepeatingPayout, setDoubleRepeatingPayout] = useState<IGameDoubleRepeatingPayout>();
  const [tripleRepeatingPayout, setTripleRepeatingPayout] = useState<IGameTripleRepeatingPayout>();
  const [gamePayout, setGamePayout] = useState<number>(0);

  const [modalDetails, setModalDetails] = React.useState<IModalDetails>({
    isOpen: false,
  });

  const standardPayoutQuery = useServerConfigGameTypeQuery({
    skip: gameCtx.id.length > 0 ? false : true,
    variables: { id: gameCtx.gameType.id },
    onCompleted(data) {
      const mapData = mapGQLGameTypeData(data.gameType);

      setTimeout(() => {
        setGamePayout(mapData.latestPayout);
      }, 100);
    },
  });

  const doubleRepeatingPayoutQuery = useServerConfigGetLatestGameDoubleRepeatingPayoutQuery({
    skip: gameCtx.id.length > 0 ? false : true,
    variables: { gameTypeId: gameCtx.gameType.id },
    onCompleted(data) {
      const mappedData = mapGQLGameDoubleRepeatingPayout(data.getLatestGameDoubleRepeatingPayout);
      setTimeout(() => {
        setDoubleRepeatingPayout(mappedData);
      }, 100);
    },
  });

  const tripleRepeatingPayoutQuery = useServerConfigGetLatestGameTripleRepeatingPayoutQuery({
    skip: gameCtx.id.length > 0 ? false : true,
    variables: { gameTypeId: gameCtx.gameType.id },
    onCompleted(data) {
      const mappedData = mapGQLGameTripleRepeatingPayout(data.getLatestGameTripleRepeatingPayout);
      setTimeout(() => {
        setTripleRepeatingPayout(mappedData);
      }, 100);
    },
  });

  const openModal = (action: string) => {
    setModalDetails({ isOpen: true, action });
  };

  const handleStandardPayoutChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if ((numValue.length > 0 || numValue === '') && doubleRepeatingPayout) {
      setGamePayout(numValue === '' ? 0 : parseInt(numValue));
    }
  };

  const handleDoublePayoutChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if ((numValue.length > 0 || numValue === '') && doubleRepeatingPayout) {
      setDoubleRepeatingPayout({
        ...doubleRepeatingPayout,
        payout: numValue === '' ? 0 : parseInt(numValue),
      });
    }
  };

  const handleTriplePayoutChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if ((numValue.length > 0 || numValue === '') && tripleRepeatingPayout) {
      setTripleRepeatingPayout({
        ...tripleRepeatingPayout,
        payout: numValue === '' ? 0 : parseInt(numValue),
      });
    }
  };

  const confirmEdit = () => {
    if (modalDetails.action === 'server config update game config') {
      if (gameCtx.gameType.id) {
        const input: CreateGamePayoutInput = {
          payout: gamePayout,
          gameTypeId: gameCtx.gameType.id,
        };

        createGamePayoutMutation({
          variables: { input },
          onCompleted: () => {
            console.log('game config updated', gamePayout);
            standardPayoutQuery.refetch();
          },
        });
      }
    } else if (modalDetails.action === 'double payout' && doubleRepeatingPayout) {
      const input: CreateGameDoubleRepeatingPayoutInput = {
        payout: doubleRepeatingPayout.payout,
        gameTypeId: gameCtx.gameType.id,
      };
      createGameDoubleRepeatingPayoutMutation({
        variables: { input },
        onError: (error) => {
          console.error(error);
        },
        onCompleted: () => {
          doubleRepeatingPayoutQuery.refetch();
        },
      });
    } else if (modalDetails.action === 'triple payout' && tripleRepeatingPayout) {
      const input: CreateGameTripleRepeatingPayoutInput = {
        payout: tripleRepeatingPayout.payout,
        gameTypeId: gameCtx.gameType.id,
      };
      createGameTripleRepeatingPayoutMutation({
        variables: { input },
        onError: (error) => {
          console.error(error);
        },
        onCompleted: () => {
          tripleRepeatingPayoutQuery.refetch();
        },
      });
    }
    setModalDetails({ ...modalDetails, isOpen: false });
  };

  return (
    <>
      <Grid container spacing={1} justifyContent={'center'} mt={3}>
        <Grid item md={4} justifyContent={'center'} display={'flex'}>
          <TextField
            label="Standard Payout"
            variant="standard"
            onChange={(e) => handleStandardPayoutChange(e.target.value)}
            value={gamePayout}
          />
          <Button
            sx={{ ml: 3, mt: 2 }}
            variant="contained"
            size="small"
            type="submit"
            onClick={() => openModal('server config update game config')}>
            Edit
          </Button>
        </Grid>

        {/* Double Payout */}
        {gameCtx.gameType.gameConfig.hasDoubleRepeatingPayout && (
          <Grid item md={4} justifyContent={'center'} display={'flex'}>
            <TextField
              label="Double Payout"
              variant="standard"
              value={doubleRepeatingPayout?.payout ? doubleRepeatingPayout.payout : 0}
              onChange={(e) => handleDoublePayoutChange(e.target.value)}
            />
            <Button sx={{ ml: 3, mt: 2 }} variant="contained" size="small" type="submit" onClick={() => openModal('double payout')}>
              Edit
            </Button>
          </Grid>
        )}
        {/* Triple Payout */}
        {gameCtx.gameType.gameConfig.hasTripleRepeatingPayout && (
          <Grid item md={4} justifyContent={'center'} display={'flex'}>
            <TextField
              label="Triple Payout"
              variant="standard"
              value={tripleRepeatingPayout?.payout ? tripleRepeatingPayout.payout : 0}
              onChange={(e) => handleTriplePayoutChange(e.target.value)}
            />
            <Button sx={{ ml: 3, mt: 2 }} variant="contained" size="small" type="submit" onClick={() => openModal('triple payout')}>
              Edit
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmationModal modalDetails={modalDetails} setModalDetails={setModalDetails} confirm={confirmEdit} />
    </>
  );
};
