import type { TDocumentDefinitions } from 'pdfmake/interfaces';

import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

// interfaces
import { IRegistrationCodeTableData } from '@/shared/interfaces';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function printRegistrationCode(registrationCodes: IRegistrationCodeTableData[]) {
  const codes = registrationCodes.map((x) => ({ text: x.code, alignment: 'center', margin: [10, 10, 10, 10] }));

  const tableData = [];

  const arrayLength = registrationCodes.length;

  const perColumn = Math.floor(arrayLength / 3);

  let counter = 0;

  for (let i = 0; i < perColumn; i++) {
    tableData.push(codes.slice(counter, counter + 3));

    counter += 3;
  }

  if (counter < arrayLength) {
    const lastArr = codes.slice(counter, arrayLength);
    const loopCounter = 3 - lastArr.length;

    for (let i = 0; i < loopCounter; i++) {
      lastArr.push({ text: '', alignment: 'center', margin: [10, 10, 10, 10] });
    }
    tableData.push(lastArr);
  }

  const documentDefinition: TDocumentDefinitions = {
    pageSize: 'LETTER',
    pageMargins: [50, 50, 50, 50],
    content: [
      {
        style: 'tableExample',
        table: {
          widths: ['*', '*', '*'],
          heights: 30,
          body: tableData,
        },
      },
    ],
    styles: {
      tableExample: {
        margin: [0, 2, 0, 7],
      },
      tableExample1: {
        margin: [0, 15, 0, 15],
        fontSize: 10,
        bold: true,
        fillColor: '#dfdfdf',
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: '#343434',
        fillColor: '#dfdfdf',
      },
      tableHeader1: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'right',
      },
      tableHeader2: {
        bold: true,
        fontSize: 13,
        color: '#343434',
        fillColor: '#dfdfdf',
        alignment: 'left',
      },
      tableHeader3: {
        bold: true,
        fontSize: 9,
        color: '#343434',
        margin: [0, 0, 0, 1000],
      },
      tableHeader4: {
        bold: true,
        fontSize: 9,
        alignment: 'right',
        color: '#343434',
      },
    },
  };
  pdfMake.createPdf(documentDefinition).print();
}
