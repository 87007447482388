import React from 'react';
import { NavLink } from 'react-router-dom';

import './error.css';

export function NotFound() {
  return (
    <div className="errormain-wrapper">
      <div className="errormain-responsive">
        <div className="error-innercontainer">
          <div className="error-message">ERROR 404: I'm afraid there's nothing here</div>
          <NavLink to="/dashboard/game-types">
            <button className="error-backbutton"> Go Back </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
