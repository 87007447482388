export function retainDecimalValue(decimal: number, decimalPlaceValue: number): number {
  try {
    const strMeron = decimal.toString().split('.');
    const wholeNum = strMeron[0];
    const decimalNum = strMeron[1];
    // combine whole and decimal
    return parseFloat(`${wholeNum}.${decimalNum.slice(0, decimalPlaceValue)}`);
  } catch (error) {
    return decimal;
  }
}
