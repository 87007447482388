import { useUpdateUsherNameReceiptVisibilityMutation } from '@/generated/graphql';
import { IUsherTable } from '@/shared/interfaces';

import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Modal from 'react-modal';

interface IUsherProp {
  datas: IUsherTable | undefined;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
  refetchData: () => void;
}

export function ShowUsherNameOnReceiptConfirmation(props: IUsherProp) {
  const { datas, isOpen, openEvent, refetchData } = props;

  const [updateUsherNameReceiptVisibilityMutation] = useUpdateUsherNameReceiptVisibilityMutation();

  const handleClose = () => openEvent(false);

  const toggle = () => {
    updateUsherNameReceiptVisibilityMutation({
      variables: {
        input: {
          id: datas?.id ?? '',
          isVisible: !datas?.showUsherNameOnReceipt,
        },
      },
      onCompleted() {
        refetchData();
      },
    });

    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          position: 'absolute',
          margin: '0.1% auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: 0,
          marginRight: '-50%',
          transform: 'translate(-50%, -48vh)',
          border: '0px',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        },
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: 450,
          height: 350,
        }}>
        <Divider
          sx={{
            height: '25px',
            backgroundColor: '#87ADD4',
          }}
        />
        <Box p={1} mt={5}>
          <Typography
            sx={{
              textAlign: 'center',
            }}
            id="modal-modal-title"
            variant="h5"
            component="h5">
            {datas?.showUsherNameOnReceipt ? (
              <>Are sure you want to hide name on receipt?</>
            ) : (
              <>Are sure you want to show name on receipt?</>
            )}
          </Typography>
          <Box m={9}>
            <Grid container spacing={1}>
              <Grid item alignItems={'center'} alignContent="center" xs={6}>
                <Button
                  sx={{
                    width: '100%',
                  }}
                  onClick={() => {
                    toggle();
                  }}>
                  <Box
                    sx={{
                      backgroundColor: '#427FC1',
                      color: '#fff',
                      width: '100%',
                    }}
                    p={1}>
                    Yes
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                  }}
                  onClick={handleClose}>
                  <Box
                    sx={{
                      backgroundColor: '#8E0A0E',
                      color: '#fff',
                      width: '100%',
                    }}
                    p={1}>
                    No
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
