import type { TelegramReportDestination, GetTelegramBotUpdatesPayload, TelegramReportDestinationsFilter } from '@/generated/graphql';
import { ITelegramReport, mapGQLTelegramReport } from './telegram-report';

export interface ITelegramReportDestination {
  id: string;
  botName: string;
  botUsername: string;
  token: string;
  groupChatName: string;
  chatId: string;
  topicId: string;
  topicName: string;
  type: string;

  telegramReport: ITelegramReport;

  createdAt: string;
  updatedAt: string;
}

export interface ITelegramReportDestinationTable {
  id: string;
  botName: string;
  botUsername: string;
  token: string;
  groupChatName: string;
  chatId: string;
  topicId: string;
  topicName: string;
  type: string;
  telegramReport: string;
  createdAt: string;
}

export interface ITelegramReportDestinationQueryVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: TelegramReportDestinationsFilter;
}

export interface ICreateTelegramReportDestinationInput {
  token: string;
}

export interface IGetTelegramBotUpdateInput {
  token: string;
  botName?: string;
  botUsername?: string;
  groupChatName?: string;
  chatId?: string;
  topicId?: string;
  topicName?: string;
}

export interface IGetTelegramBotUpdateResp {
  botName: string;
  botUsername: string;
  token: string;
  groupChatName: string;
  chatId: string;
  topicId: string;
  topicName: string;
  type: string;
  isSelected: boolean;
}

export function mapGQLGetTelegramBotUpdateResp(update?: GetTelegramBotUpdatesPayload | null): IGetTelegramBotUpdateResp {
  return {
    botName: update ? update.botName ?? '' : '',
    botUsername: update ? update.botUsername ?? '' : '',
    token: update ? update.token ?? '' : '',
    groupChatName: update ? update.groupChatName ?? '' : '',
    chatId: update ? update.chatId ?? '' : '',
    topicId: update ? update.topicId ?? '' : '',
    topicName: update ? update.topicName ?? '' : '',
    type: update ? update.type ?? '' : '',
    isSelected: false,
  };
}

export function mapGQLTelegramReportDestination(destination?: TelegramReportDestination | null): ITelegramReportDestination {
  return {
    id: destination?.id ?? '',
    botName: destination ? destination.botName ?? '' : '',
    botUsername: destination ? destination.botUsername ?? '' : '',
    token: destination ? destination.token ?? '' : '',
    groupChatName: destination ? destination.groupChatName ?? '' : '',
    chatId: destination ? destination.chatId ?? '' : '',
    topicId: destination ? destination.topicId ?? '' : '',
    topicName: destination ? destination.topicName ?? '' : '',
    type: destination ? destination.type ?? '' : '',

    createdAt: destination
      ? new Date(destination.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: destination
      ? new Date(destination.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    telegramReport: mapGQLTelegramReport(destination?.telegramReport),
  };
}
