// material ui
import { Typography } from '@mui/material';

//interfaces
import type { IGamesOverviewReport } from '@/shared/interfaces';

interface IProps {
  gamesReportData: IGamesOverviewReport | undefined;
}

export const OverviewGamesReport: React.FC<IProps> = ({ gamesReportData }) => {
  // Number Handler Function
  const wholeNum = (num: number) => Math.floor(num);

  return (
    <>
      <div className="summary-content">
        <div className="summary-bets summary-totals">
          <Typography variant="h5" sx={{ fontWeight: 600, color: '#333' }}>
            Total Bets
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: 600, color: '#555' }}>
            {wholeNum(gamesReportData ? gamesReportData.totalNoOfbets : 0)}
          </Typography>
        </div>

        <div className="summary-hits summary-totals">
          <Typography variant="h5" sx={{ fontWeight: 600, color: '#333' }}>
            Total Hits
          </Typography>

          <Typography variant="h2" sx={{ fontWeight: 600, color: '#555' }}>
            {wholeNum(gamesReportData ? gamesReportData.totalHits : 0)}
          </Typography>
        </div>
        <div className="summary-sales summary-totals">
          <Typography variant="h5" sx={{ fontWeight: 600, color: '#333' }}>
            Total Sales
          </Typography>

          <Typography variant="h2" sx={{ fontWeight: 600, color: '#555' }}>
            {wholeNum(gamesReportData ? gamesReportData.totalSales : 0)}
          </Typography>
        </div>
      </div>
    </>
  );
};
