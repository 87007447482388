import type { GameDrawtimeLimit, GameDrawtimeLimitFilterField } from '@/generated/graphql';

import { mapGQLGameDrawTime } from './game-drawtime';

import type { IGameDrawTime } from './game-drawtime';

export interface IGameDrawtimeLimit {
  id: string;
  limit: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;

  gameDrawtime: IGameDrawTime;
}
export interface IGameDrawtimelimitTableData {
  id: string;
  drawtime: string;
  limit: number;
  startDate: string;
  endDate: string;
  gameDrawtimeId: string;
  createdAt: string;
}

export interface IGameDrawtimeLimitQueryVariable {
  filterBy?: GameDrawtimeLimitFilterField;
  first: number;
  after?: string;
  before?: string;
}

export interface ICreateGameDrawtimeLimit {
  gameDrawtimeId: string;
  limit: number;
  startDate: string;
  endDate: string;
}

export interface IUpdateGameDrawtimeLimitInput {
  limit: number;
  startDate: string;
  endDate: string;
}

export function mapGameDrawtimeLimitData(gameDrawtimeLimit?: GameDrawtimeLimit | null): IGameDrawtimeLimit {
  return {
    id: gameDrawtimeLimit?.id ?? '',
    limit: gameDrawtimeLimit ? gameDrawtimeLimit.limit ?? 0 : 0,
    startDate: gameDrawtimeLimit ? gameDrawtimeLimit.startDate ?? '' : '',
    endDate: gameDrawtimeLimit ? gameDrawtimeLimit.endDate ?? '' : '',
    updatedAt: gameDrawtimeLimit ? gameDrawtimeLimit.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: gameDrawtimeLimit ? gameDrawtimeLimit.createdAt ?? new Date().toISOString() : new Date().toISOString(),

    gameDrawtime: mapGQLGameDrawTime(gameDrawtimeLimit?.gameDrawtime),
  };
}

export function mapGameDrawtimeLimitTableData(gameDrawtimeLimit: IGameDrawtimeLimit): IGameDrawtimelimitTableData {
  return {
    id: gameDrawtimeLimit.id,
    limit: gameDrawtimeLimit.limit,
    startDate: gameDrawtimeLimit.startDate,
    endDate: gameDrawtimeLimit.endDate,
    drawtime: gameDrawtimeLimit.gameDrawtime.drawTime.name,
    gameDrawtimeId: gameDrawtimeLimit.gameDrawtime.id,
    createdAt: gameDrawtimeLimit
      ? new Date(gameDrawtimeLimit.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
  };
}
