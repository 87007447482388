import { useState } from 'react';

// interfaces
import { useBetsGameDrawTimesQuery, useBetsDisabledUshersQuery } from '@/generated/graphql';
import type { IBetQueryVariable, IGameDrawTime, IGame } from '@/shared/interfaces';
import { mapGQLGameDrawTime } from '@/shared/interfaces';

// material-ui
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';

// graphql

interface IDataFilter {
  dateFrom: string;
  dateTo: string;
}

interface IBetQueryInputFilter {
  transactionCode: string;
  numberCombination: string;
  maxAmount: string;
  minAmount: string;
}

interface Props {
  setBetQueryVariable: React.Dispatch<React.SetStateAction<IBetQueryVariable>>;
  betQueryVariable: IBetQueryVariable;
  usherName: string;
  game: IGame;
  dateFromDefaultDate: string;
  dateToDefaultDate: string;
}

export const BetsFilter: React.FC<Props> = ({
  setBetQueryVariable,
  betQueryVariable,
  game,
  dateFromDefaultDate,
  dateToDefaultDate,
  usherName,
}) => {
  const [gameDrawTimes, setGameDrawTime] = useState<IGameDrawTime[]>([]);

  const [dataFilter, setDataFilter] = useState<IDataFilter>({
    dateFrom: dateFromDefaultDate,
    dateTo: dateToDefaultDate,
  });
  const [betQueryInputFilter, setBetQueryInputFilter] = useState<IBetQueryInputFilter>({
    transactionCode: '',
    numberCombination: '',
    maxAmount: '',
    minAmount: '',
  });

  // QUERY

  useBetsGameDrawTimesQuery({
    variables: { filterBy: { gameId: game.id } },
    skip: game.id.length <= 0,
    onCompleted: (data) => {
      const mappedGameDrawTime: IGameDrawTime[] = data.gameDrawTimes.nodes.map((gameDrawTime) => mapGQLGameDrawTime(gameDrawTime));

      setGameDrawTime(mappedGameDrawTime);
    },
  });

  const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();

  const betsFilterDate = () => {
    const { dateFrom, dateTo } = dataFilter;

    if (dateFrom.length > 0 && dateTo.length > 0) {
      setBetQueryVariable({
        ...betQueryVariable,
        filterBy: { ...betQueryVariable.filterBy, dateFrom: toUTC(dateFrom, '12:00:00 AM'), dateTo: toUTC(dateTo, '11:59:59 PM') },
      });
    }
  };

  const handleInputDataFilter = (key: string, type: 'TransactionCode' | 'Combination' | 'Max' | 'Min') => {
    if (key === 'Enter') {
      if (type === 'TransactionCode') {
        setBetQueryVariable({
          ...betQueryVariable,
          filterBy: { ...betQueryVariable.filterBy, transactionCode: betQueryInputFilter.transactionCode },
        });
      } else if (type === 'Combination') {
        const input = betQueryInputFilter.numberCombination.split('').join(',');
        setBetQueryVariable({ ...betQueryVariable, filterBy: { ...betQueryVariable.filterBy, numberCombination: input } });
      } else if (type === 'Min') {
        setBetQueryVariable({
          ...betQueryVariable,
          filterBy: { ...betQueryVariable.filterBy, minAmount: parseFloat(betQueryInputFilter.minAmount) },
        });
      } else if (type === 'Max') {
        setBetQueryVariable({
          ...betQueryVariable,
          filterBy: { ...betQueryVariable.filterBy, maxAmount: parseFloat(betQueryInputFilter.maxAmount) },
        });
      }
    }
  };

  return (
    <>
      <Box bgcolor={'#EDEDED'} px={3} py={2} mt={0}>
        <Grid container spacing={4} py={1}>
          {/* AGENT */}
          <Grid item md={3}>
            <Stack direction="row" spacing={2} justifyContent={'start'} alignItems={'center'}>
              <Typography variant="subtitle2" gutterBottom my={0}>
                Agent Name:
              </Typography>
              <Typography variant="subtitle1" gutterBottom my={0}>
                {usherName}
              </Typography>
            </Stack>
          </Grid>

          {/* DATE */}
          <Grid item md={3}>
            <FormControl fullWidth size="small">
              <TextField
                id="date"
                label="Start date"
                type="date"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setDataFilter({ ...dataFilter, dateFrom: event.target.value });
                }}
                value={dataFilter.dateFrom}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth size="small">
              <TextField
                id="date"
                label="End date"
                type="date"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setDataFilter({ ...dataFilter, dateTo: event.target.value });
                }}
                value={dataFilter.dateTo}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <Button variant="contained" size="medium" type="submit" onClick={betsFilterDate}>
              Filter
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={2} py={1}>
          {/* <Grid item md={2} className='pt-2'>

                    </Grid> */}

          {/* Transaction Code */}
          <Grid item md={3} className="pt-2">
            <FormControl fullWidth>
              <OutlinedInput
                value={betQueryInputFilter.transactionCode}
                onChange={(event) => setBetQueryInputFilter({ ...betQueryInputFilter, transactionCode: event.target.value })}
                onKeyDown={(event) => handleInputDataFilter(event.key, 'TransactionCode')}
                size="small"
                placeholder="Transaction ID"
              />
            </FormControl>
          </Grid>
          <Grid item md={4} className="pt-2">
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Combination"
                    variant="outlined"
                    value={betQueryInputFilter.numberCombination}
                    onChange={(event) => setBetQueryInputFilter({ ...betQueryInputFilter, numberCombination: event.target.value })}
                    onKeyDown={(event) => handleInputDataFilter(event.key, 'Combination')}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Draw</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={betQueryVariable.filterBy?.gameDrawTimeId ?? ''}
                    label="Age"
                    // onChange={handleChange}
                  >
                    <MenuItem
                      onClick={() =>
                        setBetQueryVariable({ ...betQueryVariable, filterBy: { ...betQueryVariable.filterBy, gameDrawTimeId: undefined } })
                      }
                      value={''}>
                      All
                    </MenuItem>
                    {gameDrawTimes.map((gameDrawTime) => (
                      <MenuItem
                        key={gameDrawTime.id}
                        value={gameDrawTime.id}
                        onClick={() =>
                          setBetQueryVariable({
                            ...betQueryVariable,
                            filterBy: { ...betQueryVariable.filterBy, gameDrawTimeId: gameDrawTime.id },
                          })
                        }>
                        {gameDrawTime.drawTime.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} className="pt-2">
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Min Play"
                    variant="outlined"
                    value={betQueryInputFilter.minAmount}
                    onChange={(event) => setBetQueryInputFilter({ ...betQueryInputFilter, minAmount: event.target.value })}
                    onKeyDown={(event) => handleInputDataFilter(event.key, 'Min')}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Max Play"
                    variant="outlined"
                    value={betQueryInputFilter.maxAmount}
                    onChange={(event) => setBetQueryInputFilter({ ...betQueryInputFilter, maxAmount: event.target.value })}
                    onKeyDown={(event) => handleInputDataFilter(event.key, 'Max')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} className="pt-2">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) =>
                      setBetQueryVariable({
                        ...betQueryVariable,
                        filterBy: { ...betQueryVariable.filterBy, isOriginalBet: event.target.checked },
                      })
                    }
                  />
                }
                label="Show Original Bet Only"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
