import { TextField } from '@mui/material';

// interfaces
import type { IGameDrawTime, IDrawTime } from '@/shared/interfaces';

interface IProps {
  gameDrawTime: IGameDrawTime;
  gameDrawTimes: IGameDrawTime[];
  setGameDrawTimes: React.Dispatch<React.SetStateAction<IGameDrawTime[]>>;
}

export const DrawTime: React.FC<IProps> = ({ gameDrawTime, gameDrawTimes, setGameDrawTimes }) => {
  const updateDrawTimeInputDate = (drawTimeId: string, value: string, field: keyof IDrawTime) => {
    setGameDrawTimes(
      gameDrawTimes.map((dt) => (dt.drawTime.id === drawTimeId ? { ...dt, drawTime: { ...dt.drawTime, [field]: value } } : dt)),
    );
  };

  return (
    <>
      <TextField
        onChange={(e) => updateDrawTimeInputDate(gameDrawTime.drawTime.id, e.target.value, 'endTime')}
        size="small"
        label="Draw Time"
        type="time"
        value={gameDrawTime.drawTime.endTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
      <TextField
        onChange={(e) => updateDrawTimeInputDate(gameDrawTime.drawTime.id, e.target.value, 'startTime')}
        size="small"
        label="Start Time"
        type="time"
        value={gameDrawTime.drawTime.startTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ ml: 6 }}
      />
      <TextField
        onChange={(e) => updateDrawTimeInputDate(gameDrawTime.drawTime.id, e.target.value, 'cutOffTime')}
        size="small"
        label="Cut-Off"
        type="time"
        value={gameDrawTime.drawTime.cutOffTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ ml: 6 }}
      />
    </>
  );
};
