import { Grid, Typography } from '@mui/material';

import { useState } from 'react';

import './development.page.css';

// graphql
import type { PageInfo } from '@/generated/graphql';
import { useDevelopmentUploadedAppApkQuery } from '@/generated/graphql';

// interfaces
import type { IAppApkTableData, IAppApkQueryVariable } from '@/shared/interfaces';
import { mapGQLAppApk } from '@/shared/interfaces';

//Components
import { AppUpload } from './components/app-upload';
import { AppApkTable } from './components/uploaded-app-apk-table';

// material ui

export function Development() {
  const [appApkTableData, setAppApkTableData] = useState<IAppApkTableData[]>([]);

  const [appApkQueryVariable, setAppApkQueryVariable] = useState<IAppApkQueryVariable>({
    first: 10,
  });

  const [pageInfoApkApk, setPageInfoApkApk] = useState<PageInfo>();
  const [totalCountApkApk, setTotalCountApkApk] = useState<number>(0);

  const uploadedAppApkQuery = useDevelopmentUploadedAppApkQuery({
    variables: appApkQueryVariable,
    onCompleted: (data) => {
      const mappedData: IAppApkTableData[] = data.uploadedAppApk.nodes.map((appApk) => {
        const { id, originalFileName, appVersion, createdAt, fileSize, appName, createdAtISOString } = mapGQLAppApk(appApk);
        return {
          id,
          originalFileName,
          appVersion,
          appName,
          fileSize: formatBytes(fileSize),
          createdAt,
          createdAtISOString,
        };
      });

      setTimeout(() => {
        setAppApkTableData(mappedData);
        setPageInfoApkApk(data.uploadedAppApk.pageInfo);
        setTotalCountApkApk(data.uploadedAppApk.totalCount);
      }, 100);
    },
  });

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const refetchData = () => {
    uploadedAppApkQuery.refetch();
  };

  return (
    <>
      <Grid item md={12} p={4}>
        <Typography variant="h5" gutterBottom sx={{ color: '#17416D', fontFamily: 'Inter', fontWeight: '600' }}>
          App Uploaded Files
        </Typography>
        <AppUpload formatBytes={formatBytes} refetchData={refetchData} />
        <AppApkTable
          rows={appApkTableData}
          appApkQueryVariable={appApkQueryVariable}
          setAppApkQueryVariable={setAppApkQueryVariable}
          totalCount={totalCountApkApk}
          pageInfo={pageInfoApkApk}
        />
      </Grid>
    </>
  );
}
