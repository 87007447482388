import { Buffer } from 'buffer';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tabs, Avatar } from '@mui/material';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//GraphQL
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import { useGamesDashboardByGameTypeQuery } from '@/generated/graphql';

//Interfaces
import type { IGame, GameTypePhoto } from '@/shared/interfaces';
import { mapGQLGame } from '@/shared/interfaces';

// images
import ball20 from '../../assets/images/ball20.png';

interface Props {
  setGameCtx: React.Dispatch<React.SetStateAction<IGame>>;
  gameCtx: IGame;
}

export const GameTypeDashboardLayout: React.FC<Props> = ({ setGameCtx, gameCtx }) => {
  const location = useLocation();
  const routepathname = location.pathname.split('/'); // path is /contact
  const routepath = routepathname[2];

  //States
  const [games, setGames] = useState<IGame[]>([]);
  const [imagePerGameType, setImagePerGameType] = useState<GameTypePhoto[]>([]);
  const [value, setValue] = React.useState(0);
  const [tabAllGame, setTabAllGame] = React.useState('1');

  //Image Handling
  const GetGameTypePhoto = (id: string) =>
    axios.get(
      (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL) +
        '/api/v1/get-game-type-photo/' +
        id,
      {
        responseType: 'arraybuffer',
      },
    );

  useGamesDashboardByGameTypeQuery({
    onCompleted: (data) => {
      const gameTypeMap: IGame[] = data.games.nodes.map((game) => mapGQLGame(game));
      const gameDefault = gameTypeMap.find((item) => item.gameType.name === 'Swertres') ?? gameTypeMap[0];
      setGames(gameTypeMap);

      if (routepath !== 'reports' && routepath !== 'operators-report') {
        setTimeout(() => {
          setGameCtx(gameDefault);
        }, 50);
      }

      // game type photo
      const gameTypeMappingImages: GameTypePhoto[] = [];
      gameTypeMap.map((game, index) => {
        GetGameTypePhoto(game?.gameType?.id ?? '')
          .then((res) => {
            if (game?.gameType?.id) {
              if (res.headers['content-type'] === 'image/jpeg') {
                const imageBase64 = Buffer.from(res.data, 'binary').toString('base64');
                gameTypeMappingImages.push({
                  id: game.gameType.id,
                  image: `data:image/jpeg;base64,${imageBase64}`,
                });
              } else {
                gameTypeMappingImages.push({
                  id: game?.gameType?.id,
                  image: ball20,
                });
              }
            }
            if (index === gameTypeMap.length - 1) {
              setImagePerGameType(gameTypeMappingImages);
            }
          })
          .catch((err) => {
            console.error(err, 'EEEERRR');
          });
      });
    },
  });

  const handleSelection = (gameSelected: IGame, index: number) => {
    if (gameSelected.id) {
      setGameCtx(gameSelected);
      setValue(index);
    }
  };

  const handleChangeAllGameTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabAllGame(newValue);
    if (newValue === '1') {
      setGameCtx({ ...gameCtx, id: '' });
    } else if (newValue === '2') {
      const gameDefault = games.find((item) => item.gameType.name === 'Swertres') ?? games[0];
      setGameCtx(gameDefault);
      setValue(games.indexOf(gameDefault));
    }
  };

  useEffect(() => {
    if (routepath === 'reports' || routepath === 'operators-report') {
      setGameCtx({ ...gameCtx, id: '' });
    }
  }, [routepath]);

  return (
    <>
      {routepath === 'reports' || routepath === 'operators-report' ? (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabAllGame}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeAllGameTab} aria-label="lab API tabs example">
                <Tab label="ALL GAMES" value="1" />
                <Tab label="BY GAME TYPE" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ py: 2 }} />
            <TabPanel value="2">
              <Box
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderBottom: 1,
                  borderColor: 'divider',
                  overflow: 'inherit',
                }}>
                <Tabs selectionFollowsFocus aria-label="basic tabs example" variant="scrollable" value={value} scrollButtons>
                  {games.map((game, index) => (
                    <Tab
                      sx={{ width: '12em', pl: 2, pr: 2 }}
                      icon={
                        <Avatar
                          sx={{ borderRadius: 0 }}
                          alt="test avatar"
                          src={imagePerGameType.filter((img) => img.id === game?.gameType?.id)[0]?.image ?? ball20}
                        />
                      }
                      label={game.gameType.name}
                      key={game.id}
                      onClick={() => handleSelection(game, index)}
                    />
                  ))}
                </Tabs>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderBottom: 1,
            borderColor: 'divider',
            overflow: 'inherit',
          }}>
          <Tabs selectionFollowsFocus aria-label="basic tabs example" variant="scrollable" value={value} scrollButtons>
            {games.map((game, index) => (
              <Tab
                sx={{ width: '12em', pl: 2, pr: 2 }}
                icon={
                  <Avatar
                    sx={{ borderRadius: 0 }}
                    alt="test avatar"
                    src={imagePerGameType.filter((img) => img.id === game?.gameType?.id)[0]?.image ?? ball20}
                  />
                }
                label={game.gameType.name}
                key={game.id}
                onClick={() => handleSelection(game, index)}
              />
            ))}
          </Tabs>
        </Box>
      )}
    </>
  );
};
