import { TextField } from '@mui/material';

import type { INumberCombInput } from '@/shared/interfaces';

//interfaces
interface ICominationInput {
  perCombinationLength: number;
  combinationLength: number;
  numberCombInputs: INumberCombInput[];
  setNumberCombInputs: React.Dispatch<React.SetStateAction<INumberCombInput[]>>;
}

export const CombinationInput: React.FC<ICominationInput> = ({
  perCombinationLength,
  combinationLength,
  numberCombInputs,
  setNumberCombInputs,
}) => {
  //Functions
  const handleFormChange = (index: number, value: string) => {
    const updatedNumberCombiInput: INumberCombInput[] = [];

    numberCombInputs.map((elem) => {
      if (elem.id === index) {
        // console.log('set value to', value)
        elem.value = value;
        elem.value = value.replace(/[^0-9]/g, '');
        if (elem.value.length === perCombinationLength && index !== numberCombInputs.length - 1 && value !== '') {
          // console.log('NEXT',value !== '')

          numberCombInputs[index].isFocused = false;
          numberCombInputs[index + 1].isFocused = true;
        } else {
          // find the element which focus is true and set it to false
          const focusedElement = numberCombInputs.find((elem) => elem.isFocused === true);
          if (focusedElement) {
            focusedElement.isFocused = false;
          }
        }
        updatedNumberCombiInput.push(elem);
      } else {
        updatedNumberCombiInput.push(elem);
      }
    });

    setNumberCombInputs(updatedNumberCombiInput);
  };

  const handleInputKeyDown = (index: number, key: string) => {
    if (key === 'Backspace') {
      if (numberCombInputs[index].value.length === 0) {
        const updatedNumberCombiInput: INumberCombInput[] = [];

        numberCombInputs.map((elem) => {
          if (elem.id === index) {
            elem.isFocused = false;
            if (index > 0) {
              numberCombInputs[index - 1].value = '';
              numberCombInputs[index - 1].isFocused = true;
            }
            updatedNumberCombiInput.push(elem);
          } else {
            updatedNumberCombiInput.push(elem);
          }
        });

        setNumberCombInputs(updatedNumberCombiInput);
      }
    }
  };

  return (
    <>
      <div className="combination-input-container" style={{ display: 'grid', gridTemplateColumns: ' 1fr'.repeat(combinationLength) }}>
        {numberCombInputs.map((inputE, index) => (
          <TextField
            variant="outlined"
            style={{ width: '1fr', maxWidth: '5em', display: 'flex', justifyContent: 'space-between' }}
            key={index}
            name="ball"
            value={inputE.value}
            inputRef={(input) => (input && inputE.isFocused ? input.focus() : null)}
            inputProps={{ maxLength: perCombinationLength }}
            sx={{ '& input': { textAlign: 'center' } }}
            onChange={(event) => handleFormChange(index, event.target.value)}
            onKeyDown={(event) => {
              handleInputKeyDown(index, event.key);
            }}
          />
        ))}
      </div>
    </>
  );
};
