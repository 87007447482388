import { useDisabledDrawAdminMutation } from '@/generated/graphql';
import { IDrawAdminTable } from '@/shared/interfaces';

import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CgDanger } from 'react-icons/cg';

interface IProp {
  datas: IDrawAdminTable | undefined;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
  refetchData: () => void;
}

export function DisableDrawAdmin(props: IProp) {
  const { datas, isOpen, openEvent, refetchData } = props;

  const [disabledDrawAdminMutation] = useDisabledDrawAdminMutation();

  const handleClose = () => openEvent(false);

  const toggle = async () => {
    await disabledDrawAdminMutation({
      variables: {
        disabledDrawAdminId: datas?.id ?? '',
      },
      onCompleted() {
        refetchData();
      },
    });

    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          position: 'absolute',
          margin: '0.1% auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: 0,
          marginRight: '-50%',
          transform: 'translate(-50%, -48vh)',
          border: '0px',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        },
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: 450,
          height: 350,
        }}>
        <Divider
          sx={{
            height: '25px',
            backgroundColor: '#87ADD4',
          }}
        />
        <Box p={1}>
          <Stack direction="row" spacing={2} justifyContent={'center'} mt={5}>
            <CgDanger color="#f8bc86" fontSize={80} />
          </Stack>
          <Typography
            sx={{
              textAlign: 'center',
            }}
            variant="h4">
            Are you sure?
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              mt: 3,
            }}
            variant="body2">
            You will not able to recover this data!
          </Typography>

          <Stack direction="row" spacing={2} justifyContent={'center'} mt={5}>
            <Button variant="contained" sx={{ background: 'grey' }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={toggle}>
              Yes, delete
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
