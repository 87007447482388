import { useState } from 'react';
import { useParams } from 'react-router-dom';

// material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// components
import { TelegramReportTypeTable } from './components/report-type/report-types-table';
import { CreateReportType } from './components/report-type/modal-create';
import { TelegramReportDestinationTable } from './components/report-destination/report-destinations-table';
import { CreateReportDestination } from './components/report-destination/modal-create';
import { TelegramReportTable } from './components/report/table';
import { CreateReport } from './components/report/create';
import { ModalTriggerManualReport } from './components/report-type/modal-trigger-manual-repot';
import { ReportsFilterSection } from './components/report/filter-section';
import { ReportDestinationsFilterSection } from './components/report-destination/filter-section';

// graphql
import { useTelegramReportTypesQuery, useTelegramReportDestinationsQuery, useTelegramReportsQuery } from '@/generated/graphql';
import type { GetTelegramReportsFilter, PageInfo } from '@/generated/graphql';

// interfaces
import type {
  ITelegramReportTypeTable,
  ITelegramReportTypeQueryVariable,
  ITelegramReportDestination,
  ITelegramReportDestinationQueryVariable,
  ITelegramReportDestinationTable,
  ITelegramReportQueryVariable,
  ITelegramReportTable,
} from '@/shared/interfaces';
import { mapGQLTelegramReportType, mapGQLTelegramReportDestination, mapGQLTelegramReport } from '@/shared/interfaces';

// css
import './styles/style.css';

export function TelegramReportsPage() {
  const [tabValue, setTabValue] = useState('1');

  // report variables
  const [reportQueryVariable, setReportQueryVariable] = useState<ITelegramReportQueryVariable>({
    first: 25,
  });
  const [tableDataReports, setTableDataReports] = useState<ITelegramReportTable[]>([]);

  const [pageInfoReport, setPageInfoReport] = useState<PageInfo>();
  const [totalCountReport, setTotalCountReport] = useState<number>(0);

  const [modalCreateReport, setModalCreateReport] = useState<boolean>(false);

  // -------------------END

  // report type variables
  const [reportTypeQueryVariable, setReportTypeQueryVariable] = useState<ITelegramReportTypeQueryVariable>({
    first: 25,
  });
  const [tableDataReportTypes, setTableDataReportTypes] = useState<ITelegramReportTypeTable[]>([]);

  const [pageInfoReportType, setPageInfoReportType] = useState<PageInfo>();
  const [totalCountReportType, setTotalCountReportType] = useState<number>(0);

  const [modalCreateReportType, setModalCreateReportType] = useState<boolean>(false);
  const [modalManualReport, setModalManualReport] = useState<boolean>(false);

  // -------------------END
  // report destination variables

  const [reportDestinationQueryVariable, setReportDestinationQueryVariable] = useState<ITelegramReportDestinationQueryVariable>({
    first: 25,
  });
  const [tableDataReportDestinations, setTableDataReportDestinations] = useState<ITelegramReportDestinationTable[]>([]);

  const [pageInfoReportDestination, setPageInfoReportDestination] = useState<PageInfo>();
  const [totalCountReportDestination, setTotalCountReportDestination] = useState<number>(0);

  const [modalCreateReportDestination, setModalCreateReportDestination] = useState<boolean>(false);

  // -------------------END

  const getReportTypes = useTelegramReportTypesQuery({
    variables: reportTypeQueryVariable,
    onCompleted(data) {
      const mappedData = data.telegramReportTypes.nodes.map((x) => mapGQLTelegramReportType(x));

      const mappedTableData: ITelegramReportTypeTable[] = mappedData.map((x) => ({
        id: x.id,
        name: x.name,
        description: x.description,
        createdAt: x.createdAt,
      }));

      setTimeout(() => {
        setTableDataReportTypes(mappedTableData);
        setTotalCountReportType(data.telegramReportTypes.totalCount);
        setPageInfoReportType(data.telegramReportTypes.pageInfo);
      }, 100);
    },
  });

  const getReportDestinations = useTelegramReportDestinationsQuery({
    variables: reportDestinationQueryVariable,
    onCompleted(data) {
      const mappedData = data.telegramReportDestinations.nodes.map((x) => mapGQLTelegramReportDestination(x));

      const mappedTableData: ITelegramReportDestinationTable[] = mappedData.map((x) => ({
        id: x.id,
        botName: x.botName,
        botUsername: x.botUsername,
        token: x.token,
        groupChatName: x.groupChatName,
        chatId: x.chatId,
        topicId: x.topicId,
        topicName: x.topicName,
        type: x.type,
        telegramReport: x.telegramReport.name,
        createdAt: x.createdAt,
      }));

      setTimeout(() => {
        setTableDataReportDestinations(mappedTableData);
        setTotalCountReportDestination(data.telegramReportDestinations.totalCount);
        setPageInfoReportDestination(data.telegramReportDestinations.pageInfo);
      }, 100);
    },
  });

  const getReports = useTelegramReportsQuery({
    variables: reportQueryVariable,
    onCompleted(data) {
      const mappedData = data.telegramReports.nodes.map((x) => mapGQLTelegramReport(x));

      const mappedTableData: ITelegramReportTable[] = mappedData.map((x) => ({
        id: x.id,
        name: x.name,
        reportType: x.telegramReportType.name,
        description: x.description,
        createdAt: x.createdAt,
      }));

      setTimeout(() => {
        setTableDataReports(mappedTableData);
        setTotalCountReport(data.telegramReports.totalCount);
        setPageInfoReport(data.telegramReports.pageInfo);
      }, 100);
    },
  });

  const refetchData = () => {
    getReportTypes.refetch({
      ...reportTypeQueryVariable,
    });
    getReportDestinations.refetch({
      ...reportDestinationQueryVariable,
    });
    getReports.refetch({
      ...reportQueryVariable,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Report Destination" value="1" />
              <Tab label="Report Types" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: '0em' }}>
            <Box
              sx={{
                backgroundColor: '#C5D8EC',
                height: '100%',
              }}
              p={2}>
              <Stack justifyContent={'space-between'} display={'flex'} direction={'row'} mt={2}>
                <ReportDestinationsFilterSection
                  queryVariable={reportDestinationQueryVariable}
                  setQueryVariable={setReportDestinationQueryVariable}
                  tableDataReportTypes={tableDataReportTypes}
                />
                <Button className="btn-cm-font" variant="contained" onClick={() => setModalCreateReportDestination(true)}>
                  Create Report Destination
                </Button>
              </Stack>
              <TelegramReportDestinationTable
                rows={tableDataReportDestinations}
                setTelegramReportDestinationQueryVariable={setReportDestinationQueryVariable}
                telegramReportDestinationQueryVariable={reportDestinationQueryVariable}
                totalCount={totalCountReportDestination}
                pageInfo={pageInfoReportDestination}
                getTelegramReportDestinationLoading={getReportDestinations.loading}
                refetchData={refetchData}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2" style={{ padding: '0em' }}>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Box
                  sx={{
                    backgroundColor: '#C5D8EC',
                    height: '100%',
                  }}
                  p={2}>
                  <Stack justifyContent={'space-between'} display={'flex'} direction={'row'} mt={2}>
                    <ReportsFilterSection
                      reportQueryVariable={reportQueryVariable}
                      setReportQueryVariable={setReportQueryVariable}
                      tableDataReportTypes={tableDataReportTypes}
                    />

                    <Button className="btn-cm-font" variant="contained" onClick={() => setModalCreateReport(true)}>
                      Create Report
                    </Button>
                  </Stack>

                  <TelegramReportTable
                    rows={tableDataReports}
                    setTelegramReportQueryVariable={setReportQueryVariable}
                    telegramReportQueryVariable={reportTypeQueryVariable}
                    totalCount={totalCountReport}
                    pageInfo={pageInfoReport}
                    getTelegramReportLoading={getReports.loading}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  sx={{
                    backgroundColor: '#C5D8EC',
                    height: '100%',
                  }}
                  p={2}>
                  <Grid justifyContent={'end'} display={'flex'}>
                    <Button className="btn-cm-font" variant="contained" onClick={() => setModalCreateReportType(true)}>
                      Create Report Type
                    </Button>
                    <Button
                      sx={{ ml: 2 }}
                      color={'success'}
                      className="btn-cm-font"
                      variant="contained"
                      onClick={() => setModalManualReport(true)}>
                      Trigger manual report
                    </Button>
                  </Grid>

                  <TelegramReportTypeTable
                    rows={tableDataReportTypes}
                    setTelegramReportTypeQueryVariable={setReportTypeQueryVariable}
                    telegramReportTypeQueryVariable={reportTypeQueryVariable}
                    totalCount={totalCountReportType}
                    pageInfo={pageInfoReportType}
                    getTelegramReportTypeLoading={getReportTypes.loading}
                  />
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
      <CreateReportType open={modalCreateReportType} setOpen={setModalCreateReportType} refetchData={refetchData} />
      <CreateReport open={modalCreateReport} setOpen={setModalCreateReport} refetchData={refetchData} />
      <CreateReportDestination open={modalCreateReportDestination} setOpen={setModalCreateReportDestination} refetchData={refetchData} />
      <ModalTriggerManualReport open={modalManualReport} setOpen={setModalManualReport} reportTypes={tableDataReportTypes} />
    </>
  );
}
