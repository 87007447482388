import { useState, useEffect } from 'react';

// graphql
import { useDrawAdminsQuery, PageInfo } from '@/generated/graphql';

// material ui
import { Box, Button, Grid } from '@mui/material';

// interfaces
import { IDrawAdmin, IDrawAdminQueryVariable, mapGQLDrawAdmin, IDrawAdminTable } from '@/shared/interfaces';

// css
import './draw-admin-style.css';

// components
import { TableDrawAdmins } from './components/table.draw-admins';
import { AddDrawAdmin } from './components/add-draw-admin';
import { EditDrawAdmin } from './components/edit.draw-admin';
import { ChangePasswordDrawAdmin } from './components/edit-draw-admin-password';
import { DisableDrawAdmin } from './components/disable-draw-admin';

export const DrawAdminsPage = () => {
  const [drawAdmins, setDrawAdmins] = useState<IDrawAdminTable[]>([]);
  const [drawAdminsQueryVariable, setDrawAdminsQueryVariable] = useState<IDrawAdminQueryVariable>({
    first: 25,
  });

  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [getDrawAdminLoading, setGetDrawAdminLoading] = useState<boolean>(false);
  const [addOpen, setAddOpen] = useState(false);

  // edit drawAdmin
  const [editOpen, setEditOpen] = useState(false);
  const [disableOpen, setDisableOpen] = useState(false);
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);
  const [editDrawAdminData, setEditDrawAdminData] = useState<IDrawAdminTable>();

  // QUERIES
  const drawAdminQuery = useDrawAdminsQuery({
    variables: drawAdminsQueryVariable,
    onCompleted(data) {
      const mapDrawAdmins: IDrawAdmin[] = data.drawAdmins.nodes.map((drawAdmin) => mapGQLDrawAdmin(drawAdmin));

      setTimeout(() => {
        setDrawAdmins(
          mapDrawAdmins.map((drawAdmin) => ({
            id: drawAdmin.id,
            name: `${drawAdmin.firstName} ${drawAdmin.lastName}`,
            firstName: drawAdmin.firstName,
            lastName: drawAdmin.lastName,
            username: drawAdmin.user.username,
            email: drawAdmin.user.email,
            createdAt: drawAdmin.createdAt,
          })),
        );
        setPageInfo(data.drawAdmins.pageInfo);
        setTotalCount(data.drawAdmins.totalCount);
      }, 100);
    },
  });

  // END QUERIES

  const drawAdminEvent = (data: IDrawAdminTable, eventName: string) => {
    console.log('🚀 ~ file: drawAdmins.page.tsx:124 ~ drawAdminEvent ~ eventName:', eventName);
    if (eventName == 'edit') {
      setEditDrawAdminData(data);
      setEditOpen(true);
    } else if (eventName == 'update password') {
      setEditDrawAdminData(data);
      setUpdatePasswordOpen(true);
    } else if (eventName == 'disable') {
      setEditDrawAdminData(data);
      setDisableOpen(true);
    }
  };

  const refetchData = () => {
    drawAdminQuery.refetch({
      ...drawAdminsQueryVariable,
    });
  };

  useEffect(() => {
    setGetDrawAdminLoading(drawAdminQuery.loading);
  }, [drawAdminQuery.loading]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#C5D8EC',
          height: '100%',
        }}
        p={2}>
        <Grid justifyContent={'end'} display={'flex'}>
          <Button variant="contained" onClick={() => setAddOpen(true)}>
            Add Draw Admin
          </Button>
        </Grid>
        <TableDrawAdmins
          rows={drawAdmins}
          setDrawAdminQueryVariable={setDrawAdminsQueryVariable}
          drawAdminQueryVariable={drawAdminsQueryVariable}
          totalCount={totalCount}
          pageInfo={pageInfo}
          drawAdminEvent={drawAdminEvent}
          getDrawAdminLoading={getDrawAdminLoading}
        />
      </Box>
      <AddDrawAdmin open={addOpen} setOpen={setAddOpen} refetchData={refetchData} />
      <EditDrawAdmin open={editOpen} setOpen={setEditOpen} refetchData={refetchData} drawAdmin={editDrawAdminData} />
      <ChangePasswordDrawAdmin
        isOpen={updatePasswordOpen}
        openEvent={setUpdatePasswordOpen}
        refetchData={refetchData}
        datas={editDrawAdminData}
      />
      <DisableDrawAdmin isOpen={disableOpen} openEvent={setDisableOpen} refetchData={refetchData} datas={editDrawAdminData} />
    </>
  );
};
