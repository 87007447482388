import type { GameSalesNumberLimit, GameSalesNumberLimitFilterField } from '@/generated/graphql';

import { mapGQLGame } from './game';

import type { IGame } from './game';

export interface IGameSalesNumberLimit {
  id: string;
  numberCombination: string;
  limit: number;
  sales: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;

  game: IGame;
}
export interface ILimitTableData {
  id: string;
  numberCombination: string;
  numberCombinationOriginal: string;
  limit: number;
  sales: number;
  startDate: string;
  endDate: string;
}

export interface ILimitQueryVariable {
  filterBy?: GameSalesNumberLimitFilterField;
  first: number;
  after?: string;
  before?: string;
}

export interface ICreateGameNumberSalesLimit {
  numberCombination: string;
  limit: number;
  startDate: string;
  endDate: string;
}

export function mapGameSalesNumberLimitData(gameSalesNumberLimit?: GameSalesNumberLimit | null) {
  return {
    id: gameSalesNumberLimit?.id ?? '',
    numberCombination: gameSalesNumberLimit ? gameSalesNumberLimit.numberCombination ?? '' : '',
    limit: gameSalesNumberLimit ? gameSalesNumberLimit.limit ?? 0 : 0,
    sales: gameSalesNumberLimit ? gameSalesNumberLimit.sales ?? 0 : 0,
    startDate: gameSalesNumberLimit ? gameSalesNumberLimit.startDate ?? '' : '',
    endDate: gameSalesNumberLimit ? gameSalesNumberLimit.endDate ?? '' : '',
    updatedAt: gameSalesNumberLimit ? gameSalesNumberLimit.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: gameSalesNumberLimit ? gameSalesNumberLimit.createdAt ?? new Date().toISOString() : new Date().toISOString(),

    game: mapGQLGame(gameSalesNumberLimit?.game),
  };
}
