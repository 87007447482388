import { useState } from 'react';

// graphql
import { useCoordinatorQuery } from '@/generated/graphql';

// material ui
import { Box, Button, FormControl, IconButton, Stack, Typography } from '@mui/material';
import { IoReceipt } from 'react-icons/io5';
import { FaUserTie } from 'react-icons/fa6';
import { FaCalendarDay } from 'react-icons/fa6';
import { FaHandHoldingUsd } from 'react-icons/fa';

// interfaces
import type { ICoordinatorDailyCollectionVariable, ICoordinator } from '@/shared/interfaces';

// components
import { AddPayment } from './add-payment';

interface IProps {
  CDCQueryVariable: ICoordinatorDailyCollectionVariable;
  forwardBalance: number;
  coordinator: ICoordinator | undefined;
  refetchData: () => void;
}

export function TableCollectionHeader({ CDCQueryVariable, forwardBalance, coordinator, refetchData }: IProps) {
  const monthsArray = Array.from({ length: 12 }, (_, i) => {
    return new Date(2000, i).toLocaleString('en-US', { month: 'long' });
  });

  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  return (
    <>
      <Box bgcolor={'#6591BD'} mb={2} px={2} py={2}>
        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
          <Stack direction="row" spacing={4}>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="account" sx={{ color: 'black' }}>
                <FaUserTie />
              </IconButton>
              <Stack justifyContent={'flex-start'}>
                <Typography variant="button" m={0}>
                  {coordinator?.name}
                </Typography>
                <Typography variant="caption" align="left" fontSize={10}>
                  Operator name
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="account" sx={{ color: 'black' }}>
                <FaHandHoldingUsd />
              </IconButton>
              <Stack justifyContent={'flex-start'}>
                <Typography variant="button" m={0} fontSize={16}>
                  {coordinator?.commission} %
                </Typography>
                <Typography variant="caption" align="left" fontSize={10}>
                  Commission
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="account" sx={{ color: 'black' }}>
                <IoReceipt />
              </IconButton>
              <Stack justifyContent={'flex-start'}>
                <Typography variant="button" m={0} fontWeight={600} fontSize={16}>
                  ₱ {forwardBalance}
                </Typography>
                <Typography variant="caption" align="left" fontSize={10}>
                  Forward balance
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="account" sx={{ color: 'black' }}>
                <FaCalendarDay />
              </IconButton>
              <Stack justifyContent={'flex-start'}>
                <Typography variant="button" m={0}>
                  {monthsArray[CDCQueryVariable.filterBy.month - 1]} {CDCQueryVariable.filterBy.year} Operators Daily Collection
                </Typography>
                <Typography variant="caption" align="left" fontSize={10}>
                  Date
                </Typography>
              </Stack>
            </Stack>
            {/* <Typography variant="button" m={0} fontWeight={600}>
              Operators Daily Collection
            </Typography> */}
          </Stack>

          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Button
              sx={{
                ml: 1,
                fontSize: 12,
                fontWeight: 600,
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingTop: '7px',
                paddingBottom: '7px',
                textTransform: 'unset',
              }}
              type="submit"
              size="small"
              onClick={() => setAddPaymentModalOpen(true)}
              variant="contained">
              ADD PAYMENT
            </Button>
          </Stack>
        </Stack>
      </Box>
      <AddPayment refetchData={refetchData} open={addPaymentModalOpen} setOpen={setAddPaymentModalOpen} coordinatorId={coordinator?.id} />
    </>
  );
}
