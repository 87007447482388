import type { GameResult, GameResultFilterField } from '@/generated/graphql';

import { mapGQLGameDrawTime } from './game-drawtime';

import type { IGameDrawTime } from './game-drawtime';

export interface IGameResult {
  id: string;
  resultCombination: string;
  date: string;
  createdAt: string;
  updatedAt: string;

  gameDrawTime: IGameDrawTime;
}

export interface IGameResultTableData {
  id: string;
  resultCombination: string;
  originalResultCombination: string; // with comma
  draw: string;
  drawDate: string;
  gameDrawTimeId: string;
}

export interface IGameResultQueryVariable {
  filterBy?: GameResultFilterField;
  first: number;
  after?: string;
  before?: string;
}

export function mapGQLGameResult(gameResult?: GameResult | null): IGameResult {
  return {
    id: gameResult?.id ?? '',
    resultCombination: gameResult ? gameResult.resultCombination ?? '' : '',
    date: gameResult
      ? new Date(gameResult.date).toLocaleString('en-US', { timeZone: 'Asia/Manila', month: 'long', year: 'numeric', day: '2-digit' }) ?? ''
      : '',

    updatedAt: gameResult ? gameResult.updatedAt ?? '' : '',
    createdAt: gameResult ? gameResult.createdAt ?? '' : '',

    gameDrawTime: mapGQLGameDrawTime(gameResult?.gameDrawTime),
  };
}
