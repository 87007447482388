import React, { useEffect, useState, useContext, useRef } from 'react';
import { orderBy } from 'lodash';
import { CSVLink } from 'react-csv';

import '../../styles/reports.css';

// graphql
import { useReportGameDrawTimesQuery, PageInfo } from '@/generated/graphql';

// material ui
import { Grid, Button, Skeleton } from '@mui/material';

// interface
import { IUsherSalesReport, mapGQLGameDrawTime, IGameDrawTime, IBet } from '@/shared/interfaces';

// components
import { UsherSalesTable, Data, HeadCell, Align } from './usher-sales-table';

// context api
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

// services
import { usherSaleReportExportToPDF, generateDataForCSVExport } from '../../services';

interface IGameDrawTime2 extends HeadCell {
  endTime: string;
  gameName: string;
}

interface Props {
  ushersSalesReport: IUsherSalesReport[];
  winnerBets: IBet[];
  USRpageInfo: PageInfo | undefined;
  resetData: boolean;
  dateRangeLabel: string;
  USRtotalCount: number;
}

export const UsherSalesTab: React.FC<Props> = ({
  ushersSalesReport,
  USRpageInfo,
  USRtotalCount,
  resetData,
  dateRangeLabel,
  winnerBets,
}) => {
  const { gameCtx } = useGameDashboardCtx();
  const [CSVData, setCSVData] = useState<any[]>([]);
  const csvLink: any = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);
  const [headCells, setHeadCells] = useState<HeadCell[]>([]);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

  const [tableData, setTableData] = useState<Data[]>([]);

  const left: Align = 'left' as Align;
  const right: Align = 'right' as Align;

  const createHeadCell = (gameDrawTimesPrams: IGameDrawTime[], filterGameName?: string) => {
    let newHeadCell: HeadCell[] = [
      {
        id: 'usherId',
        label: 'Usher ID',
        numeric: false,
        tbCellAlign: 'left',
        hide: true,
        className: 'usherIDColumn',
      },
      {
        id: 'agentCode',
        label: 'Agent #',
        numeric: false,
        tbCellAlign: 'left',
        hide: false,
        className: 'agentNumColumn',
      },
      {
        id: 'agentName',
        label: 'Agent Name',
        numeric: false,
        tbCellAlign: 'left',
        hide: false,
        className: 'agentNameColumn',
      },
    ];

    const gameDrawTimes2: IGameDrawTime2[] = gameDrawTimesPrams.map((gameDrawTime) => {
      return {
        id: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`.replace(/ /g, ''),
        label: `${gameDrawTime.game.gameType.name} ${gameDrawTime.drawTime.name}`,
        numeric: true,
        endTime: gameDrawTime.drawTime.endTime,
        gameName: gameDrawTime.game.gameType.name,
        tbCellAlign: right,
        hide: false,
        className: 'GDTColumns',
      };
    });
    const gameNames = Array.from(new Set(gameDrawTimes2.map((x) => x.gameName)));

    gameNames.forEach((game) => {
      if (filterGameName && game.replace(/ /g, '') === filterGameName) {
        newHeadCell = newHeadCell.concat(
          orderBy(
            gameDrawTimes2.filter((x) => x.gameName === game),
            ['endTime'],
            'asc',
          ),
        );
      } else if (!filterGameName) {
        newHeadCell = newHeadCell.concat(
          orderBy(
            gameDrawTimes2.filter((x) => x.gameName === game),
            ['endTime'],
            'asc',
          ),
        );
      }
    });

    newHeadCell = newHeadCell.concat([
      {
        id: 'totalSales',
        label: 'Total Sales',
        numeric: true,
        tbCellAlign: right,
        hide: false,
        className: 'totSalesColumn',
      },
      {
        id: 'payableWinner',
        label: 'Total Win',
        numeric: true,
        tbCellAlign: right,
        hide: false,
        className: 'payableWinnerColumn',
      },
    ]);

    setHeadCells(newHeadCell);
  };

  // QUERIES
  useReportGameDrawTimesQuery({
    onCompleted(data) {
      const mappedGDT = data.gameDrawTimes.nodes.map((x) => mapGQLGameDrawTime(x));
      setGameDrawTimes(mappedGDT);
      createHeadCell(mappedGDT);
    },
  });

  const populateTable = () => {
    setTimeout(() => {
      const sortedUshersSalesReport = orderBy(ushersSalesReport, 'totalSales', 'desc');
      const ushersSalesReportTableData = sortedUshersSalesReport.map((usherReport) => {
        const { usher, usherGameSales } = usherReport;

        const dynamicObject: Data = {};

        usherGameSales.forEach((gameSales) => {
          gameSales.gameDrawTimeSales.forEach((gameDrawTimeSales) => {
            dynamicObject[`${gameSales.name}${gameDrawTimeSales.drawName}`.replace(/ /g, '')] = gameDrawTimeSales.sales;
          });
        });

        dynamicObject.agentCode = usher.uniqueCode;
        dynamicObject.agentName = `${usher.firstName} ${usher.lastName}`;
        dynamicObject.totalSales = usherReport.totalSales;
        dynamicObject.payableWinner = usherReport.payableWinner;
        dynamicObject.totalBets = usherReport.totalBets;
        dynamicObject.hits = usherReport.hits;
        dynamicObject.usherId = usherReport.usher.id;

        return dynamicObject;
      });
      setTableData(ushersSalesReportTableData);
      setCSVData(generateDataForCSVExport(ushersSalesReportTableData, headCells, 'STL Usher Summary Report'));
    }, 10);
  };

  // this will watch USRpageInfo change to check if all data is arrived
  useEffect(() => {
    if (USRpageInfo && !USRpageInfo.hasNextPage) {
      populateTable();
    } else if (tableData.length === 0 && ushersSalesReport.length > 25) {
      populateTable();
    }
  }, [USRpageInfo]);

  useEffect(() => {
    if (resetData) {
      setTableData([]);
    }
  }, [resetData]);

  useEffect(() => {
    if (gameCtx.id.length > 0) {
      const gameName = gameCtx.gameType.name.replace(/ /g, '');
      createHeadCell(gameDrawTimes, gameName);
    } else if (gameCtx.id === '') {
      createHeadCell(gameDrawTimes);
    }
  }, [gameCtx]);

  const clickExportToCSV = async () => {
    csvLink.current.link.click();
  };

  return (
    <>
      <CSVLink data={CSVData} filename={`stl-usher-summary-report-${date}.csv`} className="hidden" ref={csvLink} target="_blank" />
      <Grid container mb={3}>
        <Grid item md={12} alignItems={'center'} display={'flex'} justifyContent={'end'}>
          {USRpageInfo && !USRpageInfo.hasNextPage ? (
            <>
              <Button
                variant="contained"
                size="medium"
                type="submit"
                onClick={() => {
                  usherSaleReportExportToPDF(tableData, headCells, dateRangeLabel, 'STL Ushers Summary Report');
                }}>
                Export to PDF
              </Button>
              <Button onClick={clickExportToCSV} variant="contained" color="success" sx={{ ml: 2 }}>
                Export to CSV
              </Button>
            </>
          ) : (
            <>
              <Skeleton variant="rounded" width={150} height={40} />
              <Skeleton variant="rounded" width={150} height={40} sx={{ ml: 2 }} />
            </>
          )}
        </Grid>
      </Grid>
      <UsherSalesTable rows={tableData} headCells={headCells} winnerBets={winnerBets} USRtotalCount={USRtotalCount}></UsherSalesTable>
    </>
  );
};
