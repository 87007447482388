import { FormControl, TextField, Select, MenuItem, Grid } from '@mui/material';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { alpha, styled } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';

import React, { useEffect, useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import type { PageInfo } from '@/generated/graphql';

import { ChangePageType } from '@/shared/enums';

import type { IRegistrationCodeTableData, IRegistrationCodeQueryVariable } from '@/shared/interfaces';

import '../../styles/ushers.css';
// enum ChangePageType {
//   NEXT = 'Next',
//   PREV = 'Prev',
// }

type Order = 'asc' | 'desc';

interface TablePageLabel {
  start: number;
  end: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IRegistrationCodeTableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IRegistrationCodeTableData;
  label: string;
  numeric: boolean;
  className: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Registration Code',
    className: 'agent-code-column',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Created On',
    className: 'agent-code-column',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export type Align = 'left' | 'right';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledSelect = styled(Select)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans,Roboto',
    fontWeight: 600,
    fontSize: 15,
    pt: 15,
    pb: 15,
    pl: 15,
  },
  '& .MuiTableCell-hover	': {
    backgroundColor: '#C2DAF0',
    border: '0.08em solid #C2DAF0',
  },
  // '&:nth-of-type(odd)': {
  //   backgroundColor: '#E8F0F7',
  //   fontFamily: 'Poppins',
  // },
  // '&:nth-of-type(even)': {
  //   backgroundColor: '#E0EAF5',
  //   fontFamily: 'Poppins',
  // },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IRegistrationCodeTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: '0.08em solid #ccc', boxShadow: '0em 0.18em 0.4em #bbb' }}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: '600', lineHeight: '1.2', color: '#555' }}
            className={headCell.className}
            sx={{ pl: 2, py: 1 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
        Registration Codes
      </Typography>
      <FormControl sx={{ ml: 2, mb: 2, mt: 1, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="outlined"
          size="small"
        />
      </FormControl>
    </Toolbar>
  );
};

interface lUshersProps {
  rows: readonly IRegistrationCodeTableData[];
  setRegistrationCodeQueryVariable: React.Dispatch<React.SetStateAction<IRegistrationCodeQueryVariable>>;
  registrationCodeQueryVariable: IRegistrationCodeQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

export function CodesTable(props: lUshersProps) {
  const { rows, setRegistrationCodeQueryVariable, registrationCodeQueryVariable, totalCount, pageInfo } = props;
  const [searchUsher, setSearchUsher] = useState<IRegistrationCodeTableData[]>([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IRegistrationCodeTableData>('code');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [dense] = useState(true);
  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: registrationCodeQueryVariable.first,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IRegistrationCodeTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearch(value);
    type IUsherKey = keyof IRegistrationCodeTableData;
    const searchKeys: IUsherKey[] = ['code', 'createdAt'];

    const newUsher = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchUsher(newUsher);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#ffffff',
        }}>
        <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(search.length >= 1 ? searchUsher : rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      sx={{
                        className: 'ushers-table-row',
                        backgroundColor: '#dedede',
                        opacity: '0.75',
                        borderBottom: 1.1,
                        borderBottomColor: '#9F9F9F',
                      }}
                      // hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item md={10} justifyContent={'end'} display={'flex'}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
