import type { GameDrawTime, GameDrawTimeFilter, GameDrawTimeReport } from '@/generated/graphql';
import type { GameDrawTimeStatus } from '@/generated/graphql';

import { mapGQLDrawTime, mapGQLDrawOverviewDrawTimeResult } from './draw-time';

import { mapGQLGame } from './game';

import { mapGQLGameResult } from './game-result';

import type { IDrawTime, IDrawOverviewDrawTimeResult } from './draw-time';
import type { IGame } from './game';

import type { IGameResult } from './game-result';

export interface IGameDrawTime {
  gameType: any;
  id: string;
  status: GameDrawTimeStatus | string;
  isDisabled: boolean;
  createdAt: string;
  updatedAt: string;

  game: IGame;
  drawTime: IDrawTime;
}
export interface IGameDrawTimeReport {
  totalSales: number;
  totalBets: number;
  hits: number;
  totalWinners: number;
  payableWinner: number;
}

export interface IGameDrawTimeResult {
  gameType: any;
  id: string;
  status: GameDrawTimeStatus | string;
  createdAt: string;
  updatedAt: string;

  game: IGame;
  drawTime: IDrawTime;
  gameResult: IGameResult;
  gameDrawTimeReport: IGameDrawTimeReport;
}

export interface IGameDrawTimeQueryVariable {
  filterBy?: GameDrawTimeFilter;
  first: number;
  after?: string;
  before?: string;
}

export interface IDrawOverviewGameDrawTimeResult {
  id: string;
  game: IGame;
  drawTime: IDrawOverviewDrawTimeResult;
}

export function mapGQLGameDrawTime(gameDrawTime?: GameDrawTime | null): IGameDrawTime {
  return {
    gameType: gameDrawTime?.game?.gameType,

    id: gameDrawTime?.id ?? '',
    status: gameDrawTime ? gameDrawTime.status ?? '' : '',
    isDisabled: gameDrawTime && gameDrawTime.isDisabled ? gameDrawTime.isDisabled : false,
    createdAt: gameDrawTime ? gameDrawTime.createdAt ?? '' : '',
    updatedAt: gameDrawTime ? gameDrawTime.updatedAt ?? '' : '',

    game: mapGQLGame(gameDrawTime?.game),
    drawTime: mapGQLDrawTime(gameDrawTime?.drawTime),
  };
}
export function mapGQLGameDrawTimeReport(gameDrawTimeReport?: GameDrawTimeReport | null): IGameDrawTimeReport {
  return {
    totalSales: gameDrawTimeReport?.totalSales ? gameDrawTimeReport.totalSales : 0,
    totalBets: gameDrawTimeReport?.totalBets ? gameDrawTimeReport.totalBets : 0,
    hits: gameDrawTimeReport?.hits ? gameDrawTimeReport.hits : 0,
    totalWinners: gameDrawTimeReport?.totalWinners ? gameDrawTimeReport.totalWinners : 0,
    payableWinner: gameDrawTimeReport?.payableWinner ? gameDrawTimeReport.payableWinner : 0,
  };
}

export function mapGQLGameDrawTimeResult(gameDrawTime?: GameDrawTime | null): IGameDrawTimeResult {
  return {
    gameType: gameDrawTime?.game?.gameType,

    id: gameDrawTime?.id ?? '',
    status: gameDrawTime ? gameDrawTime.status ?? '' : '',
    createdAt: gameDrawTime ? gameDrawTime.createdAt ?? '' : '',
    updatedAt: gameDrawTime ? gameDrawTime.updatedAt ?? '' : '',

    game: mapGQLGame(gameDrawTime?.game),
    drawTime: mapGQLDrawTime(gameDrawTime?.drawTime),
    gameResult: mapGQLGameResult(gameDrawTime?.gameResult),
    gameDrawTimeReport: mapGQLGameDrawTimeReport(gameDrawTime?.gameDrawTimeReport),
  };
}

export function mapGQLDrawOverviewGameDrawTimeResult(gameDrawTime?: GameDrawTime | null): IDrawOverviewGameDrawTimeResult {
  return {
    id: gameDrawTime?.id ?? '',
    game: mapGQLGame(gameDrawTime?.game),
    drawTime: mapGQLDrawOverviewDrawTimeResult(gameDrawTime?.drawTime),
  };
}
