import React, { useState } from 'react';
import moment from 'moment';
import './clock.css';
import { BiTimeFive } from 'react-icons/bi';

// export type ClockVariantTypes = 'full' | 'minimal';

export enum ClockVariantTypes {
  FULL = 'full',
  MINIMAL = 'minimal',
}

interface ClockProps {
  variant: ClockVariantTypes;
  isCollapsed?: boolean;
}

export const Clock: React.FC<ClockProps> = ({ variant, isCollapsed }) => {
  //States
  const [today, setToday] = useState(moment().format('Do MMM YY |[\n] hh:mm:ss A'));

  return (
    <>
      <div className="clock--wrapper">
        {variant === ClockVariantTypes.MINIMAL && <div className="clock__variant--minimal">{today}</div>}
        {variant === ClockVariantTypes.FULL && (
          <div className="clock__variant--full" style={{ display: isCollapsed ? 'none' : 'inline-flex' }}>
            <BiTimeFive color="linear-gradient(180deg, #afcae2 100%, #dcedff 100%)" size="4rem" style={{ paddingLeft: '0em' }} />
            <p className="clock__variant--full--text">{today}</p>
          </div>
        )}
      </div>
    </>
  );
};
