import React, { useContext, useState, useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';

// graphql
import type { PageInfo } from '@/generated/graphql';
import { useBetsQuery, GameDrawTimeStatus, useGreedySoldOutBetsQuery } from '@/generated/graphql';

// component
import { BetStatusEnum } from '@/shared/enums';
import type { IBet, IGreedySoldoutBet, IBetTableData, IBetQueryVariable } from '@/shared/interfaces';
import { mapGQLBet, mapGQLGreedySoldOutBet } from '@/shared/interfaces';
import { BetsFilter } from './components/bets-filter';
import { BetsTable } from './components/bets-table';

// context
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import TabPanel from '@mui/lab/TabPanel';

// interfaces

// enums

// material ui

export const Bets: React.FC = () => {
  const { gameCtx } = useGameDashboardCtx();

  const today = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
    }),
  );

  const defaultDateFilter = {
    dateFrom: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
    dateTo: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
  };

  const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();

  const [betQueryVariable, setBetQueryVariable] = useState<IBetQueryVariable>({
    first: 25,
    filterBy: {
      dateFrom: toUTC(defaultDateFilter.dateFrom, '12:00:00 AM'),
      dateTo: toUTC(defaultDateFilter.dateTo, '11:59:59 PM'),
    },
  });

  const [betTableDatas, setBetTableDatas] = useState<IBetTableData[]>([]);
  const [greedySoldOutbetTableDatas, setGreedySoldOutBetTableDatas] = useState<IBetTableData[]>([]);

  // pagination
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tabValue, setTabValue] = useState('1');

  const [greedySoldOutBetPageInfo, setGreedySoldoutBetPageInfo] = useState<PageInfo>();
  const [greedySoldOutBetTotalCount, setGreedySoldOutBetTotalCount] = useState<number>(0);

  const checkBetStatus = (createdAt: string, isWinner: boolean, gameDrawTimeStatus: GameDrawTimeStatus): BetStatusEnum => {
    const nowPHTime = new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Manila',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour12: true,
      minute: '2-digit',
      hour: '2-digit',
    });

    if (nowPHTime.split('at')[0] === createdAt.split('at')[0]) {
      return gameDrawTimeStatus === GameDrawTimeStatus.Ongoing ? BetStatusEnum.PENDING : isWinner ? BetStatusEnum.WIN : BetStatusEnum.LOSE;
    }
    return isWinner ? BetStatusEnum.WIN : BetStatusEnum.LOSE;
  };

  useBetsQuery({
    variables: betQueryVariable,
    skip: betQueryVariable.filterBy?.gameId ? false : true,
    onCompleted: (data) => {
      const betTableData: IBetTableData[] = [];
      data.bets.nodes.map((bet) => {
        const mapBet: IBet = mapGQLBet(bet);

        betTableData.push({
          id: mapBet.id,
          transactionCode: mapBet.transaction.transactionCode,
          transactionId: mapBet.transaction.id,
          numberCombination: mapBet.numberCombination.replace(/,/g, ''),
          betType: mapBet.betDetail.betType.name,
          amount: mapBet.amount,
          originalBetAmount: mapBet.originalBetAmount,
          draw: mapBet.gameDrawTime.drawTime.name,
          agent: mapBet.transaction.usher.firstName + ' ' + mapBet.transaction.usher.lastName,
          time: mapBet.createdAt,
          status: checkBetStatus(mapBet.createdAt, mapBet.isWinner, mapBet.gameDrawTime.status as GameDrawTimeStatus),
          betAmountBeforeGreedy: mapBet.betAmountBeforeGreedy,
          returnBetAmount: mapBet.returnBetAmount,
        });
      });
      setTimeout(() => {
        setBetTableDatas(betTableData);
        setPageInfo(data.bets.pageInfo);
        setTotalCount(data.bets.totalCount);
      }, 100);
    },
  });

  useGreedySoldOutBetsQuery({
    variables: betQueryVariable,
    skip: betQueryVariable.filterBy?.gameId ? false : true,
    onCompleted: (data) => {
      const betTableData: IBetTableData[] = [];
      data.greedySoldOutBets.nodes.map((bet) => {
        const mapBet: IGreedySoldoutBet = mapGQLGreedySoldOutBet(bet);

        betTableData.push({
          id: mapBet.id,
          transactionCode: mapBet.transaction.transactionCode,
          transactionId: mapBet.transaction.id,
          numberCombination: mapBet.numberCombination.replace(/,/g, ''),
          betType: mapBet.betType.name,
          amount: 0,
          originalBetAmount: mapBet.originalBetAmount,
          draw: mapBet.gameDrawTime.drawTime.name,
          agent: mapBet.transaction.usher.firstName + ' ' + mapBet.transaction.usher.lastName,
          time: mapBet.createdAt,
          status: BetStatusEnum.LOSE,
          betAmountBeforeGreedy: mapBet.amount,
          returnBetAmount: mapBet.amount,
        });
      });
      setTimeout(() => {
        setGreedySoldOutBetTableDatas(betTableData);
        setGreedySoldoutBetPageInfo(data.greedySoldOutBets.pageInfo);
        setGreedySoldOutBetTotalCount(data.greedySoldOutBets.totalCount);
      }, 100);
    },
  });

  useEffect(() => {
    if (gameCtx.id.length > 1) {
      setBetQueryVariable({ ...betQueryVariable, filterBy: { ...betQueryVariable.filterBy, gameId: gameCtx.id } });
    }
  }, [gameCtx]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <BetsFilter
        betQueryVariable={betQueryVariable}
        setBetQueryVariable={setBetQueryVariable}
        game={gameCtx}
        dateFromDefaultDate={defaultDateFilter.dateFrom}
        dateToDefaultDate={defaultDateFilter.dateTo}
      />

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Bets" value="1" />
            <Tab label="Sold Out Bets" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <BetsTable
            rows={betTableDatas}
            betQueryVariable={betQueryVariable}
            setBetQueryVariable={setBetQueryVariable}
            totalCount={totalCount}
            pageInfo={pageInfo}
          />
        </TabPanel>
        <TabPanel value="2">
          <BetsTable
            rows={greedySoldOutbetTableDatas}
            betQueryVariable={betQueryVariable}
            setBetQueryVariable={setBetQueryVariable}
            totalCount={greedySoldOutBetTotalCount}
            pageInfo={greedySoldOutBetPageInfo}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};
