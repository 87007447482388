import type { NotificationAdminQuery, NotificationsFilterField } from '@/generated/graphql';

export interface INotification {
  id: string;
  title: string;
  subTitle: string;
  message: string;

  createdAt: string;
  updatedAt: string;

  views: number;
}

export interface INotificationTable {
  id: string;
  title: string;
  subTitle: string;
  message: string;
  views: number;
  createdAt: string;
}

export interface INotificationQueryVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: NotificationsFilterField;
}

export interface ICreateNotificationInput {
  title: string;
  subTitle: string;
  message: string;
}

export function mapGQLNotification(notification?: NotificationAdminQuery | null): INotification {
  return {
    id: notification?.id ?? '',
    title: notification ? notification.title ?? '' : '',
    subTitle: notification ? notification.subTitle ?? '' : '',
    message: notification ? notification.message ?? '' : '',
    views: notification ? notification.views ?? 0 : 0,
    createdAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    updatedAt: notification
      ? new Date(notification.createdAt).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
  };
}
