import { useState } from 'react';

// graphql
import { useShowUsherNameOnReceiptQuery, useHideShowUsherNameOnReceiptMutation } from '@/generated/graphql';

// interfaces

// material ui
import CircularProgress from '@mui/material/CircularProgress';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

// components
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

interface IProps {}

export const ShowUsherNameOnReceipt: React.FC<IProps> = () => {
  // mutation
  const [hideShowUsherNameOnReceiptMutation] = useHideShowUsherNameOnReceiptMutation();

  const [showName, setShowName] = useState(true);

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const showUsherNameOnReceiptQuery = useShowUsherNameOnReceiptQuery({
    onCompleted(data) {
      setTimeout(() => {
        setShowName(data.showUsherNameOnReceipt);
      }, 100);
    },
  });

  const updateStatus = () => {
    // setShowName(!showName);

    setConfirmationModal({
      ...confirmationModal,
      isOpen: true,
      messages: ['You want to update this status'],
    });
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmUpdate = () => {
    activateLoading();

    hideShowUsherNameOnReceiptMutation({
      variables: { value: !showName },
      onCompleted(data) {
        showUsherNameOnReceiptQuery.refetch();
        setConfirmationModal({
          isOpen: false,
          title: 'Are you sure?',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'Status updated successfully',
          type: 'success',
        });
        setShowLoading(false);
      },
    });
  };

  return (
    <>
      <Grid item md={12} justifyContent={'center'} display={'flex'} mb={4}>
        <Grid item xl={8} lg={11} justifyContent={'center'} display={'flex'} sx={{ background: '#A2BCD8', pb: 3, pt: 4 }}>
          {/* <Grid container spacing={3}> */}
          <FormControlLabel
            control={<Switch checked={showName} onChange={updateStatus} name="Disabled" />}
            label={showName ? 'Show' : 'Hide'}
          />
          {/* </Grid> */}
        </Grid>
      </Grid>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmUpdate}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
