import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';

// interfaces
import Modal from 'react-modal';
import { CombinationInput } from '@/components/Input/combination-input';
import { useGameDashboardCtx } from '@/layouts/components/sidebar';
import type { UpdateGameSalesNumberLimitInput } from '@/generated/graphql';
import { useUpdateGameSalesNumberLimitMutation } from '@/generated/graphql';
import type { INumberCombInput, ILimitTableData } from '@/shared/interfaces';

import type { IIndividualLimitInput } from './create-number-sales-limits';

// material-ui

// context api

// graphql

// components

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isLimitModalOpen: boolean;
  setIsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  limitData: ILimitTableData;
  refetchData: () => void;
}

export const EditNumberSalesLimitModal: React.FC<Props> = ({ isLimitModalOpen, setIsLimitModalOpen, limitData, refetchData }) => {
  const { gameCtx } = useGameDashboardCtx();

  const [updateGameSalesNumberLimit] = useUpdateGameSalesNumberLimitMutation();

  const [numberCombInputs, setNumberCombInputs] = useState<INumberCombInput[]>([]);
  const [limitInput, setLimitInput] = useState<IIndividualLimitInput>({
    individualLimit: '',
    startDate: '',
    endDate: '',
  });

  const closeModal = () => {
    setIsLimitModalOpen(false);
  };

  const handleLimitAmountChange = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (numValue.length > 0 || numValue === '') {
      setLimitInput({
        ...limitInput,
        individualLimit: numValue === '' ? '' : parseInt(numValue),
      });
    }
  };

  useEffect(() => {
    if (limitData) {
      const emtyCombi: INumberCombInput[] = [];
      const numberCombinationList = limitData.numberCombinationOriginal.split(',');

      numberCombinationList.forEach((combination, index) => {
        emtyCombi.push({
          value: combination,
          id: index,
          isFocused: false,
        });
      });
      setNumberCombInputs(emtyCombi);
      setLimitInput({
        individualLimit: limitData.limit,
        startDate: limitData.startDate,
        endDate: limitData.endDate,
      });
    }
  }, [limitData]);

  const editLimit = () => {
    const { combinationLimit, placeValue } = gameCtx.gameType.gameConfig;

    const InputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    if (gameCtx && InputCombination.replace(/,/g, '').length === combinationLimit * placeValue) {
      const { startDate, endDate, individualLimit } = limitInput;

      const input: UpdateGameSalesNumberLimitInput = {
        id: limitData.id,
        gameId: gameCtx.id,
        startDate,
        endDate,
        limit: parseInt(individualLimit.toString()),
        numberCombination: InputCombination,
      };
      console.log(input);

      updateGameSalesNumberLimit({
        variables: { input },
        onCompleted: () => {
          refetchData();
          setIsLimitModalOpen(false);
        },
      });
    }
  };

  return (
    <>
      <Modal isOpen={isLimitModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 600, py: 3, pb: 3, pt: 1, pl: 2, pr: 2 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit Limit
            </Typography>

            <div className="createindividuallimit-limit">
              <TextField
                label="Limit"
                variant="outlined"
                value={limitInput?.individualLimit}
                onChange={(e) => {
                  handleLimitAmountChange(e.target.value);
                }}
              />
            </div>

            <TextField
              onChange={(e) => setLimitInput({ ...limitInput, startDate: e.target.value })}
              id="date"
              label="Start date"
              type="date"
              size="small"
              value={limitInput?.startDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              onChange={(e) => setLimitInput({ ...limitInput, endDate: e.target.value })}
              id="date"
              label="End date"
              type="date"
              size="small"
              value={limitInput?.endDate}
              sx={{ width: 1, mt: 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Grid container justifyContent={'center'} mt={6}>
              <CombinationInput
                perCombinationLength={gameCtx.gameType.gameConfig.placeValue}
                combinationLength={gameCtx.gameType.gameConfig.combinationLimit}
                setNumberCombInputs={setNumberCombInputs}
                numberCombInputs={numberCombInputs}
              />
            </Grid>
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{
            display: 'flex',
            paddingBottom: '2em',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <Button variant="contained" onClick={editLimit}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};
