import { IUpdateUsherInput, IUpdateTransactionAccessInput, IUsherTable } from '@/shared/interfaces';
import { Box, Button, Divider, FormControlLabel, FormLabel, RadioGroup, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import Modal from 'react-modal';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { ChangeEvent, useEffect, useState } from 'react';
import { UsherTransactionAccessEnum, useUpdateTransactionAccessMutation } from '@/generated/graphql';
import { RandomKeyString } from '../services';

// Define validation schema with yup
const schema = yup.object().shape({
  transactionAccess: yup.string().oneOf(Object.values(UsherTransactionAccessEnum)),
});

type FormData = {
  transactionAccess: UsherTransactionAccessEnum;
};

interface IUsherProp {
  id: string;
  usherTransactionAccess: UsherTransactionAccessEnum;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
  refetchData: () => void;
}

export function UpdateTransactionAccess(props: IUsherProp) {
  const { id, isOpen, openEvent, refetchData, usherTransactionAccess } = props;

  const handleClose = () => openEvent(false);

  const { control, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [updateTransactionAccessMutation] = useUpdateTransactionAccessMutation();

  const onSubmit = async (dataInput: FormData) => {
    const { transactionAccess } = dataInput;

    const input: IUpdateTransactionAccessInput = {
      id,
      transactionAccess,
    };

    const { data } = await updateTransactionAccessMutation({
      variables: {
        input,
      },
    });
    //  if (errors) alert(errors);
    refetchData();
    handleClose();
    setErrorMessage('');
  };

  useEffect(() => {
    setValue('transactionAccess', usherTransactionAccess);
  }, [usherTransactionAccess]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          position: 'absolute',
          margin: '0.1% auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: 0,
          marginRight: '-50%',
          transform: 'translate(-50%, -48vh)',
          border: '0px',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        },
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: 450,
        }}>
        <Divider
          sx={{
            height: '25px',
            backgroundColor: '#87ADD4',
          }}
        />
        <Box sx={{}} p={5}>
          <Typography
            sx={{
              textAlign: 'center',
              mb: 4,
            }}
            id="modal-modal-title"
            variant="h5"
            component="h5">
            Update Usher Transaction Access
          </Typography>

          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <FormLabel id="transactionAccess">Select access</FormLabel>
            <Controller
              name="transactionAccess"
              control={control}
              defaultValue={usherTransactionAccess}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={UsherTransactionAccessEnum.AppAndTextAccess}
                    control={<Radio />}
                    label={
                      UsherTransactionAccessEnum.AppAndTextAccess.replace(/_/g, ' ').charAt(0).toUpperCase() +
                      UsherTransactionAccessEnum.AppAndTextAccess.replace(/_/g, ' ').slice(1).toLowerCase()
                    }
                  />
                  <FormControlLabel
                    value={UsherTransactionAccessEnum.AppAccess}
                    control={<Radio />}
                    label={
                      UsherTransactionAccessEnum.AppAccess.replace(/_/g, ' ').charAt(0).toUpperCase() +
                      UsherTransactionAccessEnum.AppAccess.replace(/_/g, ' ').slice(1).toLowerCase()
                    }
                  />
                </RadioGroup>
              )}
            />
            <Box
              sx={{
                textAlign: 'center',
              }}
              mt={10}>
              <Button type="submit">
                <Box
                  sx={{
                    backgroundColor: '#427FC1',
                    color: '#fff',
                  }}
                  p={1}>
                  Update Access
                </Box>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
