import Modal from 'react-modal';

// material ui
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// graphql
import { useCreateTelegramReportDestinationMutation } from '@/generated/graphql';

// interfaces
import { IGetTelegramBotUpdateResp, ICreateTelegramReportInput, ITelegramReportTable } from '@/shared/interfaces';

// icon
import { MdOutlinePostAdd } from 'react-icons/md';
import { TiGroup } from 'react-icons/ti';
import { BsChatDots } from 'react-icons/bs';

// Styled Paper component with scrollable content
const ScrollablePaper = styled(Paper)(({ theme }) => ({
  maxHeight: 500, // Adjust the height as needed
  marginTop: 10,
  marginRight: 10,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    // backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
}));

interface ITelegramReport extends ITelegramReportTable {
  isSelected: boolean;
}

interface IUsherProps {
  refetchData: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  botUpdate: IGetTelegramBotUpdateResp | undefined;
  reportTypes: ITelegramReport[];
  setOpenCreate: React.Dispatch<React.SetStateAction<boolean>>;
  setBotUpdates: React.Dispatch<React.SetStateAction<IGetTelegramBotUpdateResp[]>>;
  setReports: React.Dispatch<React.SetStateAction<ITelegramReport[]>>;
}

export const ConfirmationModal = ({
  open,
  setOpen,
  setOpenCreate,
  refetchData,
  botUpdate,
  reportTypes,
  setBotUpdates,
  setReports,
}: IUsherProps) => {
  const [createTelegramReportDestinationMutation] = useCreateTelegramReportDestinationMutation();

  const handleClose = () => {
    setOpen(false);

    setTimeout(() => {
      setOpenCreate(true);
    }, 300);
  };

  const confirm = async () => {
    if (botUpdate) {
      const { data } = await createTelegramReportDestinationMutation({
        variables: {
          input: {
            botName: botUpdate.botName,
            botUsername: botUpdate.botUsername,
            token: botUpdate.token,
            groupChatName: botUpdate.groupChatName,
            chatId: botUpdate.chatId,
            topicId: botUpdate.topicId,
            topicName: botUpdate.topicName,
            type: botUpdate.type,
            telegramReportIds: reportTypes.map((x) => x.id),
          },
        },
      });

      if (data) console.log(data);
      setReports((prevItems) => prevItems.map((item, i) => (item.isSelected === true ? { ...item, isSelected: false } : item)));
      setBotUpdates([]);
      refetchData();
      setOpen(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'inherit',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
          },
          content: {
            width: '440px',
            overflow: 'unset',
            position: 'absolute',
            margin: '0.1% auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 0,
            marginRight: '-50%',
            transform: 'translate(-50%, -48vh)',
            border: '0px',
            background: 'transparent',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            overflow: 'unset',
            borderRadius: '5px',
          }}
          mt={9}>
          <Box sx={{ px: 5, py: 2 }} alignItems={'center'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="subtitle2" m={0}>
                Confirm Report Destination
              </Typography>
              <MdOutlinePostAdd fontSize={18} />
            </Stack>
          </Box>
          <Divider />

          <Box px={5} pt={2} pb={2}>
            <Grid container justifyContent={'start'} mb={3}>
              <Card
                sx={{
                  minWidth: 275,
                  mt: 2,
                  py: 1,
                  px: 2,
                  cursor: 'pointer',
                }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <TiGroup fontSize={14} />
                    <Typography sx={{ fontSize: 12, fontWeight: 700 }} style={{ marginLeft: '3px' }} color="black">
                      {botUpdate?.groupChatName}
                    </Typography>
                  </Stack>
                  {botUpdate?.type === 'Topic chat group' && (
                    <Stack direction={'row'} alignItems={'center'}>
                      <BsChatDots fontSize={14} />
                      <Typography sx={{ fontSize: 12, fontWeight: 700 }} style={{ marginLeft: '3px' }} color="black">
                        {botUpdate?.topicName}
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                <Stack justifyContent={'space-between'} direction={'row'} mt={1}>
                  <Typography sx={{ fontSize: 10 }}>Chat ID: {botUpdate?.chatId}</Typography>
                  {botUpdate?.type === 'Topic chat group' && (
                    <Typography sx={{ fontSize: 10, ml: 1 }}>Topic ID: {botUpdate.topicId}</Typography>
                  )}
                </Stack>
                <Stack direction={'row'}>
                  <Typography sx={{ fontSize: 10 }}>Bot: {botUpdate?.botName}</Typography>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography sx={{ fontSize: 10, ml: 2 }}>Username: </Typography>
                    <Link sx={{ fontSize: 10 }}>@{botUpdate?.botUsername}</Link>
                  </Stack>
                </Stack>
              </Card>
            </Grid>

            {/* <Stack direction={'column'} alignItems={'center'} justifyContent={'end'}> */}
            <Typography variant="overline" display="block" mt={2}>
              Choosen report type
            </Typography>
            {reportTypes.length === 0 ? (
              <ListItem disablePadding>
                <ListItemText primary="No items available" />
              </ListItem>
            ) : (
              reportTypes.map((reportType, index) => (
                <Typography variant="caption" display="block">
                  - {reportType.name}
                </Typography>
              ))
            )}
            {/* </Stack> */}

            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} mt={3}>
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{
                  background: 'grey',
                  fontSize: 10,
                  fontWeight: 600,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  textTransform: 'unset',
                }}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={confirm}
                sx={{
                  ml: 1,
                  fontSize: 10,
                  fontWeight: 600,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  textTransform: 'unset',
                }}
                type="submit"
                size="small"
                variant="contained">
                Confirm
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
