import { NumberCombinationSoldOut, NumberCombinationSoldOutFilter } from '@/generated/graphql';
import { IGameDrawTime, mapGQLGameDrawTime } from './game-drawtime';

export interface INumberCombinationSoldOut {
  id: String;
  numberCombination: String;
  date: String;
  createdAt: String;
  updatedAt: String;
  gameDrawTime: IGameDrawTime;
}

export interface INumberCombinationSoldOutTableData {
  id: String;
  numberCombination: String;
}

export interface INumberCombinationSoldOutQueryVariable {
  filterBy?: NumberCombinationSoldOutFilter;
  first: number;
  after?: string;
  before?: string;
}

export function mapGQLNumberCombinationSoldOut(numberCombinationSoldOut?: NumberCombinationSoldOut | null): INumberCombinationSoldOut {
  return {
    id: numberCombinationSoldOut?.id ? numberCombinationSoldOut.id : '',
    numberCombination: numberCombinationSoldOut?.numberCombination ? numberCombinationSoldOut.numberCombination : '',
    date: numberCombinationSoldOut?.date ? numberCombinationSoldOut.date : '',
    createdAt: numberCombinationSoldOut?.createdAt ? numberCombinationSoldOut.createdAt : '',
    updatedAt: numberCombinationSoldOut?.updatedAt ? numberCombinationSoldOut.updatedAt : '',
    gameDrawTime: mapGQLGameDrawTime(numberCombinationSoldOut?.gameDrawTime),
  };
}

export function MapGQLNumberCombinationSoldOutTableData(
  soldOutTableData?: NumberCombinationSoldOut | null,
): INumberCombinationSoldOutTableData {
  return {
    id: soldOutTableData?.id ? soldOutTableData.id : '',
    numberCombination: soldOutTableData?.numberCombination ? soldOutTableData.numberCombination : '',
  };
}
