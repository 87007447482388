import React, { useContext, useEffect, useState } from 'react';

//Component
import { CombinationInput } from '@/components/Input/combination-input';
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';

import { useGameDashboardCtx } from '@/layouts/components/sidebar';

//graphql
import type { CreateGameHotNumberInput } from '@/generated/graphql';
import { useCreateGameHotNumberMutation } from '@/generated/graphql';

// interfaces
import type { INumberCombInput } from '@/shared/interfaces';

// validation schema

// material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
export interface IGameHotNumberInput {
  payout: string | number;
  salesLimit: string | number;
  startDate: string;
  endDate: string;
}
interface IlimitsTableProps {
  refetchData: () => void;
}

export const CreateGameHotNumber: React.FC<IlimitsTableProps> = ({ refetchData }) => {
  //States
  const [isCompressed, setIsCompressed] = useState(false);

  const { gameCtx } = useGameDashboardCtx();

  // const inputRef = useRef<HTMLInputElement>(null);

  const [createGameHotNumber] = useCreateGameHotNumberMutation();
  const [numberCombInputs, setNumberCombInputs] = useState<INumberCombInput[]>([]);
  const [gameHotNumberInput, setGameHotNumberInput] = useState<IGameHotNumberInput>({
    payout: '',
    salesLimit: '',
    startDate: '',
    endDate: '',
  });

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  //onSubmit Function
  const onSubmitForm = async () => {
    const InputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    if (gameHotNumberInput && gameHotNumberInput.payout && InputCombination) {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: ['You want to add this limit'],
      });
    }
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmAdd = () => {
    activateLoading();
    const InputCombination = numberCombInputs.map((numberCombi) => numberCombi.value).join(',');

    const { startDate, endDate, payout, salesLimit } = gameHotNumberInput;

    const input: CreateGameHotNumberInput = {
      gameId: gameCtx.id,
      startDate,
      endDate,
      payout: parseInt(payout.toString()),
      salesLimit: parseInt(salesLimit.toString()),
      numberCombination: InputCombination,
    };

    createGameHotNumber({
      variables: { input },
      onCompleted(data) {
        // update all value of numberCombInputs to empty string
        setNumberCombInputs(
          numberCombInputs.map((numberCombi) => {
            numberCombi.value = '';
            return numberCombi;
          }),
        );
        // update limitAmount to empty string
        setGameHotNumberInput({
          payout: '',
          salesLimit: '',
          startDate: '',
          endDate: '',
        });
        refetchData();
        setConfirmationModal({
          isOpen: false,
          title: 'Confirm Result',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'Limit added successfully',
          type: 'success',
        });
        setShowLoading(false);
      },
    });
  };

  const handleLimitAmountChange = (value: string, type: 'payout' | 'salesLimit') => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (type === 'payout' && (numValue.length > 0 || numValue === '')) {
      setGameHotNumberInput({ ...gameHotNumberInput, payout: numValue === '' ? '' : parseInt(numValue) });
    } else if (type === 'salesLimit' && (numValue.length > 0 || numValue === '')) {
      setGameHotNumberInput({ ...gameHotNumberInput, salesLimit: numValue === '' ? '' : parseInt(numValue) });
    }
  };

  useEffect(() => {
    console.log('useEffect');
    if (gameCtx.id.length > 1) {
      const emtyCombi: INumberCombInput[] = [];
      for (let i = 0; i < gameCtx.gameType.gameConfig.combinationLimit; i++) {
        emtyCombi.push({
          value: '',
          id: i,
          isFocused: false,
        });
      }
      setNumberCombInputs(emtyCombi);
    }
    if (gameCtx.gameType.gameConfig.combinationLimit * gameCtx.gameType.gameConfig.placeValue > 6) {
      setIsCompressed(true);
    } else {
      setIsCompressed(false);
    }
  }, [gameCtx]);

  return (
    <>
      {/* {console.log(numberCombInputs, '--')} */}
      <div className="createindividuallimit">
        <p className="createindividuallimit-headertext" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          Create Hot Number
        </p>
        <div className="createindividuallimit-wrapper" style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
          <div className="createindividuallimit-column1">
            <div
              className="createindividuallimit-content1"
              style={{ display: 'grid', gridTemplateColumns: isCompressed ? '2fr 1fr' : '1fr 1fr' }}>
              <div className="createindividuallimit-combination">
                <CombinationInput
                  setNumberCombInputs={setNumberCombInputs}
                  numberCombInputs={numberCombInputs}
                  perCombinationLength={gameCtx.gameType.gameConfig.placeValue}
                  combinationLength={gameCtx.gameType.gameConfig.combinationLimit}
                />
              </div>
              <div className="payout-limit-container" style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                <div className="createindividuallimit-limit">
                  <TextField
                    label="Payout"
                    variant="outlined"
                    value={gameHotNumberInput?.payout}
                    onChange={(e) => {
                      handleLimitAmountChange(e.target.value, 'payout');
                    }}
                  />
                </div>
                <div className="createindividuallimit-limit">
                  <TextField
                    label="Sales Limit"
                    variant="outlined"
                    value={gameHotNumberInput?.salesLimit}
                    onChange={(e) => {
                      handleLimitAmountChange(e.target.value, 'salesLimit');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="createindividuallimit-content2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <TextField
                onChange={(e) => setGameHotNumberInput({ ...gameHotNumberInput, startDate: e.target.value })}
                id="date"
                label="Start date"
                type="date"
                size="small"
                value={gameHotNumberInput?.startDate}
                sx={{ width: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                onChange={(e) => setGameHotNumberInput({ ...gameHotNumberInput, endDate: e.target.value })}
                id="date"
                label="End date"
                type="date"
                size="small"
                value={gameHotNumberInput?.endDate}
                sx={{ width: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          {/* column-1 */}
          <div className="createindividuallimit-column2">
            <button onClick={onSubmitForm} className="createindividuallimit-submit">
              {' '}
              Create Hot Number{' '}
            </button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmAdd}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
