import type { GameDrawTimeSchedule, GameDrawTimeScheduleFilter } from '@/generated/graphql';

import { IGameDrawTime, mapGQLGameDrawTime } from './game-drawtime';

export interface IGameDrawTimeSchedule {
  id: string;
  drawStartTime: string;
  drawEndTime: string;
  drawCutOffTime: string;
  startDateTime: string;
  endDateTime: string;

  createdAt: string;
  updatedAt: string;

  gameDrawTime: IGameDrawTime;
}

export interface IGameDrawTimeSceduleTable {
  id: string;
  drawTimeName: string;
  startDateTime: string;
  endDateTime: string;
  drawTime: string;
  startTime: string;
  cutOffTime: string;
}

export interface IGameDrawTimeSceduleVariable {
  first: number;
  after?: string;
  before?: string;
  filterBy?: GameDrawTimeScheduleFilter;
}

export function mapGQLGameDrawtimeSched(gameDrawTimeSched?: GameDrawTimeSchedule | null): IGameDrawTimeSchedule {
  const dtObjDrawStartTime: Date | null = gameDrawTimeSched ? new Date(gameDrawTimeSched.drawStartTime) ?? null : null;
  const dtObjDrawEndTime: Date | null = gameDrawTimeSched ? new Date(gameDrawTimeSched.drawEndTime) ?? null : null;
  const dtObjDrawCutOffTime: Date | null = gameDrawTimeSched ? new Date(gameDrawTimeSched.drawCutOffTime) ?? null : null;

  return {
    id: gameDrawTimeSched?.id ?? '',

    drawStartTime: gameDrawTimeSched
      ? new Date(gameDrawTimeSched.drawStartTime).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    drawEndTime: gameDrawTimeSched
      ? new Date(gameDrawTimeSched.drawEndTime).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    drawCutOffTime: gameDrawTimeSched
      ? new Date(gameDrawTimeSched.drawCutOffTime).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    // drawStartTime: dtObjDrawStartTime
    //   ? (dtObjDrawStartTime.getHours().toString().length == 1
    //       ? '0' + dtObjDrawStartTime.getHours().toString()
    //       : dtObjDrawStartTime.getHours().toString()) +
    //       ':' +
    //       (dtObjDrawStartTime.getMinutes().toString().length == 1
    //         ? '0' + dtObjDrawStartTime.getMinutes().toString()
    //         : dtObjDrawStartTime.getMinutes().toString()) ?? ''
    //   : '',
    // drawEndTime: dtObjDrawEndTime
    //   ? (dtObjDrawEndTime.getHours().toString().length == 1
    //       ? '0' + dtObjDrawEndTime.getHours().toString()
    //       : dtObjDrawEndTime.getHours().toString()) +
    //       ':' +
    //       (dtObjDrawEndTime.getMinutes().toString().length == 1
    //         ? '0' + dtObjDrawEndTime.getMinutes().toString()
    //         : dtObjDrawEndTime.getMinutes().toString()) ?? ''
    //   : '',
    // drawCutOffTime: dtObjDrawCutOffTime
    //   ? (dtObjDrawCutOffTime.getHours().toString().length == 1
    //       ? '0' + dtObjDrawCutOffTime.getHours().toString()
    //       : dtObjDrawCutOffTime.getHours().toString()) +
    //       ':' +
    //       (dtObjDrawCutOffTime.getMinutes().toString().length == 1
    //         ? '0' + dtObjDrawCutOffTime.getMinutes().toString()
    //         : dtObjDrawCutOffTime.getMinutes().toString()) ?? ''
    //   : '',

    startDateTime: gameDrawTimeSched
      ? new Date(gameDrawTimeSched.startDateTime).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',

    endDateTime: gameDrawTimeSched
      ? new Date(gameDrawTimeSched.endDateTime).toLocaleString('en-US', {
          timeZone: 'Asia/Manila',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour12: true,
          minute: '2-digit',
          hour: '2-digit',
        }) ?? ''
      : '',
    createdAt: gameDrawTimeSched ? gameDrawTimeSched.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: gameDrawTimeSched ? gameDrawTimeSched.updatedAt ?? new Date().toISOString() : new Date().toISOString(),

    gameDrawTime: mapGQLGameDrawTime(gameDrawTimeSched?.gameDrawTime),
  };
}
