import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import React, { useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';

// interface
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import { ConfirmationModal } from '@/components/modal/confirmation';
import type { PageInfo } from '@/generated/graphql';
import { useDeleteGameHotNumberMutation } from '@/generated/graphql';
import { ChangePageType } from '@/shared/enums';
import type { IGameHotNumberTableData, IGameHotNumberQueryVariable } from '@/shared/interfaces';

// enums
// graphql
// components
import { EditGameHotNumberModal } from './edit-game-hot-number';

interface TablePageLabel {
  start: number;
  end: number;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof IGameHotNumberTableData | '';
  label: string;
  numeric: boolean;
}

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFFFFF',
    color: '#6B6B6B',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  '&:active tableCell.Mui-active': {
    color: '#FFFFFF',
  },
}));

const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#333',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#111',
    },
    '&:hover fieldset': {
      borderColor: '#333',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333',
    },
  },
  input: {
    color: '#111',
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 15,
    pt: 8,
    pb: 8,
    pl: 15,
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#E8F0F7',
    fontFamily: 'Poppins',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#E0EAF5',
    fontFamily: 'Poppins',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells: readonly HeadCell[] = [
  {
    id: 'numberCombination',
    numeric: false,
    disablePadding: true,
    label: 'Number Combination',
  },
  {
    id: 'payout',
    numeric: false,
    disablePadding: true,
    label: 'Current Payout',
  },
  {
    id: 'salesLimit',
    numeric: false,
    disablePadding: true,
    label: 'Sales Limit',
  },
  {
    id: 'sales',
    numeric: false,
    disablePadding: true,
    label: 'Current Sales',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: true,
    label: 'Start Date',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: true,
    label: 'End Date',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IGameHotNumberTableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof IGameHotNumberTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ pl: 2, fontWeight: 600 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id === '' ? undefined : createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        backgroundColor: '#93c1f2',
        color: '#333',

        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      <Typography sx={{ fontWeight: 400, flex: '1 1 100%', fontSize: 18, color: '#111' }} variant="h6" id="tableTitle" component="div">
        Individual Game Hot Numbers
      </Typography>
      <FormControl sx={{ ml: 2, mb: 1, mt: 1, mr: 1, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="outlined"
          size="small"
        />
      </FormControl>
    </Toolbar>
  );
};

interface gameHotNumberTableTableProps {
  rows: readonly IGameHotNumberTableData[];
  setGameHotNumberQueryVariable: React.Dispatch<React.SetStateAction<IGameHotNumberQueryVariable>>;
  GameHotNumberQueryVariable: IGameHotNumberQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;
  refetchData: () => void;
}

export function GameHotNumberTable(props: gameHotNumberTableTableProps) {
  const { rows, GameHotNumberQueryVariable, setGameHotNumberQueryVariable, totalCount, pageInfo, refetchData } = props;
  const [searchedGameHotNumber, setSearchedGameHotNumber] = useState<IGameHotNumberTableData[]>([]);
  const [searchWord, setSearchWord] = useState('');

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IGameHotNumberTableData>('numberCombination');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage] = React.useState(5);

  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: GameHotNumberQueryVariable.first,
  });

  // edit gameHotNumber modal

  const [deleteGameGameHotNumber] = useDeleteGameHotNumberMutation();

  const [isGameHotNumberModalOpen, setIsGameHotNumberModalOpen] = useState(false);
  const [selectedGameHotNumber, setSelectedGameHotNumber] = useState<IGameHotNumberTableData>({
    numberCombination: '',
    numberCombinationOriginal: '',
    payout: 0,
    salesLimit: 0,
    startDate: '',
    endDate: '',
    id: '',
    sales: 0,
  });
  // delete gameHotNumber modal
  const [confirmationDeleteModal, setConfirmationDeleteModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Confirm Result',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const clickEdit = (gameHotNumber: IGameHotNumberTableData) => {
    setIsGameHotNumberModalOpen(true);
    setSelectedGameHotNumber(gameHotNumber);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IGameHotNumberTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type IGameHotNumberTableDataKey = keyof IGameHotNumberTableData;
    const searchKeys: IGameHotNumberTableDataKey[] = ['numberCombination', 'payout'];

    const newGameHotNumber = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedGameHotNumber(newGameHotNumber);
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setGameHotNumberQueryVariable({
        ...GameHotNumberQueryVariable,
        after: pageInfo.endCursor,
        before: undefined,
      });
      let end = pageLabel.end + GameHotNumberQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start + GameHotNumberQueryVariable.first, end });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setGameHotNumberQueryVariable({
        ...GameHotNumberQueryVariable,
        before: pageInfo.startCursor,
        after: undefined,
      });
      let end = pageLabel.end - GameHotNumberQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start - GameHotNumberQueryVariable.first, end });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setGameHotNumberQueryVariable({
      ...GameHotNumberQueryVariable,
      first: intValue,
    });
  };

  const clickDeleteBtn = (gameHotNumber: IGameHotNumberTableData) => {
    setConfirmationDeleteModal({
      isOpen: true,
      title: 'Confirm Result',
      messages: ['Are you sure you want to delete this hot number?'],
    });
    setSelectedGameHotNumber(gameHotNumber);
  };

  const cofirmedDelete = () => {
    deleteGameGameHotNumber({
      variables: { id: selectedGameHotNumber.id },
      onCompleted: () => {
        refetchData();
        setConfirmationDeleteModal({
          isOpen: false,
          title: 'Confirm Result',
          messages: [],
        });
        setSuccessDetails({
          isOpen: true,
          message: 'GameHotNumber delete successfully',
          type: 'success',
        });
      },
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%', mt: 0 }}>
        <Paper sx={{ width: '100%', mb: 2, background: '#C5D8EC' }}>
          <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                {(searchWord.length >= 1 ? searchedGameHotNumber : rows).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      sx={{
                        pl: 2,
                        fontWeight: 500,
                        fontFamily: 'Poppins! important',
                      }}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}>
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ pl: 2, fontWeight: 500 }}>
                        {row.numberCombination.replace(/,/g, '')}
                      </TableCell>
                      <TableCell align="left">{row.payout}</TableCell>
                      <TableCell align="left">{row.salesLimit}</TableCell>
                      <TableCell align="left">{row.sales.toFixed(2)}</TableCell>
                      <TableCell align="left">{row.startDate}</TableCell>
                      <TableCell align="left">{row.endDate}</TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="delete" color="error" onClick={() => clickDeleteBtn(row)}>
                          <MdDelete />
                        </IconButton>
                        <IconButton aria-label="edit" color="primary" onClick={() => clickEdit(row)}>
                          <AiFillEdit />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              backgroundColor: '#93c1f2',
              color: '#111',
              mt: 1,
              justifyContent: 'end',
              alignItems: 'center',
              display: 'flex',
            }}>
            <Typography variant="body2" mr={4} gutterBottom>
              Rows per page:
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
              <Select
                sx={{ color: '#111', fontWeight: 600 }}
                onChange={(e) => {
                  handleChangeRowsPerPage(e.target.value);
                }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={GameHotNumberQueryVariable.first}
                label="Row">
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" mx={4} gutterBottom>
              {pageLabel.start} - {pageLabel.end} of {totalCount}
            </Typography>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.PREV)}
              disabled={pageInfo?.hasPreviousPage ? false : true}
              size={'small'}
              sx={{ mr: 2 }}
              aria-label="prev">
              <IoIosArrowBack />
            </IconButton>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.NEXT)}
              disabled={pageInfo?.hasNextPage ? false : true}
              size={'small'}
              aria-label="next">
              <IoIosArrowForward />
            </IconButton>
          </Box>
        </Paper>
      </Box>
      <EditGameHotNumberModal
        isGameHotNumberModalOpen={isGameHotNumberModalOpen}
        setIsGameHotNumberModalOpen={setIsGameHotNumberModalOpen}
        gameHotNumberData={selectedGameHotNumber}
        refetchData={refetchData}
      />
      <ConfirmationModal
        modalDetails={confirmationDeleteModal}
        setModalDetails={setConfirmationDeleteModal}
        confirm={cofirmedDelete}
        confirmText={'Delete'}
      />
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
}
