import { useState, useEffect } from 'react';

// graphql
import { useGetInactiveUshersQuery, PageInfo, UsherTransactionAccessEnum } from '@/generated/graphql';

// interfaces
import { IInactiveUshserQueryVariable, IUsherTable, IUsher, mapGQLUsher } from '@/shared/interfaces';

// components
import { TableUshers } from './table-ushers';
import {
  EnableDisableUsher,
  AddUshers,
  EditUshers,
  ChangePasswordUsher,
  TableDisabledUshers,
  UpdateTransactionAccess,
  UsherApplicants,
  RegistrationCodes,
  ShowUsherNameOnReceiptConfirmation,
  UsherDisableImmunity,
} from '../../components';

// material ui
import { Box, Grid, TextField, Typography } from '@mui/material';

export const InactiveUshers: React.FC = () => {
  const [userInactiveUsherQueryVariable, setInactiveUsherQueryVariable] = useState<IInactiveUshserQueryVariable>({
    filterBy: { daysInactive: 14 },
  });
  const [ushersTableData, setUshersTableData] = useState<IUsherTable[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [getUsherLoading, setGetUsherLoading] = useState<boolean>(false);
  const [daysInactive, setDaysInactive] = useState<number>(14);

  const [disableOpen, setDisableOpen] = useState<boolean>(false);
  const [selectedUsher, setSelectedUsher] = useState<IUsherTable>();
  const [editDatas, setEditData] = useState<IUsherTable>();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [changePasswordDatas, setChangePasswordData] = useState<IUsherTable>();
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [updateTransactionAccessOpen, setUpdateTransactionAccessOpen] = useState<boolean>(false);
  const [updateTransactionAccessData, setUpdateTransactionAccessData] = useState<IUsherTable>();

  const [showUsherNameOnReceiptConfirmationOpen, setShowUsherNameOnReceiptConfirmationOpen] = useState<boolean>(false);
  const [showUsherDisableImmunityConfirmationOpen, setShowUsherDisableImmunityConfirmationOpen] = useState<boolean>(false);

  const getUshers = useGetInactiveUshersQuery({
    variables: userInactiveUsherQueryVariable,
    onCompleted(data) {
      const mappedUshers: IUsherTable[] = [];

      data.getInactiveUshers.map((result) => {
        const mapResult: IUsher = mapGQLUsher(result);

        mappedUshers.push({
          id: mapResult.id,
          fullName: mapResult.firstName + ' ' + mapResult.lastName,
          firstName: mapResult.firstName,
          lastName: mapResult.lastName,
          contactNumber: mapResult.contactNumber,
          usherType: mapResult.usherType?.name,
          uniqueCode: mapResult.uniqueCode,
          address: mapResult.address,
          username: mapResult.user.username,
          email: mapResult.user.email,
          isDisabled: mapResult.user.isDisabled,
          createdAt: mapResult.createdAt,
          DTlastTransaction: mapResult.DTlastTransaction,
          transactionAccess: mapResult.transactionAccess,
          coordinator: mapResult.coordinator,
          showUsherNameOnReceipt: mapResult.showUsherNameOnReceipt,
          immuneToDisable: mapResult.immuneToDisable,
        });
      });

      setTimeout(() => {
        setUshersTableData(mappedUshers);
      }, 100);
    },
  });

  const refetch = () => {
    getUshers.refetch();
  };

  const usherEvent = (data: IUsherTable, opn: boolean, eventName: string) => {
    if (eventName == 'enableDisable') {
      setSelectedUsher(data);
      setDisableOpen(opn);
    }

    if (eventName == 'edit') {
      setEditData(data);
      setEditOpen(opn);
    }
    if (eventName == 'changePassword') {
      setChangePasswordData(data);
      setChangePasswordOpen(opn);
    }
    if (eventName == 'updateTransactionAccess') {
      console.log('PASS', opn);
      setUpdateTransactionAccessData(data);
      setUpdateTransactionAccessOpen(opn);
    }
    if (eventName == 'showUsherNameOnReceipt') {
      console.log('PASS', opn, data);
      setSelectedUsher(data);
      setShowUsherNameOnReceiptConfirmationOpen(opn);
    }

    if (eventName == 'disableImmunity') {
      setSelectedUsher(data);
      setShowUsherDisableImmunityConfirmationOpen(opn);
    }
  };

  const openEnableDisableEvent = (opn: boolean) => setDisableOpen(opn);
  const openEditEvent = (opn: boolean) => setEditOpen(opn);
  const openShowUsherNameOnReceiptEvent = (opn: boolean) => setShowUsherNameOnReceiptConfirmationOpen(opn);
  const openShowUsherDisableImmunityEvent = (opn: boolean) => setShowUsherDisableImmunityConfirmationOpen(opn);
  const openChangePassword = (opn: boolean) => setChangePasswordOpen(opn);
  const openUpdateTransactionAccess = (opn: boolean) => setUpdateTransactionAccessOpen(opn);

  const handleDaysInactiveInput = (value: string) => {
    const numValue = value.replace(/[^0-9]/g, '');

    if (numValue.length > 0 || numValue === '') {
      setDaysInactive(numValue === '' ? 0 : parseInt(numValue));
    }
  };

  useEffect(() => {
    setGetUsherLoading(getUshers.loading);
  }, [getUshers.loading]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#87ADD4',
        }}
        p={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography
              sx={{
                color: '#fff',
              }}
              variant="h4"></Typography>
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="standard-basic"
              label="Days Inactive"
              variant="standard"
              value={daysInactive}
              onChange={(e) => {
                handleDaysInactiveInput(e.target.value);
              }}
              onKeyDown={(event) =>
                event.key === 'Enter' &&
                setInactiveUsherQueryVariable({
                  ...userInactiveUsherQueryVariable,
                  filterBy: { ...userInactiveUsherQueryVariable.filterBy, daysInactive },
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <TableUshers
        rows={ushersTableData}
        setUsherQueryVariable={setInactiveUsherQueryVariable}
        usherQueryVariable={userInactiveUsherQueryVariable}
        totalCount={totalCount}
        pageInfo={pageInfo}
        getUsherLoading={getUsherLoading}
        usherEvent={usherEvent}
      />
      <EnableDisableUsher datas={selectedUsher} isOpen={disableOpen} openEvent={openEnableDisableEvent} refetchData={refetch} />
      <EditUshers datas={editDatas} isOpen={editOpen} openEvent={openEditEvent} refetchData={refetch} />
      <ChangePasswordUsher datas={changePasswordDatas} isOpen={changePasswordOpen} openEvent={openChangePassword} refetchData={refetch} />
      <ShowUsherNameOnReceiptConfirmation
        datas={selectedUsher}
        isOpen={showUsherNameOnReceiptConfirmationOpen}
        openEvent={openShowUsherNameOnReceiptEvent}
        refetchData={refetch}
      />
      <UpdateTransactionAccess
        id={updateTransactionAccessData ? updateTransactionAccessData.id : ''}
        usherTransactionAccess={
          updateTransactionAccessData ? updateTransactionAccessData.transactionAccess : UsherTransactionAccessEnum.AppAccess
        }
        isOpen={updateTransactionAccessOpen}
        openEvent={openUpdateTransactionAccess}
        refetchData={refetch}
      />
      <UsherDisableImmunity
        datas={selectedUsher}
        isOpen={showUsherDisableImmunityConfirmationOpen}
        openEvent={openShowUsherDisableImmunityEvent}
        refetchData={refetch}
      />
    </>
  );
};
