import type { BetDetail } from '@/generated/graphql';

import { mapGQLBetType } from './bet-type';

import type { IBetType } from './bet-type';

export interface IBetDetail {
  id: string;
  createdAt: string;
  updatedAt: string;

  betType: IBetType;
}

export function mapGQLBetDetail(betDetail?: BetDetail | null): IBetDetail {
  return {
    id: betDetail?.id ?? '',
    createdAt: betDetail ? betDetail.createdAt ?? new Date().toISOString() : new Date().toISOString(),
    updatedAt: betDetail ? betDetail.updatedAt ?? new Date().toISOString() : new Date().toISOString(),

    betType: mapGQLBetType(betDetail?.betType),
  };
}
