import { useContext } from 'react';

// material ui
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import React, { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// interface
import { PageInfo, UsherApplicantSortField, SortDirection, useDeclineUsherApplicantMutation } from '@/generated/graphql';
import { ChangePageType } from '@/shared/enums';
import type { IUsherApplicantTableData, IUsherApplicantQueryVariable } from '@/shared/interfaces';

// child components
import { ConfirmUsherApplicantModal } from './confirm-usher-applicant';

import { ConfirmationModal, IModalConfirmationDetails } from '@/components/modal/confirmation';
import { FeedbackAlert, IAlertFeedBackDetails } from '@/components/feedback/alert';

// context api
import { UsherPageRefetchUsherCtx } from '@/contexts/user-page-refetch-usher';

interface TablePageLabel {
  start: number;
  end: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IUsherApplicantTableData | '';
  label: string;
  numeric: boolean;
}
const StyledTextField = styled(TextField)(({}) => ({
  '& label, & label.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#93C1F2',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
  },
  input: {
    color: '#FFFFFF',
  },
}));

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFFFFF',
    color: '#6B6B6B',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  '&:active tableCell.Mui-active': {
    color: '#FFFFFF',
  },
}));
const StyledTableRow = styled(TableRow)(({}) => ({
  '& .MuiTableCell-root': {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: 15,
    pt: 20,
    pb: 20,
    pl: 15,
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#E8F0F7',
    fontFamily: 'Poppins',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#E0EAF5',
    fontFamily: 'Poppins',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells: readonly HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last Name ',
  },
  {
    id: 'contactNumber',
    numeric: false,
    disablePadding: true,
    label: 'Contact Number',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: true,
    label: 'Address ',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Ushername ',
  },
  {
    id: 'registrationCode',
    numeric: false,
    disablePadding: true,
    label: 'Code ',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IUsherApplicantTableData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUsherApplicantQueryVariable: React.Dispatch<React.SetStateAction<IUsherApplicantQueryVariable>>;
  userApplicantQueryVariable: IUsherApplicantQueryVariable;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    setUsherApplicantQueryVariable,
    userApplicantQueryVariable,
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof IUsherApplicantTableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
    // if (property === 'userApplicant') {
    //   setUsherApplicantQueryVariable({
    //     ...userApplicantQueryVariable,
    //     field: UsherApplicantSortField.Numbercombination,
    //     direction: order === 'desc' ? SortDirection.Desc : SortDirection.Asc,
    //   });
    // } else if (property === 'sales') {
    //   setUsherApplicantQueryVariable({
    //     ...userApplicantQueryVariable,
    //     field: UsherApplicantSortField.Sale,
    //     direction: order === 'desc' ? SortDirection.Desc : SortDirection.Asc,
    //   });
    // } else if (property === 'numberOfBets') {
    //   setUsherApplicantQueryVariable({
    //     ...userApplicantQueryVariable,
    //     field: UsherApplicantSortField.NumberOfBets,
    //     direction: order === 'desc' ? SortDirection.Desc : SortDirection.Asc,
    //   });
    // }
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ pl: 2 }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id === '' ? undefined : createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchHandler: (value: string) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, searchHandler } = props;

  return (
    <Toolbar
      sx={{
        backgroundColor: '#6591BD',
        color: '#FFFFFF',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {/* <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">

      </Typography> */}
      {/* <FormControl sx={{ ml: 2, mb: 2, mt: 0, width: 300 }}>
        <StyledTextField
          onChange={(e) => {
            searchHandler(e.target.value);
          }}
          id="filled-search"
          label="Search field"
          type="search"
          variant="standard"
        />
      </FormControl> */}
    </Toolbar>
  );
};

interface UsherApplicantTableProps {
  rows: readonly IUsherApplicantTableData[];
  setUsherApplicantQueryVariable: React.Dispatch<React.SetStateAction<IUsherApplicantQueryVariable>>;
  userApplicantQueryVariable: IUsherApplicantQueryVariable;
  totalCount: number;
  pageInfo: PageInfo | undefined;
  refetch: () => void;
}

export function UsherApplicantTable(props: UsherApplicantTableProps) {
  const [declineUsherApplicant] = useDeclineUsherApplicantMutation();
  const { setRefetchUsherData } = useContext(UsherPageRefetchUsherCtx);

  const { rows, userApplicantQueryVariable, setUsherApplicantQueryVariable, totalCount, pageInfo, refetch } = props;
  const [searchedBets, setSearchedBets] = useState<IUsherApplicantTableData[]>([]);
  const [searchWord, setSearchWord] = useState('');
  const [optSelectedOption, setOptSelectedOption] = useState('');

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IUsherApplicantTableData>('firstName');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage] = React.useState(5);

  // options modal
  const [isConfirmUsherApplicantModalOpen, setIsConfirmUsherApplicantModalOpen] = useState(false);
  const [isAddNumberLimitModalOpen, setIsAddNumberLimitModalOpen] = useState(false);
  const [selectedUsherApplicant, setSelectedUsherApplicant] = useState<IUsherApplicantTableData>();

  // decline user applicant
  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Decline Applicant',
    messages: [],
  });
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [showLoading, setShowLoading] = useState(false);

  const [pageLabel, setPageLabel] = useState<TablePageLabel>({
    start: 1,
    end: userApplicantQueryVariable.first,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUsherApplicantTableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const searchHandler = (value: string) => {
    setSearchWord(value);
    type IUsherApplicantTableDataKey = keyof IUsherApplicantTableData;
    const searchKeys: IUsherApplicantTableDataKey[] = ['firstName'];

    const newBets = rows.filter((row) => searchKeys.some((searchKey) => row[searchKey]?.toString().includes(value)));
    setSearchedBets(newBets);
  };

  const handleChangePage = (type: ChangePageType) => {
    if (type === ChangePageType.NEXT && pageInfo?.endCursor) {
      // next
      setUsherApplicantQueryVariable({
        ...userApplicantQueryVariable,
        after: pageInfo.endCursor,
        before: undefined,
      });
      let end = pageLabel.end + userApplicantQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start + userApplicantQueryVariable.first, end });
    } else if (type === ChangePageType.PREV && pageInfo?.startCursor) {
      // prev
      setUsherApplicantQueryVariable({
        ...userApplicantQueryVariable,
        before: pageInfo.startCursor,
        after: undefined,
      });
      let end = pageLabel.end - userApplicantQueryVariable.first;
      if (end > totalCount) {
        end = totalCount;
      }
      setPageLabel({ start: pageLabel.start - userApplicantQueryVariable.first, end });
    }
  };

  const handleChangeRowsPerPage = (value: string | number) => {
    const intValue = parseInt(value.toString(), 10);

    setUsherApplicantQueryVariable({
      ...userApplicantQueryVariable,
      first: intValue,
    });
  };

  const clickOption = (type: string, usherApplicant: IUsherApplicantTableData) => {
    if (type === 'confirm') {
      setIsConfirmUsherApplicantModalOpen(true);
    } else if (type === 'decline') {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: ['You are about to decline usher.'],
      });
    }
    setSelectedUsherApplicant(usherApplicant);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const confirmDecline = () => {
    if (selectedUsherApplicant) {
      activateLoading();

      declineUsherApplicant({
        variables: { Id: selectedUsherApplicant.id },
        onCompleted: () => {
          refetch();
          setRefetchUsherData(true);
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Usher added declined',
            type: 'success',
          });
          setIsConfirmUsherApplicantModalOpen(false);
          setShowLoading(false);
        },
      });
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, background: '#C5D8EC' }}>
          <EnhancedTableToolbar numSelected={selected.length} searchHandler={searchHandler} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                setUsherApplicantQueryVariable={setUsherApplicantQueryVariable}
                userApplicantQueryVariable={userApplicantQueryVariable}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {(searchWord.length >= 1 ? searchedBets : rows).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}>
                      <StyledTableCell align="left">{row.firstName}</StyledTableCell>
                      <StyledTableCell align="left">{row.lastName}</StyledTableCell>
                      <StyledTableCell align="left">{row.contactNumber}</StyledTableCell>
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.email}</StyledTableCell>
                      <StyledTableCell align="left">{row.username}</StyledTableCell>
                      <StyledTableCell align="left">{row.registrationCode}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="contained"
                          sx={{ mr: 2, fontSize: 10 }}
                          size="small"
                          color="success"
                          onClick={() => clickOption('confirm', row)}>
                          Activate
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ fontSize: 10 }}
                          color="error"
                          size="small"
                          onClick={() => clickOption('decline', row)}>
                          Decline
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              backgroundColor: '#6591bd',
              color: '#FFFFFF',
              mt: 1,
              justifyContent: 'end',
              alignItems: 'center',
              display: 'flex',
            }}>
            <Typography variant="body2" mr={4} style={{ fontWeight: 600, letterSpacing: 1 }} gutterBottom>
              Rows per page:
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
              <Select
                sx={{ color: '#FFFFFF', fontWeight: 600 }}
                onChange={(e) => {
                  handleChangeRowsPerPage(e.target.value);
                }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={userApplicantQueryVariable.first}
                label="Row">
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" mx={4} gutterBottom>
              {pageLabel.start} - {pageLabel.end} of {totalCount}
            </Typography>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.PREV)}
              disabled={pageInfo?.hasPreviousPage ? false : true}
              size={'small'}
              sx={{ mr: 2 }}
              aria-label="prev">
              <IoIosArrowBack />
            </IconButton>
            <IconButton
              onClick={() => handleChangePage(ChangePageType.NEXT)}
              disabled={pageInfo?.hasNextPage ? false : true}
              size={'small'}
              aria-label="next">
              <IoIosArrowForward />
            </IconButton>
          </Box>
        </Paper>
      </Box>
      <ConfirmUsherApplicantModal
        isConfirmUsherApplicantModalOpen={isConfirmUsherApplicantModalOpen}
        setIsConfirmUsherApplicantModalOpen={setIsConfirmUsherApplicantModalOpen}
        selectedUsherApplicant={selectedUsherApplicant}
        refetch={refetch}
      />
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={confirmDecline}
        confirmText={'Confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
}
