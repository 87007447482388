import type {
  GameStatus,
  Game,
  GamesOverviewReport,
  GameDrawtimeOverviewReport,
  Maybe,
  GameSalesReportFilterField,
} from '@/generated/graphql';

import { mapGQLGameDrawTime, mapGQLDrawOverviewGameDrawTimeResult } from './game-drawtime';

import { mapGQLGameTypeData, mapGQLGamesSalesReportGameType } from './game-type';

import type { IGameDrawTime, IDrawOverviewGameDrawTimeResult } from './game-drawtime';

import type { IGameType, IGamesSalesReportGameType } from './game-type';

import { IDrawTime, mapGQLDrawTime } from './draw-time';

import { IGameResult, mapGQLGameResult } from './game-result';

export interface IGame {
  id: string;
  name: string;
  description: string;
  status: GameStatus | string;
  updatedAt: string;
  createdAt: string;

  gameType: IGameType;
  gameDrawTimes: IGameDrawTime[];
}

export interface IGamesOverviewReportQueryVariable {
  filterBy: GameSalesReportFilterField;
}

export interface IGamesOverviewReport {
  totalSales: number;
  totalHits: number;
  totalNoOfbets: number;
  gameReport: GameReport;
}

export interface IGameDrawtimeOverviewReport {
  drawTime: IDrawTime;
  sales: number;
  noOfbets: number;
  hits: number;
  gameResult?: IGameResult;
}

export interface GameReport {
  game: IGame;
  sales: number;
  hits: number;
  noOfbets: number;
  gameDrawTimesReport: IGameDrawtimeOverviewReport[];
}

export interface ICreateGameInput {
  name: string;
  description: string;
  combinationLimit: number;
  globalNumberLimit: number;
  isRepeating: boolean;
  isRumble: boolean;
  payout: number;
  minNumber: number;
  maxNumber: number;
  hasDoubleRepeatingPayout: boolean;
  hasTripleRepeatingPayout: boolean;
}

export interface IDrawTimeInput {
  name: string;
  startDateTime: string;
  endDateTime: string;
  cutOffDateTime: string;
  startTime: string;
  endTime: string;
  cutOff: string;
  schedule: string;
}

export interface IDrawOverviewGameResult {
  id: string;
  gameType: IGameType;
  gameDrawTime: IDrawOverviewGameDrawTimeResult[];
}

export interface IDrawOverviewGameResultQueryVariable {
  first: number;
}

export interface IGamesSalesReportGame {
  id: string;
  gameType: IGamesSalesReportGameType;
}

export function mapGQLGame(game?: Game | null): IGame {
  return {
    id: game?.id ?? '',
    name: game ? game.name ?? '' : '',
    description: game ? game.description ?? '' : '',
    status: game ? game.status ?? '' : '',
    updatedAt: game ? game.updatedAt ?? new Date().toISOString() : new Date().toISOString(),
    createdAt: game ? game.createdAt ?? new Date().toISOString() : new Date().toISOString(),

    gameType: mapGQLGameTypeData(game?.gameType),
    gameDrawTimes: game?.gameDrawTime?.map((gameDrawTime) => mapGQLGameDrawTime(gameDrawTime)) ?? [],
  };
}

export function mapGQLDrawOverviewGameResult(game?: Game | null): IDrawOverviewGameResult {
  return {
    id: game?.id ?? '',
    gameType: mapGQLGameTypeData(game?.gameType),
    gameDrawTime: game?.gameDrawTime?.map((gameDrawTime) => mapGQLDrawOverviewGameDrawTimeResult(gameDrawTime)) ?? [],
  };
}

export function mapGQLGamesSalesReportGame(game?: Game | null): IGamesSalesReportGame {
  return {
    id: game?.id ? game.id ?? '' : '',
    gameType: mapGQLGamesSalesReportGameType(game?.gameType),
  };
}

function mapGQLGameDrawtimeOverviewReport(gameDrawTime?: Maybe<GameDrawtimeOverviewReport>): IGameDrawtimeOverviewReport {
  return {
    drawTime: mapGQLDrawTime(gameDrawTime?.drawTime),
    sales: gameDrawTime?.sales ? gameDrawTime.sales ?? 0 : 0,
    noOfbets: gameDrawTime?.noOfbets ? gameDrawTime.noOfbets ?? 0 : 0,
    hits: gameDrawTime?.hits ? gameDrawTime.hits ?? 0 : 0,
    gameResult: mapGQLGameResult(gameDrawTime?.gameResult),
  };
}

export function mapGQLGamesOverviewReport(report?: GamesOverviewReport | null): IGamesOverviewReport {
  const gameDrawTimesReport = report?.gameReport?.gameDrawTimesReport?.map((x) => mapGQLGameDrawtimeOverviewReport(x));

  return {
    totalSales: report?.totalSales ? report.totalSales ?? 0 : 0,
    totalHits: report?.totalHits ? report.totalHits ?? 0 : 0,
    totalNoOfbets: report?.totalNoOfbets ? report?.totalNoOfbets ?? 0 : 0,
    gameReport: {
      game: mapGQLGame(report?.gameReport?.game),
      sales: report?.gameReport?.sales ? report?.gameReport?.sales ?? 0 : 0,
      noOfbets: report?.gameReport?.noOfbets ? report?.gameReport?.noOfbets ?? 0 : 0,
      hits: report?.gameReport?.hits ? report?.gameReport?.hits ?? 0 : 0,
      gameDrawTimesReport: gameDrawTimesReport ? gameDrawTimesReport : [],
    },
  };
}
