import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CardMedia, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillEdit } from 'react-icons/ai';
import { MdAddCircleOutline, MdDelete } from 'react-icons/md';
import { RiImageAddFill } from 'react-icons/ri';
import Modal from 'react-modal';
import * as yup from 'yup';

// graphql
import type { CreateGameInput, CreateDrawTimeInput } from '@/generated/graphql';
import { GameStatus, useCreateGameMutation } from '@/generated/graphql';

// interface
import type { IDrawTimeInput, ICreateGameInput } from '@/shared/interfaces';

// material

// components
import { ChildModal } from './create-draw-time';
import { DrawTimeTable } from './draw-time-table';
import type { ChangeEvent } from 'react';

const schema = yup
  .object({
    name: yup.string().required(),
    description: yup.string(),
    globalNumberLimit: yup.number().required(),
    combinationLimit: yup.number().required(),
    maxNumber: yup.number().required(),
    minNumber: yup.number().required(),
    isRepeating: yup.boolean().required(),
    isRumble: yup.boolean().required(),
    payout: yup.number().required(),
    hasDoubleRepeatingPayout: yup.boolean().required(),
    hasTripleRepeatingPayout: yup.boolean().required(),
  })
  .required();

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#app');
interface ICreateGameCMProps {
  refetchDataGames: () => void;
}

export const CreateGame: React.FC<ICreateGameCMProps> = ({ refetchDataGames }) => {
  const [drawTimeInputs, setdrawTimeInput] = useState<IDrawTimeInput[]>([]);
  const [imageToUpload, setImageToUpload] = useState<File | null>(null);
  const [image64, setImage64] = useState<string | null>(null);

  const [createGameMutation, { loading, error }] = useCreateGameMutation();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateGameInput>({
    resolver: yupResolver(schema),
  });

  const deleteDrawTimeInput = (names: string[]) => {
    // delete drawTimeInputs by names
    const newDrawTimeInputs = drawTimeInputs.filter((dt) => !names.includes(dt.name));
    setdrawTimeInput(newDrawTimeInputs);
    // setdrawTimeInput(drawTimeInputs.filter(selected => selected.name !== drawTimeInput));
  };

  const onSubmit = async (dataInput: ICreateGameInput) => {
    console.log(dataInput);
    const {
      name,
      description,
      globalNumberLimit,
      combinationLimit,
      minNumber,
      maxNumber,
      isRepeating,
      payout,
      isRumble,
      hasDoubleRepeatingPayout,
      hasTripleRepeatingPayout,
    } = dataInput;
    const dateNow = new Date();
    const date = dateNow.toISOString().split('T')[0].split('-').join('');
    const time = dateNow.toISOString().split('T')[1].split(':').slice(0, 2).join('');

    const drawTimes: CreateDrawTimeInput[] = [];

    drawTimeInputs.map((drawTimeInput) => {
      const { name, startDateTime, endDateTime, cutOffDateTime } = drawTimeInput;

      drawTimes.push({
        name: name,
        startDateTime,
        endDateTime,
        cutOff: cutOffDateTime,
      });
    });

    const input: CreateGameInput = {
      name: `REF${date}${time}`,
      description: 'Create Game',
      status: GameStatus.Open,
      gameType: {
        name,
        description,
        globalNumberLimit,
        combinationLimit,
        minNumber,
        maxNumber,
        hasDoubleRepeatingPayout,
        hasTripleRepeatingPayout,
        placeValue: maxNumber > 9 ? 2 : 1,
        isRepeating,
        payout,
        isRumble,
      },
      drawTimes,
    };

    const { data } = await createGameMutation({ variables: { input } });

    if (!loading && !error && data) {
      if (imageToUpload) {
        const formData = new FormData();
        formData.append('gameTypePhoto', imageToUpload);
        if (data.createGame.game) {
          const { gameType } = data.createGame.game;
          if (gameType?.id) {
            axios
              .post(
                (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL) +
                  '/api/v1/upload-game-type-photo/' +
                  gameType.id,
                formData,
              )
              .then((res) => {
                console.log(res);
              });
          }
        }
      }
      refetchDataGames();
      reset();
      setdrawTimeInput([]);
      setIsOpen(false);
      setImage64(null);
      setImageToUpload(null);
    }
  };

  // modal details
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    console.log('open');
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSetImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);

      reader.onloadend = function () {
        setImage64(reader.result as string);
      };
      console.log(url); // Would see a path?

      setImageToUpload(files[0]);
    }

    // Or if you don't prefer Object destructuring assignment...
    // const files = event.target.files;

    // Rest of the logic here
  };

  const resetImage = () => {
    setImageToUpload(null);
    setImage64(null);
  };

  return (
    <div>
      <Button onClick={openModal} variant="contained" startIcon={<MdAddCircleOutline size="40" />}>
        Create Game Type
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <Box sx={{ height: 15, background: '#87ADD4' }} />
        <Box sx={{ width: 1000, p: 3 }}>
          <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'} pt={2}>
            Create Game Type
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box
                  sx={{
                    height: 130,
                    background: '#CCCCCC',
                    border: '1px dashed grey',
                    borderColor: '#7b7979',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  {image64 ? (
                    <>
                      <Grid container>
                        <Grid item md={3} />
                        <Grid item md={6}>
                          <CardMedia height="120" component="img" image={image64} />
                        </Grid>
                        <Grid item md={3}>
                          <Button
                            variant="contained"
                            sx={{
                              background: '#001E3C',
                              borderRadius: '50%',
                              width: 20,
                              minWidth: 30,
                              height: 30,
                              p: 0,
                              ml: 1,
                            }}
                            component="label">
                            <AiFillEdit size={15} />
                            <input hidden accept="image/*" multiple type="file" onChange={(event) => handleSetImage(event)} />
                          </Button>
                          <Button
                            onClick={() => resetImage()}
                            variant="contained"
                            sx={{
                              background: 'red',
                              borderRadius: '50%',
                              width: 20,
                              minWidth: 30,
                              height: 30,
                              p: 0,
                              ml: 1,
                              mt: 1,
                            }}
                            component="label">
                            <MdDelete size={15} />
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          background: '#001E3C',
                          borderRadius: '50%',
                          width: 20,
                          height: 60,
                        }}
                        component="label">
                        <RiImageAddFill size={20} />
                        <input hidden accept="image/*" multiple type="file" onChange={(event) => handleSetImage(event)} />
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item md={8}>
                <div>
                  <FormControl>
                    <TextField
                      error={errors.name?.message ? true : false}
                      helperText={errors.name?.message ? errors.name?.message : null}
                      {...register('name')}
                      label="Name"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <TextField
                      {...register('description')}
                      id="standard-multiline-static"
                      label="Description"
                      placeholder="Insert Description for Game Type..."
                      multiline
                      rows={2}
                      variant="standard"
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item md={5}>
                <Box sx={{ background: '#87ADD4' }}>
                  <Typography variant="body2" display="block" ml={1} py={0.5} mb={0} color={'white'} sx={{ fontWeight: 600 }} gutterBottom>
                    Ball Configration
                  </Typography>
                </Box>
                <Box sx={{ background: '#C5D8EC', p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <FormControl>
                        <TextField
                          error={errors.minNumber?.message ? true : false}
                          helperText={errors.minNumber?.message ? 'required and must be number' : null}
                          {...register('minNumber')}
                          label="Min Number"
                          size="small"
                          variant="standard"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl>
                        <TextField
                          error={errors.maxNumber?.message ? true : false}
                          helperText={errors.maxNumber?.message ? 'required and must be number' : null}
                          {...register('maxNumber')}
                          label="Max Number"
                          size="small"
                          variant="standard"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl>
                    <TextField
                      error={errors.combinationLimit?.message ? true : false}
                      helperText={errors.combinationLimit?.message ? 'required and must be number' : null}
                      {...register('combinationLimit')}
                      label="Combinations"
                      size="small"
                      variant="standard"
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box sx={{ background: '#87ADD4' }}>
                  <Typography variant="body2" display="block" ml={1} py={0.5} mb={0} color={'white'} sx={{ fontWeight: 600 }} gutterBottom>
                    Combination and Bet Configuration
                  </Typography>
                </Box>
                <Box sx={{ background: '#C5D8EC', p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <FormControl>
                        <TextField
                          error={errors.globalNumberLimit?.message ? true : false}
                          helperText={errors.globalNumberLimit?.message ? 'required and must be number' : null}
                          {...register('globalNumberLimit')}
                          label="Global Limit"
                          size="small"
                          variant="standard"
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          error={errors.payout?.message ? true : false}
                          helperText={errors.payout?.message ? 'required and must be number' : null}
                          {...register('payout')}
                          label="Winning Multiplier"
                          size="small"
                          variant="standard"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={7}>
                      <FormGroup sx={{ mt: 3 }}>
                        <FormControlLabel {...register('isRumble')} control={<Checkbox />} label="Rumble Functionality" />
                        <FormControlLabel {...register('isRepeating')} control={<Checkbox />} label="is Repeating Allowed" />
                        <FormControlLabel
                          {...register('hasDoubleRepeatingPayout')}
                          control={<Checkbox />}
                          label="Has Double Repeating Payout"
                        />
                        <FormControlLabel
                          {...register('hasTripleRepeatingPayout')}
                          control={<Checkbox />}
                          label="Has Triple Repeating Payout"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
          <Grid container justifyContent={'end'} mt={1}>
            <ChildModal setdrawTimeInput={setdrawTimeInput} drawTimeInputs={drawTimeInputs} />
          </Grid>

          <Grid container spacing={3} mt={1}>
            <Grid item md={12}>
              <DrawTimeTable rows={drawTimeInputs} deleteRow={deleteDrawTimeInput} />
            </Grid>
          </Grid>
          <Grid container justifyContent={'end'} mt={10}>
            <Button onClick={handleSubmit(onSubmit)} variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
