import { useContext, useState, useEffect } from 'react';
import { orderBy } from 'lodash';

//styles
import './styles/limits.css';

// graphql
import {
  useGameSalesNumberLimitsQuery,
  useGameHotNumbersQuery,
  useGameDrawtimeLimitsQuery,
  useLimitsGameDrawTimesQuery,
} from '@/generated/graphql';
import type { PageInfo } from '@/generated/graphql';

// interfaces
import {
  mapGameSalesNumberLimitData,
  mapGameHotNumberData,
  IGameSalesNumberLimit,
  mapGameDrawtimeLimitTableData,
  mapGameDrawtimeLimitData,
  mapGQLGameDrawTime,
  mapGQLGame,
} from '@/shared/interfaces';
import type {
  IGameDrawtimeLimit,
  ILimitTableData,
  ILimitQueryVariable,
  IGame,
  IGameHotNumberQueryVariable,
  IGameHotNumber,
  IGameHotNumberTableData,
  IGameDrawtimelimitTableData,
  IGameDrawtimeLimitQueryVariable,
  IGameDrawTime,
  IGameDrawTimeQueryVariable,
} from '@/shared/interfaces';

// material ui
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Tab, TextField } from '@mui/material';

// context
import { useGameDashboardCtx } from '@/layouts/components/sidebar';

//Components
import { NumberSalesLimitTable } from '../game-limits/components/number-sales-limits-tab/number-sales-limits-table';
import { CreateGameHotNumber } from '../game-limits/components/game-hot-number-tab/create-game-hot-number';
import { GameHotNumberTable } from '../game-limits/components/game-hot-number-tab/game-hot-number-table';
import { GlobalLimit } from '../game-limits/components/global-limit';
import { CreateNumberSalesLimit } from '../game-limits/components/number-sales-limits-tab/create-number-sales-limits';
import { GameTypeDashboardLayout } from '@/components/gametype-carousel/gametype-carousel';

// Game Drawtime limit
import { CreateGameDrawtimeLimit } from '../game-limits/components/game-drawtime-limit-tab/create-game-drawtime-limit';
import { GameDrawtimeLimitTable } from '../game-limits/components/game-drawtime-limit-tab/game-drawtime-limit-table';
import GlobalLimits from './components/global-limit';

export interface IDataFilter {
  dateFrom: string;
  dateTo: string;
}

export function Limits() {
  const { gameCtx } = useGameDashboardCtx();
  const [gameCtx2, setGameCtx2] = useState<IGame>(mapGQLGame());

  const today = new Date();

  const defaultDateFilter = {
    dateFrom: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
    dateTo: `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`,
  };

  // const toUTC = (date: string, time: string): string => new Date(date.replace(/-/g, '/') + ' ' + time).toISOString();
  const [dataFilter, setDataFilter] = useState<IDataFilter>({
    dateFrom: defaultDateFilter.dateFrom,
    dateTo: defaultDateFilter.dateTo,
  });

  const [tabValue, setTabValue] = useState('1');

  //Individual Limits
  const [individuallimit, setindividuallimit] = useState<ILimitTableData[]>([]);
  const [LimitQueryVariable, setLimitQueryVariable] = useState<ILimitQueryVariable>({
    first: 10,
    filterBy: {
      startDate: defaultDateFilter.dateFrom,
      endDate: defaultDateFilter.dateTo,
    },
  });

  // hot number limits
  const [gameHotNumberTableData, setGameHotNumberTableData] = useState<IGameHotNumberTableData[]>([]);
  const [gameHotNumberQueryVariable, setGameHotNumberQueryVariable] = useState<IGameHotNumberQueryVariable>({
    first: 10,
    filterBy: {
      startDate: defaultDateFilter.dateFrom,
      endDate: defaultDateFilter.dateTo,
    },
  });

  // game drawtime limit
  const [gameDrawtimeLimitTableData, setDrawtimeLimitTableData] = useState<IGameDrawtimelimitTableData[]>([]);
  const [gameDrawtimeLimitQueryVariable, setGameDrawtimeLimitQueryVariable] = useState<IGameDrawtimeLimitQueryVariable>({
    first: 10,
    filterBy: {
      startDate: defaultDateFilter.dateFrom,
      endDate: defaultDateFilter.dateTo,
    },
  });

  // game drawtimes
  const [gameDrawTimeQueryVariable, setGameDrawTimeQueryVariable] = useState<IGameDrawTimeQueryVariable>({
    first: 25,
  });
  const [gameDrawTimes, setGameDrawTimes] = useState<IGameDrawTime[]>([]);

  // pagination
  const [pageInfoLimit, setPageInfoLimit] = useState<PageInfo>();
  const [totalCountLimit, setTotalCountLimit] = useState<number>(0);

  const [pageInfoGameHotNumber, setPageInfoHotNumber] = useState<PageInfo>();
  const [totalCountGameHotNumber, setTotalCountHotNumber] = useState<number>(0);

  const [pageInfoGameDrawtimeLimit, setPageInfoDrawtimeLimit] = useState<PageInfo>();
  const [totalCountGameDrawtimeLimit, setTotalCountDrawtimeLimit] = useState<number>(0);

  //GRAPHQL QUERIES
  const getGameLimit = useGameSalesNumberLimitsQuery({
    variables: LimitQueryVariable,
    onCompleted: (data) => {
      // const mappedGameLimits: ILimitTableData[] = data.gameSalesNumberLimits.nodes.map((result) => mapGameSalesNumberLimitData(result));

      const limitTableData: ILimitTableData[] = [];

      data.gameSalesNumberLimits.nodes.map((limit) => {
        const mapLimit: IGameSalesNumberLimit = mapGameSalesNumberLimitData(limit);

        limitTableData.push({
          id: mapLimit.id,
          numberCombination: mapLimit.numberCombination.replace(/,/g, ''),
          numberCombinationOriginal: mapLimit.numberCombination,
          limit: mapLimit.limit,
          sales: mapLimit.sales,
          startDate: mapLimit.startDate,
          endDate: mapLimit.endDate,
        });
      });
      setTimeout(() => {
        setindividuallimit(limitTableData);
        setPageInfoLimit(data.gameSalesNumberLimits.pageInfo);
        setTotalCountLimit(data.gameSalesNumberLimits.totalCount);
      }, 100);
    },
  });

  const getGameHotNumber = useGameHotNumbersQuery({
    variables: gameHotNumberQueryVariable,
    onCompleted: (data) => {
      const gameHotNumberTableData: IGameHotNumberTableData[] = [];

      data.gameHotNumbers.nodes.map((gameHotNumber) => {
        const mapGameHotNumber: IGameHotNumber = mapGameHotNumberData(gameHotNumber);

        gameHotNumberTableData.push({
          id: mapGameHotNumber.id,
          numberCombination: mapGameHotNumber.numberCombination.replace(/,/g, ''),
          numberCombinationOriginal: mapGameHotNumber.numberCombination,
          payout: mapGameHotNumber.payout,
          salesLimit: mapGameHotNumber.salesLimit,
          sales: mapGameHotNumber.sales,
          startDate: mapGameHotNumber.startDate,
          endDate: mapGameHotNumber.endDate,
        });
      });

      setTimeout(() => {
        setGameHotNumberTableData(gameHotNumberTableData);
        setPageInfoHotNumber(data.gameHotNumbers.pageInfo);
        setTotalCountHotNumber(data.gameHotNumbers.totalCount);
      }, 100);
    },
  });

  const gameDrawtimeLimitsQuery = useGameDrawtimeLimitsQuery({
    variables: gameDrawtimeLimitQueryVariable,
    onCompleted(data) {
      const mappedDatas = data.gameDrawtimeLimits.nodes.map((gameDrawtimeLimit) => mapGameDrawtimeLimitData(gameDrawtimeLimit));

      setTimeout(() => {
        setDrawtimeLimitTableData(mappedDatas.map((mappedData: IGameDrawtimeLimit) => mapGameDrawtimeLimitTableData(mappedData)));
        setPageInfoDrawtimeLimit(data.gameDrawtimeLimits.pageInfo);
        setTotalCountDrawtimeLimit(data.gameDrawtimeLimits.totalCount);
      }, 100);
    },
  });

  useLimitsGameDrawTimesQuery({
    variables: gameDrawTimeQueryVariable,
    skip: gameDrawTimeQueryVariable.filterBy?.gameId ? false : true,
    onCompleted(data) {
      const mappedGDTData: IGameDrawTime[] = data.gameDrawTimes.nodes.map((x) => mapGQLGameDrawTime(x));

      setTimeout(() => {
        const sortedGameDrawTimes = orderBy(mappedGDTData, ['drawTime.endTime'], ['asc']);
        setGameDrawTimes(sortedGameDrawTimes);
      }, 100);
    },
  });

  const refetchData = () => {
    getGameLimit.refetch({
      ...LimitQueryVariable,
    });
    getGameHotNumber.refetch({
      ...gameHotNumberQueryVariable,
    });
    gameDrawtimeLimitsQuery.refetch({
      ...gameHotNumberQueryVariable,
    });
  };

  const filterDataDate = (value: string, type: 'dateFrom' | 'dateTo') => {
    if (type === 'dateFrom') {
      setDataFilter({ ...dataFilter, dateFrom: value });
      setLimitQueryVariable({
        ...LimitQueryVariable,
        filterBy: { ...LimitQueryVariable.filterBy, startDate: value },
      });
      setGameHotNumberQueryVariable({
        ...gameHotNumberQueryVariable,
        filterBy: { ...gameHotNumberQueryVariable.filterBy, startDate: value },
      });
      setGameDrawtimeLimitQueryVariable({
        ...gameDrawtimeLimitQueryVariable,
        filterBy: { ...gameDrawtimeLimitQueryVariable.filterBy, startDate: value },
      });
    } else if (type === 'dateTo') {
      setDataFilter({ ...dataFilter, dateTo: value });
      setLimitQueryVariable({
        ...LimitQueryVariable,
        filterBy: { ...LimitQueryVariable.filterBy, endDate: value },
      });
      setGameHotNumberQueryVariable({
        ...gameHotNumberQueryVariable,
        filterBy: { ...gameHotNumberQueryVariable.filterBy, endDate: value },
      });
      setGameDrawtimeLimitQueryVariable({
        ...gameDrawtimeLimitQueryVariable,
        filterBy: { ...gameDrawtimeLimitQueryVariable.filterBy, endDate: value },
      });
    }
  };

  useEffect(() => {
    if (gameCtx.id.length > 1) {
      setGameDrawTimeQueryVariable({
        ...gameDrawTimeQueryVariable,
        filterBy: { ...gameDrawTimeQueryVariable.filterBy, gameId: gameCtx.id },
      });
    }
  }, [gameCtx]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <>
      <div className="page-wrapper">
        <GameTypeDashboardLayout gameCtx={gameCtx2} setGameCtx={setGameCtx2} />
        <div className="limits-header">
          <GlobalLimits />
          <div className="date-time-filter">
            <TextField
              id="date"
              label="Start Date"
              type="date"
              size="small"
              value={dataFilter.dateFrom}
              onChange={(e) => filterDataDate(e.target.value, 'dateFrom')}
              sx={{ width: 220, mx: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
