import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGameDashboardCtx } from '@/layouts/components/sidebar';

//graphql
import type { UpdateGameConfigInput } from '@/generated/graphql';
import { useGameLimitUpdateGameConfigMutation } from '@/generated/graphql';

// validation schema
const schema = yup
  .object({
    globalLimit: yup.number().required(),
  })
  .required();

//Interface
interface IGlobalLimitInput {
  globalLimit: number;
}

export function GlobalLimit() {
  const { gameCtx } = useGameDashboardCtx();
  const {
    register,
    handleSubmit,
    formState: {},
    setValue,
  } = useForm<IGlobalLimitInput>({
    resolver: yupResolver(schema),
  });

  const [updateGameConfigMutation] = useGameLimitUpdateGameConfigMutation();

  const onSubmit = async (dataInput: IGlobalLimitInput) => {
    const input: UpdateGameConfigInput = {
      globalNumberLimit: parseFloat(dataInput.globalLimit.toString()),
      combinationLimit: 0,
      placeValue: 0,
      isRepeating: false,
      isRumble: false,
      id: gameCtx.gameType.gameConfig.id,
    };

    const { data } = await updateGameConfigMutation({ variables: { input } });

    if (data) {
      console.log('game config updated');
    }
  };
  useEffect(() => {
    if (gameCtx.gameType.gameConfig.globalNumberLimit > 0) {
      setValue('globalLimit', gameCtx.gameType.gameConfig.globalNumberLimit);
    }
  }, [gameCtx]);
  return (
    <div className="globallimit">
      Global Limit Settings
      <div className="globallimit-content">
        <div className="globallimit-inputcontainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              style={{ fontFamily: 'Open Sans', fontWeight: 600 }}
              label="Current Global Limit"
              variant="standard"
              focused
              {...register('globalLimit')}
            />
            <button type="submit" className="global-limit--edit-button">
              {' '}
              Edit{' '}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
