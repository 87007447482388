// images

// material ui
import { Card, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';

// interfaces
import type { IGame } from '@/shared/interfaces';
import type { GameTypePhoto } from '@/shared/interfaces';
import ball20 from '../../../../assets/images/ball20.png';

interface IProps {
  game: IGame;
  gameTypePhoto: GameTypePhoto[];
}

export const GameTypeCard: React.FC<IProps> = ({ game, gameTypePhoto }) => (
  <Card sx={{ p: 4, borderBottom: 4, borderBlockColor: '#2782E4' }}>
    <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
      <Grid item xl={7} lg={8}>
        <CardMedia component="img" image={gameTypePhoto.filter((img) => img.id === game?.gameType?.id)[0]?.image ?? ball20} />
      </Grid>
    </Grid>
    <Typography variant="subtitle1" mt={3} align="center" component="div">
      {game.gameType && game.gameType.name
        ? game.gameType.name?.length > 15
          ? game.gameType.name.substring(0, 15) + '...'
          : game.gameType.name
        : null}
    </Typography>
  </Card>
);
