import { useState, useEffect } from 'react';

// interfaces
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import type { ICurrentUserLogin } from '@/shared/interfaces';

// enums
import { UserType } from '@/shared/enums';

// material-ui
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Typography, Select } from '@mui/material';
import Modal from 'react-modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api

// graphql
import type { UpdateAdminInput } from '@/generated/graphql';
import { useAdminQuery, useUpdateAdminMutation } from '@/generated/graphql';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isEditProfileModalOpen: boolean;
  setIsEditProfileModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchData: () => void;
  currentUserLogin: ICurrentUserLogin | null;
}

export const EditProfileModal: React.FC<Props> = ({ isEditProfileModalOpen, setIsEditProfileModalOpen, refetchData, currentUserLogin }) => {
  // mutation
  const [updateAdmin] = useUpdateAdminMutation();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const [adminProfileInput, setAdminProfileInput] = useState<UpdateAdminInput>();

  // Query
  useAdminQuery({
    variables: { id: currentUserLogin ? currentUserLogin.id : '' },
    skip: currentUserLogin && currentUserLogin.role === UserType.ADMIN.toString() ? false : true,
    onCompleted(data) {
      const { firstName, lastName, middleName } = data.admin;
      if (currentUserLogin) {
        setAdminProfileInput({ ...adminProfileInput, firstName, lastName, middleName, adminId: currentUserLogin.id });
      } else {
        console.error('currentUserLogin is undefine');
      }
    },
  });

  const closeModal = () => {
    setIsEditProfileModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const clickSubmit = () => {
    if (adminProfileInput) {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: ['You want to edit this limit'],
      });
    }
  };

  const editProfile = () => {
    activateLoading();

    if (adminProfileInput) {
      updateAdmin({
        variables: { input: adminProfileInput },
        onCompleted: () => {
          refetchData();
          setIsEditProfileModalOpen(false);
          setConfirmationModal({
            isOpen: false,
            title: 'Confirm Result',
            messages: [],
          });
          setSuccessDetails({
            isOpen: true,
            message: 'Profile edited successfully',
            type: 'success',
          });
          setShowLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (currentUserLogin && currentUserLogin.role === UserType.ADMIN.toString()) {
      const { id, username } = currentUserLogin;

      setAdminProfileInput({ ...adminProfileInput, adminId: id, username });
    }
  }, [currentUserLogin]);

  return (
    <>
      <Modal isOpen={isEditProfileModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 350, px: 5, pb: 3, pt: 1 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit Profile
            </Typography>

            <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                value={adminProfileInput?.firstName}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, firstName: e.target.value } : undefined);
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                value={adminProfileInput?.lastName}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, lastName: e.target.value } : undefined);
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label="Middle Name"
                variant="outlined"
                size="small"
                value={adminProfileInput?.middleName}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, middleName: e.target.value } : undefined);
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                label="Username"
                variant="outlined"
                size="small"
                value={adminProfileInput?.username}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, username: e.target.value } : undefined);
                }}
              />
            </FormControl>
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{
            display: 'flex',
            paddingBottom: '2em',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <Button variant="contained" onClick={clickSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={editProfile}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
