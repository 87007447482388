import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

//Components
import { Clock, ClockVariantTypes } from '@/components/clock/clock';

// cookie handler
import { storeUserAuth } from '@/services/storage/user-auth.service';

// graphql
import type { LoginInput } from '@/generated/graphql';

// material ui
import { Box, TextField, Button, Typography } from '@mui/material';

//Service
import LoginService from '@/services/login/login.service';

// images
import logo from '../../assets/images/FINAL 2.png';
import ball09 from '../../assets/images/ball09.png';
import ball16 from '../../assets/images/ball16.png';

// css
import './login.css';

// enums
import { UserType } from '@/shared/enums';

// interfaces
import type { ILoginFormInput } from './interfaces/IloginForm';

// context
import { AuthContext } from '@/contexts/auth';

interface Props {
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Login: React.FC<Props> = ({ setAuthenticated }) => {
  const [today, setToday] = useState(moment().format('YYYY-MM-DD | hh:mm:ss A'));
  const { authenticated } = useContext(AuthContext);

  const { register, handleSubmit } = useForm<ILoginFormInput>();
  const { loginMutation } = LoginService();
  const [showErrorMsg, setShowErrorMsg] = useState<string | null>();

  const navigate = useNavigate();
  const location = useLocation();

  const authenticateUser: SubmitHandler<ILoginFormInput> = async (dataForm) => {
    const { username, password } = dataForm;

    const input: LoginInput = {
      username,
      password,
    };

    const loginMutationRes = await loginMutation({
      variables: { input },
      onCompleted: (data) => {
        if (data.login.token) {
          const { token, user } = data.login;

          if (user.role?.name === UserType.ADMIN) {
            storeUserAuth({
              token,
              userType: user.role.name,
            });
            setAuthenticated(true);
            const origin = location.state?.from?.pathname || '/dashboard/overview';
            navigate(origin);
          }
        } else {
          setShowErrorMsg(null);
        }
      },
      onError: (error) => {
        console.log('===', error);
        if (error.message === 'User already logged in') {
          setShowErrorMsg(error.message);
        } else {
          setShowErrorMsg('Username or Password is incorrect');
        }
      },
    });

    setTimeout(() => {
      if (!loginMutationRes.errors) console.log('Access Granted');
      else console.log('Access Denied!');
    }, 1000);
  };

  // check if user alreday login
  useEffect(() => {
    if (authenticated) {
      navigate('/dashboard/overview');
    }
  }, []);

  // useEffect(() => {
  //   setInterval(() => setToday(moment().format('YYYY-MM-DD | hh:mm:ss A')), 1000);
  // }, [today]);
  return (
    <>
      <Box className="login-page w-100">
        <Box className="d-flex align-items-start">
          <img className="login-ball-20" src={ball09} alt="background" />
        </Box>
        <Box className="d-flex align-items-end justify-content-between">
          <img className="login-ball-9" src={ball09} alt="background" />
          <img className="login-ball-16" src={ball16} alt="background" />
        </Box>

        <Box className="d-flex justify-content-center align-items-center vh-100">
          <Box className="col-md-8 d-flex justify-content-center vh-70">
            <Box className="login-post align-items-center d-flex">
              <img className="login-post-img" src={logo} />
            </Box>
            <Box className="col-md-8 login-form px-0">
              <Box sx={{ px: 0.7, py: 0.3 }} className="login-form-header d-flex justify-content-end">
                <Clock variant={ClockVariantTypes.MINIMAL} />
              </Box>
              <form className="form" onSubmit={handleSubmit(authenticateUser)}>
                <Box className="d-flex align-items-start flex-column h-90 mx-6">
                  <Box className="p-2">
                    <Box className="login-header mt-5 welcome text-left">
                      <h3>Welcome Agent!!!</h3>
                    </Box>
                    <p className="login-sub-header text-left">Please Login your Account</p>
                  </Box>
                  {showErrorMsg !== null ? (
                    <Typography variant="caption" display="block" ml={2} gutterBottom color={'red'}>
                      {showErrorMsg}
                    </Typography>
                  ) : null}
                  <Box className="p-2 text-left mt-5 w-70 login-form-section">
                    <Box className="login-form-group mb-4">
                      <label className="login-label">Username</label>
                      <TextField {...register('username', { required: true })} className="login-input" type="text" size="small" />
                    </Box>
                    <Box className="login-form-group ">
                      <label className="login-label">Password</label>
                      <TextField {...register('password')} className="login-input" type="password" size="small" />
                    </Box>
                  </Box>
                  <Box className="login-button pt-5">
                    <Box className="login-form-group">
                      <Button type="submit" className="login-submit" size="medium">
                        <Box
                          sx={{
                            backgroundColor: '#427FC1',
                            color: 'white',
                            width: '100%',
                            height: '4em',
                            py: 4,
                            mt: '5vh',
                          }}
                          className="login-subsmit-font">
                          <Typography variant="h4" component={'h4'} style={{ lineHeight: 0 }}>
                            Log In
                          </Typography>
                        </Box>{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
