import { useState, useEffect } from 'react';

// interfaces
import type { IModalConfirmationDetails } from '@/components/modal/confirmation';
import type { ICurrentUserLogin } from '@/shared/interfaces';

// enums
import { UserType } from '@/shared/enums';

// material-ui
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Typography, Select } from '@mui/material';
import Modal from 'react-modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context api

// graphql
import type { UpdateAdminPasswordInput } from '@/generated/graphql';
import { useUpdateAdminPasswordMutation } from '@/generated/graphql';

// components
import { ConfirmationModal } from '@/components/modal/confirmation';
import { FeedbackAlert } from '@/components/feedback/alert';
import type { IAlertFeedBackDetails } from '@/components/feedback/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -48vh)',
  },
};

// interface ResultSlice {
//   start: number;
//   end: number;
// }

interface Props {
  isUpdatePasswordModalOpen: boolean;
  setIsUpdatePasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchData: () => void;
  currentUserLogin: ICurrentUserLogin | null;
}

export const UpdatePasswordModal: React.FC<Props> = ({
  isUpdatePasswordModalOpen,
  setIsUpdatePasswordModalOpen,
  refetchData,
  currentUserLogin,
}) => {
  // mutation
  const [updateAdminPassword] = useUpdateAdminPasswordMutation();

  const [confirmationModal, setConfirmationModal] = useState<IModalConfirmationDetails>({
    isOpen: false,
    title: 'Are you sure ?',
    messages: [],
  });
  // for success feedback
  const [successDetails, setSuccessDetails] = useState<IAlertFeedBackDetails>({
    isOpen: false,
    message: '',
    type: 'success',
  });

  const [showLoading, setShowLoading] = useState(false);

  const [adminProfileInput, setAdminProfileInput] = useState<UpdateAdminPasswordInput>();
  const [confrimPassword, setConfrimPassword] = useState<string>();

  const closeModal = () => {
    setIsUpdatePasswordModalOpen(false);
  };

  const activateLoading = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 10000);
  };

  const clickSubmit = () => {
    if (adminProfileInput) {
      setConfirmationModal({
        ...confirmationModal,
        isOpen: true,
        messages: ['You want to edit this limit'],
      });
    }
  };

  const updatePassword = () => {
    activateLoading();

    if (adminProfileInput) {
      updateAdminPassword({
        variables: { input: adminProfileInput },
        onCompleted: (data) => {
          if (data.updateAdminPassword.message === 'Password successfully updated') {
            refetchData();
            setIsUpdatePasswordModalOpen(false);
            setConfirmationModal({
              isOpen: false,
              title: 'Confirm Result',
              messages: [],
            });
            setSuccessDetails({
              isOpen: true,
              message: 'Profile edited successfully',
              type: 'success',
            });
            setShowLoading(false);
          } else {
            setSuccessDetails({
              isOpen: true,
              message: 'Invalid Password',
              type: 'error',
            });
            setConfirmationModal({ ...confirmationModal, isOpen: false });
            setShowLoading(false);
          }
        },
        onError(error) {
          setSuccessDetails({
            isOpen: true,
            message: 'Invalid Password',
            type: 'error',
          });
          setConfirmationModal({ ...confirmationModal, isOpen: false });
          setShowLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (currentUserLogin && currentUserLogin.role === UserType.ADMIN.toString()) {
      const { id, username } = currentUserLogin;

      setAdminProfileInput({ ...adminProfileInput, adminId: id, newPassword: '', password: '' });
    }
  }, [currentUserLogin]);

  return (
    <>
      <Modal isOpen={isUpdatePasswordModalOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <Box sx={{ height: 15, background: '#87ADD4' }} />
          <Box sx={{ width: 350, px: 5, pb: 3, pt: 1 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign={'center'}>
              Edit Profile
            </Typography>

            <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
              <TextField
                type={'password'}
                label="Password"
                variant="outlined"
                size="small"
                value={adminProfileInput?.password}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, password: e.target.value } : undefined);
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                type={'password'}
                label="New Password"
                variant="outlined"
                size="small"
                value={adminProfileInput?.newPassword}
                onChange={(e) => {
                  setAdminProfileInput(adminProfileInput ? { ...adminProfileInput, newPassword: e.target.value } : undefined);
                }}
                helperText="Minimum of 8 characters"
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                type={'password'}
                label="Confirm Password"
                variant="outlined"
                size="small"
                value={confrimPassword}
                onChange={(e) => {
                  setConfrimPassword(e.target.value);
                }}
              />
              {confrimPassword && confrimPassword !== adminProfileInput?.newPassword && (
                <Typography variant="caption" display="block" gutterBottom color={'error'}>
                  Password do not match
                </Typography>
              )}
            </FormControl>
          </Box>
        </div>
        <div
          className="gameedit-submit"
          style={{
            display: 'flex',
            paddingBottom: '2em',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <Button variant="contained" onClick={clickSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        modalDetails={confirmationModal}
        setModalDetails={setConfirmationModal}
        confirm={updatePassword}
        confirmText={'confirm'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FeedbackAlert successDetails={successDetails} setSuccessDetails={setSuccessDetails} />
    </>
  );
};
