import Modal from 'react-modal';

import { ICoordinatorQueryVariable, ICoordinator, IUpdateUsherInput, IUsherTable, mapGQLCoordinator } from '@/shared/interfaces';
import { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { PageInfo, UpdateUsherInput, useSelectCoordinatorsQuery, useUpdateUsherMutation } from '@/generated/graphql';
import { RandomKeyString } from '../services';

interface IUsherProp {
  datas: IUsherTable | undefined;
  isOpen: boolean;
  openEvent: (open: boolean) => void;
  refetchData: () => void;
}

export function EditUshers(props: IUsherProp) {
  const { datas, isOpen, openEvent, refetchData } = props;

  const handleClose = () => openEvent(false);

  const schema = yup
    .object({
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup.string(),
      username: yup.string(),
      address: yup.string(),
      uniqueCode: yup.string(),
      contactNumber: yup.string(),
      coordinatorId: yup.string(),
    })
    .required();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateUsherInput>({
    resolver: yupResolver(schema),
  });

  const [updateUsherMutation] = useUpdateUsherMutation();
  const [coordinators, setCoordinators] = useState<ICoordinator[]>([]);
  const [coordinatorQueryVariable] = useState<ICoordinatorQueryVariable>({
    first: 2,
  });

  const [totalCoordinatorCount, setTotalCoordinatorCount] = useState<number>(0);
  const [coordinatorPageInfo, setCoordinatorPageInfo] = useState<PageInfo>();
  const [coordinatorValue, setCoordinatorValue] = useState('');

  const onSubmit = async (dataInput: IUpdateUsherInput) => {
    let coorId: string | undefined;
    if (datas) {
      const { address, contactNumber, lastName, firstName, uniqueCode, email, username } = dataInput;

      const input: UpdateUsherInput = {
        address,
        contactNumber,
        firstName,
        lastName,
        uniqueCode,
        id: datas.id,
        email,
        username,
        coordinatorId: coordinatorValue,
      };

      const { data } = await updateUsherMutation({
        variables: {
          input,
        },
      });

      if (data) console.log(data);
      if (errors) console.log(errors);
      refetchData();
      handleClose();
      reset();
    }
  };

  const selectCoordinators = useSelectCoordinatorsQuery({
    variables: coordinatorQueryVariable,
    onCompleted: (data) => {
      const mappedCoordinators: ICoordinator[] = data.coordinators.nodes.map((coordinator) => mapGQLCoordinator(coordinator));
      setCoordinators(mappedCoordinators);
      setTotalCoordinatorCount(data.coordinators.totalCount);
      setCoordinatorPageInfo(data.coordinators.pageInfo);
    },
  });

  useEffect(() => {
    if (coordinatorPageInfo && coordinatorPageInfo.hasNextPage && coordinatorPageInfo.endCursor) {
      selectCoordinators
        .fetchMore({
          variables: { first: 10, after: coordinatorPageInfo.endCursor },
        })
        .then((data) => {
          setCoordinatorPageInfo(data.data.coordinators.pageInfo);
          const mappedCoordinators: ICoordinator[] = data.data.coordinators.nodes.map((coordinator) => mapGQLCoordinator(coordinator));

          setCoordinators([...coordinators, ...mappedCoordinators]);
        });
    }
  }, [coordinatorPageInfo]);

  useEffect(() => {
    reset();
    openEvent(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (datas) {
      console.log('DATAAAAAA', datas);
      setCoordinatorValue(datas.coordinator.id);
    }
  }, [datas]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          position: 'absolute',
          margin: '0.1% auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: 0,
          marginRight: '-50%',
          transform: 'translate(-50%, -48vh)',
          border: '0px',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        },
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: 650,
        }}>
        <Divider
          sx={{
            height: '25px',
            backgroundColor: '#87ADD4',
          }}
        />
        <Box sx={{}} p={5}>
          <Typography
            sx={{
              textAlign: 'center',
            }}
            id="modal-modal-title"
            variant="h3"
            component="h3">
            Edit Agent
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={9}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('firstName')}
                    defaultValue={datas?.firstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('lastName')}
                    defaultValue={datas?.lastName}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('email')}
                    defaultValue={datas?.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Username"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('username')}
                    defaultValue={datas?.username}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Address"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('address')}
                    defaultValue={datas?.address}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Contact Number"
                    sx={{
                      width: '100%',
                    }}
                    size="small"
                    {...register('contactNumber')}
                    defaultValue={datas?.contactNumber}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}></Box>
            <Box mt={3}></Box>
            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Agent Code"
                    sx={{
                      width: '100%',
                    }}
                    type="text"
                    size="small"
                    {...register('uniqueCode')}
                    defaultValue={datas?.uniqueCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="select-label">Coordinator</InputLabel>
                    <Select
                      labelId="select-label"
                      input={<OutlinedInput label="Coordinator" />}
                      sx={{
                        width: '100%',
                      }}
                      size="small"
                      {...register('coordinatorId')}
                      onChange={(event) => {
                        setCoordinatorValue(event.target.value);
                      }}
                      value={coordinatorValue}
                      MenuProps={{
                        style: {
                          maxHeight: '300px',
                        },
                      }}>
                      {coordinators.map((coordinator) => (
                        <MenuItem key={RandomKeyString()} value={coordinator.id}>
                          {coordinator.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}></Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
              mt={10}>
              <Button type="submit">
                <Box
                  sx={{
                    backgroundColor: '#427FC1',
                    color: '#fff',
                  }}
                  p={1}>
                  Update Agent
                </Box>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
