import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
  Upload: any;
};

export type AddCoordinatorPaymentInput = {
  coordinatorId: Scalars['String'];
  payment: Scalars['Float'];
};

export type AddCoordinatorPaymentPayload = {
  __typename?: 'AddCoordinatorPaymentPayload';
  coordinatorPayment?: Maybe<CoordinatorPayment>;
};

export type Admin = Node & {
  __typename?: 'Admin';
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type AdminConnection = {
  __typename?: 'AdminConnection';
  edges: Array<Maybe<AdminEdge>>;
  nodes: Array<Maybe<Admin>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AdminEdge = {
  __typename?: 'AdminEdge';
  cursor: Scalars['String'];
  node: Admin;
};

export type AdminSort = {
  direction: SortDirection;
  field: AdminSortField;
};

export enum AdminSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type AdvanceBet = Node & {
  __typename?: 'AdvanceBet';
  amount?: Maybe<Scalars['Int']>;
  betType?: Maybe<BetType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  drawTime?: Maybe<DrawTime>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  numberCombination?: Maybe<Scalars['String']>;
  player?: Maybe<Player>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<AdvanceBetStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdvanceBetConnection = {
  __typename?: 'AdvanceBetConnection';
  edges: Array<Maybe<AdvanceBetEdge>>;
  nodes: Array<Maybe<AdvanceBet>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AdvanceBetEdge = {
  __typename?: 'AdvanceBetEdge';
  cursor: Scalars['String'];
  node: AdvanceBet;
};

export type AdvanceBetSort = {
  direction: SortDirection;
  field: AdvanceBetSortField;
};

export enum AdvanceBetSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum AdvanceBetStatus {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
}

export type AppApk = Node & {
  __typename?: 'AppApk';
  appName?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fileExtension?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isAppVersionBlock?: Maybe<Scalars['Boolean']>;
  originalFileName?: Maybe<Scalars['String']>;
  uniqueFileName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppApkConnection = {
  __typename?: 'AppApkConnection';
  edges: Array<Maybe<AppApkEdge>>;
  nodes: Array<Maybe<AppApk>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AppApkEdge = {
  __typename?: 'AppApkEdge';
  cursor: Scalars['String'];
  node: AppApk;
};

export enum AppApkNameEnum {
  StlMobile = 'STL_MOBILE',
}

export type AppApkSort = {
  direction: SortDirection;
  field: AppApkSortField;
};

export enum AppApkSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Bet = Node & {
  __typename?: 'Bet';
  amount?: Maybe<Scalars['Float']>;
  betAmountBeforeGreedy?: Maybe<Scalars['Float']>;
  betDetail?: Maybe<BetDetail>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  isOriginalBet?: Maybe<Scalars['Boolean']>;
  isWinner?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  numberCombination?: Maybe<Scalars['String']>;
  originalBetAmount?: Maybe<Scalars['Float']>;
  payout?: Maybe<Scalars['Float']>;
  player?: Maybe<Player>;
  returnBetAmount?: Maybe<Scalars['Float']>;
  transaction?: Maybe<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  winAmount?: Maybe<Scalars['Float']>;
};

export type BetConnection = {
  __typename?: 'BetConnection';
  edges: Array<Maybe<BetEdge>>;
  nodes: Array<Maybe<Bet>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BetDetail = Node & {
  __typename?: 'BetDetail';
  betType?: Maybe<BetType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BetEdge = {
  __typename?: 'BetEdge';
  cursor: Scalars['String'];
  node: Bet;
};

export type BetFilterField = {
  betTypeId?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameDrawTimeId?: InputMaybe<Scalars['String']>;
  gameId?: InputMaybe<Scalars['String']>;
  isOriginalBet?: InputMaybe<Scalars['Boolean']>;
  isPreviousOriginalBet?: InputMaybe<Scalars['Boolean']>;
  isWinner?: InputMaybe<Scalars['Boolean']>;
  maxAmount?: InputMaybe<Scalars['Float']>;
  minAmount?: InputMaybe<Scalars['Float']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  playerId?: InputMaybe<Scalars['String']>;
  transactionCode?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  usherId?: InputMaybe<Scalars['String']>;
};

export type BetPermutation = {
  __typename?: 'BetPermutation';
  amount: Scalars['Float'];
  betAmountBeforeGreedy?: Maybe<Scalars['Float']>;
  betTypeName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  gameDrawTimeId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOriginalBet?: Maybe<Scalars['Boolean']>;
  isSoldOut?: Maybe<Scalars['Boolean']>;
  numberCombination?: Maybe<Scalars['String']>;
  originalBetAmount: Scalars['Float'];
  returnBetAmount?: Maybe<Scalars['Float']>;
  winAmount?: Maybe<Scalars['Float']>;
};

export type BetSort = {
  direction: SortDirection;
  field: BetSortField;
};

export enum BetSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type BetTransaction = Node & {
  __typename?: 'BetTransaction';
  bet?: Maybe<Bet>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  transaction?: Maybe<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BetTransactionConnection = {
  __typename?: 'BetTransactionConnection';
  edges: Array<Maybe<BetTransactionEdge>>;
  nodes: Array<Maybe<BetTransaction>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BetTransactionEdge = {
  __typename?: 'BetTransactionEdge';
  cursor: Scalars['String'];
  node: BetTransaction;
};

export type BetTransactionSort = {
  direction: SortDirection;
  field: BetTransactionSortField;
};

export enum BetTransactionSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type BetType = Node & {
  __typename?: 'BetType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  payout?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BetTypeConnection = {
  __typename?: 'BetTypeConnection';
  edges: Array<Maybe<BetTypeEdge>>;
  nodes: Array<Maybe<BetType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BetTypeEdge = {
  __typename?: 'BetTypeEdge';
  cursor: Scalars['String'];
  node: BetType;
};

export type BetTypeSort = {
  direction: SortDirection;
  field: BetTypeSortField;
};

export enum BetTypeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type ClaimedHit = Node & {
  __typename?: 'ClaimedHit';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  transaction?: Maybe<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type ConfirmUsherApplicantInput = {
  address: Scalars['String'];
  contactNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  uniqueCode: Scalars['String'];
  username: Scalars['String'];
};

export type CoordCollectionReport = {
  __typename?: 'CoordCollectionReport';
  collections: Array<CoordinatorDailyCollection>;
  forwardBalance: Scalars['Float'];
};

export type Coordinator = Node & {
  __typename?: 'Coordinator';
  area?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefaultOperator?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  noOfUshers?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type CoordinatorCommission = Node & {
  __typename?: 'CoordinatorCommission';
  commission?: Maybe<Scalars['Float']>;
  coordinator?: Maybe<Coordinator>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CoordinatorConnection = {
  __typename?: 'CoordinatorConnection';
  edges: Array<Maybe<CoordinatorEdge>>;
  nodes: Array<Maybe<Coordinator>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CoordinatorDailyCollection = {
  __typename?: 'CoordinatorDailyCollection';
  commissionAmount: Scalars['Float'];
  day: Scalars['Int'];
  grossSales: Scalars['Float'];
  hitsAmount: Scalars['Float'];
  netAmount: Scalars['Float'];
  payment: Scalars['Float'];
  runningBalance: Scalars['Float'];
};

export type CoordinatorDailyCollectionFilterField = {
  coordinatorId: Scalars['String'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type CoordinatorEdge = {
  __typename?: 'CoordinatorEdge';
  cursor: Scalars['String'];
  node: Coordinator;
};

export type CoordinatorGameDrawTimeSales = {
  __typename?: 'CoordinatorGameDrawTimeSales';
  drawName: Scalars['String'];
  endDateTime: Scalars['DateTime'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  sales: Scalars['Float'];
};

export type CoordinatorGameSales = {
  __typename?: 'CoordinatorGameSales';
  gameDrawTimeSales: Array<CoordinatorGameDrawTimeSales>;
  name: Scalars['String'];
  totalSales: Scalars['Float'];
};

export type CoordinatorPayment = Node & {
  __typename?: 'CoordinatorPayment';
  coordinator?: Maybe<Coordinator>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  payment?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CoordinatorSalesReport = {
  __typename?: 'CoordinatorSalesReport';
  coordinator: Coordinator;
  coordinatorGameSales?: Maybe<Array<Maybe<CoordinatorGameSales>>>;
  date: Scalars['String'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  totalBets: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalWinner: Scalars['Int'];
  ushersSalesReport: Array<Maybe<UsherSalesReport>>;
};

export type CoordinatorSalesReportConnection = {
  __typename?: 'CoordinatorSalesReportConnection';
  nodes: Array<Maybe<CoordinatorSalesReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CoordinatorSalesReportFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameId?: InputMaybe<Scalars['String']>;
};

export type CoordinatorSort = {
  direction: SortDirection;
  field: CoordinatorSortField;
};

export enum CoordinatorSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type CoordinatorsFilterField = {
  search?: InputMaybe<Scalars['String']>;
};

export type CreateAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CreateAdminPayload = {
  __typename?: 'CreateAdminPayload';
  admin?: Maybe<Admin>;
};

export type CreateAdvanceBetInput = {
  amount: Scalars['Int'];
  betTypeId: Scalars['String'];
  drawTimeId: Scalars['String'];
  gameTypeId: Scalars['String'];
  numberCombination: Scalars['String'];
  playerId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type CreateAdvanceBetPayload = {
  __typename?: 'CreateAdvanceBetPayload';
  advanceBet?: Maybe<AdvanceBet>;
};

export enum CreateBetAppType {
  MobileApp = 'MOBILE_APP',
  WebApp = 'WEB_APP',
}

export type CreateBetInput = {
  amount: Scalars['Int'];
  betTypeId: Scalars['String'];
  gameDrawTimeId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  numberCombination: Scalars['String'];
  playerId?: InputMaybe<Scalars['String']>;
};

export type CreateBetInputCaching = {
  amount: Scalars['Int'];
  betTypeId: Scalars['String'];
  numberCombination: Scalars['String'];
};

export type CreateBetPayload = {
  __typename?: 'CreateBetPayload';
  bets: Array<Maybe<Bet>>;
  errorMessage: Array<Maybe<Scalars['String']>>;
  transactionCode: Scalars['String'];
};

export type CreateBetPayloadCaching = {
  __typename?: 'CreateBetPayloadCaching';
  bets: Array<Maybe<OriginalBet>>;
  errorMessage: Array<Maybe<Scalars['String']>>;
  showUsherNameOnReceipt: Scalars['Boolean'];
  totalAmountReturn: Scalars['Int'];
  totalSales: Scalars['Int'];
  transactionCode: Scalars['String'];
  transactionCreatedAt: Scalars['String'];
  transactionId: Scalars['String'];
};

export type CreateBetTransactionInput = {
  betId: Scalars['String'];
  transactionId: Scalars['String'];
};

export type CreateBetTransactionPayload = {
  __typename?: 'CreateBetTransactionPayload';
  betTransaction?: Maybe<BetTransaction>;
};

export type CreateBetTypeInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  payout: Scalars['Int'];
};

export type CreateBetTypePayload = {
  __typename?: 'CreateBetTypePayload';
  betType: BetType;
};

export type CreateBets = {
  appType?: InputMaybe<CreateBetAppType>;
  appVersion?: InputMaybe<Scalars['String']>;
  createBets: Array<CreateBetInput>;
  totalPayable?: InputMaybe<Scalars['Int']>;
  transaction: CreateTransactionInput;
  uniqueKey: Scalars['String'];
};

export type CreateBetsCaching = {
  allowDoubleTransaction?: InputMaybe<Scalars['Boolean']>;
  appType?: InputMaybe<CreateBetAppType>;
  appVersion?: InputMaybe<Scalars['String']>;
  createBets: Array<CreateBetInputCaching>;
  gameDrawTimeId: Scalars['String'];
  totalPayable?: InputMaybe<Scalars['Int']>;
  transaction: CreateTransactionInput;
  uniqueKey: Scalars['String'];
};

export type CreateClaimedHitInput = {
  transactionId: Scalars['String'];
};

export type CreateClaimedHitPayload = {
  __typename?: 'CreateClaimedHitPayload';
  claimedHit?: Maybe<ClaimedHit>;
};

export type CreateCoordinatorInput = {
  area: Scalars['String'];
  commission: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CreateCoordinatorPayload = {
  __typename?: 'CreateCoordinatorPayload';
  coordinator: Coordinator;
};

export type CreateDeviceInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  simNumber: Scalars['String'];
  usherId?: InputMaybe<Scalars['String']>;
};

export type CreateDeviceLocationInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  deviceId: Scalars['String'];
  isCurrentLocation: Scalars['Boolean'];
};

export type CreateDeviceLocationPayload = {
  __typename?: 'CreateDeviceLocationPayload';
  deviceLocation?: Maybe<DeviceLocation>;
};

export type CreateDevicePayload = {
  __typename?: 'CreateDevicePayload';
  device: Device;
};

export type CreateDrawAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CreateDrawAdminPayload = {
  __typename?: 'CreateDrawAdminPayload';
  drawAdmin?: Maybe<DrawAdmin>;
};

export type CreateDrawTimeInput = {
  cutOff: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
};

export type CreateDrawTimePayload = {
  __typename?: 'CreateDrawTimePayload';
  drawTime: DrawTime;
};

export type CreateFirebaseUsherDeviceTokenInput = {
  token: Scalars['String'];
};

export type CreateFirebaseUsherDeviceTokenPayload = {
  __typename?: 'CreateFirebaseUsherDeviceTokenPayload';
  firebaseUsherDeviceToken?: Maybe<FirebaseUsherDeviceToken>;
};

export type CreateGameConfigInput = {
  combinationLimit: Scalars['Int'];
  globalNumberLimit: Scalars['Int'];
  hasDoubleRepeatingPayout: Scalars['Boolean'];
  hasTripleRepeatingPayout: Scalars['Boolean'];
  isRepeating: Scalars['Boolean'];
  isRumble: Scalars['Boolean'];
  maxNumber: Scalars['Int'];
  minNumber: Scalars['Int'];
  placeValue: Scalars['Int'];
};

export type CreateGameConfigPayload = {
  __typename?: 'CreateGameConfigPayload';
  gameConfig: GameConfig;
};

export type CreateGameDoubleRepeatingPayoutInput = {
  gameTypeId: Scalars['String'];
  payout: Scalars['Float'];
};

export type CreateGameDoubleRepeatingPayoutPayload = {
  __typename?: 'CreateGameDoubleRepeatingPayoutPayload';
  gameDoubleRepeatingPayout?: Maybe<GameDoubleRepeatingPayout>;
};

export type CreateGameDrawTimeInput = {
  cutOff: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  gameId: Scalars['String'];
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
};

export type CreateGameDrawTimePayload = {
  __typename?: 'CreateGameDrawTimePayload';
  gameDrawTime?: Maybe<GameDrawTime>;
};

export type CreateGameDrawTimeScheduleInput = {
  drawCutOffTime: Scalars['DateTime'];
  drawEndTime: Scalars['DateTime'];
  drawStartTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  gameDrawTimeId: Scalars['String'];
  startDateTime: Scalars['DateTime'];
};

export type CreateGameDrawTimeSchedulePayload = {
  __typename?: 'CreateGameDrawTimeSchedulePayload';
  gameDrawTimeSchedule?: Maybe<GameDrawTimeSchedule>;
};

export type CreateGameDrawtimeLimitInput = {
  endDate: Scalars['Date'];
  gameDrawtimeId: Scalars['String'];
  limit: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type CreateGameDrawtimeLimitPayload = {
  __typename?: 'CreateGameDrawtimeLimitPayload';
  gameDrawtimeLimit?: Maybe<GameDrawtimeLimit>;
};

export type CreateGameHotNumberInput = {
  endDate: Scalars['Date'];
  gameId: Scalars['String'];
  numberCombination: Scalars['String'];
  payout: Scalars['Int'];
  salesLimit: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type CreateGameHotNumberPayload = {
  __typename?: 'CreateGameHotNumberPayload';
  gameHotNumber?: Maybe<GameHotNumber>;
};

export type CreateGameInput = {
  description: Scalars['String'];
  drawTimes: Array<CreateDrawTimeInput>;
  gameType: CreateGameTypeInput;
  name: Scalars['String'];
  status: GameStatus;
};

export type CreateGamePayload = {
  __typename?: 'CreateGamePayload';
  game?: Maybe<Game>;
};

export type CreateGamePayoutInput = {
  gameTypeId: Scalars['String'];
  payout: Scalars['Float'];
};

export type CreateGamePayoutPayload = {
  __typename?: 'CreateGamePayoutPayload';
  gamePayout?: Maybe<GamePayout>;
};

export type CreateGameResultInput = {
  date?: InputMaybe<Scalars['Date']>;
  gameDrawTimeId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  resultCombination: Scalars['String'];
};

export type CreateGameResultPayload = {
  __typename?: 'CreateGameResultPayload';
  gameResult?: Maybe<GameResult>;
};

export type CreateGameSalesNumberLimitInput = {
  endDate: Scalars['Date'];
  gameId: Scalars['String'];
  limit: Scalars['Int'];
  numberCombination: Scalars['String'];
  startDate: Scalars['Date'];
};

export type CreateGameSalesNumberLimitPayload = {
  __typename?: 'CreateGameSalesNumberLimitPayload';
  gameSalesNumberLimit?: Maybe<GameSalesNumberLimit>;
};

export type CreateGameTripleRepeatingPayoutInput = {
  gameTypeId: Scalars['String'];
  payout: Scalars['Float'];
};

export type CreateGameTripleRepeatingPayoutPayload = {
  __typename?: 'CreateGameTripleRepeatingPayoutPayload';
  gameTripleRepeatingPayout?: Maybe<GameTripleRepeatingPayout>;
};

export type CreateGameTypeInput = {
  combinationLimit: Scalars['Int'];
  description: Scalars['String'];
  globalNumberLimit: Scalars['Int'];
  hasDoubleRepeatingPayout: Scalars['Boolean'];
  hasTripleRepeatingPayout: Scalars['Boolean'];
  isRepeating: Scalars['Boolean'];
  isRumble: Scalars['Boolean'];
  maxNumber: Scalars['Int'];
  minNumber: Scalars['Int'];
  name: Scalars['String'];
  payout: Scalars['Int'];
  placeValue: Scalars['Int'];
};

export type CreateGameTypePayload = {
  __typename?: 'CreateGameTypePayload';
  gameType: GameType;
};

export type CreateNotificationInput = {
  message: Scalars['String'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type CreateNotificationPayload = {
  __typename?: 'CreateNotificationPayload';
  notification?: Maybe<Notification>;
};

export type CreatePlayerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreatePlayerPayload = {
  __typename?: 'CreatePlayerPayload';
  player: Player;
};

export type CreateSystemRoleInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateSystemRolePayload = {
  __typename?: 'CreateSystemRolePayload';
  systemRole: SystemRole;
};

export type CreateTelegramReportDestinationInput = {
  botName: Scalars['String'];
  botUsername: Scalars['String'];
  chatId: Scalars['String'];
  groupChatName: Scalars['String'];
  telegramReportIds: Array<Scalars['String']>;
  token: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
  topicName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateTelegramReportDestinationPayload = {
  __typename?: 'CreateTelegramReportDestinationPayload';
  isSuccess: Scalars['Boolean'];
};

export type CreateTelegramReportInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  telegramReportTypeId: Scalars['String'];
};

export type CreateTelegramReportPayload = {
  __typename?: 'CreateTelegramReportPayload';
  telegramReport: TelegramReport;
};

export type CreateTelegramReportTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTelegramReportTypePayload = {
  __typename?: 'CreateTelegramReportTypePayload';
  telegramReportType: TelegramReportType;
};

export type CreateTransactionInput = {
  deviceUniqueId: Scalars['String'];
  location?: InputMaybe<LocationData>;
  notes: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateTransactionPayload = {
  __typename?: 'CreateTransactionPayload';
  transaction?: Maybe<Transaction>;
};

export type CreateUsherApplicantInput = {
  address: Scalars['String'];
  contactNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  registrationCode: Scalars['String'];
  username: Scalars['String'];
};

export type CreateUsherApplicantPayload = {
  __typename?: 'CreateUsherApplicantPayload';
  usherApplicant: UsherApplicant;
};

export type CreateUsherInput = {
  address: Scalars['String'];
  contactNumber: Scalars['String'];
  coordinatorId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  uniqueCode: Scalars['String'];
  username: Scalars['String'];
  usherTypeId: Scalars['String'];
};

export type CreateUsherPayload = {
  __typename?: 'CreateUsherPayload';
  usher: Usher;
};

export type DeleteAdminPayload = {
  __typename?: 'DeleteAdminPayload';
  success: Scalars['Boolean'];
};

export type DeleteAdvanceBetPayload = {
  __typename?: 'DeleteAdvanceBetPayload';
  success: Scalars['Boolean'];
};

export type DeleteBetPayload = {
  __typename?: 'DeleteBetPayload';
  success: Scalars['Boolean'];
};

export type DeleteBetTypePayload = {
  __typename?: 'DeleteBetTypePayload';
  success: Scalars['Boolean'];
};

export type DeleteCoordinatorPayload = {
  __typename?: 'DeleteCoordinatorPayload';
  success: Scalars['Boolean'];
};

export type DeleteDeviceLocationPayload = {
  __typename?: 'DeleteDeviceLocationPayload';
  success: Scalars['Boolean'];
};

export type DeleteDevicePayload = {
  __typename?: 'DeleteDevicePayload';
  success: Scalars['Boolean'];
};

export type DeleteDrawTimePayload = {
  __typename?: 'DeleteDrawTimePayload';
  success: Scalars['Boolean'];
};

export type DeleteGameConfigPayload = {
  __typename?: 'DeleteGameConfigPayload';
  success: Scalars['Boolean'];
};

export type DeleteGameDrawtimeLimitPayload = {
  __typename?: 'DeleteGameDrawtimeLimitPayload';
  success: Scalars['Boolean'];
};

export type DeleteGameHotNumberPayload = {
  __typename?: 'DeleteGameHotNumberPayload';
  success: Scalars['Boolean'];
};

export type DeleteGamePayload = {
  __typename?: 'DeleteGamePayload';
  success: Scalars['Boolean'];
};

export type DeleteGameResultPayload = {
  __typename?: 'DeleteGameResultPayload';
  success: Scalars['Boolean'];
};

export type DeleteGameSalesNumberLimitPayload = {
  __typename?: 'DeleteGameSalesNumberLimitPayload';
  success: Scalars['Boolean'];
};

export type DeleteGameTypePayload = {
  __typename?: 'DeleteGameTypePayload';
  success: Scalars['Boolean'];
};

export type DeletePlayerPayload = {
  __typename?: 'DeletePlayerPayload';
  success: Scalars['Boolean'];
};

export type DeleteUsherPayload = {
  __typename?: 'DeleteUsherPayload';
  success: Scalars['Boolean'];
};

export type Device = Node & {
  __typename?: 'Device';
  createdAt?: Maybe<Scalars['DateTime']>;
  currentDeviceLocation?: Maybe<DeviceLocation>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  simNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usher?: Maybe<Usher>;
};

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  edges: Array<Maybe<DeviceEdge>>;
  nodes: Array<Maybe<Device>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  cursor: Scalars['String'];
  node: Device;
};

export type DeviceFilterField = {
  usherId?: InputMaybe<Scalars['String']>;
};

export type DeviceLocation = Node & {
  __typename?: 'DeviceLocation';
  createdAt?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Device>;
  id: Scalars['ID'];
  isCurrentLocation?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceLocationConnection = {
  __typename?: 'DeviceLocationConnection';
  edges: Array<Maybe<DeviceLocationEdge>>;
  nodes: Array<Maybe<DeviceLocation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DeviceLocationEdge = {
  __typename?: 'DeviceLocationEdge';
  cursor: Scalars['String'];
  node: DeviceLocation;
};

export type DeviceLocationFilterField = {
  deviceId?: InputMaybe<Scalars['String']>;
  isCurrentLocation?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  usherId?: InputMaybe<Scalars['String']>;
};

export type DeviceLocationSort = {
  direction: SortDirection;
  field: DeviceLocationSortField;
};

export enum DeviceLocationSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type DeviceSort = {
  direction: SortDirection;
  field: DeviceSortField;
};

export enum DeviceSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type DisabledUsherConnection = {
  __typename?: 'DisabledUsherConnection';
  edges: Array<Maybe<UsherEdge>>;
  nodes: Array<Maybe<Usher>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DisabledUsherSalesReportFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameId?: InputMaybe<Scalars['String']>;
  periodicalType?: InputMaybe<PeriodicalTypeEnum>;
};

export type DisabledUsherUsherSalesReportConnection = {
  __typename?: 'DisabledUsherUsherSalesReportConnection';
  nodes: Array<Maybe<UsherSalesReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DisabledUshersFilterField = {
  coordinatorId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type DrawAdmin = Node & {
  __typename?: 'DrawAdmin';
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type DrawAdminConnection = {
  __typename?: 'DrawAdminConnection';
  edges: Array<Maybe<DrawAdminEdge>>;
  nodes: Array<Maybe<DrawAdmin>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DrawAdminEdge = {
  __typename?: 'DrawAdminEdge';
  cursor: Scalars['String'];
  node: DrawAdmin;
};

export type DrawAdminGame = Node & {
  __typename?: 'DrawAdminGame';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  gameDrawTimes?: Maybe<Array<Maybe<GameDrawTime>>>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<GameStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DrawAdminGameConnection = {
  __typename?: 'DrawAdminGameConnection';
  edges: Array<Maybe<DrawAdminGameEdge>>;
  nodes: Array<Maybe<DrawAdminGame>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DrawAdminGameEdge = {
  __typename?: 'DrawAdminGameEdge';
  cursor: Scalars['String'];
  node: DrawAdminGame;
};

export type DrawAdminSort = {
  direction: SortDirection;
  field: DrawAdminSortField;
};

export enum DrawAdminSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type DrawTime = Node & {
  __typename?: 'DrawTime';
  admin?: Maybe<Admin>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cutOff?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DrawTimeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DrawTimeConnection = {
  __typename?: 'DrawTimeConnection';
  edges: Array<Maybe<DrawTimeEdge>>;
  nodes: Array<Maybe<DrawTime>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DrawTimeEdge = {
  __typename?: 'DrawTimeEdge';
  cursor: Scalars['String'];
  node: DrawTime;
};

export type DrawTimeSort = {
  direction: SortDirection;
  field: DrawTimeSortField;
};

export enum DrawTimeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum DrawTimeStatus {
  Deleted = 'DELETED',
  New = 'NEW',
  Outdated = 'OUTDATED',
  Updated = 'UPDATED',
}

export type DummySubscriptionPayload = {
  __typename?: 'DummySubscriptionPayload';
  dummy?: Maybe<Scalars['String']>;
};

export type EnableDisableGameDrawTimeInput = {
  disable: Scalars['Boolean'];
  id: Scalars['String'];
};

export type EnableDisableUsherInput = {
  status: Scalars['Boolean'];
  usherId: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type FirebaseUsherDeviceToken = Node & {
  __typename?: 'FirebaseUsherDeviceToken';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usher?: Maybe<Usher>;
};

export type Game = Node & {
  __typename?: 'Game';
  admin?: Maybe<Admin>;
  allGamePayouts?: Maybe<Array<Maybe<GamePayoutTypeList>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentGameDrawTime?: Maybe<GameDrawTime>;
  description?: Maybe<Scalars['String']>;
  gameDrawTime?: Maybe<Array<Maybe<GameDrawTime>>>;
  gamePayout?: Maybe<GamePayout>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<GameStatus>;
  todayGameHotNumber?: Maybe<Array<Maybe<GameHotNumber>>>;
  todayGameSoldOutNumberCombinations?: Maybe<Array<Maybe<GameDrawTimeSoldOutNumber>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameConfig = Node & {
  __typename?: 'GameConfig';
  combinationLimit?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  globalNumberLimit?: Maybe<Scalars['Int']>;
  hasDoubleRepeatingPayout?: Maybe<Scalars['Boolean']>;
  hasTripleRepeatingPayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isRepeating?: Maybe<Scalars['Boolean']>;
  isRumble?: Maybe<Scalars['Boolean']>;
  maxNumber?: Maybe<Scalars['Int']>;
  minNumber?: Maybe<Scalars['Int']>;
  placeValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameConfigConnection = {
  __typename?: 'GameConfigConnection';
  edges: Array<Maybe<GameConfigEdge>>;
  nodes: Array<Maybe<GameConfig>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameConfigEdge = {
  __typename?: 'GameConfigEdge';
  cursor: Scalars['String'];
  node: GameConfig;
};

export type GameConfigSort = {
  direction: SortDirection;
  field: GameConfigSortField;
};

export enum GameConfigSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GameConnection = {
  __typename?: 'GameConnection';
  edges: Array<Maybe<GameEdge>>;
  nodes: Array<Maybe<Game>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameDoubleRepeatingPayout = Node & {
  __typename?: 'GameDoubleRepeatingPayout';
  createdAt?: Maybe<Scalars['DateTime']>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  payout?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameDrawTime = Node & {
  __typename?: 'GameDrawTime';
  createdAt?: Maybe<Scalars['DateTime']>;
  drawTime?: Maybe<DrawTime>;
  game?: Maybe<Game>;
  gameDrawTimeReport?: Maybe<GameDrawTimeReport>;
  gameResult?: Maybe<GameResult>;
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  previousDrawTime?: Maybe<DrawTime>;
  status?: Maybe<GameDrawTimeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameDrawTimeConnection = {
  __typename?: 'GameDrawTimeConnection';
  edges: Array<Maybe<GameDrawTimeEdge>>;
  nodes: Array<Maybe<GameDrawTime>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameDrawTimeEdge = {
  __typename?: 'GameDrawTimeEdge';
  cursor: Scalars['String'];
  node: GameDrawTime;
};

export type GameDrawTimeFilter = {
  gameId?: InputMaybe<Scalars['String']>;
};

export type GameDrawTimeReport = {
  __typename?: 'GameDrawTimeReport';
  hits?: Maybe<Scalars['Float']>;
  payableWinner?: Maybe<Scalars['Float']>;
  totalBets?: Maybe<Scalars['Int']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalWinners?: Maybe<Scalars['Int']>;
};

export type GameDrawTimeResultReport = {
  __typename?: 'GameDrawTimeResultReport';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  gameDrawTimeReport?: Maybe<GameDrawTimeReport>;
  id: Scalars['String'];
  resultCombination?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameDrawTimeSales = {
  __typename?: 'GameDrawTimeSales';
  drawName: Scalars['String'];
  endDateTime: Scalars['DateTime'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  sales: Scalars['Float'];
};

export type GameDrawTimeSchedule = Node & {
  __typename?: 'GameDrawTimeSchedule';
  createdAt?: Maybe<Scalars['DateTime']>;
  drawCutOffTime?: Maybe<Scalars['DateTime']>;
  drawEndTime?: Maybe<Scalars['DateTime']>;
  drawStartTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  startDateTime?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameDrawTimeScheduleConnection = {
  __typename?: 'GameDrawTimeScheduleConnection';
  edges: Array<Maybe<GameDrawTimeScheduleEdge>>;
  nodes: Array<Maybe<GameDrawTimeSchedule>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameDrawTimeScheduleEdge = {
  __typename?: 'GameDrawTimeScheduleEdge';
  cursor: Scalars['String'];
  node: GameDrawTimeSchedule;
};

export type GameDrawTimeScheduleFilter = {
  gameId?: InputMaybe<Scalars['String']>;
};

export type GameDrawTimeScheduleSort = {
  direction: SortDirection;
  field: GameDrawTimeScheduleSortField;
};

export enum GameDrawTimeScheduleSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GameDrawTimeSoldOutNumber = {
  __typename?: 'GameDrawTimeSoldOutNumber';
  drawTimeName: Scalars['String'];
  soldOutNumberCombinations?: Maybe<Array<Maybe<SoldOutNumberCombination>>>;
};

export type GameDrawTimeSort = {
  direction: SortDirection;
  field: GameDrawTimeSortField;
};

export enum GameDrawTimeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum GameDrawTimeStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Ongoing = 'ONGOING',
  Pending = 'PENDING',
}

export type GameDrawtimeLimit = Node & {
  __typename?: 'GameDrawtimeLimit';
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['Date']>;
  gameDrawtime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameDrawtimeLimitConnection = {
  __typename?: 'GameDrawtimeLimitConnection';
  edges: Array<Maybe<GameDrawtimeLimitEdge>>;
  nodes: Array<Maybe<GameDrawtimeLimit>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameDrawtimeLimitEdge = {
  __typename?: 'GameDrawtimeLimitEdge';
  cursor: Scalars['String'];
  node: GameDrawtimeLimit;
};

export type GameDrawtimeLimitFilterField = {
  endDate?: InputMaybe<Scalars['Date']>;
  gameId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GameDrawtimeLimitSort = {
  direction: SortDirection;
  field: GameDrawtimeLimitSortField;
};

export enum GameDrawtimeLimitSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GameDrawtimeOverviewReport = {
  __typename?: 'GameDrawtimeOverviewReport';
  drawTime: DrawTime;
  gameResult?: Maybe<GameResult>;
  hits?: Maybe<Scalars['Float']>;
  noOfbets?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

export type GameEdge = {
  __typename?: 'GameEdge';
  cursor: Scalars['String'];
  node: Game;
};

export type GameFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  drawTimeId?: InputMaybe<Scalars['String']>;
  gameTypeId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};

export type GameHotNumber = Node & {
  __typename?: 'GameHotNumber';
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['Date']>;
  game?: Maybe<Game>;
  id: Scalars['ID'];
  numberCombination?: Maybe<Scalars['String']>;
  payout?: Maybe<Scalars['Int']>;
  sales?: Maybe<Scalars['Float']>;
  salesLimit?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameHotNumberConnection = {
  __typename?: 'GameHotNumberConnection';
  edges: Array<Maybe<GameHotNumberEdge>>;
  nodes: Array<Maybe<GameHotNumber>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameHotNumberEdge = {
  __typename?: 'GameHotNumberEdge';
  cursor: Scalars['String'];
  node: GameHotNumber;
};

export type GameHotNumberFilterField = {
  endDate?: InputMaybe<Scalars['Date']>;
  gameDrawTimeId?: InputMaybe<Scalars['String']>;
  gameId?: InputMaybe<Scalars['String']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GameHotNumberSort = {
  direction: SortDirection;
  field: GameHotNumberSortField;
};

export enum GameHotNumberSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GamePayout = Node & {
  __typename?: 'GamePayout';
  createdAt?: Maybe<Scalars['DateTime']>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  payout?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum GamePayoutType {
  Double = 'DOUBLE',
  Standard = 'STANDARD',
  Triple = 'TRIPLE',
}

export type GamePayoutTypeList = {
  __typename?: 'GamePayoutTypeList';
  payout: Scalars['Float'];
  type: GamePayoutType;
};

export type GameReport = {
  __typename?: 'GameReport';
  game: Game;
  gameDrawTimesReport?: Maybe<Array<Maybe<GameDrawtimeOverviewReport>>>;
  hits?: Maybe<Scalars['Float']>;
  noOfbets?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

export type GameResult = Node & {
  __typename?: 'GameResult';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  resultCombination?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameResultConnection = {
  __typename?: 'GameResultConnection';
  edges: Array<Maybe<GameResultEdge>>;
  nodes: Array<Maybe<GameResult>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameResultEdge = {
  __typename?: 'GameResultEdge';
  cursor: Scalars['String'];
  node: GameResult;
};

export type GameResultFilterField = {
  date?: InputMaybe<Scalars['Date']>;
  gameId?: InputMaybe<Scalars['String']>;
  resultCombination?: InputMaybe<Scalars['String']>;
};

export type GameResultReport = Node & {
  __typename?: 'GameResultReport';
  createdAt?: Maybe<Scalars['DateTime']>;
  gameDrawTimeResultReport?: Maybe<Array<Maybe<GameDrawTimeResultReport>>>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<GameStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameResultReportConnection = {
  __typename?: 'GameResultReportConnection';
  edges: Array<Maybe<GameResultReportEdge>>;
  nodes: Array<Maybe<GameResultReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameResultReportEdge = {
  __typename?: 'GameResultReportEdge';
  cursor: Scalars['String'];
  node: GameResultReport;
};

export type GameResultReportFilterField = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};

export type GameResultSort = {
  direction: SortDirection;
  field: GameResultSortField;
};

export enum GameResultSortField {
  CreatedAt = 'CREATED_AT',
  Date = 'DATE',
  UpdatedAt = 'UPDATED_AT',
}

export type GameSalesNumberLimit = Node & {
  __typename?: 'GameSalesNumberLimit';
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['Date']>;
  game?: Maybe<Game>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  numberCombination?: Maybe<Scalars['String']>;
  sales?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameSalesNumberLimitConnection = {
  __typename?: 'GameSalesNumberLimitConnection';
  edges: Array<Maybe<GameSalesNumberLimitEdge>>;
  nodes: Array<Maybe<GameSalesNumberLimit>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameSalesNumberLimitEdge = {
  __typename?: 'GameSalesNumberLimitEdge';
  cursor: Scalars['String'];
  node: GameSalesNumberLimit;
};

export type GameSalesNumberLimitFilterField = {
  endDate?: InputMaybe<Scalars['Date']>;
  gameId?: InputMaybe<Scalars['String']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GameSalesNumberLimitSort = {
  direction: SortDirection;
  field: GameSalesNumberLimitSortField;
};

export enum GameSalesNumberLimitSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GameSalesReport = {
  __typename?: 'GameSalesReport';
  game: Game;
  gameDrawTimeSales?: Maybe<Array<Maybe<GameDrawTimeSales>>>;
  totalBets?: Maybe<Scalars['Float']>;
  totalHits?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type GameSalesReportConnection = {
  __typename?: 'GameSalesReportConnection';
  nodes: Array<Maybe<GameSalesReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameSalesReportEdge = {
  __typename?: 'GameSalesReportEdge';
  cursor: Scalars['String'];
  node: GameSalesReport;
};

export type GameSalesReportFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameId?: InputMaybe<Scalars['String']>;
};

export type GameSort = {
  direction: SortDirection;
  field: GameSortField;
};

export enum GameSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum GameStatus {
  Close = 'CLOSE',
  Open = 'OPEN',
}

export type GameTripleRepeatingPayout = Node & {
  __typename?: 'GameTripleRepeatingPayout';
  createdAt?: Maybe<Scalars['DateTime']>;
  gameType?: Maybe<GameType>;
  id: Scalars['ID'];
  payout?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameType = Node & {
  __typename?: 'GameType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  gameConfig?: Maybe<GameConfig>;
  id: Scalars['ID'];
  latestPayout?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameTypeConnection = {
  __typename?: 'GameTypeConnection';
  edges: Array<Maybe<GameTypeEdge>>;
  nodes: Array<Maybe<GameType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GameTypeEdge = {
  __typename?: 'GameTypeEdge';
  cursor: Scalars['String'];
  node: GameType;
};

export type GameTypeSort = {
  direction: SortDirection;
  field: GameTypeSortField;
};

export enum GameTypeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type GamesOverviewReport = {
  __typename?: 'GamesOverviewReport';
  gameReport?: Maybe<GameReport>;
  totalHits?: Maybe<Scalars['Float']>;
  totalNoOfbets?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type GetCacheTransactionsPayload = {
  __typename?: 'GetCacheTransactionsPayload';
  endCursor: Scalars['Int'];
  hasMoreCache: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  rowPerPage: Scalars['Int'];
  transactions?: Maybe<Array<Maybe<TransactionCache>>>;
};

export type GetTelegramBotUpdatesInput = {
  token: Scalars['String'];
};

export type GetTelegramBotUpdatesPayload = {
  __typename?: 'GetTelegramBotUpdatesPayload';
  botName: Scalars['String'];
  botUsername: Scalars['String'];
  chatId: Scalars['String'];
  groupChatName: Scalars['String'];
  token: Scalars['String'];
  topicId?: Maybe<Scalars['String']>;
  topicName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type GetTelegramReportsFilter = {
  reportTypeId?: InputMaybe<Scalars['String']>;
};

export type GreedySoldOutBet = Node & {
  __typename?: 'GreedySoldOutBet';
  amount?: Maybe<Scalars['Float']>;
  betType?: Maybe<BetType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  isOriginalBet?: Maybe<Scalars['Boolean']>;
  numberCombination?: Maybe<Scalars['String']>;
  originalBetAmount?: Maybe<Scalars['Float']>;
  transaction?: Maybe<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GreedySoldOutBetConnection = {
  __typename?: 'GreedySoldOutBetConnection';
  edges: Array<Maybe<GreedySoldOutBetEdge>>;
  nodes: Array<Maybe<GreedySoldOutBet>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GreedySoldOutBetEdge = {
  __typename?: 'GreedySoldOutBetEdge';
  cursor: Scalars['String'];
  node: GreedySoldOutBet;
};

export type GreedySoldOutBetFilterField = {
  betTypeId?: InputMaybe<Scalars['String']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameDrawTimeId?: InputMaybe<Scalars['String']>;
  gameId?: InputMaybe<Scalars['String']>;
  isOriginalBet?: InputMaybe<Scalars['Boolean']>;
  maxAmount?: InputMaybe<Scalars['Float']>;
  minAmount?: InputMaybe<Scalars['Float']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  transactionCode?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  usherId?: InputMaybe<Scalars['String']>;
};

export type InactiveUshersFilterField = {
  coordinatorId?: InputMaybe<Scalars['String']>;
  daysInactive: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};

export type LastTransaction = {
  __typename?: 'LastTransaction';
  createdAt: Scalars['DateTime'];
  drawName?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalBets?: Maybe<Array<Maybe<OriginalBet>>>;
  reprintedDate?: Maybe<Scalars['DateTime']>;
  showUsherNameOnReceipt: Scalars['Boolean'];
  totalAmountReturn: Scalars['Float'];
  totalSales: Scalars['Float'];
  transactionCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usher?: Maybe<LastTransactionUsherInfo>;
};

export type LastTransactionUsherInfo = {
  __typename?: 'LastTransactionUsherInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Location = Node & {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type LocationData = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  token: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated No longer supported */
  _dummy?: Maybe<Scalars['String']>;
  addCoordinatorPayment: AddCoordinatorPaymentPayload;
  assignUsherCoordinator: Scalars['String'];
  blockAppVersion: AppApk;
  confirmUsherApplicant: User;
  createAdmin: CreateAdminPayload;
  createAdvanceBet: CreateAdvanceBetPayload;
  createBetCaching: CreateBetPayloadCaching;
  createBetTransaction: CreateBetTransactionPayload;
  createClaimedHit: CreateClaimedHitPayload;
  createCoordinator: CreateCoordinatorPayload;
  createDevice: CreateDevicePayload;
  createDeviceLocation: CreateDeviceLocationPayload;
  createDrawAdmin: CreateDrawAdminPayload;
  createDrawTime: CreateDrawTimePayload;
  createFirebaseUsherDeviceToken: CreateFirebaseUsherDeviceTokenPayload;
  createGame: CreateGamePayload;
  createGameConfig: CreateGameConfigPayload;
  createGameDoubleRepeatingPayout: CreateGameDoubleRepeatingPayoutPayload;
  createGameDrawTime: CreateGameDrawTimePayload;
  createGameDrawTimeSchedule: CreateGameDrawTimeSchedulePayload;
  createGameDrawtimeLimit: CreateGameDrawtimeLimitPayload;
  createGameHotNumber: CreateGameHotNumberPayload;
  createGamePayout: CreateGamePayoutPayload;
  createGameResult: CreateGameResultPayload;
  createGameSalesNumberLimit: CreateGameSalesNumberLimitPayload;
  createGameTripleRepeatingPayout: CreateGameTripleRepeatingPayoutPayload;
  createGameType: CreateGameTypePayload;
  createNotification: CreateNotificationPayload;
  createPlayer: CreatePlayerPayload;
  createRegistrationCode: Scalars['Int'];
  createTelegramReport: CreateTelegramReportPayload;
  createTelegramReportDestination: CreateTelegramReportDestinationPayload;
  createTelegramReportType: CreateTelegramReportTypePayload;
  createUsher: CreateUsherPayload;
  createUsherApplicant: CreateUsherApplicantPayload;
  declineUsherApplicant?: Maybe<Scalars['String']>;
  deleteGameDrawTimeSchedule: Scalars['Boolean'];
  deleteGameDrawtimeLimit?: Maybe<Scalars['Boolean']>;
  deleteGameHotNumber?: Maybe<Scalars['Boolean']>;
  deleteGameSalesNumberLimit?: Maybe<Scalars['Boolean']>;
  deleteReportDestination: Scalars['Boolean'];
  disabledDrawAdmin: Scalars['Boolean'];
  enableDisableGameDrawtime: GameDrawTime;
  enableDisableUsher: Scalars['Boolean'];
  getTelegramBotUpdates: Array<Maybe<GetTelegramBotUpdatesPayload>>;
  login: LoginPayload;
  logout?: Maybe<Scalars['String']>;
  multipleUpload: Array<File>;
  readNotification: Scalars['String'];
  singleUpload: File;
  systeConfigReceiptHideShowUsherName: Scalars['String'];
  triggerManualReport: Scalars['Boolean'];
  unassignUsherCoordinator: Scalars['String'];
  updateAdmin: UpdateAdminPayload;
  updateAdminPassword: UpdateAdminPasswordPayload;
  updateAdvanceBet: UpdateAdvanceBetPayload;
  updateCoordinator: UpdateCoordinatorPayload;
  updateCoordinatorCommission: UpdateCoordinatorCommissionPayload;
  updateDevice: UpdateDevicePayload;
  updateDeviceLocation: UpdateDeviceLocationPayload;
  updateDrawAdmin: UpdateDrawAdminPayload;
  updateDrawAdminPassword: UpdateDrawAdminPasswordPayload;
  updateDrawTime: UpdateDrawTimePayload;
  updateGame: UpdateGamePayload;
  updateGameConfig: UpdateGameConfigPayload;
  updateGameDrawtimeLimit: UpdateGameDrawtimeLimitPayload;
  updateGameHotNumber: UpdateGameHotNumberPayload;
  updateGameResult: UpdateGameResultPayload;
  updateGameSalesNumberLimit: UpdateGameSalesNumberLimitPayload;
  updateGameType: UpdateGameTypePayload;
  updatePlayer: UpdatePlayerPayload;
  updateTransactionAccess: UsherTransactionAccessEnum;
  updateUsher: UpdateUsherPayload;
  updateUsherDisableImmunity: Scalars['Boolean'];
  updateUsherNameReceiptVisibility: Scalars['Boolean'];
  updateUsherPassword: UpdateUsherPasswordPayload;
  verifyRegistrationCode: Scalars['Boolean'];
};

export type MutationAddCoordinatorPaymentArgs = {
  input: AddCoordinatorPaymentInput;
};

export type MutationAssignUsherCoordinatorArgs = {
  input: AssignUsherCoordinatorInput;
};

export type MutationBlockAppVersionArgs = {
  id: Scalars['String'];
};

export type MutationConfirmUsherApplicantArgs = {
  input: ConfirmUsherApplicantInput;
};

export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

export type MutationCreateAdvanceBetArgs = {
  input: CreateAdvanceBetInput;
};

export type MutationCreateBetCachingArgs = {
  input: CreateBetsCaching;
};

export type MutationCreateBetTransactionArgs = {
  input: CreateBetTransactionInput;
};

export type MutationCreateClaimedHitArgs = {
  input: CreateClaimedHitInput;
};

export type MutationCreateCoordinatorArgs = {
  input: CreateCoordinatorInput;
};

export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};

export type MutationCreateDeviceLocationArgs = {
  input: CreateDeviceLocationInput;
};

export type MutationCreateDrawAdminArgs = {
  input: CreateDrawAdminInput;
};

export type MutationCreateDrawTimeArgs = {
  input: CreateDrawTimeInput;
};

export type MutationCreateFirebaseUsherDeviceTokenArgs = {
  input: CreateFirebaseUsherDeviceTokenInput;
};

export type MutationCreateGameArgs = {
  input: CreateGameInput;
};

export type MutationCreateGameConfigArgs = {
  input: CreateGameConfigInput;
};

export type MutationCreateGameDoubleRepeatingPayoutArgs = {
  input: CreateGameDoubleRepeatingPayoutInput;
};

export type MutationCreateGameDrawTimeArgs = {
  input: CreateGameDrawTimeInput;
};

export type MutationCreateGameDrawTimeScheduleArgs = {
  input: CreateGameDrawTimeScheduleInput;
};

export type MutationCreateGameDrawtimeLimitArgs = {
  input: CreateGameDrawtimeLimitInput;
};

export type MutationCreateGameHotNumberArgs = {
  input: CreateGameHotNumberInput;
};

export type MutationCreateGamePayoutArgs = {
  input: CreateGamePayoutInput;
};

export type MutationCreateGameResultArgs = {
  input: CreateGameResultInput;
};

export type MutationCreateGameSalesNumberLimitArgs = {
  input: CreateGameSalesNumberLimitInput;
};

export type MutationCreateGameTripleRepeatingPayoutArgs = {
  input: CreateGameTripleRepeatingPayoutInput;
};

export type MutationCreateGameTypeArgs = {
  input: CreateGameTypeInput;
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};

export type MutationCreatePlayerArgs = {
  input: CreatePlayerInput;
};

export type MutationCreateRegistrationCodeArgs = {
  numberOfCodes: Scalars['Int'];
};

export type MutationCreateTelegramReportArgs = {
  input: CreateTelegramReportInput;
};

export type MutationCreateTelegramReportDestinationArgs = {
  input: CreateTelegramReportDestinationInput;
};

export type MutationCreateTelegramReportTypeArgs = {
  input: CreateTelegramReportTypeInput;
};

export type MutationCreateUsherArgs = {
  input: CreateUsherInput;
};

export type MutationCreateUsherApplicantArgs = {
  input: CreateUsherApplicantInput;
};

export type MutationDeclineUsherApplicantArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameDrawTimeScheduleArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameDrawtimeLimitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameHotNumberArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameSalesNumberLimitArgs = {
  id: Scalars['String'];
};

export type MutationDeleteReportDestinationArgs = {
  id: Scalars['String'];
};

export type MutationDisabledDrawAdminArgs = {
  id: Scalars['String'];
};

export type MutationEnableDisableGameDrawtimeArgs = {
  input: EnableDisableGameDrawTimeInput;
};

export type MutationEnableDisableUsherArgs = {
  input: EnableDisableUsherInput;
};

export type MutationGetTelegramBotUpdatesArgs = {
  input: GetTelegramBotUpdatesInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMultipleUploadArgs = {
  files: Array<Scalars['Upload']>;
};

export type MutationReadNotificationArgs = {
  id: Scalars['String'];
};

export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};

export type MutationSysteConfigReceiptHideShowUsherNameArgs = {
  value: Scalars['Boolean'];
};

export type MutationTriggerManualReportArgs = {
  input: TriggerManualReportInput;
};

export type MutationUnassignUsherCoordinatorArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};

export type MutationUpdateAdminPasswordArgs = {
  input: UpdateAdminPasswordInput;
};

export type MutationUpdateAdvanceBetArgs = {
  input: UpdateAdvanceBetInput;
};

export type MutationUpdateCoordinatorArgs = {
  input: UpdateCoordinatorInput;
};

export type MutationUpdateCoordinatorCommissionArgs = {
  input: UpdateCoordinatorCommissionInput;
};

export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};

export type MutationUpdateDeviceLocationArgs = {
  input: UpdateDeviceLocationInput;
};

export type MutationUpdateDrawAdminArgs = {
  input: UpdateDrawAdminInput;
};

export type MutationUpdateDrawAdminPasswordArgs = {
  input: UpdateDrawAdminPasswordInput;
};

export type MutationUpdateDrawTimeArgs = {
  input: UpdateDrawTimeInput;
};

export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};

export type MutationUpdateGameConfigArgs = {
  input: UpdateGameConfigInput;
};

export type MutationUpdateGameDrawtimeLimitArgs = {
  input: UpdateGameDrawtimeLimitInput;
};

export type MutationUpdateGameHotNumberArgs = {
  input: UpdateGameHotNumberInput;
};

export type MutationUpdateGameResultArgs = {
  input: UpdateGameResultInput;
};

export type MutationUpdateGameSalesNumberLimitArgs = {
  input: UpdateGameSalesNumberLimitInput;
};

export type MutationUpdateGameTypeArgs = {
  input: UpdateGameTypeInput;
};

export type MutationUpdatePlayerArgs = {
  input: UpdatePlayerInput;
};

export type MutationUpdateTransactionAccessArgs = {
  input: UpdateTransactionAccessInput;
};

export type MutationUpdateUsherArgs = {
  input: UpdateUsherInput;
};

export type MutationUpdateUsherDisableImmunityArgs = {
  input: UpdateUsherDisableImmunityInput;
};

export type MutationUpdateUsherNameReceiptVisibilityArgs = {
  input: UpdateUsherNameReceiptVisibilityInput;
};

export type MutationUpdateUsherPasswordArgs = {
  input: UpdateUsherPasswordInput;
};

export type MutationVerifyRegistrationCodeArgs = {
  code: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationAdminQuery = Node & {
  __typename?: 'NotificationAdminQuery';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  views?: Maybe<Scalars['Int']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<Maybe<NotificationEdge>>;
  nodes: Array<Maybe<Notification>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NotificationConnectionAdmin = {
  __typename?: 'NotificationConnectionAdmin';
  edges: Array<Maybe<NotificationEdgeAdmin>>;
  nodes: Array<Maybe<NotificationAdminQuery>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export type NotificationEdgeAdmin = {
  __typename?: 'NotificationEdgeAdmin';
  cursor: Scalars['String'];
  node: NotificationAdminQuery;
};

export type NotificationSort = {
  direction: SortDirection;
  field: NotificationSortField;
};

export enum NotificationSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type NotificationsFilterField = {
  search?: InputMaybe<Scalars['String']>;
};

export type NumberCombinationSales = Node & {
  __typename?: 'NumberCombinationSales';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  numberCombination?: Maybe<Scalars['String']>;
  numberOfBets?: Maybe<Scalars['Int']>;
  sales?: Maybe<Scalars['Float']>;
};

export type NumberCombinationSalesEdge = {
  __typename?: 'NumberCombinationSalesEdge';
  cursor: Scalars['String'];
  node: NumberCombinationSales;
};

export type NumberCombinationSalesFilter = {
  date: Scalars['Date'];
  gameDrawTimeId: Scalars['String'];
  gameId?: InputMaybe<Scalars['String']>;
  numberCombination?: InputMaybe<Scalars['String']>;
};

export type NumberCombinationSalesSort = {
  direction: SortDirection;
  field: NumberCombinationSalesSortField;
};

export enum NumberCombinationSalesSortField {
  CreatedAt = 'CREATED_AT',
  Numbercombination = 'NUMBERCOMBINATION',
  NumberOfBets = 'NUMBER_OF_BETS',
  Sale = 'SALE',
  UpdatedAt = 'UPDATED_AT',
}

export type NumberCombinationSoldOut = Node & {
  __typename?: 'NumberCombinationSoldOut';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  gameDrawTime?: Maybe<GameDrawTime>;
  id: Scalars['ID'];
  numberCombination?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NumberCombinationSoldOutConnection = {
  __typename?: 'NumberCombinationSoldOutConnection';
  edges: Array<Maybe<NumberCombinationSoldOutEdge>>;
  nodes: Array<Maybe<NumberCombinationSoldOut>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NumberCombinationSoldOutEdge = {
  __typename?: 'NumberCombinationSoldOutEdge';
  cursor: Scalars['String'];
  node: NumberCombinationSoldOut;
};

export type NumberCombinationSoldOutFilter = {
  date?: InputMaybe<Scalars['Date']>;
  gameDrawTimeId?: InputMaybe<Scalars['String']>;
  gameId?: InputMaybe<Scalars['String']>;
};

export type NumberCombinationSoldOutSort = {
  direction: SortDirection;
  field: NumberCombinationSoldOutSortField;
};

export enum NumberCombinationSoldOutSortField {
  CreatedAt = 'CREATED_AT',
  NumberCombination = 'NUMBER_COMBINATION',
  UpdatedAt = 'UPDATED_AT',
}

export type OriginalBet = {
  __typename?: 'OriginalBet';
  amount?: Maybe<Scalars['Float']>;
  betPermutations?: Maybe<Array<Maybe<BetPermutation>>>;
  betTypeName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSoldOut?: Maybe<Scalars['Boolean']>;
  numberCombination?: Maybe<Scalars['String']>;
  originalBetAmount?: Maybe<Scalars['Float']>;
  returnBetAmount?: Maybe<Scalars['Float']>;
  winAmount?: Maybe<Scalars['Float']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PeriodicSalesReport = {
  __typename?: 'PeriodicSalesReport';
  date: Scalars['String'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  totalBets: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalWinner: Scalars['Int'];
};

export enum PeriodicalTypeEnum {
  Daily = 'DAILY',
}

export type Player = Node & {
  __typename?: 'Player';
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlayerConnection = {
  __typename?: 'PlayerConnection';
  edges: Array<Maybe<PlayerEdge>>;
  nodes: Array<Maybe<Player>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlayerEdge = {
  __typename?: 'PlayerEdge';
  cursor: Scalars['String'];
  node: Player;
};

export type PlayerSort = {
  direction: SortDirection;
  field: PlayerSortField;
};

export enum PlayerSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Query = {
  __typename?: 'Query';
  /** @deprecated No longer supported */
  _dummy?: Maybe<Scalars['String']>;
  admin: Admin;
  admins: AdminConnection;
  advanceBets: AdvanceBetConnection;
  betTransactions: BetTransactionConnection;
  betTypes: BetTypeConnection;
  bets: BetConnection;
  coordinator: Coordinator;
  coordinatorDailyCollection: CoordCollectionReport;
  coordinators: CoordinatorConnection;
  coordinatorsSalesReport: Array<Maybe<CoordinatorSalesReport>>;
  currentGames: GameConnection;
  deviceLocations: DeviceLocationConnection;
  devices: DeviceConnection;
  disabledUshers: DisabledUsherConnection;
  disabledUshersSalesReport: DisabledUsherUsherSalesReportConnection;
  drawAdmin: DrawAdmin;
  drawAdminGames: DrawAdminGameConnection;
  drawAdmins: DrawAdminConnection;
  drawTimes: DrawTimeConnection;
  gameConfig: GameConfigConnection;
  gameDrawTimeSchedules: GameDrawTimeScheduleConnection;
  gameDrawTimes: GameDrawTimeConnection;
  gameDrawtimeLimits: GameDrawtimeLimitConnection;
  gameHotNumbers: GameHotNumberConnection;
  gameResults: GameResultConnection;
  gameResultsReport: GameResultReportConnection;
  gameSalesNumberLimits: GameSalesNumberLimitConnection;
  gameType: GameType;
  gameTypes: GameTypeConnection;
  games: GameConnection;
  gamesOverviewReport?: Maybe<GamesOverviewReport>;
  gamesSalesReport: GameSalesReportConnection;
  getApkLatestVersion: Scalars['String'];
  getCacheTransaction?: Maybe<TransactionCache>;
  getCacheTransactions?: Maybe<GetCacheTransactionsPayload>;
  getClaimedHit: ClaimedHit;
  getInactiveUshers: Array<Maybe<Usher>>;
  getLatestGameDoubleRepeatingPayout?: Maybe<GameDoubleRepeatingPayout>;
  getLatestGameTripleRepeatingPayout?: Maybe<GameTripleRepeatingPayout>;
  greedySoldOutBets: GreedySoldOutBetConnection;
  lastTransaction?: Maybe<LastTransaction>;
  me?: Maybe<User>;
  meDrawAdmin: DrawAdmin;
  meUsher: Usher;
  noGameResults: GameResultConnection;
  notifications: NotificationConnection;
  notificationsAdminQuery: NotificationConnectionAdmin;
  numberCombinationSales: Array<Maybe<NumberCombinationSales>>;
  numberCombinationSoldOut: NumberCombinationSoldOutConnection;
  openGames: GameConnection;
  players: PlayerConnection;
  recentTransactions: RecentTransactionConnection;
  registrationCodes: RegistrationCodeConnection;
  showUsherNameOnReceipt: Scalars['Boolean'];
  systemRoles: SystemRoleConnection;
  telegramBots: Array<Scalars['String']>;
  telegramGroupChats: Array<Scalars['String']>;
  telegramReportDestinations: TelegramReportDestinationConnection;
  telegramReportTypes: TelegramReportTypeConnection;
  telegramReports: TelegramReportConnection;
  telegramTopics: Array<Scalars['String']>;
  transaction: Transaction;
  transactionByTransactionCode: Transaction;
  transactions: TransactionConnection;
  uploadedAppApk: AppApkConnection;
  users: UserConnection;
  usherApplicants: UsherApplicantConnection;
  usherSalesReport: UsherSalesReport;
  usherSalesReportPresent: UsherSalesReportPresent;
  usherTypes: UsherTypeConnection;
  usherUnreadNotificationsCount?: Maybe<Scalars['Int']>;
  ushers: UsherConnection;
  ushersNoCoordinator: UsherConnection;
  ushersSalesReport: UsherUsherSalesReportConnection;
  winnerTransactions: WinnerTransactionConnection;
};

export type QueryAdminArgs = {
  id: Scalars['String'];
};

export type QueryAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AdminSort>;
};

export type QueryAdvanceBetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AdvanceBetSort>;
};

export type QueryBetTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BetTransactionSort>;
};

export type QueryBetTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BetTypeSort>;
};

export type QueryBetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BetFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BetSort>;
};

export type QueryCoordinatorArgs = {
  id: Scalars['String'];
};

export type QueryCoordinatorDailyCollectionArgs = {
  filterBy: CoordinatorDailyCollectionFilterField;
};

export type QueryCoordinatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<CoordinatorsFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CoordinatorSort>;
};

export type QueryCoordinatorsSalesReportArgs = {
  filterBy?: InputMaybe<CoordinatorSalesReportFilterField>;
};

export type QueryCurrentGamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryDeviceLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DeviceLocationFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DeviceLocationSort>;
};

export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DeviceFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DeviceSort>;
};

export type QueryDisabledUshersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DisabledUshersFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryDisabledUshersSalesReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DisabledUsherSalesReportFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryDrawAdminArgs = {
  id: Scalars['String'];
};

export type QueryDrawAdminGamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryDrawAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DrawAdminSort>;
};

export type QueryDrawTimesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DrawTimeSort>;
};

export type QueryGameConfigArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameConfigSort>;
};

export type QueryGameDrawTimeSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameDrawTimeScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameDrawTimeScheduleSort>;
};

export type QueryGameDrawTimesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameDrawTimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameDrawTimeSort>;
};

export type QueryGameDrawtimeLimitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameDrawtimeLimitFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameDrawtimeLimitSort>;
};

export type QueryGameHotNumbersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameHotNumberFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameHotNumberSort>;
};

export type QueryGameResultsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameResultFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameResultSort>;
};

export type QueryGameResultsReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameResultReportFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryGameSalesNumberLimitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameSalesNumberLimitFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSalesNumberLimitSort>;
};

export type QueryGameTypeArgs = {
  id: Scalars['String'];
};

export type QueryGameTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameTypeSort>;
};

export type QueryGamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryGamesOverviewReportArgs = {
  filterBy: GameSalesReportFilterField;
};

export type QueryGamesSalesReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameSalesReportFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryGetApkLatestVersionArgs = {
  appName: AppApkNameEnum;
};

export type QueryGetCacheTransactionArgs = {
  Id: Scalars['String'];
};

export type QueryGetCacheTransactionsArgs = {
  after?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['Int']>;
  loadMore?: InputMaybe<Scalars['Boolean']>;
  rowPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryGetClaimedHitArgs = {
  transactionId: Scalars['String'];
};

export type QueryGetInactiveUshersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy: InactiveUshersFilterField;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryGetLatestGameDoubleRepeatingPayoutArgs = {
  gameTypeId: Scalars['String'];
};

export type QueryGetLatestGameTripleRepeatingPayoutArgs = {
  gameTypeId: Scalars['String'];
};

export type QueryGreedySoldOutBetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GreedySoldOutBetFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BetSort>;
};

export type QueryNoGameResultsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameResultFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameResultSort>;
};

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<NotificationSort>;
};

export type QueryNotificationsAdminQueryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<NotificationsFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<NotificationSort>;
};

export type QueryNumberCombinationSalesArgs = {
  filterBy: NumberCombinationSalesFilter;
};

export type QueryNumberCombinationSoldOutArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<NumberCombinationSoldOutFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<NumberCombinationSoldOutSort>;
};

export type QueryOpenGamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<GameSort>;
};

export type QueryPlayersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PlayerSort>;
};

export type QueryRecentTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TransactionSort>;
};

export type QueryRegistrationCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<RegistrationCodeSort>;
};

export type QuerySystemRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SystemRoleSort>;
};

export type QueryTelegramReportDestinationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<TelegramReportDestinationsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TelegramReportDestinationSort>;
};

export type QueryTelegramReportTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TelegramReportTypeSort>;
};

export type QueryTelegramReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GetTelegramReportsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TelegramReportSort>;
};

export type QueryTransactionArgs = {
  Id: Scalars['String'];
};

export type QueryTransactionByTransactionCodeArgs = {
  transactionCode: Scalars['String'];
};

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TransactionSort>;
};

export type QueryUploadedAppApkArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AppApkSort>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UserSort>;
};

export type QueryUsherApplicantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherApplicantSort>;
};

export type QueryUsherSalesReportArgs = {
  filterBy?: InputMaybe<UsherSalesReportFilterField>;
};

export type QueryUsherSalesReportPresentArgs = {
  filterBy?: InputMaybe<UsherSalesReportFilterField>;
};

export type QueryUsherTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherTypeSort>;
};

export type QueryUshersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UshersFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryUshersNoCoordinatorArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UshersNoCoordinatorFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryUshersSalesReportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UsherSalesReportFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UsherSort>;
};

export type QueryWinnerTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<WinnerTransactionFilterField>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TransactionSort>;
};

export type RecentTransactionConnection = {
  __typename?: 'RecentTransactionConnection';
  edges: Array<Maybe<TransactionEdge>>;
  nodes: Array<Maybe<Transaction>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  profileImageUrl?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
  username: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  success: Scalars['Boolean'];
};

export type RegistrationCode = Node & {
  __typename?: 'RegistrationCode';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isUsed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type RegistrationCodeConnection = {
  __typename?: 'RegistrationCodeConnection';
  edges: Array<Maybe<RegistrationCodeEdge>>;
  nodes: Array<Maybe<RegistrationCode>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RegistrationCodeEdge = {
  __typename?: 'RegistrationCodeEdge';
  cursor: Scalars['String'];
  node: RegistrationCode;
};

export type RegistrationCodeRegistrationCodeConnection = {
  __typename?: 'RegistrationCodeRegistrationCodeConnection';
  nodes: Array<Maybe<RegistrationCode>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RegistrationCodeSort = {
  direction: SortDirection;
  field: RegistrationCodeSortField;
};

export enum RegistrationCodeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type SoldOutNumberCombination = {
  __typename?: 'SoldOutNumberCombination';
  numberCombination: Scalars['String'];
  sales: Scalars['Float'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Subscription = {
  __typename?: 'Subscription';
  /** @deprecated No longer supported */
  _dummy?: Maybe<DummySubscriptionPayload>;
};

export type SystemRole = Node & {
  __typename?: 'SystemRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemRoleConnection = {
  __typename?: 'SystemRoleConnection';
  edges: Array<Maybe<SystemRoleEdge>>;
  nodes: Array<Maybe<SystemRole>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SystemRoleEdge = {
  __typename?: 'SystemRoleEdge';
  cursor: Scalars['String'];
  node: SystemRole;
};

export type SystemRoleSort = {
  direction: SortDirection;
  field: SystemRoleSortField;
};

export enum SystemRoleSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type TelegramReport = Node & {
  __typename?: 'TelegramReport';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  telegramReportType?: Maybe<TelegramReportType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelegramReportConnection = {
  __typename?: 'TelegramReportConnection';
  edges: Array<Maybe<TelegramReportEdge>>;
  nodes: Array<Maybe<TelegramReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TelegramReportDestination = Node & {
  __typename?: 'TelegramReportDestination';
  botName?: Maybe<Scalars['String']>;
  botUsername?: Maybe<Scalars['String']>;
  chatId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  groupChatName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  telegramReport?: Maybe<TelegramReport>;
  token?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  topicName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelegramReportDestinationConnection = {
  __typename?: 'TelegramReportDestinationConnection';
  edges: Array<Maybe<TelegramReportDestinationEdge>>;
  nodes: Array<Maybe<TelegramReportDestination>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TelegramReportDestinationEdge = {
  __typename?: 'TelegramReportDestinationEdge';
  cursor: Scalars['String'];
  node: TelegramReportDestination;
};

export type TelegramReportDestinationSort = {
  direction: SortDirection;
  field: TelegramReportDestinationSortField;
};

export enum TelegramReportDestinationSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type TelegramReportDestinationsFilter = {
  botName?: InputMaybe<Scalars['String']>;
  groupChatName?: InputMaybe<Scalars['String']>;
  reportTypeId?: InputMaybe<Scalars['String']>;
  topicName?: InputMaybe<Scalars['String']>;
};

export type TelegramReportEdge = {
  __typename?: 'TelegramReportEdge';
  cursor: Scalars['String'];
  node: TelegramReport;
};

export type TelegramReportSort = {
  direction: SortDirection;
  field: TelegramReportSortField;
};

export enum TelegramReportSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type TelegramReportType = Node & {
  __typename?: 'TelegramReportType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelegramReportTypeConnection = {
  __typename?: 'TelegramReportTypeConnection';
  edges: Array<Maybe<TelegramReportTypeEdge>>;
  nodes: Array<Maybe<TelegramReportType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TelegramReportTypeEdge = {
  __typename?: 'TelegramReportTypeEdge';
  cursor: Scalars['String'];
  node: TelegramReportType;
};

export type TelegramReportTypeSort = {
  direction: SortDirection;
  field: TelegramReportTypeSortField;
};

export enum TelegramReportTypeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Transaction = Node & {
  __typename?: 'Transaction';
  betTransactions?: Maybe<Array<Maybe<BetTransaction>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  drawName?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  hasWinner?: Maybe<Scalars['Boolean']>;
  hits?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isInClaimedHits?: Maybe<Scalars['Boolean']>;
  location?: Maybe<TransactionLocation>;
  notes?: Maybe<Scalars['String']>;
  originalBets?: Maybe<Array<Maybe<OriginalBet>>>;
  showUsherNameOnReceipt?: Maybe<Scalars['Boolean']>;
  totalAmountReturn?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  transactionCode?: Maybe<Scalars['String']>;
  transactionNumber?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usher?: Maybe<Usher>;
};

export type TransactionCache = {
  __typename?: 'TransactionCache';
  createdAt: Scalars['String'];
  drawName?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalBets?: Maybe<Array<Maybe<OriginalBet>>>;
  reprintedDate?: Maybe<Scalars['DateTime']>;
  showUsherNameOnReceipt: Scalars['Boolean'];
  totalAmountReturn: Scalars['Float'];
  totalSales: Scalars['Float'];
  transactionCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  usher?: Maybe<LastTransactionUsherInfo>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges: Array<Maybe<TransactionEdge>>;
  nodes: Array<Maybe<Transaction>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['String'];
  node: Transaction;
};

export type TransactionLocation = Node & {
  __typename?: 'TransactionLocation';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionSort = {
  direction: SortDirection;
  field: TransactionSortField;
};

export enum TransactionSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type TriggerManualReportInput = {
  date: Scalars['Date'];
  gameDrawtTimeId: Scalars['String'];
  reportTypeIds: Array<Scalars['String']>;
};

export type UpdateAdminInput = {
  adminId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateAdminPasswordInput = {
  adminId: Scalars['String'];
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateAdminPasswordPayload = {
  __typename?: 'UpdateAdminPasswordPayload';
  message: Scalars['String'];
};

export type UpdateAdminPayload = {
  __typename?: 'UpdateAdminPayload';
  admin: Admin;
};

export type UpdateAdvanceBetInput = {
  amount?: InputMaybe<Scalars['Int']>;
  betTypeId?: InputMaybe<Scalars['String']>;
  drawTimeId?: InputMaybe<Scalars['String']>;
  gameTypeId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  numberCombination?: InputMaybe<Scalars['String']>;
  playerId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateAdvanceBetPayload = {
  __typename?: 'UpdateAdvanceBetPayload';
  advanceBet?: Maybe<AdvanceBet>;
};

export type UpdateBetInput = {
  amount?: InputMaybe<Scalars['Int']>;
  betTypeId?: InputMaybe<Scalars['String']>;
  gameDrawTimeId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  playerId?: InputMaybe<Scalars['String']>;
};

export type UpdateBetPayload = {
  __typename?: 'UpdateBetPayload';
  bet?: Maybe<Bet>;
};

export type UpdateBetTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  payout?: InputMaybe<Scalars['Int']>;
};

export type UpdateBetTypePayload = {
  __typename?: 'UpdateBetTypePayload';
  betType?: Maybe<BetType>;
};

export type UpdateCoordinatorCommissionInput = {
  commission: Scalars['Float'];
  coordinatorId: Scalars['String'];
};

export type UpdateCoordinatorCommissionPayload = {
  __typename?: 'UpdateCoordinatorCommissionPayload';
  coordinatorCommission?: Maybe<CoordinatorCommission>;
};

export type UpdateCoordinatorInput = {
  area?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UpdateCoordinatorPayload = {
  __typename?: 'UpdateCoordinatorPayload';
  coordinator?: Maybe<Coordinator>;
};

export type UpdateDeviceInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  simNumber: Scalars['String'];
  usherId?: InputMaybe<Scalars['String']>;
};

export type UpdateDeviceLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isCurrentLocation?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDeviceLocationPayload = {
  __typename?: 'UpdateDeviceLocationPayload';
  deviceLocation?: Maybe<DeviceLocation>;
};

export type UpdateDevicePayload = {
  __typename?: 'UpdateDevicePayload';
  device?: Maybe<Device>;
};

export type UpdateDrawAdminInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateDrawAdminPasswordInput = {
  drawDrawAdminId: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateDrawAdminPasswordPayload = {
  __typename?: 'UpdateDrawAdminPasswordPayload';
  message: Scalars['String'];
};

export type UpdateDrawAdminPayload = {
  __typename?: 'UpdateDrawAdminPayload';
  drawAdmin: DrawAdmin;
};

export type UpdateDrawTimeInput = {
  cutOff?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateDrawTimePayload = {
  __typename?: 'UpdateDrawTimePayload';
  drawTime?: Maybe<DrawTime>;
};

export type UpdateGameConfigInput = {
  combinationLimit?: InputMaybe<Scalars['Int']>;
  globalNumberLimit?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  isRepeating?: InputMaybe<Scalars['Boolean']>;
  isRumble?: InputMaybe<Scalars['Boolean']>;
  placeValue?: InputMaybe<Scalars['Int']>;
};

export type UpdateGameConfigPayload = {
  __typename?: 'UpdateGameConfigPayload';
  gameConfig?: Maybe<GameConfig>;
};

export type UpdateGameDrawtimeLimitInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  gameDrawtimeId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateGameDrawtimeLimitPayload = {
  __typename?: 'UpdateGameDrawtimeLimitPayload';
  gameDrawtimeLimit?: Maybe<GameDrawtimeLimit>;
};

export type UpdateGameHotNumberInput = {
  endDate: Scalars['Date'];
  gameId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  numberCombination?: InputMaybe<Scalars['String']>;
  payout?: InputMaybe<Scalars['Int']>;
  salesLimit?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['Date'];
};

export type UpdateGameHotNumberPayload = {
  __typename?: 'UpdateGameHotNumberPayload';
  gameHotNumber?: Maybe<GameHotNumber>;
};

export type UpdateGameInput = {
  description?: InputMaybe<Scalars['String']>;
  drawTimeId?: InputMaybe<Scalars['String']>;
  gameTypeId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};

export type UpdateGamePayload = {
  __typename?: 'UpdateGamePayload';
  game?: Maybe<Game>;
};

export type UpdateGameResultInput = {
  gameDrawTimeId: Scalars['String'];
  id: Scalars['String'];
  resultCombination: Scalars['String'];
};

export type UpdateGameResultPayload = {
  __typename?: 'UpdateGameResultPayload';
  gameResult: GameResult;
};

export type UpdateGameSalesNumberLimitInput = {
  endDate: Scalars['Date'];
  gameId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  numberCombination?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type UpdateGameSalesNumberLimitPayload = {
  __typename?: 'UpdateGameSalesNumberLimitPayload';
  gameSalesNumberLimit?: Maybe<GameSalesNumberLimit>;
};

export type UpdateGameTypeInput = {
  description: Scalars['String'];
  gameConfigId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateGameTypePayload = {
  __typename?: 'UpdateGameTypePayload';
  gameType?: Maybe<GameType>;
};

export type UpdatePlayerInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdatePlayerPayload = {
  __typename?: 'UpdatePlayerPayload';
  player?: Maybe<Player>;
};

export type UpdateTransactionAccessInput = {
  id: Scalars['String'];
  transactionAccess: UsherTransactionAccessEnum;
};

export type UpdateTransactionInput = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};

export type UpdateTransactionPayload = {
  __typename?: 'UpdateTransactionPayload';
  transaction?: Maybe<Transaction>;
};

export type UpdateUsherDisableImmunityInput = {
  id: Scalars['String'];
  immunity: Scalars['Boolean'];
};

export type UpdateUsherInput = {
  address?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  coordinatorId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  uniqueCode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateUsherNameReceiptVisibilityInput = {
  id: Scalars['String'];
  isVisible: Scalars['Boolean'];
};

export type UpdateUsherPasswordInput = {
  password: Scalars['String'];
  usherId: Scalars['String'];
};

export type UpdateUsherPasswordPayload = {
  __typename?: 'UpdateUsherPasswordPayload';
  usher?: Maybe<Usher>;
};

export type UpdateUsherPayload = {
  __typename?: 'UpdateUsherPayload';
  usher?: Maybe<Usher>;
};

export type UploadBets = {
  appType?: InputMaybe<CreateBetAppType>;
  appVersion?: InputMaybe<Scalars['String']>;
  createBets: Array<CreateBetInput>;
  transactionCode: Scalars['String'];
  transactionInput: CreateTransactionInput;
};

export type User = Node & {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  lastDisabledDatetime?: Maybe<Scalars['DateTime']>;
  role?: Maybe<SystemRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<UserEdge>>;
  nodes: Array<Maybe<User>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserDisableHistory = Node & {
  __typename?: 'UserDisableHistory';
  action?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fromUser?: Maybe<User>;
  id: Scalars['ID'];
  toUser?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortField;
};

export enum UserSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Usher = Node & {
  __typename?: 'Usher';
  DTlastTransaction?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  coordinator?: Maybe<Coordinator>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  immuneToDisable?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  showUsherNameOnReceipt?: Maybe<Scalars['Boolean']>;
  transactionAccess?: Maybe<UsherTransactionAccessEnum>;
  uniqueCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  usherType?: Maybe<UsherType>;
};

export type UsherApplicant = Node & {
  __typename?: 'UsherApplicant';
  address?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  registrationCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type UsherApplicantConnection = {
  __typename?: 'UsherApplicantConnection';
  edges: Array<Maybe<UsherApplicantEdge>>;
  nodes: Array<Maybe<UsherApplicant>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsherApplicantEdge = {
  __typename?: 'UsherApplicantEdge';
  cursor: Scalars['String'];
  node: UsherApplicant;
};

export type UsherApplicantSort = {
  direction: SortDirection;
  field: UsherApplicantSortField;
};

export enum UsherApplicantSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type UsherApplicantUsherApplicantConnection = {
  __typename?: 'UsherApplicantUsherApplicantConnection';
  nodes: Array<Maybe<UsherApplicant>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsherConnection = {
  __typename?: 'UsherConnection';
  edges: Array<Maybe<UsherEdge>>;
  nodes: Array<Maybe<Usher>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsherEdge = {
  __typename?: 'UsherEdge';
  cursor: Scalars['String'];
  node: Usher;
};

export type UsherGameSales = {
  __typename?: 'UsherGameSales';
  gameDrawTimeSales?: Maybe<Array<Maybe<GameDrawTimeSales>>>;
  name: Scalars['String'];
  totalSales?: Maybe<Scalars['Float']>;
};

export type UsherSalesReport = {
  __typename?: 'UsherSalesReport';
  date: Scalars['String'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  periodicSalesReport?: Maybe<Array<Maybe<PeriodicSalesReport>>>;
  totalBets: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalWinner: Scalars['Int'];
  usher: Usher;
  usherGameSales?: Maybe<Array<Maybe<UsherGameSales>>>;
  winnerBets?: Maybe<Array<Maybe<Bet>>>;
};

export type UsherSalesReportFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  gameId?: InputMaybe<Scalars['String']>;
  periodicalType?: InputMaybe<PeriodicalTypeEnum>;
};

export type UsherSalesReportPresent = {
  __typename?: 'UsherSalesReportPresent';
  date: Scalars['String'];
  hits: Scalars['Float'];
  payableWinner: Scalars['Float'];
  totalBets: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalWinner: Scalars['Int'];
  usher: Usher;
};

export type UsherSort = {
  direction: SortDirection;
  field: UsherSortField;
};

export enum UsherSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum UsherTransactionAccessEnum {
  AppAccess = 'APP_ACCESS',
  AppAndTextAccess = 'APP_AND_TEXT_ACCESS',
  TextAccess = 'TEXT_ACCESS',
}

export type UsherType = Node & {
  __typename?: 'UsherType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsherTypeConnection = {
  __typename?: 'UsherTypeConnection';
  edges: Array<Maybe<UsherTypeEdge>>;
  nodes: Array<Maybe<UsherType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsherTypeEdge = {
  __typename?: 'UsherTypeEdge';
  cursor: Scalars['String'];
  node: UsherType;
};

export type UsherTypeSort = {
  direction: SortDirection;
  field: UsherTypeSortField;
};

export enum UsherTypeSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type UsherUsherSalesReportConnection = {
  __typename?: 'UsherUsherSalesReportConnection';
  nodes: Array<Maybe<UsherSalesReport>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UshersFilterField = {
  coordinatorId?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export type UshersNoCoordinatorFilterField = {
  search?: InputMaybe<Scalars['String']>;
};

export type WinnerTransactionConnection = {
  __typename?: 'WinnerTransactionConnection';
  edges: Array<Maybe<TransactionEdge>>;
  nodes: Array<Maybe<Transaction>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WinnerTransactionFilterField = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};

export type AssignUsherCoordinatorInput = {
  coordinatorId: Scalars['String'];
  usherIds: Array<Scalars['String']>;
};

export type BetsGameDrawTimesQueryVariables = Exact<{
  filterBy?: InputMaybe<GameDrawTimeFilter>;
}>;

export type BetsGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
    } | null>;
  };
};

export type BetsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BetFilterField>;
}>;

export type BetsQuery = {
  __typename?: 'Query';
  bets: {
    __typename?: 'BetConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Bet';
      id: string;
      numberCombination?: string | null;
      originalBetAmount?: number | null;
      amount?: number | null;
      isWinner?: boolean | null;
      createdAt?: any | null;
      betAmountBeforeGreedy?: number | null;
      returnBetAmount?: number | null;
      betDetail?: {
        __typename?: 'BetDetail';
        id: string;
        betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
      } | null;
      transaction?: {
        __typename?: 'Transaction';
        id: string;
        transactionCode?: string | null;
        usher?: { __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null;
      } | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        status?: GameDrawTimeStatus | null;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type BetsDisabledUshersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type BetsDisabledUshersQuery = {
  __typename?: 'Query';
  disabledUshers: {
    __typename?: 'DisabledUsherConnection';
    totalCount: number;
    nodes: Array<{ __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type GreedySoldOutBetsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GreedySoldOutBetFilterField>;
}>;

export type GreedySoldOutBetsQuery = {
  __typename?: 'Query';
  greedySoldOutBets: {
    __typename?: 'GreedySoldOutBetConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GreedySoldOutBet';
      id: string;
      numberCombination?: string | null;
      originalBetAmount?: number | null;
      amount?: number | null;
      createdAt?: any | null;
      betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
      transaction?: {
        __typename?: 'Transaction';
        id: string;
        transactionCode?: string | null;
        usher?: { __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null;
      } | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        status?: GameDrawTimeStatus | null;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type BetsUshersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type BetsUshersQuery = {
  __typename?: 'Query';
  ushers: {
    __typename?: 'UsherConnection';
    totalCount: number;
    nodes: Array<{ __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type TransactionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type TransactionQuery = {
  __typename?: 'Query';
  transaction: {
    __typename?: 'Transaction';
    id: string;
    totalSales?: number | null;
    createdAt?: any | null;
    location?: { __typename?: 'TransactionLocation'; id: string; latitude?: number | null; longitude?: number | null } | null;
    betTransactions?: Array<{
      __typename?: 'BetTransaction';
      id: string;
      bet?: {
        __typename?: 'Bet';
        id: string;
        isOriginalBet?: boolean | null;
        originalBetAmount?: number | null;
        numberCombination?: string | null;
        winAmount?: number | null;
        betDetail?: {
          __typename?: 'BetDetail';
          id: string;
          betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GamesDashboardByGameTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GamesDashboardByGameTypeQuery = {
  __typename?: 'Query';
  games: {
    __typename?: 'GameConnection';
    nodes: Array<{
      __typename?: 'Game';
      id: string;
      gameType?: {
        __typename?: 'GameType';
        id: string;
        name?: string | null;
        latestPayout?: number | null;
        gameConfig?: {
          __typename?: 'GameConfig';
          id: string;
          combinationLimit?: number | null;
          placeValue?: number | null;
          globalNumberLimit?: number | null;
          hasTripleRepeatingPayout?: boolean | null;
          hasDoubleRepeatingPayout?: boolean | null;
        } | null;
      } | null;
    } | null>;
  };
};

export type AddCoordinatorPaymentMutationVariables = Exact<{
  input: AddCoordinatorPaymentInput;
}>;

export type AddCoordinatorPaymentMutation = {
  __typename?: 'Mutation';
  addCoordinatorPayment: {
    __typename?: 'AddCoordinatorPaymentPayload';
    coordinatorPayment?: { __typename?: 'CoordinatorPayment'; id: string } | null;
  };
};

export type AssignUsherCoordinatorMutationVariables = Exact<{
  input: AssignUsherCoordinatorInput;
}>;

export type AssignUsherCoordinatorMutation = { __typename?: 'Mutation'; assignUsherCoordinator: string };

export type CoordinatorDailyCollectionQueryVariables = Exact<{
  filterBy: CoordinatorDailyCollectionFilterField;
}>;

export type CoordinatorDailyCollectionQuery = {
  __typename?: 'Query';
  coordinatorDailyCollection: {
    __typename?: 'CoordCollectionReport';
    forwardBalance: number;
    collections: Array<{
      __typename?: 'CoordinatorDailyCollection';
      day: number;
      grossSales: number;
      commissionAmount: number;
      hitsAmount: number;
      netAmount: number;
      payment: number;
      runningBalance: number;
    }>;
  };
};

export type UshersNoCoordinatorQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UshersNoCoordinatorFilterField>;
}>;

export type UshersNoCoordinatorQuery = {
  __typename?: 'Query';
  ushersNoCoordinator: {
    __typename?: 'UsherConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Usher';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      createdAt?: any | null;
      contactNumber?: string | null;
      uniqueCode?: string | null;
      usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
      user?: { __typename?: 'User'; id: string; isDisabled?: boolean | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type UnassignUsherCoordinatorMutationVariables = Exact<{
  usherIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type UnassignUsherCoordinatorMutation = { __typename?: 'Mutation'; unassignUsherCoordinator: string };

export type CoordinatorQueryVariables = Exact<{
  coordinatorId: Scalars['String'];
}>;

export type CoordinatorQuery = {
  __typename?: 'Query';
  coordinator: {
    __typename?: 'Coordinator';
    id: string;
    name?: string | null;
    area?: string | null;
    isDefaultOperator?: boolean | null;
    commission?: number | null;
  };
};

export type CreateCoordinatorMutationVariables = Exact<{
  input: CreateCoordinatorInput;
}>;

export type CreateCoordinatorMutation = {
  __typename?: 'Mutation';
  createCoordinator: { __typename?: 'CreateCoordinatorPayload'; coordinator: { __typename?: 'Coordinator'; id: string } };
};

export type UpdateCoordinatorCommissionMutationVariables = Exact<{
  input: UpdateCoordinatorCommissionInput;
}>;

export type UpdateCoordinatorCommissionMutation = {
  __typename?: 'Mutation';
  updateCoordinatorCommission: {
    __typename?: 'UpdateCoordinatorCommissionPayload';
    coordinatorCommission?: { __typename?: 'CoordinatorCommission'; id: string } | null;
  };
};

export type CoordinatorsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<CoordinatorsFilterField>;
}>;

export type CoordinatorsQuery = {
  __typename?: 'Query';
  coordinators: {
    __typename?: 'CoordinatorConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Coordinator';
      id: string;
      name?: string | null;
      area?: string | null;
      description?: string | null;
      noOfUshers?: number | null;
      isDefaultOperator?: boolean | null;
      commission?: number | null;
      user?: { __typename?: 'User'; id: string; username?: string | null; email?: string | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type UpdateCoordinatorMutationVariables = Exact<{
  input: UpdateCoordinatorInput;
}>;

export type UpdateCoordinatorMutation = {
  __typename?: 'Mutation';
  updateCoordinator: { __typename?: 'UpdateCoordinatorPayload'; coordinator?: { __typename?: 'Coordinator'; id: string } | null };
};

export type CoordinatorsSalesReportQueryVariables = Exact<{
  filterBy?: InputMaybe<CoordinatorSalesReportFilterField>;
}>;

export type CoordinatorsSalesReportQuery = {
  __typename?: 'Query';
  coordinatorsSalesReport: Array<{
    __typename?: 'CoordinatorSalesReport';
    totalSales: number;
    totalBets: number;
    totalWinner: number;
    payableWinner: number;
    hits: number;
    date: string;
    coordinator: { __typename?: 'Coordinator'; id: string; name?: string | null };
    coordinatorGameSales?: Array<{
      __typename?: 'CoordinatorGameSales';
      name: string;
      totalSales: number;
      gameDrawTimeSales: Array<{
        __typename?: 'CoordinatorGameDrawTimeSales';
        drawName: string;
        sales: number;
        hits: number;
        endDateTime: any;
        payableWinner: number;
      }>;
    } | null> | null;
    ushersSalesReport: Array<{
      __typename?: 'UsherSalesReport';
      totalSales: number;
      totalBets: number;
      hits: number;
      date: string;
      totalWinner: number;
      payableWinner: number;
      usher: {
        __typename?: 'Usher';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        uniqueCode?: string | null;
        coordinator?: { __typename?: 'Coordinator'; id: string } | null;
      };
      usherGameSales?: Array<{
        __typename?: 'UsherGameSales';
        name: string;
        gameDrawTimeSales?: Array<{
          __typename?: 'GameDrawTimeSales';
          drawName: string;
          sales: number;
          endDateTime: any;
          hits: number;
          payableWinner: number;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null>;
};

export type DevelopmentUploadedAppApkQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type DevelopmentUploadedAppApkQuery = {
  __typename?: 'Query';
  uploadedAppApk: {
    __typename?: 'AppApkConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'AppApk';
      id: string;
      originalFileName?: string | null;
      fileSize?: number | null;
      appName?: string | null;
      appVersion?: string | null;
      createdAt?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CreateDeviceMutationVariables = Exact<{
  input: CreateDeviceInput;
}>;

export type CreateDeviceMutation = {
  __typename?: 'Mutation';
  createDevice: {
    __typename?: 'CreateDevicePayload';
    device: {
      __typename?: 'Device';
      id: string;
      name?: string | null;
      simNumber?: string | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    };
  };
};

export type TerminalUshersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type TerminalUshersQuery = {
  __typename?: 'Query';
  ushers: {
    __typename?: 'UsherConnection';
    totalCount: number;
    nodes: Array<{ __typename?: 'Usher'; id: string; firstName?: string | null } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type TableDevicesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DeviceFilterField>;
}>;

export type TableDevicesQuery = {
  __typename?: 'Query';
  devices: {
    __typename?: 'DeviceConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Device';
      id: string;
      name?: string | null;
      simNumber?: string | null;
      description?: string | null;
      usher?: {
        __typename?: 'Usher';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        address?: string | null;
        contactNumber?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CreateDrawAdminMutationVariables = Exact<{
  input: CreateDrawAdminInput;
}>;

export type CreateDrawAdminMutation = {
  __typename?: 'Mutation';
  createDrawAdmin: { __typename?: 'CreateDrawAdminPayload'; drawAdmin?: { __typename?: 'DrawAdmin'; id: string } | null };
};

export type DisabledDrawAdminMutationVariables = Exact<{
  disabledDrawAdminId: Scalars['String'];
}>;

export type DisabledDrawAdminMutation = { __typename?: 'Mutation'; disabledDrawAdmin: boolean };

export type DrawAdminsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type DrawAdminsQuery = {
  __typename?: 'Query';
  drawAdmins: {
    __typename?: 'DrawAdminConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'DrawAdmin';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      createdAt?: any | null;
      user?: { __typename?: 'User'; id: string; username?: string | null; email?: string | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type UpdateDrawAdminPasswordMutationVariables = Exact<{
  input: UpdateDrawAdminPasswordInput;
}>;

export type UpdateDrawAdminPasswordMutation = {
  __typename?: 'Mutation';
  updateDrawAdminPassword: { __typename?: 'UpdateDrawAdminPasswordPayload'; message: string };
};

export type UpdateDrawAdminMutationVariables = Exact<{
  input: UpdateDrawAdminInput;
}>;

export type UpdateDrawAdminMutation = {
  __typename?: 'Mutation';
  updateDrawAdmin: { __typename?: 'UpdateDrawAdminPayload'; drawAdmin: { __typename?: 'DrawAdmin'; id: string } };
};

export type LimitCreateGameDrawtimeLimitMutationVariables = Exact<{
  input: CreateGameDrawtimeLimitInput;
}>;

export type LimitCreateGameDrawtimeLimitMutation = {
  __typename?: 'Mutation';
  createGameDrawtimeLimit: {
    __typename?: 'CreateGameDrawtimeLimitPayload';
    gameDrawtimeLimit?: { __typename?: 'GameDrawtimeLimit'; id: string } | null;
  };
};

export type CreateGameHotNumberMutationVariables = Exact<{
  input: CreateGameHotNumberInput;
}>;

export type CreateGameHotNumberMutation = {
  __typename?: 'Mutation';
  createGameHotNumber: { __typename?: 'CreateGameHotNumberPayload'; gameHotNumber?: { __typename?: 'GameHotNumber'; id: string } | null };
};

export type CreateIndividualLimitMutationVariables = Exact<{
  input: CreateGameSalesNumberLimitInput;
}>;

export type CreateIndividualLimitMutation = {
  __typename?: 'Mutation';
  createGameSalesNumberLimit: {
    __typename?: 'CreateGameSalesNumberLimitPayload';
    gameSalesNumberLimit?: { __typename?: 'GameSalesNumberLimit'; id: string } | null;
  };
};

export type LimitDeleteGameDrawtimeLimitMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type LimitDeleteGameDrawtimeLimitMutation = { __typename?: 'Mutation'; deleteGameDrawtimeLimit?: boolean | null };

export type DeleteGameHotNumberMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteGameHotNumberMutation = { __typename?: 'Mutation'; deleteGameHotNumber?: boolean | null };

export type DeleteGameSalesNumberLimitMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteGameSalesNumberLimitMutation = { __typename?: 'Mutation'; deleteGameSalesNumberLimit?: boolean | null };

export type GameDrawtimeLimitsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameDrawtimeLimitFilterField>;
}>;

export type GameDrawtimeLimitsQuery = {
  __typename?: 'Query';
  gameDrawtimeLimits: {
    __typename?: 'GameDrawtimeLimitConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameDrawtimeLimit';
      id: string;
      limit?: number | null;
      startDate?: any | null;
      endDate?: any | null;
      createdAt?: any | null;
      gameDrawtime?: {
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type LimitsGameDrawTimesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filterBy?: InputMaybe<GameDrawTimeFilter>;
}>;

export type LimitsGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null } | null;
    } | null>;
  };
};

export type GameHotNumbersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameHotNumberFilterField>;
}>;

export type GameHotNumbersQuery = {
  __typename?: 'Query';
  gameHotNumbers: {
    __typename?: 'GameHotNumberConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameHotNumber';
      id: string;
      numberCombination?: string | null;
      payout?: number | null;
      salesLimit?: number | null;
      startDate?: any | null;
      endDate?: any | null;
      sales?: number | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GameSalesNumberLimitsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameSalesNumberLimitFilterField>;
}>;

export type GameSalesNumberLimitsQuery = {
  __typename?: 'Query';
  gameSalesNumberLimits: {
    __typename?: 'GameSalesNumberLimitConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameSalesNumberLimit';
      id: string;
      numberCombination?: string | null;
      limit?: number | null;
      sales?: number | null;
      startDate?: any | null;
      endDate?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GlobalLimitGameTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalLimitGameTypesQuery = {
  __typename?: 'Query';
  gameTypes: {
    __typename?: 'GameTypeConnection';
    nodes: Array<{ __typename?: 'GameType'; gameConfig?: { __typename?: 'GameConfig'; globalNumberLimit?: number | null } | null } | null>;
  };
};

export type GameLimitUpdateGameConfigMutationVariables = Exact<{
  input: UpdateGameConfigInput;
}>;

export type GameLimitUpdateGameConfigMutation = {
  __typename?: 'Mutation';
  updateGameConfig: { __typename?: 'UpdateGameConfigPayload'; gameConfig?: { __typename?: 'GameConfig'; id: string } | null };
};

export type LimitUpdateGameDrawtimeLimitMutationVariables = Exact<{
  input: UpdateGameDrawtimeLimitInput;
}>;

export type LimitUpdateGameDrawtimeLimitMutation = {
  __typename?: 'Mutation';
  updateGameDrawtimeLimit: {
    __typename?: 'UpdateGameDrawtimeLimitPayload';
    gameDrawtimeLimit?: { __typename?: 'GameDrawtimeLimit'; id: string } | null;
  };
};

export type UpdateGameHotNumberMutationVariables = Exact<{
  input: UpdateGameHotNumberInput;
}>;

export type UpdateGameHotNumberMutation = {
  __typename?: 'Mutation';
  updateGameHotNumber: { __typename?: 'UpdateGameHotNumberPayload'; gameHotNumber?: { __typename?: 'GameHotNumber'; id: string } | null };
};

export type UpdateGameSalesNumberLimitMutationVariables = Exact<{
  input: UpdateGameSalesNumberLimitInput;
}>;

export type UpdateGameSalesNumberLimitMutation = {
  __typename?: 'Mutation';
  updateGameSalesNumberLimit: {
    __typename?: 'UpdateGameSalesNumberLimitPayload';
    gameSalesNumberLimit?: { __typename?: 'GameSalesNumberLimit'; id: string } | null;
  };
};

export type CreateGameResultMutationVariables = Exact<{
  input: CreateGameResultInput;
}>;

export type CreateGameResultMutation = {
  __typename?: 'Mutation';
  createGameResult: { __typename?: 'CreateGameResultPayload'; gameResult?: { __typename?: 'GameResult'; id: string } | null };
};

export type PageGameResultGameDrawTimesQueryVariables = Exact<{
  filterBy: GameDrawTimeFilter;
}>;

export type PageGameResultGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      status?: GameDrawTimeStatus | null;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
    } | null>;
  };
};

export type GameResultsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameResultFilterField>;
}>;

export type GameResultsQuery = {
  __typename?: 'Query';
  gameResults: {
    __typename?: 'GameResultConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameResult';
      id: string;
      resultCombination?: string | null;
      date?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type NoGameResultsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GameResultFilterField>;
}>;

export type NoGameResultsQuery = {
  __typename?: 'Query';
  noGameResults: {
    __typename?: 'GameResultConnection';
    nodes: Array<{
      __typename?: 'GameResult';
      id: string;
      resultCombination?: string | null;
      date?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
  };
};

export type UpdateGameResultMutationVariables = Exact<{
  input: UpdateGameResultInput;
}>;

export type UpdateGameResultMutation = {
  __typename?: 'Mutation';
  updateGameResult: { __typename?: 'UpdateGameResultPayload'; gameResult: { __typename?: 'GameResult'; id: string } };
};

export type AdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AdminQuery = {
  __typename?: 'Query';
  admin: { __typename?: 'Admin'; id: string; firstName?: string | null; lastName?: string | null; middleName?: string | null };
};

export type CurrentUserLoginQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserLoginQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    id: string;
    username?: string | null;
    role?: { __typename?: 'SystemRole'; id: string; name?: string | null } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout?: string | null };

export type UpdateAdminPasswordMutationVariables = Exact<{
  input: UpdateAdminPasswordInput;
}>;

export type UpdateAdminPasswordMutation = {
  __typename?: 'Mutation';
  updateAdminPassword: { __typename?: 'UpdateAdminPasswordPayload'; message: string };
};

export type UpdateAdminMutationVariables = Exact<{
  input: UpdateAdminInput;
}>;

export type UpdateAdminMutation = {
  __typename?: 'Mutation';
  updateAdmin: { __typename?: 'UpdateAdminPayload'; admin: { __typename?: 'Admin'; id: string } };
};

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  createNotification: { __typename?: 'CreateNotificationPayload'; notification?: { __typename?: 'Notification'; id: string } | null };
};

export type NotificationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<NotificationsFilterField>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notificationsAdminQuery: {
    __typename?: 'NotificationConnectionAdmin';
    totalCount: number;
    nodes: Array<{
      __typename?: 'NotificationAdminQuery';
      id: string;
      title: string;
      subTitle: string;
      message?: string | null;
      views?: number | null;
      createdAt?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type OpStatsGameDrawTimesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filterBy?: InputMaybe<GameDrawTimeFilter>;
}>;

export type OpStatsGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null } | null;
    } | null>;
  };
};

export type OpStatsNumberCombinationSalesQueryVariables = Exact<{
  filterBy: NumberCombinationSalesFilter;
}>;

export type OpStatsNumberCombinationSalesQuery = {
  __typename?: 'Query';
  numberCombinationSales: Array<{
    __typename?: 'NumberCombinationSales';
    id: string;
    numberCombination?: string | null;
    sales?: number | null;
    numberOfBets?: number | null;
  } | null>;
};

export type OpStatsNumberCombinationsSoldOutQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<NumberCombinationSoldOutFilter>;
}>;

export type OpStatsNumberCombinationsSoldOutQuery = {
  __typename?: 'Query';
  numberCombinationSoldOut: {
    __typename?: 'NumberCombinationSoldOutConnection';
    nodes: Array<{ __typename?: 'NumberCombinationSoldOut'; id: string; numberCombination?: string | null } | null>;
  };
};

export type DrawOverviewGameResultQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
}>;

export type DrawOverviewGameResultQuery = {
  __typename?: 'Query';
  games: {
    __typename?: 'GameConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Game';
      id: string;
      gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null;
      gameDrawTime?: Array<{
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; endDateTime?: any | null; name?: string | null } | null;
        gameResult?: { __typename?: 'GameResult'; id: string; resultCombination?: string | null } | null;
      } | null> | null;
    } | null>;
  };
};

export type OverviewGameDrawTimesResultsQueryVariables = Exact<{
  filterBy?: InputMaybe<GameDrawTimeFilter>;
}>;

export type OverviewGameDrawTimesResultsQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null } | null;
      gameDrawTimeReport?: {
        __typename?: 'GameDrawTimeReport';
        totalSales?: number | null;
        totalBets?: number | null;
        hits?: number | null;
      } | null;
      gameResult?: { __typename?: 'GameResult'; id: string; resultCombination?: string | null } | null;
    } | null>;
  };
};

export type GamesOverviewReportQueryVariables = Exact<{
  filterBy: GameSalesReportFilterField;
}>;

export type GamesOverviewReportQuery = {
  __typename?: 'Query';
  gamesOverviewReport?: {
    __typename?: 'GamesOverviewReport';
    totalSales?: number | null;
    totalHits?: number | null;
    totalNoOfbets?: number | null;
    gameReport?: {
      __typename?: 'GameReport';
      sales?: number | null;
      hits?: number | null;
      noOfbets?: number | null;
      game: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null };
      gameDrawTimesReport?: Array<{
        __typename?: 'GameDrawtimeOverviewReport';
        sales?: number | null;
        hits?: number | null;
        noOfbets?: number | null;
        drawTime: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null };
        gameResult?: { __typename?: 'GameResult'; id: string; resultCombination?: string | null } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GamesSalesReportQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filterBy?: InputMaybe<GameSalesReportFilterField>;
}>;

export type GamesSalesReportQuery = {
  __typename?: 'Query';
  gamesSalesReport: {
    __typename?: 'GameSalesReportConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameSalesReport';
      totalHits?: number | null;
      totalSales?: number | null;
      totalBets?: number | null;
      game: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null };
    } | null>;
  };
};

export type DisabledUshersSalesReportQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DisabledUsherSalesReportFilterField>;
}>;

export type DisabledUshersSalesReportQuery = {
  __typename?: 'Query';
  disabledUshersSalesReport: {
    __typename?: 'DisabledUsherUsherSalesReportConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'UsherSalesReport';
      totalSales: number;
      hits: number;
      totalBets: number;
      totalWinner: number;
      payableWinner: number;
      date: string;
      winnerBets?: Array<{
        __typename?: 'Bet';
        id: string;
        numberCombination?: string | null;
        winAmount?: number | null;
        amount?: number | null;
        createdAt?: any | null;
        betDetail?: {
          __typename?: 'BetDetail';
          id: string;
          betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
        } | null;
        gameDrawTime?: {
          __typename?: 'GameDrawTime';
          id: string;
          game?: {
            __typename?: 'Game';
            id: string;
            gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null;
          } | null;
          drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
        } | null;
        transaction?: {
          __typename?: 'Transaction';
          id: string;
          transactionCode?: string | null;
          usher?: { __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
      } | null> | null;
      usher: {
        __typename?: 'Usher';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        contactNumber?: string | null;
        address?: string | null;
        uniqueCode?: string | null;
        usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
        user?: { __typename?: 'User'; id: string; isDisabled?: boolean | null } | null;
      };
      usherGameSales?: Array<{
        __typename?: 'UsherGameSales';
        name: string;
        gameDrawTimeSales?: Array<{
          __typename?: 'GameDrawTimeSales';
          drawName: string;
          sales: number;
          endDateTime: any;
          hits: number;
          payableWinner: number;
        } | null> | null;
      } | null> | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type ReportsBetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<BetFilterField>;
}>;

export type ReportsBetsQuery = {
  __typename?: 'Query';
  bets: {
    __typename?: 'BetConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Bet';
      id: string;
      numberCombination?: string | null;
      winAmount?: number | null;
      amount?: number | null;
      createdAt?: any | null;
      betDetail?: {
        __typename?: 'BetDetail';
        id: string;
        betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
      } | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        game?: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null } | null;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
      transaction?: {
        __typename?: 'Transaction';
        id: string;
        transactionCode?: string | null;
        usher?: { __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type ReportGameDrawTimesQueryVariables = Exact<{ [key: string]: never }>;

export type ReportGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null } | null;
      game?: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null } | null;
    } | null>;
  };
};

export type UshersSalesReportQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UsherSalesReportFilterField>;
}>;

export type UshersSalesReportQuery = {
  __typename?: 'Query';
  ushersSalesReport: {
    __typename?: 'UsherUsherSalesReportConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'UsherSalesReport';
      totalSales: number;
      hits: number;
      totalBets: number;
      totalWinner: number;
      payableWinner: number;
      date: string;
      winnerBets?: Array<{
        __typename?: 'Bet';
        id: string;
        numberCombination?: string | null;
        winAmount?: number | null;
        amount?: number | null;
        createdAt?: any | null;
        betDetail?: {
          __typename?: 'BetDetail';
          id: string;
          betType?: { __typename?: 'BetType'; id: string; name?: string | null } | null;
        } | null;
        gameDrawTime?: {
          __typename?: 'GameDrawTime';
          id: string;
          game?: {
            __typename?: 'Game';
            id: string;
            gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null;
          } | null;
          drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
        } | null;
        transaction?: {
          __typename?: 'Transaction';
          id: string;
          transactionCode?: string | null;
          usher?: { __typename?: 'Usher'; id: string; firstName?: string | null; lastName?: string | null } | null;
        } | null;
      } | null> | null;
      usher: {
        __typename?: 'Usher';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        contactNumber?: string | null;
        address?: string | null;
        uniqueCode?: string | null;
        usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
        user?: { __typename?: 'User'; id: string; isDisabled?: boolean | null } | null;
      };
      usherGameSales?: Array<{
        __typename?: 'UsherGameSales';
        name: string;
        gameDrawTimeSales?: Array<{
          __typename?: 'GameDrawTimeSales';
          drawName: string;
          sales: number;
          endDateTime: any;
          hits: number;
          payableWinner: number;
        } | null> | null;
      } | null> | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type ServerConfigCreateGameDoubleRepeatingPayoutMutationVariables = Exact<{
  input: CreateGameDoubleRepeatingPayoutInput;
}>;

export type ServerConfigCreateGameDoubleRepeatingPayoutMutation = {
  __typename?: 'Mutation';
  createGameDoubleRepeatingPayout: {
    __typename?: 'CreateGameDoubleRepeatingPayoutPayload';
    gameDoubleRepeatingPayout?: { __typename?: 'GameDoubleRepeatingPayout'; id: string } | null;
  };
};

export type ServerConfigCreateGameDrawTimeMutationVariables = Exact<{
  input: CreateGameDrawTimeInput;
}>;

export type ServerConfigCreateGameDrawTimeMutation = {
  __typename?: 'Mutation';
  createGameDrawTime: { __typename?: 'CreateGameDrawTimePayload'; gameDrawTime?: { __typename?: 'GameDrawTime'; id: string } | null };
};

export type CreateGameDrawTimeScheduleMutationVariables = Exact<{
  input: CreateGameDrawTimeScheduleInput;
}>;

export type CreateGameDrawTimeScheduleMutation = {
  __typename?: 'Mutation';
  createGameDrawTimeSchedule: {
    __typename?: 'CreateGameDrawTimeSchedulePayload';
    gameDrawTimeSchedule?: { __typename?: 'GameDrawTimeSchedule'; id: string } | null;
  };
};

export type ServerConfigCreateGamePayoutMutationVariables = Exact<{
  input: CreateGamePayoutInput;
}>;

export type ServerConfigCreateGamePayoutMutation = {
  __typename?: 'Mutation';
  createGamePayout: { __typename?: 'CreateGamePayoutPayload'; gamePayout?: { __typename?: 'GamePayout'; id: string } | null };
};

export type ServerConfigCreateGameTripleRepeatingPayoutMutationVariables = Exact<{
  input: CreateGameTripleRepeatingPayoutInput;
}>;

export type ServerConfigCreateGameTripleRepeatingPayoutMutation = {
  __typename?: 'Mutation';
  createGameTripleRepeatingPayout: {
    __typename?: 'CreateGameTripleRepeatingPayoutPayload';
    gameTripleRepeatingPayout?: { __typename?: 'GameTripleRepeatingPayout'; id: string } | null;
  };
};

export type DeleteGameDrawTimeScheduleMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteGameDrawTimeScheduleMutation = { __typename?: 'Mutation'; deleteGameDrawTimeSchedule: boolean };

export type EnableDisableGameDrawtimeMutationVariables = Exact<{
  input: EnableDisableGameDrawTimeInput;
}>;

export type EnableDisableGameDrawtimeMutation = {
  __typename?: 'Mutation';
  enableDisableGameDrawtime: { __typename?: 'GameDrawTime'; id: string };
};

export type GameDrawTimeSchedulesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filterBy?: InputMaybe<GameDrawTimeScheduleFilter>;
}>;

export type GameDrawTimeSchedulesQuery = {
  __typename?: 'Query';
  gameDrawTimeSchedules: {
    __typename?: 'GameDrawTimeScheduleConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'GameDrawTimeSchedule';
      id: string;
      startDateTime?: any | null;
      endDateTime?: any | null;
      drawStartTime?: any | null;
      drawEndTime?: any | null;
      drawCutOffTime?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type ServerConfigGameTypeQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ServerConfigGameTypeQuery = {
  __typename?: 'Query';
  gameType: { __typename?: 'GameType'; id: string; latestPayout?: number | null };
};

export type ServerConfigGameDrawTimesQueryVariables = Exact<{
  filterBy: GameDrawTimeFilter;
}>;

export type ServerConfigGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      status?: GameDrawTimeStatus | null;
      isDisabled?: boolean | null;
      drawTime?: {
        __typename?: 'DrawTime';
        id: string;
        name?: string | null;
        startDateTime?: any | null;
        endDateTime?: any | null;
        cutOff?: any | null;
      } | null;
    } | null>;
  };
};

export type ServerConfigGetGameDrawtimeSchedsQueryVariables = Exact<{ [key: string]: never }>;

export type ServerConfigGetGameDrawtimeSchedsQuery = {
  __typename?: 'Query';
  gameDrawTimeSchedules: {
    __typename?: 'GameDrawTimeScheduleConnection';
    nodes: Array<{
      __typename?: 'GameDrawTimeSchedule';
      id: string;
      startDateTime?: any | null;
      endDateTime?: any | null;
      drawStartTime?: any | null;
      drawEndTime?: any | null;
      drawCutOffTime?: any | null;
      gameDrawTime?: {
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null } | null;
      } | null;
    } | null>;
  };
};

export type ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables = Exact<{
  gameTypeId: Scalars['String'];
}>;

export type ServerConfigGetLatestGameDoubleRepeatingPayoutQuery = {
  __typename?: 'Query';
  getLatestGameDoubleRepeatingPayout?: { __typename?: 'GameDoubleRepeatingPayout'; id: string; payout?: number | null } | null;
};

export type ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables = Exact<{
  gameTypeId: Scalars['String'];
}>;

export type ServerConfigGetLatestGameTripleRepeatingPayoutQuery = {
  __typename?: 'Query';
  getLatestGameTripleRepeatingPayout?: { __typename?: 'GameTripleRepeatingPayout'; id: string; payout?: number | null } | null;
};

export type HideShowUsherNameOnReceiptMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type HideShowUsherNameOnReceiptMutation = { __typename?: 'Mutation'; systeConfigReceiptHideShowUsherName: string };

export type ShowUsherNameOnReceiptQueryVariables = Exact<{ [key: string]: never }>;

export type ShowUsherNameOnReceiptQuery = { __typename?: 'Query'; showUsherNameOnReceipt: boolean };

export type ServerConfigUpdateDrawTimeMutationVariables = Exact<{
  input: UpdateDrawTimeInput;
}>;

export type ServerConfigUpdateDrawTimeMutation = {
  __typename?: 'Mutation';
  updateDrawTime: { __typename?: 'UpdateDrawTimePayload'; drawTime?: { __typename?: 'DrawTime'; id: string } | null };
};

export type CreateTelegramReportDestinationMutationVariables = Exact<{
  input: CreateTelegramReportDestinationInput;
}>;

export type CreateTelegramReportDestinationMutation = {
  __typename?: 'Mutation';
  createTelegramReportDestination: { __typename?: 'CreateTelegramReportDestinationPayload'; isSuccess: boolean };
};

export type CreateTelegramReportTypeMutationVariables = Exact<{
  input: CreateTelegramReportTypeInput;
}>;

export type CreateTelegramReportTypeMutation = {
  __typename?: 'Mutation';
  createTelegramReportType: {
    __typename?: 'CreateTelegramReportTypePayload';
    telegramReportType: { __typename?: 'TelegramReportType'; id: string };
  };
};

export type CreateTelegramReportMutationVariables = Exact<{
  input: CreateTelegramReportInput;
}>;

export type CreateTelegramReportMutation = {
  __typename?: 'Mutation';
  createTelegramReport: { __typename?: 'CreateTelegramReportPayload'; telegramReport: { __typename?: 'TelegramReport'; id: string } };
};

export type DeleteReportDestinationMutationVariables = Exact<{
  deleteReportDestinationId: Scalars['String'];
}>;

export type DeleteReportDestinationMutation = { __typename?: 'Mutation'; deleteReportDestination: boolean };

export type GetTelegramBotUpdatesMutationVariables = Exact<{
  input: GetTelegramBotUpdatesInput;
}>;

export type GetTelegramBotUpdatesMutation = {
  __typename?: 'Mutation';
  getTelegramBotUpdates: Array<{
    __typename?: 'GetTelegramBotUpdatesPayload';
    botName: string;
    botUsername: string;
    token: string;
    groupChatName: string;
    chatId: string;
    topicId?: string | null;
    topicName?: string | null;
    type: string;
  } | null>;
};

export type TelegramReportGameDrawTimesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filterBy?: InputMaybe<GameDrawTimeFilter>;
}>;

export type TelegramReportGameDrawTimesQuery = {
  __typename?: 'Query';
  gameDrawTimes: {
    __typename?: 'GameDrawTimeConnection';
    nodes: Array<{
      __typename?: 'GameDrawTime';
      id: string;
      game?: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null } | null;
      drawTime?: { __typename?: 'DrawTime'; id: string; name?: string | null; endDateTime?: any | null } | null;
    } | null>;
  };
};

export type TelegramBotsQueryVariables = Exact<{ [key: string]: never }>;

export type TelegramBotsQuery = { __typename?: 'Query'; telegramBots: Array<string> };

export type TelegramGroupChatsQueryVariables = Exact<{ [key: string]: never }>;

export type TelegramGroupChatsQuery = { __typename?: 'Query'; telegramGroupChats: Array<string> };

export type TelegramReportDestinationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<TelegramReportDestinationsFilter>;
}>;

export type TelegramReportDestinationsQuery = {
  __typename?: 'Query';
  telegramReportDestinations: {
    __typename?: 'TelegramReportDestinationConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'TelegramReportDestination';
      id: string;
      groupChatName?: string | null;
      topicName?: string | null;
      chatId?: string | null;
      topicId?: string | null;
      token?: string | null;
      type?: string | null;
      botName?: string | null;
      createdAt?: any | null;
      telegramReport?: { __typename?: 'TelegramReport'; id: string; name?: string | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type TelegramReportTypesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type TelegramReportTypesQuery = {
  __typename?: 'Query';
  telegramReportTypes: {
    __typename?: 'TelegramReportTypeConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'TelegramReportType';
      id: string;
      name?: string | null;
      description?: string | null;
      createdAt?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type TelegramReportsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<GetTelegramReportsFilter>;
}>;

export type TelegramReportsQuery = {
  __typename?: 'Query';
  telegramReports: {
    __typename?: 'TelegramReportConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'TelegramReport';
      id: string;
      name?: string | null;
      description?: string | null;
      createdAt?: any | null;
      telegramReportType?: { __typename?: 'TelegramReportType'; id: string; name?: string | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type TelegramTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type TelegramTopicsQuery = { __typename?: 'Query'; telegramTopics: Array<string> };

export type TriggerManualReportMutationVariables = Exact<{
  input: TriggerManualReportInput;
}>;

export type TriggerManualReportMutation = { __typename?: 'Mutation'; triggerManualReport: boolean };

export type ConfirmUsherApplicantMutationVariables = Exact<{
  input: ConfirmUsherApplicantInput;
}>;

export type ConfirmUsherApplicantMutation = { __typename?: 'Mutation'; confirmUsherApplicant: { __typename?: 'User'; id: string } };

export type CreateRegistrationCodeMutationVariables = Exact<{
  numberOfCodes: Scalars['Int'];
}>;

export type CreateRegistrationCodeMutation = { __typename?: 'Mutation'; createRegistrationCode: number };

export type CreateUsherMutationVariables = Exact<{
  input: CreateUsherInput;
}>;

export type CreateUsherMutation = {
  __typename?: 'Mutation';
  createUsher: {
    __typename?: 'CreateUsherPayload';
    usher: {
      __typename?: 'Usher';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      address?: string | null;
      contactNumber?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      coordinator?: {
        __typename?: 'Coordinator';
        id: string;
        name?: string | null;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
      usherType?: {
        __typename?: 'UsherType';
        id: string;
        name?: string | null;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
    };
  };
};

export type DeclineUsherApplicantMutationVariables = Exact<{
  Id: Scalars['String'];
}>;

export type DeclineUsherApplicantMutation = { __typename?: 'Mutation'; declineUsherApplicant?: string | null };

export type DisabledUshersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<DisabledUshersFilterField>;
}>;

export type DisabledUshersQuery = {
  __typename?: 'Query';
  disabledUshers: {
    __typename?: 'DisabledUsherConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Usher';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      uniqueCode?: string | null;
      address?: string | null;
      contactNumber?: string | null;
      createdAt?: any | null;
      DTlastTransaction?: any | null;
      immuneToDisable?: boolean | null;
      coordinator?: { __typename?: 'Coordinator'; id: string; name?: string | null } | null;
      usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
      user?: {
        __typename?: 'User';
        id: string;
        username?: string | null;
        email?: string | null;
        isDisabled?: boolean | null;
        lastDisabledDatetime?: any | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type EnableDisableUsherMutationVariables = Exact<{
  input: EnableDisableUsherInput;
}>;

export type EnableDisableUsherMutation = { __typename?: 'Mutation'; enableDisableUsher: boolean };

export type UsherRegistrationCodesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type UsherRegistrationCodesQuery = {
  __typename?: 'Query';
  registrationCodes: {
    __typename?: 'RegistrationCodeConnection';
    totalCount: number;
    nodes: Array<{ __typename?: 'RegistrationCode'; id: string; code?: string | null; createdAt?: any | null } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type UsherApplicantsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type UsherApplicantsQuery = {
  __typename?: 'Query';
  usherApplicants: {
    __typename?: 'UsherApplicantConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'UsherApplicant';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      contactNumber?: string | null;
      address?: string | null;
      username?: string | null;
      email?: string | null;
      registrationCode?: string | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetInactiveUshersQueryVariables = Exact<{
  filterBy: InactiveUshersFilterField;
}>;

export type GetInactiveUshersQuery = {
  __typename?: 'Query';
  getInactiveUshers: Array<{
    __typename?: 'Usher';
    id: string;
    uniqueCode?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    contactNumber?: string | null;
    DTlastTransaction?: any | null;
    transactionAccess?: UsherTransactionAccessEnum | null;
    immuneToDisable?: boolean | null;
    address?: string | null;
    createdAt?: any | null;
    usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
    coordinator?: { __typename?: 'Coordinator'; id: string; name?: string | null } | null;
    user?: { __typename?: 'User'; id: string; email?: string | null; username?: string | null } | null;
  } | null>;
};

export type SelectCoordinatorsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type SelectCoordinatorsQuery = {
  __typename?: 'Query';
  coordinators: {
    __typename?: 'CoordinatorConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Coordinator';
      id: string;
      name?: string | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type SelectUsherTypesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type SelectUsherTypesQuery = {
  __typename?: 'Query';
  usherTypes: {
    __typename?: 'UsherTypeConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'UsherType';
      id: string;
      name?: string | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type TableUshersQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<UshersFilterField>;
}>;

export type TableUshersQuery = {
  __typename?: 'Query';
  ushers: {
    __typename?: 'UsherConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Usher';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      uniqueCode?: string | null;
      address?: string | null;
      contactNumber?: string | null;
      transactionAccess?: UsherTransactionAccessEnum | null;
      showUsherNameOnReceipt?: boolean | null;
      createdAt?: any | null;
      DTlastTransaction?: any | null;
      immuneToDisable?: boolean | null;
      coordinator?: { __typename?: 'Coordinator'; id: string; name?: string | null } | null;
      usherType?: { __typename?: 'UsherType'; id: string; name?: string | null } | null;
      user?: { __typename?: 'User'; id: string; username?: string | null; email?: string | null; isDisabled?: boolean | null } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type UpdateTransactionAccessMutationVariables = Exact<{
  input: UpdateTransactionAccessInput;
}>;

export type UpdateTransactionAccessMutation = { __typename?: 'Mutation'; updateTransactionAccess: UsherTransactionAccessEnum };

export type UpdateUsherDisableImmunityMutationVariables = Exact<{
  input: UpdateUsherDisableImmunityInput;
}>;

export type UpdateUsherDisableImmunityMutation = { __typename?: 'Mutation'; updateUsherDisableImmunity: boolean };

export type UpdateUsherNameReceiptVisibilityMutationVariables = Exact<{
  input: UpdateUsherNameReceiptVisibilityInput;
}>;

export type UpdateUsherNameReceiptVisibilityMutation = { __typename?: 'Mutation'; updateUsherNameReceiptVisibility: boolean };

export type UpdateUsherPasswordMutationVariables = Exact<{
  input: UpdateUsherPasswordInput;
}>;

export type UpdateUsherPasswordMutation = {
  __typename?: 'Mutation';
  updateUsherPassword: { __typename?: 'UpdateUsherPasswordPayload'; usher?: { __typename?: 'Usher'; id: string } | null };
};

export type UpdateUsherMutationVariables = Exact<{
  input: UpdateUsherInput;
}>;

export type UpdateUsherMutation = {
  __typename?: 'Mutation';
  updateUsher: { __typename?: 'UpdateUsherPayload'; usher?: { __typename?: 'Usher'; id: string } | null };
};

export type CreateDrawTimeMutationVariables = Exact<{
  input: CreateDrawTimeInput;
}>;

export type CreateDrawTimeMutation = {
  __typename?: 'Mutation';
  createDrawTime: { __typename?: 'CreateDrawTimePayload'; drawTime: { __typename?: 'DrawTime'; id: string } };
};

export type DrawTimesQueryVariables = Exact<{ [key: string]: never }>;

export type DrawTimesQuery = {
  __typename?: 'Query';
  drawTimes: {
    __typename?: 'DrawTimeConnection';
    nodes: Array<{
      __typename?: 'DrawTime';
      id: string;
      name?: string | null;
      startDateTime?: any | null;
      endDateTime?: any | null;
      status?: DrawTimeStatus | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null>;
  };
};

export type CreateGameMutationVariables = Exact<{
  input: CreateGameInput;
}>;

export type CreateGameMutation = {
  __typename?: 'Mutation';
  createGame: {
    __typename?: 'CreateGamePayload';
    game?: { __typename?: 'Game'; id: string; gameType?: { __typename?: 'GameType'; id: string } | null } | null;
  };
};

export type GamesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type GamesQuery = {
  __typename?: 'Query';
  games: {
    __typename?: 'GameConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Game';
      id: string;
      name?: string | null;
      description?: string | null;
      status?: GameStatus | null;
      createdAt?: any | null;
      gameType?: { __typename?: 'GameType'; id: string; name?: string | null } | null;
      gameDrawTime?: Array<{
        __typename?: 'GameDrawTime';
        id: string;
        drawTime?: {
          __typename?: 'DrawTime';
          id: string;
          name?: string | null;
          status?: DrawTimeStatus | null;
          startDateTime?: any | null;
          endDateTime?: any | null;
        } | null;
      } | null> | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginPayload';
    token: string;
    user: { __typename?: 'User'; id: string; role?: { __typename?: 'SystemRole'; name?: string | null } | null };
  };
};

export const BetsGameDrawTimesDocument = gql`
  query BetsGameDrawTimes($filterBy: GameDrawTimeFilter) {
    gameDrawTimes(filterBy: $filterBy) {
      nodes {
        id
        drawTime {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBetsGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useBetsGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetsGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetsGameDrawTimesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBetsGameDrawTimesQuery(baseOptions?: Apollo.QueryHookOptions<BetsGameDrawTimesQuery, BetsGameDrawTimesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BetsGameDrawTimesQuery, BetsGameDrawTimesQueryVariables>(BetsGameDrawTimesDocument, options);
}
export function useBetsGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BetsGameDrawTimesQuery, BetsGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BetsGameDrawTimesQuery, BetsGameDrawTimesQueryVariables>(BetsGameDrawTimesDocument, options);
}
export type BetsGameDrawTimesQueryHookResult = ReturnType<typeof useBetsGameDrawTimesQuery>;
export type BetsGameDrawTimesLazyQueryHookResult = ReturnType<typeof useBetsGameDrawTimesLazyQuery>;
export type BetsGameDrawTimesQueryResult = Apollo.QueryResult<BetsGameDrawTimesQuery, BetsGameDrawTimesQueryVariables>;
export const BetsDocument = gql`
  query Bets($first: Int!, $after: String, $before: String, $filterBy: BetFilterField) {
    bets(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        numberCombination
        originalBetAmount
        amount
        isWinner
        createdAt
        betAmountBeforeGreedy
        returnBetAmount
        betDetail {
          id
          betType {
            id
            name
          }
        }
        transaction {
          id
          transactionCode
          usher {
            id
            firstName
            lastName
          }
        }
        gameDrawTime {
          id
          status
          drawTime {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useBetsQuery__
 *
 * To run a query within a React component, call `useBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBetsQuery(baseOptions: Apollo.QueryHookOptions<BetsQuery, BetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BetsQuery, BetsQueryVariables>(BetsDocument, options);
}
export function useBetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BetsQuery, BetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BetsQuery, BetsQueryVariables>(BetsDocument, options);
}
export type BetsQueryHookResult = ReturnType<typeof useBetsQuery>;
export type BetsLazyQueryHookResult = ReturnType<typeof useBetsLazyQuery>;
export type BetsQueryResult = Apollo.QueryResult<BetsQuery, BetsQueryVariables>;
export const BetsDisabledUshersDocument = gql`
  query BetsDisabledUshers($first: Int!, $after: String, $before: String) {
    disabledUshers(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
        lastName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useBetsDisabledUshersQuery__
 *
 * To run a query within a React component, call `useBetsDisabledUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetsDisabledUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetsDisabledUshersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBetsDisabledUshersQuery(
  baseOptions: Apollo.QueryHookOptions<BetsDisabledUshersQuery, BetsDisabledUshersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BetsDisabledUshersQuery, BetsDisabledUshersQueryVariables>(BetsDisabledUshersDocument, options);
}
export function useBetsDisabledUshersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BetsDisabledUshersQuery, BetsDisabledUshersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BetsDisabledUshersQuery, BetsDisabledUshersQueryVariables>(BetsDisabledUshersDocument, options);
}
export type BetsDisabledUshersQueryHookResult = ReturnType<typeof useBetsDisabledUshersQuery>;
export type BetsDisabledUshersLazyQueryHookResult = ReturnType<typeof useBetsDisabledUshersLazyQuery>;
export type BetsDisabledUshersQueryResult = Apollo.QueryResult<BetsDisabledUshersQuery, BetsDisabledUshersQueryVariables>;
export const GreedySoldOutBetsDocument = gql`
  query GreedySoldOutBets($first: Int!, $after: String, $before: String, $filterBy: GreedySoldOutBetFilterField) {
    greedySoldOutBets(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        numberCombination
        originalBetAmount
        amount
        createdAt
        betType {
          id
          name
        }
        transaction {
          id
          transactionCode
          usher {
            id
            firstName
            lastName
          }
        }
        gameDrawTime {
          id
          status
          drawTime {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGreedySoldOutBetsQuery__
 *
 * To run a query within a React component, call `useGreedySoldOutBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGreedySoldOutBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGreedySoldOutBetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGreedySoldOutBetsQuery(baseOptions: Apollo.QueryHookOptions<GreedySoldOutBetsQuery, GreedySoldOutBetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GreedySoldOutBetsQuery, GreedySoldOutBetsQueryVariables>(GreedySoldOutBetsDocument, options);
}
export function useGreedySoldOutBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GreedySoldOutBetsQuery, GreedySoldOutBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GreedySoldOutBetsQuery, GreedySoldOutBetsQueryVariables>(GreedySoldOutBetsDocument, options);
}
export type GreedySoldOutBetsQueryHookResult = ReturnType<typeof useGreedySoldOutBetsQuery>;
export type GreedySoldOutBetsLazyQueryHookResult = ReturnType<typeof useGreedySoldOutBetsLazyQuery>;
export type GreedySoldOutBetsQueryResult = Apollo.QueryResult<GreedySoldOutBetsQuery, GreedySoldOutBetsQueryVariables>;
export const BetsUshersDocument = gql`
  query BetsUshers($first: Int!, $after: String, $before: String) {
    ushers(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
        lastName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useBetsUshersQuery__
 *
 * To run a query within a React component, call `useBetsUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetsUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetsUshersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBetsUshersQuery(baseOptions: Apollo.QueryHookOptions<BetsUshersQuery, BetsUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BetsUshersQuery, BetsUshersQueryVariables>(BetsUshersDocument, options);
}
export function useBetsUshersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BetsUshersQuery, BetsUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BetsUshersQuery, BetsUshersQueryVariables>(BetsUshersDocument, options);
}
export type BetsUshersQueryHookResult = ReturnType<typeof useBetsUshersQuery>;
export type BetsUshersLazyQueryHookResult = ReturnType<typeof useBetsUshersLazyQuery>;
export type BetsUshersQueryResult = Apollo.QueryResult<BetsUshersQuery, BetsUshersQueryVariables>;
export const TransactionDocument = gql`
  query Transaction($id: String!) {
    transaction(Id: $id) {
      id
      totalSales
      createdAt
      location {
        id
        latitude
        longitude
      }
      betTransactions {
        id
        bet {
          id
          isOriginalBet
          originalBetAmount
          numberCombination
          winAmount
          betDetail {
            id
            betType {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTransactionQuery__
 *
 * To run a query within a React component, call `useTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionQuery(baseOptions: Apollo.QueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
}
export function useTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
}
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<typeof useTransactionLazyQuery>;
export type TransactionQueryResult = Apollo.QueryResult<TransactionQuery, TransactionQueryVariables>;
export const GamesDashboardByGameTypeDocument = gql`
  query GamesDashboardByGameType {
    games {
      nodes {
        id
        gameType {
          id
          name
          latestPayout
          gameConfig {
            id
            combinationLimit
            placeValue
            globalNumberLimit
            hasTripleRepeatingPayout
            hasDoubleRepeatingPayout
          }
        }
      }
    }
  }
`;

/**
 * __useGamesDashboardByGameTypeQuery__
 *
 * To run a query within a React component, call `useGamesDashboardByGameTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesDashboardByGameTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesDashboardByGameTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamesDashboardByGameTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GamesDashboardByGameTypeQuery, GamesDashboardByGameTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GamesDashboardByGameTypeQuery, GamesDashboardByGameTypeQueryVariables>(GamesDashboardByGameTypeDocument, options);
}
export function useGamesDashboardByGameTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GamesDashboardByGameTypeQuery, GamesDashboardByGameTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GamesDashboardByGameTypeQuery, GamesDashboardByGameTypeQueryVariables>(
    GamesDashboardByGameTypeDocument,
    options,
  );
}
export type GamesDashboardByGameTypeQueryHookResult = ReturnType<typeof useGamesDashboardByGameTypeQuery>;
export type GamesDashboardByGameTypeLazyQueryHookResult = ReturnType<typeof useGamesDashboardByGameTypeLazyQuery>;
export type GamesDashboardByGameTypeQueryResult = Apollo.QueryResult<GamesDashboardByGameTypeQuery, GamesDashboardByGameTypeQueryVariables>;
export const AddCoordinatorPaymentDocument = gql`
  mutation AddCoordinatorPayment($input: AddCoordinatorPaymentInput!) {
    addCoordinatorPayment(input: $input) {
      coordinatorPayment {
        id
      }
    }
  }
`;
export type AddCoordinatorPaymentMutationFn = Apollo.MutationFunction<
  AddCoordinatorPaymentMutation,
  AddCoordinatorPaymentMutationVariables
>;

/**
 * __useAddCoordinatorPaymentMutation__
 *
 * To run a mutation, you first call `useAddCoordinatorPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCoordinatorPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCoordinatorPaymentMutation, { data, loading, error }] = useAddCoordinatorPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCoordinatorPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCoordinatorPaymentMutation, AddCoordinatorPaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCoordinatorPaymentMutation, AddCoordinatorPaymentMutationVariables>(AddCoordinatorPaymentDocument, options);
}
export type AddCoordinatorPaymentMutationHookResult = ReturnType<typeof useAddCoordinatorPaymentMutation>;
export type AddCoordinatorPaymentMutationResult = Apollo.MutationResult<AddCoordinatorPaymentMutation>;
export type AddCoordinatorPaymentMutationOptions = Apollo.BaseMutationOptions<
  AddCoordinatorPaymentMutation,
  AddCoordinatorPaymentMutationVariables
>;
export const AssignUsherCoordinatorDocument = gql`
  mutation AssignUsherCoordinator($input: assignUsherCoordinatorInput!) {
    assignUsherCoordinator(input: $input)
  }
`;
export type AssignUsherCoordinatorMutationFn = Apollo.MutationFunction<
  AssignUsherCoordinatorMutation,
  AssignUsherCoordinatorMutationVariables
>;

/**
 * __useAssignUsherCoordinatorMutation__
 *
 * To run a mutation, you first call `useAssignUsherCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsherCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsherCoordinatorMutation, { data, loading, error }] = useAssignUsherCoordinatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignUsherCoordinatorMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignUsherCoordinatorMutation, AssignUsherCoordinatorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignUsherCoordinatorMutation, AssignUsherCoordinatorMutationVariables>(
    AssignUsherCoordinatorDocument,
    options,
  );
}
export type AssignUsherCoordinatorMutationHookResult = ReturnType<typeof useAssignUsherCoordinatorMutation>;
export type AssignUsherCoordinatorMutationResult = Apollo.MutationResult<AssignUsherCoordinatorMutation>;
export type AssignUsherCoordinatorMutationOptions = Apollo.BaseMutationOptions<
  AssignUsherCoordinatorMutation,
  AssignUsherCoordinatorMutationVariables
>;
export const CoordinatorDailyCollectionDocument = gql`
  query CoordinatorDailyCollection($filterBy: CoordinatorDailyCollectionFilterField!) {
    coordinatorDailyCollection(filterBy: $filterBy) {
      forwardBalance
      collections {
        day
        grossSales
        commissionAmount
        hitsAmount
        netAmount
        payment
        runningBalance
      }
    }
  }
`;

/**
 * __useCoordinatorDailyCollectionQuery__
 *
 * To run a query within a React component, call `useCoordinatorDailyCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorDailyCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorDailyCollectionQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useCoordinatorDailyCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<CoordinatorDailyCollectionQuery, CoordinatorDailyCollectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoordinatorDailyCollectionQuery, CoordinatorDailyCollectionQueryVariables>(
    CoordinatorDailyCollectionDocument,
    options,
  );
}
export function useCoordinatorDailyCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorDailyCollectionQuery, CoordinatorDailyCollectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoordinatorDailyCollectionQuery, CoordinatorDailyCollectionQueryVariables>(
    CoordinatorDailyCollectionDocument,
    options,
  );
}
export type CoordinatorDailyCollectionQueryHookResult = ReturnType<typeof useCoordinatorDailyCollectionQuery>;
export type CoordinatorDailyCollectionLazyQueryHookResult = ReturnType<typeof useCoordinatorDailyCollectionLazyQuery>;
export type CoordinatorDailyCollectionQueryResult = Apollo.QueryResult<
  CoordinatorDailyCollectionQuery,
  CoordinatorDailyCollectionQueryVariables
>;
export const UshersNoCoordinatorDocument = gql`
  query UshersNoCoordinator($first: Int!, $after: String, $before: String, $filterBy: UshersNoCoordinatorFilterField) {
    ushersNoCoordinator(
      filterBy: $filterBy
      first: $first
      after: $after
      before: $before
      sortBy: { direction: DESC, field: CREATED_AT }
    ) {
      nodes {
        id
        firstName
        lastName
        createdAt
        contactNumber
        usherType {
          id
          name
        }
        uniqueCode
        user {
          id
          isDisabled
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useUshersNoCoordinatorQuery__
 *
 * To run a query within a React component, call `useUshersNoCoordinatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUshersNoCoordinatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUshersNoCoordinatorQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useUshersNoCoordinatorQuery(
  baseOptions: Apollo.QueryHookOptions<UshersNoCoordinatorQuery, UshersNoCoordinatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UshersNoCoordinatorQuery, UshersNoCoordinatorQueryVariables>(UshersNoCoordinatorDocument, options);
}
export function useUshersNoCoordinatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UshersNoCoordinatorQuery, UshersNoCoordinatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UshersNoCoordinatorQuery, UshersNoCoordinatorQueryVariables>(UshersNoCoordinatorDocument, options);
}
export type UshersNoCoordinatorQueryHookResult = ReturnType<typeof useUshersNoCoordinatorQuery>;
export type UshersNoCoordinatorLazyQueryHookResult = ReturnType<typeof useUshersNoCoordinatorLazyQuery>;
export type UshersNoCoordinatorQueryResult = Apollo.QueryResult<UshersNoCoordinatorQuery, UshersNoCoordinatorQueryVariables>;
export const UnassignUsherCoordinatorDocument = gql`
  mutation UnassignUsherCoordinator($usherIds: [String!]!) {
    unassignUsherCoordinator(ids: $usherIds)
  }
`;
export type UnassignUsherCoordinatorMutationFn = Apollo.MutationFunction<
  UnassignUsherCoordinatorMutation,
  UnassignUsherCoordinatorMutationVariables
>;

/**
 * __useUnassignUsherCoordinatorMutation__
 *
 * To run a mutation, you first call `useUnassignUsherCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUsherCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUsherCoordinatorMutation, { data, loading, error }] = useUnassignUsherCoordinatorMutation({
 *   variables: {
 *      usherIds: // value for 'usherIds'
 *   },
 * });
 */
export function useUnassignUsherCoordinatorMutation(
  baseOptions?: Apollo.MutationHookOptions<UnassignUsherCoordinatorMutation, UnassignUsherCoordinatorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignUsherCoordinatorMutation, UnassignUsherCoordinatorMutationVariables>(
    UnassignUsherCoordinatorDocument,
    options,
  );
}
export type UnassignUsherCoordinatorMutationHookResult = ReturnType<typeof useUnassignUsherCoordinatorMutation>;
export type UnassignUsherCoordinatorMutationResult = Apollo.MutationResult<UnassignUsherCoordinatorMutation>;
export type UnassignUsherCoordinatorMutationOptions = Apollo.BaseMutationOptions<
  UnassignUsherCoordinatorMutation,
  UnassignUsherCoordinatorMutationVariables
>;
export const CoordinatorDocument = gql`
  query Coordinator($coordinatorId: String!) {
    coordinator(id: $coordinatorId) {
      id
      name
      area
      isDefaultOperator
      commission
    }
  }
`;

/**
 * __useCoordinatorQuery__
 *
 * To run a query within a React component, call `useCoordinatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorQuery({
 *   variables: {
 *      coordinatorId: // value for 'coordinatorId'
 *   },
 * });
 */
export function useCoordinatorQuery(baseOptions: Apollo.QueryHookOptions<CoordinatorQuery, CoordinatorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoordinatorQuery, CoordinatorQueryVariables>(CoordinatorDocument, options);
}
export function useCoordinatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorQuery, CoordinatorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoordinatorQuery, CoordinatorQueryVariables>(CoordinatorDocument, options);
}
export type CoordinatorQueryHookResult = ReturnType<typeof useCoordinatorQuery>;
export type CoordinatorLazyQueryHookResult = ReturnType<typeof useCoordinatorLazyQuery>;
export type CoordinatorQueryResult = Apollo.QueryResult<CoordinatorQuery, CoordinatorQueryVariables>;
export const CreateCoordinatorDocument = gql`
  mutation CreateCoordinator($input: CreateCoordinatorInput!) {
    createCoordinator(input: $input) {
      coordinator {
        id
      }
    }
  }
`;
export type CreateCoordinatorMutationFn = Apollo.MutationFunction<CreateCoordinatorMutation, CreateCoordinatorMutationVariables>;

/**
 * __useCreateCoordinatorMutation__
 *
 * To run a mutation, you first call `useCreateCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoordinatorMutation, { data, loading, error }] = useCreateCoordinatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCoordinatorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCoordinatorMutation, CreateCoordinatorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCoordinatorMutation, CreateCoordinatorMutationVariables>(CreateCoordinatorDocument, options);
}
export type CreateCoordinatorMutationHookResult = ReturnType<typeof useCreateCoordinatorMutation>;
export type CreateCoordinatorMutationResult = Apollo.MutationResult<CreateCoordinatorMutation>;
export type CreateCoordinatorMutationOptions = Apollo.BaseMutationOptions<CreateCoordinatorMutation, CreateCoordinatorMutationVariables>;
export const UpdateCoordinatorCommissionDocument = gql`
  mutation UpdateCoordinatorCommission($input: UpdateCoordinatorCommissionInput!) {
    updateCoordinatorCommission(input: $input) {
      coordinatorCommission {
        id
      }
    }
  }
`;
export type UpdateCoordinatorCommissionMutationFn = Apollo.MutationFunction<
  UpdateCoordinatorCommissionMutation,
  UpdateCoordinatorCommissionMutationVariables
>;

/**
 * __useUpdateCoordinatorCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateCoordinatorCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoordinatorCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoordinatorCommissionMutation, { data, loading, error }] = useUpdateCoordinatorCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoordinatorCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCoordinatorCommissionMutation, UpdateCoordinatorCommissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoordinatorCommissionMutation, UpdateCoordinatorCommissionMutationVariables>(
    UpdateCoordinatorCommissionDocument,
    options,
  );
}
export type UpdateCoordinatorCommissionMutationHookResult = ReturnType<typeof useUpdateCoordinatorCommissionMutation>;
export type UpdateCoordinatorCommissionMutationResult = Apollo.MutationResult<UpdateCoordinatorCommissionMutation>;
export type UpdateCoordinatorCommissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoordinatorCommissionMutation,
  UpdateCoordinatorCommissionMutationVariables
>;
export const CoordinatorsDocument = gql`
  query Coordinators($first: Int!, $after: String, $before: String, $filterBy: CoordinatorsFilterField) {
    coordinators(filterBy: $filterBy, first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        name
        area
        description
        noOfUshers
        isDefaultOperator
        commission
        user {
          id
          username
          email
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useCoordinatorsQuery__
 *
 * To run a query within a React component, call `useCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useCoordinatorsQuery(baseOptions: Apollo.QueryHookOptions<CoordinatorsQuery, CoordinatorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoordinatorsQuery, CoordinatorsQueryVariables>(CoordinatorsDocument, options);
}
export function useCoordinatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorsQuery, CoordinatorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoordinatorsQuery, CoordinatorsQueryVariables>(CoordinatorsDocument, options);
}
export type CoordinatorsQueryHookResult = ReturnType<typeof useCoordinatorsQuery>;
export type CoordinatorsLazyQueryHookResult = ReturnType<typeof useCoordinatorsLazyQuery>;
export type CoordinatorsQueryResult = Apollo.QueryResult<CoordinatorsQuery, CoordinatorsQueryVariables>;
export const UpdateCoordinatorDocument = gql`
  mutation UpdateCoordinator($input: UpdateCoordinatorInput!) {
    updateCoordinator(input: $input) {
      coordinator {
        id
      }
    }
  }
`;
export type UpdateCoordinatorMutationFn = Apollo.MutationFunction<UpdateCoordinatorMutation, UpdateCoordinatorMutationVariables>;

/**
 * __useUpdateCoordinatorMutation__
 *
 * To run a mutation, you first call `useUpdateCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoordinatorMutation, { data, loading, error }] = useUpdateCoordinatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoordinatorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCoordinatorMutation, UpdateCoordinatorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoordinatorMutation, UpdateCoordinatorMutationVariables>(UpdateCoordinatorDocument, options);
}
export type UpdateCoordinatorMutationHookResult = ReturnType<typeof useUpdateCoordinatorMutation>;
export type UpdateCoordinatorMutationResult = Apollo.MutationResult<UpdateCoordinatorMutation>;
export type UpdateCoordinatorMutationOptions = Apollo.BaseMutationOptions<UpdateCoordinatorMutation, UpdateCoordinatorMutationVariables>;
export const CoordinatorsSalesReportDocument = gql`
  query CoordinatorsSalesReport($filterBy: CoordinatorSalesReportFilterField) {
    coordinatorsSalesReport(filterBy: $filterBy) {
      totalSales
      totalBets
      totalWinner
      payableWinner
      hits
      date
      coordinator {
        id
        name
      }
      coordinatorGameSales {
        name
        totalSales
        gameDrawTimeSales {
          drawName
          sales
          hits
          endDateTime
          payableWinner
        }
      }
      ushersSalesReport {
        usher {
          id
          firstName
          lastName
          uniqueCode
          coordinator {
            id
          }
        }
        totalSales
        totalBets
        hits
        date
        totalWinner
        payableWinner
        usherGameSales {
          name
          gameDrawTimeSales {
            drawName
            sales
            endDateTime
            hits
            payableWinner
          }
        }
      }
    }
  }
`;

/**
 * __useCoordinatorsSalesReportQuery__
 *
 * To run a query within a React component, call `useCoordinatorsSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorsSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorsSalesReportQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useCoordinatorsSalesReportQuery(
  baseOptions?: Apollo.QueryHookOptions<CoordinatorsSalesReportQuery, CoordinatorsSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoordinatorsSalesReportQuery, CoordinatorsSalesReportQueryVariables>(CoordinatorsSalesReportDocument, options);
}
export function useCoordinatorsSalesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorsSalesReportQuery, CoordinatorsSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoordinatorsSalesReportQuery, CoordinatorsSalesReportQueryVariables>(CoordinatorsSalesReportDocument, options);
}
export type CoordinatorsSalesReportQueryHookResult = ReturnType<typeof useCoordinatorsSalesReportQuery>;
export type CoordinatorsSalesReportLazyQueryHookResult = ReturnType<typeof useCoordinatorsSalesReportLazyQuery>;
export type CoordinatorsSalesReportQueryResult = Apollo.QueryResult<CoordinatorsSalesReportQuery, CoordinatorsSalesReportQueryVariables>;
export const DevelopmentUploadedAppApkDocument = gql`
  query DevelopmentUploadedAppApk($first: Int!, $after: String, $before: String) {
    uploadedAppApk(first: $first, after: $after, before: $before, sortBy: { field: CREATED_AT, direction: DESC }) {
      nodes {
        id
        originalFileName
        fileSize
        appName
        appVersion
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useDevelopmentUploadedAppApkQuery__
 *
 * To run a query within a React component, call `useDevelopmentUploadedAppApkQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevelopmentUploadedAppApkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevelopmentUploadedAppApkQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDevelopmentUploadedAppApkQuery(
  baseOptions: Apollo.QueryHookOptions<DevelopmentUploadedAppApkQuery, DevelopmentUploadedAppApkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevelopmentUploadedAppApkQuery, DevelopmentUploadedAppApkQueryVariables>(
    DevelopmentUploadedAppApkDocument,
    options,
  );
}
export function useDevelopmentUploadedAppApkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DevelopmentUploadedAppApkQuery, DevelopmentUploadedAppApkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevelopmentUploadedAppApkQuery, DevelopmentUploadedAppApkQueryVariables>(
    DevelopmentUploadedAppApkDocument,
    options,
  );
}
export type DevelopmentUploadedAppApkQueryHookResult = ReturnType<typeof useDevelopmentUploadedAppApkQuery>;
export type DevelopmentUploadedAppApkLazyQueryHookResult = ReturnType<typeof useDevelopmentUploadedAppApkLazyQuery>;
export type DevelopmentUploadedAppApkQueryResult = Apollo.QueryResult<
  DevelopmentUploadedAppApkQuery,
  DevelopmentUploadedAppApkQueryVariables
>;
export const CreateDeviceDocument = gql`
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      device {
        id
        name
        simNumber
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const TerminalUshersDocument = gql`
  query TerminalUshers($first: Int!, $after: String, $before: String) {
    ushers(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useTerminalUshersQuery__
 *
 * To run a query within a React component, call `useTerminalUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalUshersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useTerminalUshersQuery(baseOptions: Apollo.QueryHookOptions<TerminalUshersQuery, TerminalUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TerminalUshersQuery, TerminalUshersQueryVariables>(TerminalUshersDocument, options);
}
export function useTerminalUshersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalUshersQuery, TerminalUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TerminalUshersQuery, TerminalUshersQueryVariables>(TerminalUshersDocument, options);
}
export type TerminalUshersQueryHookResult = ReturnType<typeof useTerminalUshersQuery>;
export type TerminalUshersLazyQueryHookResult = ReturnType<typeof useTerminalUshersLazyQuery>;
export type TerminalUshersQueryResult = Apollo.QueryResult<TerminalUshersQuery, TerminalUshersQueryVariables>;
export const TableDevicesDocument = gql`
  query TableDevices($first: Int!, $after: String, $before: String, $filterBy: DeviceFilterField) {
    devices(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        name
        simNumber
        description
        usher {
          id
          firstName
          lastName
          address
          contactNumber
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useTableDevicesQuery__
 *
 * To run a query within a React component, call `useTableDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableDevicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useTableDevicesQuery(baseOptions: Apollo.QueryHookOptions<TableDevicesQuery, TableDevicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TableDevicesQuery, TableDevicesQueryVariables>(TableDevicesDocument, options);
}
export function useTableDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableDevicesQuery, TableDevicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TableDevicesQuery, TableDevicesQueryVariables>(TableDevicesDocument, options);
}
export type TableDevicesQueryHookResult = ReturnType<typeof useTableDevicesQuery>;
export type TableDevicesLazyQueryHookResult = ReturnType<typeof useTableDevicesLazyQuery>;
export type TableDevicesQueryResult = Apollo.QueryResult<TableDevicesQuery, TableDevicesQueryVariables>;
export const CreateDrawAdminDocument = gql`
  mutation CreateDrawAdmin($input: CreateDrawAdminInput!) {
    createDrawAdmin(input: $input) {
      drawAdmin {
        id
      }
    }
  }
`;
export type CreateDrawAdminMutationFn = Apollo.MutationFunction<CreateDrawAdminMutation, CreateDrawAdminMutationVariables>;

/**
 * __useCreateDrawAdminMutation__
 *
 * To run a mutation, you first call `useCreateDrawAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrawAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDrawAdminMutation, { data, loading, error }] = useCreateDrawAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDrawAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDrawAdminMutation, CreateDrawAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDrawAdminMutation, CreateDrawAdminMutationVariables>(CreateDrawAdminDocument, options);
}
export type CreateDrawAdminMutationHookResult = ReturnType<typeof useCreateDrawAdminMutation>;
export type CreateDrawAdminMutationResult = Apollo.MutationResult<CreateDrawAdminMutation>;
export type CreateDrawAdminMutationOptions = Apollo.BaseMutationOptions<CreateDrawAdminMutation, CreateDrawAdminMutationVariables>;
export const DisabledDrawAdminDocument = gql`
  mutation DisabledDrawAdmin($disabledDrawAdminId: String!) {
    disabledDrawAdmin(id: $disabledDrawAdminId)
  }
`;
export type DisabledDrawAdminMutationFn = Apollo.MutationFunction<DisabledDrawAdminMutation, DisabledDrawAdminMutationVariables>;

/**
 * __useDisabledDrawAdminMutation__
 *
 * To run a mutation, you first call `useDisabledDrawAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisabledDrawAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disabledDrawAdminMutation, { data, loading, error }] = useDisabledDrawAdminMutation({
 *   variables: {
 *      disabledDrawAdminId: // value for 'disabledDrawAdminId'
 *   },
 * });
 */
export function useDisabledDrawAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<DisabledDrawAdminMutation, DisabledDrawAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisabledDrawAdminMutation, DisabledDrawAdminMutationVariables>(DisabledDrawAdminDocument, options);
}
export type DisabledDrawAdminMutationHookResult = ReturnType<typeof useDisabledDrawAdminMutation>;
export type DisabledDrawAdminMutationResult = Apollo.MutationResult<DisabledDrawAdminMutation>;
export type DisabledDrawAdminMutationOptions = Apollo.BaseMutationOptions<DisabledDrawAdminMutation, DisabledDrawAdminMutationVariables>;
export const DrawAdminsDocument = gql`
  query DrawAdmins($first: Int!, $after: String, $before: String) {
    drawAdmins(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
        lastName
        createdAt
        user {
          id
          username
          email
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useDrawAdminsQuery__
 *
 * To run a query within a React component, call `useDrawAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawAdminsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDrawAdminsQuery(baseOptions: Apollo.QueryHookOptions<DrawAdminsQuery, DrawAdminsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DrawAdminsQuery, DrawAdminsQueryVariables>(DrawAdminsDocument, options);
}
export function useDrawAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawAdminsQuery, DrawAdminsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DrawAdminsQuery, DrawAdminsQueryVariables>(DrawAdminsDocument, options);
}
export type DrawAdminsQueryHookResult = ReturnType<typeof useDrawAdminsQuery>;
export type DrawAdminsLazyQueryHookResult = ReturnType<typeof useDrawAdminsLazyQuery>;
export type DrawAdminsQueryResult = Apollo.QueryResult<DrawAdminsQuery, DrawAdminsQueryVariables>;
export const UpdateDrawAdminPasswordDocument = gql`
  mutation UpdateDrawAdminPassword($input: UpdateDrawAdminPasswordInput!) {
    updateDrawAdminPassword(input: $input) {
      message
    }
  }
`;
export type UpdateDrawAdminPasswordMutationFn = Apollo.MutationFunction<
  UpdateDrawAdminPasswordMutation,
  UpdateDrawAdminPasswordMutationVariables
>;

/**
 * __useUpdateDrawAdminPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateDrawAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDrawAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDrawAdminPasswordMutation, { data, loading, error }] = useUpdateDrawAdminPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDrawAdminPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDrawAdminPasswordMutation, UpdateDrawAdminPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDrawAdminPasswordMutation, UpdateDrawAdminPasswordMutationVariables>(
    UpdateDrawAdminPasswordDocument,
    options,
  );
}
export type UpdateDrawAdminPasswordMutationHookResult = ReturnType<typeof useUpdateDrawAdminPasswordMutation>;
export type UpdateDrawAdminPasswordMutationResult = Apollo.MutationResult<UpdateDrawAdminPasswordMutation>;
export type UpdateDrawAdminPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateDrawAdminPasswordMutation,
  UpdateDrawAdminPasswordMutationVariables
>;
export const UpdateDrawAdminDocument = gql`
  mutation UpdateDrawAdmin($input: UpdateDrawAdminInput!) {
    updateDrawAdmin(input: $input) {
      drawAdmin {
        id
      }
    }
  }
`;
export type UpdateDrawAdminMutationFn = Apollo.MutationFunction<UpdateDrawAdminMutation, UpdateDrawAdminMutationVariables>;

/**
 * __useUpdateDrawAdminMutation__
 *
 * To run a mutation, you first call `useUpdateDrawAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDrawAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDrawAdminMutation, { data, loading, error }] = useUpdateDrawAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDrawAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDrawAdminMutation, UpdateDrawAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDrawAdminMutation, UpdateDrawAdminMutationVariables>(UpdateDrawAdminDocument, options);
}
export type UpdateDrawAdminMutationHookResult = ReturnType<typeof useUpdateDrawAdminMutation>;
export type UpdateDrawAdminMutationResult = Apollo.MutationResult<UpdateDrawAdminMutation>;
export type UpdateDrawAdminMutationOptions = Apollo.BaseMutationOptions<UpdateDrawAdminMutation, UpdateDrawAdminMutationVariables>;
export const LimitCreateGameDrawtimeLimitDocument = gql`
  mutation LimitCreateGameDrawtimeLimit($input: CreateGameDrawtimeLimitInput!) {
    createGameDrawtimeLimit(input: $input) {
      gameDrawtimeLimit {
        id
      }
    }
  }
`;
export type LimitCreateGameDrawtimeLimitMutationFn = Apollo.MutationFunction<
  LimitCreateGameDrawtimeLimitMutation,
  LimitCreateGameDrawtimeLimitMutationVariables
>;

/**
 * __useLimitCreateGameDrawtimeLimitMutation__
 *
 * To run a mutation, you first call `useLimitCreateGameDrawtimeLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLimitCreateGameDrawtimeLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [limitCreateGameDrawtimeLimitMutation, { data, loading, error }] = useLimitCreateGameDrawtimeLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLimitCreateGameDrawtimeLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<LimitCreateGameDrawtimeLimitMutation, LimitCreateGameDrawtimeLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LimitCreateGameDrawtimeLimitMutation, LimitCreateGameDrawtimeLimitMutationVariables>(
    LimitCreateGameDrawtimeLimitDocument,
    options,
  );
}
export type LimitCreateGameDrawtimeLimitMutationHookResult = ReturnType<typeof useLimitCreateGameDrawtimeLimitMutation>;
export type LimitCreateGameDrawtimeLimitMutationResult = Apollo.MutationResult<LimitCreateGameDrawtimeLimitMutation>;
export type LimitCreateGameDrawtimeLimitMutationOptions = Apollo.BaseMutationOptions<
  LimitCreateGameDrawtimeLimitMutation,
  LimitCreateGameDrawtimeLimitMutationVariables
>;
export const CreateGameHotNumberDocument = gql`
  mutation CreateGameHotNumber($input: CreateGameHotNumberInput!) {
    createGameHotNumber(input: $input) {
      gameHotNumber {
        id
      }
    }
  }
`;
export type CreateGameHotNumberMutationFn = Apollo.MutationFunction<CreateGameHotNumberMutation, CreateGameHotNumberMutationVariables>;

/**
 * __useCreateGameHotNumberMutation__
 *
 * To run a mutation, you first call `useCreateGameHotNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameHotNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameHotNumberMutation, { data, loading, error }] = useCreateGameHotNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameHotNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGameHotNumberMutation, CreateGameHotNumberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGameHotNumberMutation, CreateGameHotNumberMutationVariables>(CreateGameHotNumberDocument, options);
}
export type CreateGameHotNumberMutationHookResult = ReturnType<typeof useCreateGameHotNumberMutation>;
export type CreateGameHotNumberMutationResult = Apollo.MutationResult<CreateGameHotNumberMutation>;
export type CreateGameHotNumberMutationOptions = Apollo.BaseMutationOptions<
  CreateGameHotNumberMutation,
  CreateGameHotNumberMutationVariables
>;
export const CreateIndividualLimitDocument = gql`
  mutation CreateIndividualLimit($input: CreateGameSalesNumberLimitInput!) {
    createGameSalesNumberLimit(input: $input) {
      gameSalesNumberLimit {
        id
      }
    }
  }
`;
export type CreateIndividualLimitMutationFn = Apollo.MutationFunction<
  CreateIndividualLimitMutation,
  CreateIndividualLimitMutationVariables
>;

/**
 * __useCreateIndividualLimitMutation__
 *
 * To run a mutation, you first call `useCreateIndividualLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndividualLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndividualLimitMutation, { data, loading, error }] = useCreateIndividualLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndividualLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIndividualLimitMutation, CreateIndividualLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIndividualLimitMutation, CreateIndividualLimitMutationVariables>(CreateIndividualLimitDocument, options);
}
export type CreateIndividualLimitMutationHookResult = ReturnType<typeof useCreateIndividualLimitMutation>;
export type CreateIndividualLimitMutationResult = Apollo.MutationResult<CreateIndividualLimitMutation>;
export type CreateIndividualLimitMutationOptions = Apollo.BaseMutationOptions<
  CreateIndividualLimitMutation,
  CreateIndividualLimitMutationVariables
>;
export const LimitDeleteGameDrawtimeLimitDocument = gql`
  mutation LimitDeleteGameDrawtimeLimit($id: String!) {
    deleteGameDrawtimeLimit(id: $id)
  }
`;
export type LimitDeleteGameDrawtimeLimitMutationFn = Apollo.MutationFunction<
  LimitDeleteGameDrawtimeLimitMutation,
  LimitDeleteGameDrawtimeLimitMutationVariables
>;

/**
 * __useLimitDeleteGameDrawtimeLimitMutation__
 *
 * To run a mutation, you first call `useLimitDeleteGameDrawtimeLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLimitDeleteGameDrawtimeLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [limitDeleteGameDrawtimeLimitMutation, { data, loading, error }] = useLimitDeleteGameDrawtimeLimitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLimitDeleteGameDrawtimeLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<LimitDeleteGameDrawtimeLimitMutation, LimitDeleteGameDrawtimeLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LimitDeleteGameDrawtimeLimitMutation, LimitDeleteGameDrawtimeLimitMutationVariables>(
    LimitDeleteGameDrawtimeLimitDocument,
    options,
  );
}
export type LimitDeleteGameDrawtimeLimitMutationHookResult = ReturnType<typeof useLimitDeleteGameDrawtimeLimitMutation>;
export type LimitDeleteGameDrawtimeLimitMutationResult = Apollo.MutationResult<LimitDeleteGameDrawtimeLimitMutation>;
export type LimitDeleteGameDrawtimeLimitMutationOptions = Apollo.BaseMutationOptions<
  LimitDeleteGameDrawtimeLimitMutation,
  LimitDeleteGameDrawtimeLimitMutationVariables
>;
export const DeleteGameHotNumberDocument = gql`
  mutation DeleteGameHotNumber($id: String!) {
    deleteGameHotNumber(id: $id)
  }
`;
export type DeleteGameHotNumberMutationFn = Apollo.MutationFunction<DeleteGameHotNumberMutation, DeleteGameHotNumberMutationVariables>;

/**
 * __useDeleteGameHotNumberMutation__
 *
 * To run a mutation, you first call `useDeleteGameHotNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameHotNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameHotNumberMutation, { data, loading, error }] = useDeleteGameHotNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGameHotNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteGameHotNumberMutation, DeleteGameHotNumberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGameHotNumberMutation, DeleteGameHotNumberMutationVariables>(DeleteGameHotNumberDocument, options);
}
export type DeleteGameHotNumberMutationHookResult = ReturnType<typeof useDeleteGameHotNumberMutation>;
export type DeleteGameHotNumberMutationResult = Apollo.MutationResult<DeleteGameHotNumberMutation>;
export type DeleteGameHotNumberMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameHotNumberMutation,
  DeleteGameHotNumberMutationVariables
>;
export const DeleteGameSalesNumberLimitDocument = gql`
  mutation DeleteGameSalesNumberLimit($id: String!) {
    deleteGameSalesNumberLimit(id: $id)
  }
`;
export type DeleteGameSalesNumberLimitMutationFn = Apollo.MutationFunction<
  DeleteGameSalesNumberLimitMutation,
  DeleteGameSalesNumberLimitMutationVariables
>;

/**
 * __useDeleteGameSalesNumberLimitMutation__
 *
 * To run a mutation, you first call `useDeleteGameSalesNumberLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameSalesNumberLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameSalesNumberLimitMutation, { data, loading, error }] = useDeleteGameSalesNumberLimitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGameSalesNumberLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteGameSalesNumberLimitMutation, DeleteGameSalesNumberLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGameSalesNumberLimitMutation, DeleteGameSalesNumberLimitMutationVariables>(
    DeleteGameSalesNumberLimitDocument,
    options,
  );
}
export type DeleteGameSalesNumberLimitMutationHookResult = ReturnType<typeof useDeleteGameSalesNumberLimitMutation>;
export type DeleteGameSalesNumberLimitMutationResult = Apollo.MutationResult<DeleteGameSalesNumberLimitMutation>;
export type DeleteGameSalesNumberLimitMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameSalesNumberLimitMutation,
  DeleteGameSalesNumberLimitMutationVariables
>;
export const GameDrawtimeLimitsDocument = gql`
  query GameDrawtimeLimits($first: Int!, $after: String, $before: String, $filterBy: GameDrawtimeLimitFilterField) {
    gameDrawtimeLimits(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        limit
        startDate
        endDate
        createdAt
        gameDrawtime {
          id
          drawTime {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGameDrawtimeLimitsQuery__
 *
 * To run a query within a React component, call `useGameDrawtimeLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameDrawtimeLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameDrawtimeLimitsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGameDrawtimeLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<GameDrawtimeLimitsQuery, GameDrawtimeLimitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameDrawtimeLimitsQuery, GameDrawtimeLimitsQueryVariables>(GameDrawtimeLimitsDocument, options);
}
export function useGameDrawtimeLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameDrawtimeLimitsQuery, GameDrawtimeLimitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameDrawtimeLimitsQuery, GameDrawtimeLimitsQueryVariables>(GameDrawtimeLimitsDocument, options);
}
export type GameDrawtimeLimitsQueryHookResult = ReturnType<typeof useGameDrawtimeLimitsQuery>;
export type GameDrawtimeLimitsLazyQueryHookResult = ReturnType<typeof useGameDrawtimeLimitsLazyQuery>;
export type GameDrawtimeLimitsQueryResult = Apollo.QueryResult<GameDrawtimeLimitsQuery, GameDrawtimeLimitsQueryVariables>;
export const LimitsGameDrawTimesDocument = gql`
  query LimitsGameDrawTimes($first: Int, $filterBy: GameDrawTimeFilter) {
    gameDrawTimes(first: $first, filterBy: $filterBy) {
      nodes {
        id
        drawTime {
          id
          name
          endDateTime
        }
      }
    }
  }
`;

/**
 * __useLimitsGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useLimitsGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLimitsGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLimitsGameDrawTimesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useLimitsGameDrawTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<LimitsGameDrawTimesQuery, LimitsGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LimitsGameDrawTimesQuery, LimitsGameDrawTimesQueryVariables>(LimitsGameDrawTimesDocument, options);
}
export function useLimitsGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LimitsGameDrawTimesQuery, LimitsGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LimitsGameDrawTimesQuery, LimitsGameDrawTimesQueryVariables>(LimitsGameDrawTimesDocument, options);
}
export type LimitsGameDrawTimesQueryHookResult = ReturnType<typeof useLimitsGameDrawTimesQuery>;
export type LimitsGameDrawTimesLazyQueryHookResult = ReturnType<typeof useLimitsGameDrawTimesLazyQuery>;
export type LimitsGameDrawTimesQueryResult = Apollo.QueryResult<LimitsGameDrawTimesQuery, LimitsGameDrawTimesQueryVariables>;
export const GameHotNumbersDocument = gql`
  query GameHotNumbers($first: Int!, $after: String, $before: String, $filterBy: GameHotNumberFilterField) {
    gameHotNumbers(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        numberCombination
        payout
        salesLimit
        startDate
        endDate
        sales
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGameHotNumbersQuery__
 *
 * To run a query within a React component, call `useGameHotNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameHotNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameHotNumbersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGameHotNumbersQuery(baseOptions: Apollo.QueryHookOptions<GameHotNumbersQuery, GameHotNumbersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameHotNumbersQuery, GameHotNumbersQueryVariables>(GameHotNumbersDocument, options);
}
export function useGameHotNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameHotNumbersQuery, GameHotNumbersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameHotNumbersQuery, GameHotNumbersQueryVariables>(GameHotNumbersDocument, options);
}
export type GameHotNumbersQueryHookResult = ReturnType<typeof useGameHotNumbersQuery>;
export type GameHotNumbersLazyQueryHookResult = ReturnType<typeof useGameHotNumbersLazyQuery>;
export type GameHotNumbersQueryResult = Apollo.QueryResult<GameHotNumbersQuery, GameHotNumbersQueryVariables>;
export const GameSalesNumberLimitsDocument = gql`
  query GameSalesNumberLimits($first: Int!, $after: String, $before: String, $filterBy: GameSalesNumberLimitFilterField) {
    gameSalesNumberLimits(
      first: $first
      after: $after
      before: $before
      sortBy: { direction: DESC, field: CREATED_AT }
      filterBy: $filterBy
    ) {
      nodes {
        id
        numberCombination
        limit
        sales
        startDate
        endDate
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGameSalesNumberLimitsQuery__
 *
 * To run a query within a React component, call `useGameSalesNumberLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameSalesNumberLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameSalesNumberLimitsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGameSalesNumberLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<GameSalesNumberLimitsQuery, GameSalesNumberLimitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameSalesNumberLimitsQuery, GameSalesNumberLimitsQueryVariables>(GameSalesNumberLimitsDocument, options);
}
export function useGameSalesNumberLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameSalesNumberLimitsQuery, GameSalesNumberLimitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameSalesNumberLimitsQuery, GameSalesNumberLimitsQueryVariables>(GameSalesNumberLimitsDocument, options);
}
export type GameSalesNumberLimitsQueryHookResult = ReturnType<typeof useGameSalesNumberLimitsQuery>;
export type GameSalesNumberLimitsLazyQueryHookResult = ReturnType<typeof useGameSalesNumberLimitsLazyQuery>;
export type GameSalesNumberLimitsQueryResult = Apollo.QueryResult<GameSalesNumberLimitsQuery, GameSalesNumberLimitsQueryVariables>;
export const GlobalLimitGameTypesDocument = gql`
  query GlobalLimitGameTypes {
    gameTypes {
      nodes {
        gameConfig {
          globalNumberLimit
        }
      }
    }
  }
`;

/**
 * __useGlobalLimitGameTypesQuery__
 *
 * To run a query within a React component, call `useGlobalLimitGameTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalLimitGameTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalLimitGameTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalLimitGameTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GlobalLimitGameTypesQuery, GlobalLimitGameTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalLimitGameTypesQuery, GlobalLimitGameTypesQueryVariables>(GlobalLimitGameTypesDocument, options);
}
export function useGlobalLimitGameTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalLimitGameTypesQuery, GlobalLimitGameTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalLimitGameTypesQuery, GlobalLimitGameTypesQueryVariables>(GlobalLimitGameTypesDocument, options);
}
export type GlobalLimitGameTypesQueryHookResult = ReturnType<typeof useGlobalLimitGameTypesQuery>;
export type GlobalLimitGameTypesLazyQueryHookResult = ReturnType<typeof useGlobalLimitGameTypesLazyQuery>;
export type GlobalLimitGameTypesQueryResult = Apollo.QueryResult<GlobalLimitGameTypesQuery, GlobalLimitGameTypesQueryVariables>;
export const GameLimitUpdateGameConfigDocument = gql`
  mutation GameLimitUpdateGameConfig($input: UpdateGameConfigInput!) {
    updateGameConfig(input: $input) {
      gameConfig {
        id
      }
    }
  }
`;
export type GameLimitUpdateGameConfigMutationFn = Apollo.MutationFunction<
  GameLimitUpdateGameConfigMutation,
  GameLimitUpdateGameConfigMutationVariables
>;

/**
 * __useGameLimitUpdateGameConfigMutation__
 *
 * To run a mutation, you first call `useGameLimitUpdateGameConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGameLimitUpdateGameConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gameLimitUpdateGameConfigMutation, { data, loading, error }] = useGameLimitUpdateGameConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGameLimitUpdateGameConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<GameLimitUpdateGameConfigMutation, GameLimitUpdateGameConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GameLimitUpdateGameConfigMutation, GameLimitUpdateGameConfigMutationVariables>(
    GameLimitUpdateGameConfigDocument,
    options,
  );
}
export type GameLimitUpdateGameConfigMutationHookResult = ReturnType<typeof useGameLimitUpdateGameConfigMutation>;
export type GameLimitUpdateGameConfigMutationResult = Apollo.MutationResult<GameLimitUpdateGameConfigMutation>;
export type GameLimitUpdateGameConfigMutationOptions = Apollo.BaseMutationOptions<
  GameLimitUpdateGameConfigMutation,
  GameLimitUpdateGameConfigMutationVariables
>;
export const LimitUpdateGameDrawtimeLimitDocument = gql`
  mutation LimitUpdateGameDrawtimeLimit($input: UpdateGameDrawtimeLimitInput!) {
    updateGameDrawtimeLimit(input: $input) {
      gameDrawtimeLimit {
        id
      }
    }
  }
`;
export type LimitUpdateGameDrawtimeLimitMutationFn = Apollo.MutationFunction<
  LimitUpdateGameDrawtimeLimitMutation,
  LimitUpdateGameDrawtimeLimitMutationVariables
>;

/**
 * __useLimitUpdateGameDrawtimeLimitMutation__
 *
 * To run a mutation, you first call `useLimitUpdateGameDrawtimeLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLimitUpdateGameDrawtimeLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [limitUpdateGameDrawtimeLimitMutation, { data, loading, error }] = useLimitUpdateGameDrawtimeLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLimitUpdateGameDrawtimeLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<LimitUpdateGameDrawtimeLimitMutation, LimitUpdateGameDrawtimeLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LimitUpdateGameDrawtimeLimitMutation, LimitUpdateGameDrawtimeLimitMutationVariables>(
    LimitUpdateGameDrawtimeLimitDocument,
    options,
  );
}
export type LimitUpdateGameDrawtimeLimitMutationHookResult = ReturnType<typeof useLimitUpdateGameDrawtimeLimitMutation>;
export type LimitUpdateGameDrawtimeLimitMutationResult = Apollo.MutationResult<LimitUpdateGameDrawtimeLimitMutation>;
export type LimitUpdateGameDrawtimeLimitMutationOptions = Apollo.BaseMutationOptions<
  LimitUpdateGameDrawtimeLimitMutation,
  LimitUpdateGameDrawtimeLimitMutationVariables
>;
export const UpdateGameHotNumberDocument = gql`
  mutation UpdateGameHotNumber($input: UpdateGameHotNumberInput!) {
    updateGameHotNumber(input: $input) {
      gameHotNumber {
        id
      }
    }
  }
`;
export type UpdateGameHotNumberMutationFn = Apollo.MutationFunction<UpdateGameHotNumberMutation, UpdateGameHotNumberMutationVariables>;

/**
 * __useUpdateGameHotNumberMutation__
 *
 * To run a mutation, you first call `useUpdateGameHotNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameHotNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameHotNumberMutation, { data, loading, error }] = useUpdateGameHotNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameHotNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGameHotNumberMutation, UpdateGameHotNumberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGameHotNumberMutation, UpdateGameHotNumberMutationVariables>(UpdateGameHotNumberDocument, options);
}
export type UpdateGameHotNumberMutationHookResult = ReturnType<typeof useUpdateGameHotNumberMutation>;
export type UpdateGameHotNumberMutationResult = Apollo.MutationResult<UpdateGameHotNumberMutation>;
export type UpdateGameHotNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameHotNumberMutation,
  UpdateGameHotNumberMutationVariables
>;
export const UpdateGameSalesNumberLimitDocument = gql`
  mutation UpdateGameSalesNumberLimit($input: UpdateGameSalesNumberLimitInput!) {
    updateGameSalesNumberLimit(input: $input) {
      gameSalesNumberLimit {
        id
      }
    }
  }
`;
export type UpdateGameSalesNumberLimitMutationFn = Apollo.MutationFunction<
  UpdateGameSalesNumberLimitMutation,
  UpdateGameSalesNumberLimitMutationVariables
>;

/**
 * __useUpdateGameSalesNumberLimitMutation__
 *
 * To run a mutation, you first call `useUpdateGameSalesNumberLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameSalesNumberLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameSalesNumberLimitMutation, { data, loading, error }] = useUpdateGameSalesNumberLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameSalesNumberLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGameSalesNumberLimitMutation, UpdateGameSalesNumberLimitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGameSalesNumberLimitMutation, UpdateGameSalesNumberLimitMutationVariables>(
    UpdateGameSalesNumberLimitDocument,
    options,
  );
}
export type UpdateGameSalesNumberLimitMutationHookResult = ReturnType<typeof useUpdateGameSalesNumberLimitMutation>;
export type UpdateGameSalesNumberLimitMutationResult = Apollo.MutationResult<UpdateGameSalesNumberLimitMutation>;
export type UpdateGameSalesNumberLimitMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameSalesNumberLimitMutation,
  UpdateGameSalesNumberLimitMutationVariables
>;
export const CreateGameResultDocument = gql`
  mutation CreateGameResult($input: CreateGameResultInput!) {
    createGameResult(input: $input) {
      gameResult {
        id
      }
    }
  }
`;
export type CreateGameResultMutationFn = Apollo.MutationFunction<CreateGameResultMutation, CreateGameResultMutationVariables>;

/**
 * __useCreateGameResultMutation__
 *
 * To run a mutation, you first call `useCreateGameResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameResultMutation, { data, loading, error }] = useCreateGameResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameResultMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGameResultMutation, CreateGameResultMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGameResultMutation, CreateGameResultMutationVariables>(CreateGameResultDocument, options);
}
export type CreateGameResultMutationHookResult = ReturnType<typeof useCreateGameResultMutation>;
export type CreateGameResultMutationResult = Apollo.MutationResult<CreateGameResultMutation>;
export type CreateGameResultMutationOptions = Apollo.BaseMutationOptions<CreateGameResultMutation, CreateGameResultMutationVariables>;
export const PageGameResultGameDrawTimesDocument = gql`
  query PageGameResultGameDrawTimes($filterBy: GameDrawTimeFilter!) {
    gameDrawTimes(filterBy: $filterBy) {
      nodes {
        id
        status
        drawTime {
          id
          name
        }
      }
    }
  }
`;

/**
 * __usePageGameResultGameDrawTimesQuery__
 *
 * To run a query within a React component, call `usePageGameResultGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageGameResultGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageGameResultGameDrawTimesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function usePageGameResultGameDrawTimesQuery(
  baseOptions: Apollo.QueryHookOptions<PageGameResultGameDrawTimesQuery, PageGameResultGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageGameResultGameDrawTimesQuery, PageGameResultGameDrawTimesQueryVariables>(
    PageGameResultGameDrawTimesDocument,
    options,
  );
}
export function usePageGameResultGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PageGameResultGameDrawTimesQuery, PageGameResultGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageGameResultGameDrawTimesQuery, PageGameResultGameDrawTimesQueryVariables>(
    PageGameResultGameDrawTimesDocument,
    options,
  );
}
export type PageGameResultGameDrawTimesQueryHookResult = ReturnType<typeof usePageGameResultGameDrawTimesQuery>;
export type PageGameResultGameDrawTimesLazyQueryHookResult = ReturnType<typeof usePageGameResultGameDrawTimesLazyQuery>;
export type PageGameResultGameDrawTimesQueryResult = Apollo.QueryResult<
  PageGameResultGameDrawTimesQuery,
  PageGameResultGameDrawTimesQueryVariables
>;
export const GameResultsDocument = gql`
  query GameResults($first: Int!, $after: String, $before: String, $filterBy: GameResultFilterField) {
    gameResults(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        resultCombination
        date
        createdAt
        updatedAt
        gameDrawTime {
          id
          drawTime {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGameResultsQuery__
 *
 * To run a query within a React component, call `useGameResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameResultsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGameResultsQuery(baseOptions: Apollo.QueryHookOptions<GameResultsQuery, GameResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameResultsQuery, GameResultsQueryVariables>(GameResultsDocument, options);
}
export function useGameResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameResultsQuery, GameResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameResultsQuery, GameResultsQueryVariables>(GameResultsDocument, options);
}
export type GameResultsQueryHookResult = ReturnType<typeof useGameResultsQuery>;
export type GameResultsLazyQueryHookResult = ReturnType<typeof useGameResultsLazyQuery>;
export type GameResultsQueryResult = Apollo.QueryResult<GameResultsQuery, GameResultsQueryVariables>;
export const NoGameResultsDocument = gql`
  query NoGameResults($first: Int!, $after: String, $before: String, $filterBy: GameResultFilterField) {
    noGameResults(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        id
        resultCombination
        date
        createdAt
        updatedAt
        gameDrawTime {
          id
          drawTime {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useNoGameResultsQuery__
 *
 * To run a query within a React component, call `useNoGameResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoGameResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoGameResultsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useNoGameResultsQuery(baseOptions: Apollo.QueryHookOptions<NoGameResultsQuery, NoGameResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NoGameResultsQuery, NoGameResultsQueryVariables>(NoGameResultsDocument, options);
}
export function useNoGameResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoGameResultsQuery, NoGameResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NoGameResultsQuery, NoGameResultsQueryVariables>(NoGameResultsDocument, options);
}
export type NoGameResultsQueryHookResult = ReturnType<typeof useNoGameResultsQuery>;
export type NoGameResultsLazyQueryHookResult = ReturnType<typeof useNoGameResultsLazyQuery>;
export type NoGameResultsQueryResult = Apollo.QueryResult<NoGameResultsQuery, NoGameResultsQueryVariables>;
export const UpdateGameResultDocument = gql`
  mutation UpdateGameResult($input: UpdateGameResultInput!) {
    updateGameResult(input: $input) {
      gameResult {
        id
      }
    }
  }
`;
export type UpdateGameResultMutationFn = Apollo.MutationFunction<UpdateGameResultMutation, UpdateGameResultMutationVariables>;

/**
 * __useUpdateGameResultMutation__
 *
 * To run a mutation, you first call `useUpdateGameResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameResultMutation, { data, loading, error }] = useUpdateGameResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameResultMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGameResultMutation, UpdateGameResultMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGameResultMutation, UpdateGameResultMutationVariables>(UpdateGameResultDocument, options);
}
export type UpdateGameResultMutationHookResult = ReturnType<typeof useUpdateGameResultMutation>;
export type UpdateGameResultMutationResult = Apollo.MutationResult<UpdateGameResultMutation>;
export type UpdateGameResultMutationOptions = Apollo.BaseMutationOptions<UpdateGameResultMutation, UpdateGameResultMutationVariables>;
export const AdminDocument = gql`
  query Admin($id: String!) {
    admin(id: $id) {
      id
      firstName
      lastName
      middleName
    }
  }
`;

/**
 * __useAdminQuery__
 *
 * To run a query within a React component, call `useAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminQuery(baseOptions: Apollo.QueryHookOptions<AdminQuery, AdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
}
export function useAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminQuery, AdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
}
export type AdminQueryHookResult = ReturnType<typeof useAdminQuery>;
export type AdminLazyQueryHookResult = ReturnType<typeof useAdminLazyQuery>;
export type AdminQueryResult = Apollo.QueryResult<AdminQuery, AdminQueryVariables>;
export const CurrentUserLoginDocument = gql`
  query currentUserLogin {
    me {
      id
      username
      role {
        id
        name
      }
    }
  }
`;

/**
 * __useCurrentUserLoginQuery__
 *
 * To run a query within a React component, call `useCurrentUserLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserLoginQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserLoginQuery, CurrentUserLoginQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserLoginQuery, CurrentUserLoginQueryVariables>(CurrentUserLoginDocument, options);
}
export function useCurrentUserLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserLoginQuery, CurrentUserLoginQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserLoginQuery, CurrentUserLoginQueryVariables>(CurrentUserLoginDocument, options);
}
export type CurrentUserLoginQueryHookResult = ReturnType<typeof useCurrentUserLoginQuery>;
export type CurrentUserLoginLazyQueryHookResult = ReturnType<typeof useCurrentUserLoginLazyQuery>;
export type CurrentUserLoginQueryResult = Apollo.QueryResult<CurrentUserLoginQuery, CurrentUserLoginQueryVariables>;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateAdminPasswordDocument = gql`
  mutation UpdateAdminPassword($input: UpdateAdminPasswordInput!) {
    updateAdminPassword(input: $input) {
      message
    }
  }
`;
export type UpdateAdminPasswordMutationFn = Apollo.MutationFunction<UpdateAdminPasswordMutation, UpdateAdminPasswordMutationVariables>;

/**
 * __useUpdateAdminPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminPasswordMutation, { data, loading, error }] = useUpdateAdminPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdminPasswordMutation, UpdateAdminPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminPasswordMutation, UpdateAdminPasswordMutationVariables>(UpdateAdminPasswordDocument, options);
}
export type UpdateAdminPasswordMutationHookResult = ReturnType<typeof useUpdateAdminPasswordMutation>;
export type UpdateAdminPasswordMutationResult = Apollo.MutationResult<UpdateAdminPasswordMutation>;
export type UpdateAdminPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminPasswordMutation,
  UpdateAdminPasswordMutationVariables
>;
export const UpdateAdminDocument = gql`
  mutation UpdateAdmin($input: UpdateAdminInput!) {
    updateAdmin(input: $input) {
      admin {
        id
      }
    }
  }
`;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
}
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const CreateNotificationDocument = gql`
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      notification {
        id
      }
    }
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
}
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const NotificationsDocument = gql`
  query Notifications($first: Int!, $after: String, $before: String, $filterBy: NotificationsFilterField) {
    notificationsAdminQuery(
      filterBy: $filterBy
      first: $first
      after: $after
      before: $before
      sortBy: { direction: DESC, field: CREATED_AT }
    ) {
      nodes {
        id
        title
        subTitle
        message
        views
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
}
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const OpStatsGameDrawTimesDocument = gql`
  query OpStatsGameDrawTimes($first: Int, $filterBy: GameDrawTimeFilter) {
    gameDrawTimes(first: $first, filterBy: $filterBy) {
      nodes {
        id
        drawTime {
          id
          name
          endDateTime
        }
      }
    }
  }
`;

/**
 * __useOpStatsGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useOpStatsGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpStatsGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpStatsGameDrawTimesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useOpStatsGameDrawTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<OpStatsGameDrawTimesQuery, OpStatsGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpStatsGameDrawTimesQuery, OpStatsGameDrawTimesQueryVariables>(OpStatsGameDrawTimesDocument, options);
}
export function useOpStatsGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpStatsGameDrawTimesQuery, OpStatsGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpStatsGameDrawTimesQuery, OpStatsGameDrawTimesQueryVariables>(OpStatsGameDrawTimesDocument, options);
}
export type OpStatsGameDrawTimesQueryHookResult = ReturnType<typeof useOpStatsGameDrawTimesQuery>;
export type OpStatsGameDrawTimesLazyQueryHookResult = ReturnType<typeof useOpStatsGameDrawTimesLazyQuery>;
export type OpStatsGameDrawTimesQueryResult = Apollo.QueryResult<OpStatsGameDrawTimesQuery, OpStatsGameDrawTimesQueryVariables>;
export const OpStatsNumberCombinationSalesDocument = gql`
  query OpStatsNumberCombinationSales($filterBy: NumberCombinationSalesFilter!) {
    numberCombinationSales(filterBy: $filterBy) {
      id
      numberCombination
      sales
      numberOfBets
    }
  }
`;

/**
 * __useOpStatsNumberCombinationSalesQuery__
 *
 * To run a query within a React component, call `useOpStatsNumberCombinationSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpStatsNumberCombinationSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpStatsNumberCombinationSalesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useOpStatsNumberCombinationSalesQuery(
  baseOptions: Apollo.QueryHookOptions<OpStatsNumberCombinationSalesQuery, OpStatsNumberCombinationSalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpStatsNumberCombinationSalesQuery, OpStatsNumberCombinationSalesQueryVariables>(
    OpStatsNumberCombinationSalesDocument,
    options,
  );
}
export function useOpStatsNumberCombinationSalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpStatsNumberCombinationSalesQuery, OpStatsNumberCombinationSalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpStatsNumberCombinationSalesQuery, OpStatsNumberCombinationSalesQueryVariables>(
    OpStatsNumberCombinationSalesDocument,
    options,
  );
}
export type OpStatsNumberCombinationSalesQueryHookResult = ReturnType<typeof useOpStatsNumberCombinationSalesQuery>;
export type OpStatsNumberCombinationSalesLazyQueryHookResult = ReturnType<typeof useOpStatsNumberCombinationSalesLazyQuery>;
export type OpStatsNumberCombinationSalesQueryResult = Apollo.QueryResult<
  OpStatsNumberCombinationSalesQuery,
  OpStatsNumberCombinationSalesQueryVariables
>;
export const OpStatsNumberCombinationsSoldOutDocument = gql`
  query OpStatsNumberCombinationsSoldOut($first: Int, $after: String, $before: String, $filterBy: NumberCombinationSoldOutFilter) {
    numberCombinationSoldOut(first: $first, after: $after, before: $before, filterBy: $filterBy) {
      nodes {
        id
        numberCombination
      }
    }
  }
`;

/**
 * __useOpStatsNumberCombinationsSoldOutQuery__
 *
 * To run a query within a React component, call `useOpStatsNumberCombinationsSoldOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpStatsNumberCombinationsSoldOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpStatsNumberCombinationsSoldOutQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useOpStatsNumberCombinationsSoldOutQuery(
  baseOptions?: Apollo.QueryHookOptions<OpStatsNumberCombinationsSoldOutQuery, OpStatsNumberCombinationsSoldOutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpStatsNumberCombinationsSoldOutQuery, OpStatsNumberCombinationsSoldOutQueryVariables>(
    OpStatsNumberCombinationsSoldOutDocument,
    options,
  );
}
export function useOpStatsNumberCombinationsSoldOutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpStatsNumberCombinationsSoldOutQuery, OpStatsNumberCombinationsSoldOutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpStatsNumberCombinationsSoldOutQuery, OpStatsNumberCombinationsSoldOutQueryVariables>(
    OpStatsNumberCombinationsSoldOutDocument,
    options,
  );
}
export type OpStatsNumberCombinationsSoldOutQueryHookResult = ReturnType<typeof useOpStatsNumberCombinationsSoldOutQuery>;
export type OpStatsNumberCombinationsSoldOutLazyQueryHookResult = ReturnType<typeof useOpStatsNumberCombinationsSoldOutLazyQuery>;
export type OpStatsNumberCombinationsSoldOutQueryResult = Apollo.QueryResult<
  OpStatsNumberCombinationsSoldOutQuery,
  OpStatsNumberCombinationsSoldOutQueryVariables
>;
export const DrawOverviewGameResultDocument = gql`
  query DrawOverviewGameResult($first: Int) {
    games(first: $first, sortBy: { direction: ASC, field: CREATED_AT }) {
      nodes {
        id
        gameType {
          id
          name
        }
        gameDrawTime {
          id
          drawTime {
            id
            endDateTime
            name
          }
          gameResult {
            id
            resultCombination
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useDrawOverviewGameResultQuery__
 *
 * To run a query within a React component, call `useDrawOverviewGameResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawOverviewGameResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawOverviewGameResultQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDrawOverviewGameResultQuery(
  baseOptions?: Apollo.QueryHookOptions<DrawOverviewGameResultQuery, DrawOverviewGameResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DrawOverviewGameResultQuery, DrawOverviewGameResultQueryVariables>(DrawOverviewGameResultDocument, options);
}
export function useDrawOverviewGameResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DrawOverviewGameResultQuery, DrawOverviewGameResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DrawOverviewGameResultQuery, DrawOverviewGameResultQueryVariables>(DrawOverviewGameResultDocument, options);
}
export type DrawOverviewGameResultQueryHookResult = ReturnType<typeof useDrawOverviewGameResultQuery>;
export type DrawOverviewGameResultLazyQueryHookResult = ReturnType<typeof useDrawOverviewGameResultLazyQuery>;
export type DrawOverviewGameResultQueryResult = Apollo.QueryResult<DrawOverviewGameResultQuery, DrawOverviewGameResultQueryVariables>;
export const OverviewGameDrawTimesResultsDocument = gql`
  query OverviewGameDrawTimesResults($filterBy: GameDrawTimeFilter) {
    gameDrawTimes(filterBy: $filterBy) {
      nodes {
        id
        drawTime {
          id
          name
          endDateTime
        }
        gameDrawTimeReport {
          totalSales
          totalBets
          hits
        }
        gameResult {
          id
          resultCombination
        }
      }
    }
  }
`;

/**
 * __useOverviewGameDrawTimesResultsQuery__
 *
 * To run a query within a React component, call `useOverviewGameDrawTimesResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewGameDrawTimesResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewGameDrawTimesResultsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useOverviewGameDrawTimesResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<OverviewGameDrawTimesResultsQuery, OverviewGameDrawTimesResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewGameDrawTimesResultsQuery, OverviewGameDrawTimesResultsQueryVariables>(
    OverviewGameDrawTimesResultsDocument,
    options,
  );
}
export function useOverviewGameDrawTimesResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OverviewGameDrawTimesResultsQuery, OverviewGameDrawTimesResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverviewGameDrawTimesResultsQuery, OverviewGameDrawTimesResultsQueryVariables>(
    OverviewGameDrawTimesResultsDocument,
    options,
  );
}
export type OverviewGameDrawTimesResultsQueryHookResult = ReturnType<typeof useOverviewGameDrawTimesResultsQuery>;
export type OverviewGameDrawTimesResultsLazyQueryHookResult = ReturnType<typeof useOverviewGameDrawTimesResultsLazyQuery>;
export type OverviewGameDrawTimesResultsQueryResult = Apollo.QueryResult<
  OverviewGameDrawTimesResultsQuery,
  OverviewGameDrawTimesResultsQueryVariables
>;
export const GamesOverviewReportDocument = gql`
  query GamesOverviewReport($filterBy: GameSalesReportFilterField!) {
    gamesOverviewReport(filterBy: $filterBy) {
      totalSales
      totalHits
      totalNoOfbets
      gameReport {
        game {
          id
          gameType {
            id
            name
          }
        }
        sales
        hits
        noOfbets
        gameDrawTimesReport {
          drawTime {
            id
            name
            endDateTime
          }
          sales
          hits
          noOfbets
          gameResult {
            id
            resultCombination
          }
        }
      }
    }
  }
`;

/**
 * __useGamesOverviewReportQuery__
 *
 * To run a query within a React component, call `useGamesOverviewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesOverviewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesOverviewReportQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGamesOverviewReportQuery(
  baseOptions: Apollo.QueryHookOptions<GamesOverviewReportQuery, GamesOverviewReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GamesOverviewReportQuery, GamesOverviewReportQueryVariables>(GamesOverviewReportDocument, options);
}
export function useGamesOverviewReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GamesOverviewReportQuery, GamesOverviewReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GamesOverviewReportQuery, GamesOverviewReportQueryVariables>(GamesOverviewReportDocument, options);
}
export type GamesOverviewReportQueryHookResult = ReturnType<typeof useGamesOverviewReportQuery>;
export type GamesOverviewReportLazyQueryHookResult = ReturnType<typeof useGamesOverviewReportLazyQuery>;
export type GamesOverviewReportQueryResult = Apollo.QueryResult<GamesOverviewReportQuery, GamesOverviewReportQueryVariables>;
export const GamesSalesReportDocument = gql`
  query GamesSalesReport($first: Int, $filterBy: GameSalesReportFilterField) {
    gamesSalesReport(first: $first, sortBy: { direction: ASC, field: CREATED_AT }, filterBy: $filterBy) {
      nodes {
        game {
          id
          gameType {
            id
            name
          }
        }
        totalHits
        totalSales
        totalBets
      }
      totalCount
    }
  }
`;

/**
 * __useGamesSalesReportQuery__
 *
 * To run a query within a React component, call `useGamesSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesSalesReportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGamesSalesReportQuery(baseOptions?: Apollo.QueryHookOptions<GamesSalesReportQuery, GamesSalesReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GamesSalesReportQuery, GamesSalesReportQueryVariables>(GamesSalesReportDocument, options);
}
export function useGamesSalesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GamesSalesReportQuery, GamesSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GamesSalesReportQuery, GamesSalesReportQueryVariables>(GamesSalesReportDocument, options);
}
export type GamesSalesReportQueryHookResult = ReturnType<typeof useGamesSalesReportQuery>;
export type GamesSalesReportLazyQueryHookResult = ReturnType<typeof useGamesSalesReportLazyQuery>;
export type GamesSalesReportQueryResult = Apollo.QueryResult<GamesSalesReportQuery, GamesSalesReportQueryVariables>;
export const DisabledUshersSalesReportDocument = gql`
  query DisabledUshersSalesReport($first: Int, $after: String, $before: String, $filterBy: DisabledUsherSalesReportFilterField) {
    disabledUshersSalesReport(first: $first, after: $after, before: $before, filterBy: $filterBy) {
      nodes {
        winnerBets {
          id
          numberCombination
          winAmount
          amount
          createdAt
          betDetail {
            id
            betType {
              id
              name
            }
          }
          gameDrawTime {
            id
            game {
              id
              gameType {
                id
                name
              }
            }
            drawTime {
              id
              name
            }
          }
          transaction {
            id
            transactionCode
            usher {
              id
              firstName
              lastName
            }
          }
        }
        usher {
          id
          firstName
          lastName
          contactNumber
          address
          uniqueCode
          usherType {
            id
            name
          }
          user {
            id
            isDisabled
          }
        }
        totalSales
        hits
        totalBets
        totalWinner
        payableWinner
        date
        usherGameSales {
          name
          gameDrawTimeSales {
            drawName
            sales
            endDateTime
            hits
            payableWinner
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useDisabledUshersSalesReportQuery__
 *
 * To run a query within a React component, call `useDisabledUshersSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisabledUshersSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisabledUshersSalesReportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useDisabledUshersSalesReportQuery(
  baseOptions?: Apollo.QueryHookOptions<DisabledUshersSalesReportQuery, DisabledUshersSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisabledUshersSalesReportQuery, DisabledUshersSalesReportQueryVariables>(
    DisabledUshersSalesReportDocument,
    options,
  );
}
export function useDisabledUshersSalesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisabledUshersSalesReportQuery, DisabledUshersSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisabledUshersSalesReportQuery, DisabledUshersSalesReportQueryVariables>(
    DisabledUshersSalesReportDocument,
    options,
  );
}
export type DisabledUshersSalesReportQueryHookResult = ReturnType<typeof useDisabledUshersSalesReportQuery>;
export type DisabledUshersSalesReportLazyQueryHookResult = ReturnType<typeof useDisabledUshersSalesReportLazyQuery>;
export type DisabledUshersSalesReportQueryResult = Apollo.QueryResult<
  DisabledUshersSalesReportQuery,
  DisabledUshersSalesReportQueryVariables
>;
export const ReportsBetsDocument = gql`
  query ReportsBets($first: Int, $after: String, $before: String, $filterBy: BetFilterField) {
    bets(first: $first, after: $after, before: $before, filterBy: $filterBy) {
      nodes {
        id
        numberCombination
        winAmount
        amount
        createdAt
        betDetail {
          id
          betType {
            id
            name
          }
        }
        gameDrawTime {
          id
          game {
            id
            gameType {
              id
              name
            }
          }
          drawTime {
            id
            name
          }
        }
        transaction {
          id
          transactionCode
          usher {
            id
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useReportsBetsQuery__
 *
 * To run a query within a React component, call `useReportsBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsBetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useReportsBetsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsBetsQuery, ReportsBetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsBetsQuery, ReportsBetsQueryVariables>(ReportsBetsDocument, options);
}
export function useReportsBetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsBetsQuery, ReportsBetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportsBetsQuery, ReportsBetsQueryVariables>(ReportsBetsDocument, options);
}
export type ReportsBetsQueryHookResult = ReturnType<typeof useReportsBetsQuery>;
export type ReportsBetsLazyQueryHookResult = ReturnType<typeof useReportsBetsLazyQuery>;
export type ReportsBetsQueryResult = Apollo.QueryResult<ReportsBetsQuery, ReportsBetsQueryVariables>;
export const ReportGameDrawTimesDocument = gql`
  query ReportGameDrawTimes {
    gameDrawTimes {
      nodes {
        id
        drawTime {
          id
          name
          endDateTime
        }
        game {
          id
          gameType {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useReportGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useReportGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportGameDrawTimesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportGameDrawTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportGameDrawTimesQuery, ReportGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportGameDrawTimesQuery, ReportGameDrawTimesQueryVariables>(ReportGameDrawTimesDocument, options);
}
export function useReportGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportGameDrawTimesQuery, ReportGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportGameDrawTimesQuery, ReportGameDrawTimesQueryVariables>(ReportGameDrawTimesDocument, options);
}
export type ReportGameDrawTimesQueryHookResult = ReturnType<typeof useReportGameDrawTimesQuery>;
export type ReportGameDrawTimesLazyQueryHookResult = ReturnType<typeof useReportGameDrawTimesLazyQuery>;
export type ReportGameDrawTimesQueryResult = Apollo.QueryResult<ReportGameDrawTimesQuery, ReportGameDrawTimesQueryVariables>;
export const UshersSalesReportDocument = gql`
  query UshersSalesReport($first: Int, $after: String, $before: String, $filterBy: UsherSalesReportFilterField) {
    ushersSalesReport(first: $first, after: $after, before: $before, filterBy: $filterBy) {
      nodes {
        winnerBets {
          id
          numberCombination
          winAmount
          amount
          createdAt
          betDetail {
            id
            betType {
              id
              name
            }
          }
          gameDrawTime {
            id
            game {
              id
              gameType {
                id
                name
              }
            }
            drawTime {
              id
              name
            }
          }
          transaction {
            id
            transactionCode
            usher {
              id
              firstName
              lastName
            }
          }
        }
        usher {
          id
          firstName
          lastName
          contactNumber
          address
          uniqueCode
          usherType {
            id
            name
          }
          user {
            id
            isDisabled
          }
        }
        totalSales
        hits
        totalBets
        totalWinner
        payableWinner
        date
        usherGameSales {
          name
          gameDrawTimeSales {
            drawName
            sales
            endDateTime
            hits
            payableWinner
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useUshersSalesReportQuery__
 *
 * To run a query within a React component, call `useUshersSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUshersSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUshersSalesReportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useUshersSalesReportQuery(baseOptions?: Apollo.QueryHookOptions<UshersSalesReportQuery, UshersSalesReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UshersSalesReportQuery, UshersSalesReportQueryVariables>(UshersSalesReportDocument, options);
}
export function useUshersSalesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UshersSalesReportQuery, UshersSalesReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UshersSalesReportQuery, UshersSalesReportQueryVariables>(UshersSalesReportDocument, options);
}
export type UshersSalesReportQueryHookResult = ReturnType<typeof useUshersSalesReportQuery>;
export type UshersSalesReportLazyQueryHookResult = ReturnType<typeof useUshersSalesReportLazyQuery>;
export type UshersSalesReportQueryResult = Apollo.QueryResult<UshersSalesReportQuery, UshersSalesReportQueryVariables>;
export const ServerConfigCreateGameDoubleRepeatingPayoutDocument = gql`
  mutation ServerConfigCreateGameDoubleRepeatingPayout($input: CreateGameDoubleRepeatingPayoutInput!) {
    createGameDoubleRepeatingPayout(input: $input) {
      gameDoubleRepeatingPayout {
        id
      }
    }
  }
`;
export type ServerConfigCreateGameDoubleRepeatingPayoutMutationFn = Apollo.MutationFunction<
  ServerConfigCreateGameDoubleRepeatingPayoutMutation,
  ServerConfigCreateGameDoubleRepeatingPayoutMutationVariables
>;

/**
 * __useServerConfigCreateGameDoubleRepeatingPayoutMutation__
 *
 * To run a mutation, you first call `useServerConfigCreateGameDoubleRepeatingPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerConfigCreateGameDoubleRepeatingPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverConfigCreateGameDoubleRepeatingPayoutMutation, { data, loading, error }] = useServerConfigCreateGameDoubleRepeatingPayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServerConfigCreateGameDoubleRepeatingPayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ServerConfigCreateGameDoubleRepeatingPayoutMutation,
    ServerConfigCreateGameDoubleRepeatingPayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ServerConfigCreateGameDoubleRepeatingPayoutMutation,
    ServerConfigCreateGameDoubleRepeatingPayoutMutationVariables
  >(ServerConfigCreateGameDoubleRepeatingPayoutDocument, options);
}
export type ServerConfigCreateGameDoubleRepeatingPayoutMutationHookResult = ReturnType<
  typeof useServerConfigCreateGameDoubleRepeatingPayoutMutation
>;
export type ServerConfigCreateGameDoubleRepeatingPayoutMutationResult =
  Apollo.MutationResult<ServerConfigCreateGameDoubleRepeatingPayoutMutation>;
export type ServerConfigCreateGameDoubleRepeatingPayoutMutationOptions = Apollo.BaseMutationOptions<
  ServerConfigCreateGameDoubleRepeatingPayoutMutation,
  ServerConfigCreateGameDoubleRepeatingPayoutMutationVariables
>;
export const ServerConfigCreateGameDrawTimeDocument = gql`
  mutation ServerConfigCreateGameDrawTime($input: CreateGameDrawTimeInput!) {
    createGameDrawTime(input: $input) {
      gameDrawTime {
        id
      }
    }
  }
`;
export type ServerConfigCreateGameDrawTimeMutationFn = Apollo.MutationFunction<
  ServerConfigCreateGameDrawTimeMutation,
  ServerConfigCreateGameDrawTimeMutationVariables
>;

/**
 * __useServerConfigCreateGameDrawTimeMutation__
 *
 * To run a mutation, you first call `useServerConfigCreateGameDrawTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerConfigCreateGameDrawTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverConfigCreateGameDrawTimeMutation, { data, loading, error }] = useServerConfigCreateGameDrawTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServerConfigCreateGameDrawTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<ServerConfigCreateGameDrawTimeMutation, ServerConfigCreateGameDrawTimeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ServerConfigCreateGameDrawTimeMutation, ServerConfigCreateGameDrawTimeMutationVariables>(
    ServerConfigCreateGameDrawTimeDocument,
    options,
  );
}
export type ServerConfigCreateGameDrawTimeMutationHookResult = ReturnType<typeof useServerConfigCreateGameDrawTimeMutation>;
export type ServerConfigCreateGameDrawTimeMutationResult = Apollo.MutationResult<ServerConfigCreateGameDrawTimeMutation>;
export type ServerConfigCreateGameDrawTimeMutationOptions = Apollo.BaseMutationOptions<
  ServerConfigCreateGameDrawTimeMutation,
  ServerConfigCreateGameDrawTimeMutationVariables
>;
export const CreateGameDrawTimeScheduleDocument = gql`
  mutation CreateGameDrawTimeSchedule($input: CreateGameDrawTimeScheduleInput!) {
    createGameDrawTimeSchedule(input: $input) {
      gameDrawTimeSchedule {
        id
      }
    }
  }
`;
export type CreateGameDrawTimeScheduleMutationFn = Apollo.MutationFunction<
  CreateGameDrawTimeScheduleMutation,
  CreateGameDrawTimeScheduleMutationVariables
>;

/**
 * __useCreateGameDrawTimeScheduleMutation__
 *
 * To run a mutation, you first call `useCreateGameDrawTimeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameDrawTimeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameDrawTimeScheduleMutation, { data, loading, error }] = useCreateGameDrawTimeScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameDrawTimeScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGameDrawTimeScheduleMutation, CreateGameDrawTimeScheduleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGameDrawTimeScheduleMutation, CreateGameDrawTimeScheduleMutationVariables>(
    CreateGameDrawTimeScheduleDocument,
    options,
  );
}
export type CreateGameDrawTimeScheduleMutationHookResult = ReturnType<typeof useCreateGameDrawTimeScheduleMutation>;
export type CreateGameDrawTimeScheduleMutationResult = Apollo.MutationResult<CreateGameDrawTimeScheduleMutation>;
export type CreateGameDrawTimeScheduleMutationOptions = Apollo.BaseMutationOptions<
  CreateGameDrawTimeScheduleMutation,
  CreateGameDrawTimeScheduleMutationVariables
>;
export const ServerConfigCreateGamePayoutDocument = gql`
  mutation ServerConfigCreateGamePayout($input: CreateGamePayoutInput!) {
    createGamePayout(input: $input) {
      gamePayout {
        id
      }
    }
  }
`;
export type ServerConfigCreateGamePayoutMutationFn = Apollo.MutationFunction<
  ServerConfigCreateGamePayoutMutation,
  ServerConfigCreateGamePayoutMutationVariables
>;

/**
 * __useServerConfigCreateGamePayoutMutation__
 *
 * To run a mutation, you first call `useServerConfigCreateGamePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerConfigCreateGamePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverConfigCreateGamePayoutMutation, { data, loading, error }] = useServerConfigCreateGamePayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServerConfigCreateGamePayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<ServerConfigCreateGamePayoutMutation, ServerConfigCreateGamePayoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ServerConfigCreateGamePayoutMutation, ServerConfigCreateGamePayoutMutationVariables>(
    ServerConfigCreateGamePayoutDocument,
    options,
  );
}
export type ServerConfigCreateGamePayoutMutationHookResult = ReturnType<typeof useServerConfigCreateGamePayoutMutation>;
export type ServerConfigCreateGamePayoutMutationResult = Apollo.MutationResult<ServerConfigCreateGamePayoutMutation>;
export type ServerConfigCreateGamePayoutMutationOptions = Apollo.BaseMutationOptions<
  ServerConfigCreateGamePayoutMutation,
  ServerConfigCreateGamePayoutMutationVariables
>;
export const ServerConfigCreateGameTripleRepeatingPayoutDocument = gql`
  mutation ServerConfigCreateGameTripleRepeatingPayout($input: CreateGameTripleRepeatingPayoutInput!) {
    createGameTripleRepeatingPayout(input: $input) {
      gameTripleRepeatingPayout {
        id
      }
    }
  }
`;
export type ServerConfigCreateGameTripleRepeatingPayoutMutationFn = Apollo.MutationFunction<
  ServerConfigCreateGameTripleRepeatingPayoutMutation,
  ServerConfigCreateGameTripleRepeatingPayoutMutationVariables
>;

/**
 * __useServerConfigCreateGameTripleRepeatingPayoutMutation__
 *
 * To run a mutation, you first call `useServerConfigCreateGameTripleRepeatingPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerConfigCreateGameTripleRepeatingPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverConfigCreateGameTripleRepeatingPayoutMutation, { data, loading, error }] = useServerConfigCreateGameTripleRepeatingPayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServerConfigCreateGameTripleRepeatingPayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ServerConfigCreateGameTripleRepeatingPayoutMutation,
    ServerConfigCreateGameTripleRepeatingPayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ServerConfigCreateGameTripleRepeatingPayoutMutation,
    ServerConfigCreateGameTripleRepeatingPayoutMutationVariables
  >(ServerConfigCreateGameTripleRepeatingPayoutDocument, options);
}
export type ServerConfigCreateGameTripleRepeatingPayoutMutationHookResult = ReturnType<
  typeof useServerConfigCreateGameTripleRepeatingPayoutMutation
>;
export type ServerConfigCreateGameTripleRepeatingPayoutMutationResult =
  Apollo.MutationResult<ServerConfigCreateGameTripleRepeatingPayoutMutation>;
export type ServerConfigCreateGameTripleRepeatingPayoutMutationOptions = Apollo.BaseMutationOptions<
  ServerConfigCreateGameTripleRepeatingPayoutMutation,
  ServerConfigCreateGameTripleRepeatingPayoutMutationVariables
>;
export const DeleteGameDrawTimeScheduleDocument = gql`
  mutation DeleteGameDrawTimeSchedule($id: String!) {
    deleteGameDrawTimeSchedule(id: $id)
  }
`;
export type DeleteGameDrawTimeScheduleMutationFn = Apollo.MutationFunction<
  DeleteGameDrawTimeScheduleMutation,
  DeleteGameDrawTimeScheduleMutationVariables
>;

/**
 * __useDeleteGameDrawTimeScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteGameDrawTimeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameDrawTimeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameDrawTimeScheduleMutation, { data, loading, error }] = useDeleteGameDrawTimeScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGameDrawTimeScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteGameDrawTimeScheduleMutation, DeleteGameDrawTimeScheduleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGameDrawTimeScheduleMutation, DeleteGameDrawTimeScheduleMutationVariables>(
    DeleteGameDrawTimeScheduleDocument,
    options,
  );
}
export type DeleteGameDrawTimeScheduleMutationHookResult = ReturnType<typeof useDeleteGameDrawTimeScheduleMutation>;
export type DeleteGameDrawTimeScheduleMutationResult = Apollo.MutationResult<DeleteGameDrawTimeScheduleMutation>;
export type DeleteGameDrawTimeScheduleMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameDrawTimeScheduleMutation,
  DeleteGameDrawTimeScheduleMutationVariables
>;
export const EnableDisableGameDrawtimeDocument = gql`
  mutation EnableDisableGameDrawtime($input: EnableDisableGameDrawTimeInput!) {
    enableDisableGameDrawtime(input: $input) {
      id
    }
  }
`;
export type EnableDisableGameDrawtimeMutationFn = Apollo.MutationFunction<
  EnableDisableGameDrawtimeMutation,
  EnableDisableGameDrawtimeMutationVariables
>;

/**
 * __useEnableDisableGameDrawtimeMutation__
 *
 * To run a mutation, you first call `useEnableDisableGameDrawtimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableDisableGameDrawtimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableDisableGameDrawtimeMutation, { data, loading, error }] = useEnableDisableGameDrawtimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableDisableGameDrawtimeMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableDisableGameDrawtimeMutation, EnableDisableGameDrawtimeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableDisableGameDrawtimeMutation, EnableDisableGameDrawtimeMutationVariables>(
    EnableDisableGameDrawtimeDocument,
    options,
  );
}
export type EnableDisableGameDrawtimeMutationHookResult = ReturnType<typeof useEnableDisableGameDrawtimeMutation>;
export type EnableDisableGameDrawtimeMutationResult = Apollo.MutationResult<EnableDisableGameDrawtimeMutation>;
export type EnableDisableGameDrawtimeMutationOptions = Apollo.BaseMutationOptions<
  EnableDisableGameDrawtimeMutation,
  EnableDisableGameDrawtimeMutationVariables
>;
export const GameDrawTimeSchedulesDocument = gql`
  query GameDrawTimeSchedules($first: Int, $filterBy: GameDrawTimeScheduleFilter) {
    gameDrawTimeSchedules(first: $first, filterBy: $filterBy) {
      nodes {
        id
        startDateTime
        endDateTime
        drawStartTime
        drawEndTime
        drawCutOffTime
        createdAt
        updatedAt
        gameDrawTime {
          id
          drawTime {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGameDrawTimeSchedulesQuery__
 *
 * To run a query within a React component, call `useGameDrawTimeSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameDrawTimeSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameDrawTimeSchedulesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGameDrawTimeSchedulesQuery(
  baseOptions?: Apollo.QueryHookOptions<GameDrawTimeSchedulesQuery, GameDrawTimeSchedulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameDrawTimeSchedulesQuery, GameDrawTimeSchedulesQueryVariables>(GameDrawTimeSchedulesDocument, options);
}
export function useGameDrawTimeSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameDrawTimeSchedulesQuery, GameDrawTimeSchedulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameDrawTimeSchedulesQuery, GameDrawTimeSchedulesQueryVariables>(GameDrawTimeSchedulesDocument, options);
}
export type GameDrawTimeSchedulesQueryHookResult = ReturnType<typeof useGameDrawTimeSchedulesQuery>;
export type GameDrawTimeSchedulesLazyQueryHookResult = ReturnType<typeof useGameDrawTimeSchedulesLazyQuery>;
export type GameDrawTimeSchedulesQueryResult = Apollo.QueryResult<GameDrawTimeSchedulesQuery, GameDrawTimeSchedulesQueryVariables>;
export const ServerConfigGameTypeDocument = gql`
  query ServerConfigGameType($id: String!) {
    gameType(id: $id) {
      id
      latestPayout
    }
  }
`;

/**
 * __useServerConfigGameTypeQuery__
 *
 * To run a query within a React component, call `useServerConfigGameTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerConfigGameTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerConfigGameTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServerConfigGameTypeQuery(
  baseOptions: Apollo.QueryHookOptions<ServerConfigGameTypeQuery, ServerConfigGameTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerConfigGameTypeQuery, ServerConfigGameTypeQueryVariables>(ServerConfigGameTypeDocument, options);
}
export function useServerConfigGameTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerConfigGameTypeQuery, ServerConfigGameTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerConfigGameTypeQuery, ServerConfigGameTypeQueryVariables>(ServerConfigGameTypeDocument, options);
}
export type ServerConfigGameTypeQueryHookResult = ReturnType<typeof useServerConfigGameTypeQuery>;
export type ServerConfigGameTypeLazyQueryHookResult = ReturnType<typeof useServerConfigGameTypeLazyQuery>;
export type ServerConfigGameTypeQueryResult = Apollo.QueryResult<ServerConfigGameTypeQuery, ServerConfigGameTypeQueryVariables>;
export const ServerConfigGameDrawTimesDocument = gql`
  query ServerConfigGameDrawTimes($filterBy: GameDrawTimeFilter!) {
    gameDrawTimes(filterBy: $filterBy) {
      nodes {
        id
        status
        drawTime {
          id
          name
          startDateTime
          endDateTime
          cutOff
        }
        isDisabled
      }
    }
  }
`;

/**
 * __useServerConfigGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useServerConfigGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerConfigGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerConfigGameDrawTimesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useServerConfigGameDrawTimesQuery(
  baseOptions: Apollo.QueryHookOptions<ServerConfigGameDrawTimesQuery, ServerConfigGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerConfigGameDrawTimesQuery, ServerConfigGameDrawTimesQueryVariables>(
    ServerConfigGameDrawTimesDocument,
    options,
  );
}
export function useServerConfigGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerConfigGameDrawTimesQuery, ServerConfigGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerConfigGameDrawTimesQuery, ServerConfigGameDrawTimesQueryVariables>(
    ServerConfigGameDrawTimesDocument,
    options,
  );
}
export type ServerConfigGameDrawTimesQueryHookResult = ReturnType<typeof useServerConfigGameDrawTimesQuery>;
export type ServerConfigGameDrawTimesLazyQueryHookResult = ReturnType<typeof useServerConfigGameDrawTimesLazyQuery>;
export type ServerConfigGameDrawTimesQueryResult = Apollo.QueryResult<
  ServerConfigGameDrawTimesQuery,
  ServerConfigGameDrawTimesQueryVariables
>;
export const ServerConfigGetGameDrawtimeSchedsDocument = gql`
  query ServerConfigGetGameDrawtimeScheds {
    gameDrawTimeSchedules {
      nodes {
        id
        startDateTime
        endDateTime
        drawStartTime
        drawEndTime
        drawCutOffTime
        gameDrawTime {
          id
          drawTime {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useServerConfigGetGameDrawtimeSchedsQuery__
 *
 * To run a query within a React component, call `useServerConfigGetGameDrawtimeSchedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerConfigGetGameDrawtimeSchedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerConfigGetGameDrawtimeSchedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerConfigGetGameDrawtimeSchedsQuery(
  baseOptions?: Apollo.QueryHookOptions<ServerConfigGetGameDrawtimeSchedsQuery, ServerConfigGetGameDrawtimeSchedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerConfigGetGameDrawtimeSchedsQuery, ServerConfigGetGameDrawtimeSchedsQueryVariables>(
    ServerConfigGetGameDrawtimeSchedsDocument,
    options,
  );
}
export function useServerConfigGetGameDrawtimeSchedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServerConfigGetGameDrawtimeSchedsQuery, ServerConfigGetGameDrawtimeSchedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServerConfigGetGameDrawtimeSchedsQuery, ServerConfigGetGameDrawtimeSchedsQueryVariables>(
    ServerConfigGetGameDrawtimeSchedsDocument,
    options,
  );
}
export type ServerConfigGetGameDrawtimeSchedsQueryHookResult = ReturnType<typeof useServerConfigGetGameDrawtimeSchedsQuery>;
export type ServerConfigGetGameDrawtimeSchedsLazyQueryHookResult = ReturnType<typeof useServerConfigGetGameDrawtimeSchedsLazyQuery>;
export type ServerConfigGetGameDrawtimeSchedsQueryResult = Apollo.QueryResult<
  ServerConfigGetGameDrawtimeSchedsQuery,
  ServerConfigGetGameDrawtimeSchedsQueryVariables
>;
export const ServerConfigGetLatestGameDoubleRepeatingPayoutDocument = gql`
  query ServerConfigGetLatestGameDoubleRepeatingPayout($gameTypeId: String!) {
    getLatestGameDoubleRepeatingPayout(gameTypeId: $gameTypeId) {
      id
      payout
    }
  }
`;

/**
 * __useServerConfigGetLatestGameDoubleRepeatingPayoutQuery__
 *
 * To run a query within a React component, call `useServerConfigGetLatestGameDoubleRepeatingPayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerConfigGetLatestGameDoubleRepeatingPayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerConfigGetLatestGameDoubleRepeatingPayoutQuery({
 *   variables: {
 *      gameTypeId: // value for 'gameTypeId'
 *   },
 * });
 */
export function useServerConfigGetLatestGameDoubleRepeatingPayoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServerConfigGetLatestGameDoubleRepeatingPayoutQuery,
    ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerConfigGetLatestGameDoubleRepeatingPayoutQuery, ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables>(
    ServerConfigGetLatestGameDoubleRepeatingPayoutDocument,
    options,
  );
}
export function useServerConfigGetLatestGameDoubleRepeatingPayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServerConfigGetLatestGameDoubleRepeatingPayoutQuery,
    ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServerConfigGetLatestGameDoubleRepeatingPayoutQuery,
    ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables
  >(ServerConfigGetLatestGameDoubleRepeatingPayoutDocument, options);
}
export type ServerConfigGetLatestGameDoubleRepeatingPayoutQueryHookResult = ReturnType<
  typeof useServerConfigGetLatestGameDoubleRepeatingPayoutQuery
>;
export type ServerConfigGetLatestGameDoubleRepeatingPayoutLazyQueryHookResult = ReturnType<
  typeof useServerConfigGetLatestGameDoubleRepeatingPayoutLazyQuery
>;
export type ServerConfigGetLatestGameDoubleRepeatingPayoutQueryResult = Apollo.QueryResult<
  ServerConfigGetLatestGameDoubleRepeatingPayoutQuery,
  ServerConfigGetLatestGameDoubleRepeatingPayoutQueryVariables
>;
export const ServerConfigGetLatestGameTripleRepeatingPayoutDocument = gql`
  query ServerConfigGetLatestGameTripleRepeatingPayout($gameTypeId: String!) {
    getLatestGameTripleRepeatingPayout(gameTypeId: $gameTypeId) {
      id
      payout
    }
  }
`;

/**
 * __useServerConfigGetLatestGameTripleRepeatingPayoutQuery__
 *
 * To run a query within a React component, call `useServerConfigGetLatestGameTripleRepeatingPayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerConfigGetLatestGameTripleRepeatingPayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerConfigGetLatestGameTripleRepeatingPayoutQuery({
 *   variables: {
 *      gameTypeId: // value for 'gameTypeId'
 *   },
 * });
 */
export function useServerConfigGetLatestGameTripleRepeatingPayoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServerConfigGetLatestGameTripleRepeatingPayoutQuery,
    ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServerConfigGetLatestGameTripleRepeatingPayoutQuery, ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables>(
    ServerConfigGetLatestGameTripleRepeatingPayoutDocument,
    options,
  );
}
export function useServerConfigGetLatestGameTripleRepeatingPayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServerConfigGetLatestGameTripleRepeatingPayoutQuery,
    ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServerConfigGetLatestGameTripleRepeatingPayoutQuery,
    ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables
  >(ServerConfigGetLatestGameTripleRepeatingPayoutDocument, options);
}
export type ServerConfigGetLatestGameTripleRepeatingPayoutQueryHookResult = ReturnType<
  typeof useServerConfigGetLatestGameTripleRepeatingPayoutQuery
>;
export type ServerConfigGetLatestGameTripleRepeatingPayoutLazyQueryHookResult = ReturnType<
  typeof useServerConfigGetLatestGameTripleRepeatingPayoutLazyQuery
>;
export type ServerConfigGetLatestGameTripleRepeatingPayoutQueryResult = Apollo.QueryResult<
  ServerConfigGetLatestGameTripleRepeatingPayoutQuery,
  ServerConfigGetLatestGameTripleRepeatingPayoutQueryVariables
>;
export const HideShowUsherNameOnReceiptDocument = gql`
  mutation HideShowUsherNameOnReceipt($value: Boolean!) {
    systeConfigReceiptHideShowUsherName(value: $value)
  }
`;
export type HideShowUsherNameOnReceiptMutationFn = Apollo.MutationFunction<
  HideShowUsherNameOnReceiptMutation,
  HideShowUsherNameOnReceiptMutationVariables
>;

/**
 * __useHideShowUsherNameOnReceiptMutation__
 *
 * To run a mutation, you first call `useHideShowUsherNameOnReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideShowUsherNameOnReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideShowUsherNameOnReceiptMutation, { data, loading, error }] = useHideShowUsherNameOnReceiptMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useHideShowUsherNameOnReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<HideShowUsherNameOnReceiptMutation, HideShowUsherNameOnReceiptMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HideShowUsherNameOnReceiptMutation, HideShowUsherNameOnReceiptMutationVariables>(
    HideShowUsherNameOnReceiptDocument,
    options,
  );
}
export type HideShowUsherNameOnReceiptMutationHookResult = ReturnType<typeof useHideShowUsherNameOnReceiptMutation>;
export type HideShowUsherNameOnReceiptMutationResult = Apollo.MutationResult<HideShowUsherNameOnReceiptMutation>;
export type HideShowUsherNameOnReceiptMutationOptions = Apollo.BaseMutationOptions<
  HideShowUsherNameOnReceiptMutation,
  HideShowUsherNameOnReceiptMutationVariables
>;
export const ShowUsherNameOnReceiptDocument = gql`
  query ShowUsherNameOnReceipt {
    showUsherNameOnReceipt
  }
`;

/**
 * __useShowUsherNameOnReceiptQuery__
 *
 * To run a query within a React component, call `useShowUsherNameOnReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowUsherNameOnReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowUsherNameOnReceiptQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowUsherNameOnReceiptQuery(
  baseOptions?: Apollo.QueryHookOptions<ShowUsherNameOnReceiptQuery, ShowUsherNameOnReceiptQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShowUsherNameOnReceiptQuery, ShowUsherNameOnReceiptQueryVariables>(ShowUsherNameOnReceiptDocument, options);
}
export function useShowUsherNameOnReceiptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShowUsherNameOnReceiptQuery, ShowUsherNameOnReceiptQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShowUsherNameOnReceiptQuery, ShowUsherNameOnReceiptQueryVariables>(ShowUsherNameOnReceiptDocument, options);
}
export type ShowUsherNameOnReceiptQueryHookResult = ReturnType<typeof useShowUsherNameOnReceiptQuery>;
export type ShowUsherNameOnReceiptLazyQueryHookResult = ReturnType<typeof useShowUsherNameOnReceiptLazyQuery>;
export type ShowUsherNameOnReceiptQueryResult = Apollo.QueryResult<ShowUsherNameOnReceiptQuery, ShowUsherNameOnReceiptQueryVariables>;
export const ServerConfigUpdateDrawTimeDocument = gql`
  mutation ServerConfigUpdateDrawTime($input: UpdateDrawTimeInput!) {
    updateDrawTime(input: $input) {
      drawTime {
        id
      }
    }
  }
`;
export type ServerConfigUpdateDrawTimeMutationFn = Apollo.MutationFunction<
  ServerConfigUpdateDrawTimeMutation,
  ServerConfigUpdateDrawTimeMutationVariables
>;

/**
 * __useServerConfigUpdateDrawTimeMutation__
 *
 * To run a mutation, you first call `useServerConfigUpdateDrawTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServerConfigUpdateDrawTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serverConfigUpdateDrawTimeMutation, { data, loading, error }] = useServerConfigUpdateDrawTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServerConfigUpdateDrawTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<ServerConfigUpdateDrawTimeMutation, ServerConfigUpdateDrawTimeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ServerConfigUpdateDrawTimeMutation, ServerConfigUpdateDrawTimeMutationVariables>(
    ServerConfigUpdateDrawTimeDocument,
    options,
  );
}
export type ServerConfigUpdateDrawTimeMutationHookResult = ReturnType<typeof useServerConfigUpdateDrawTimeMutation>;
export type ServerConfigUpdateDrawTimeMutationResult = Apollo.MutationResult<ServerConfigUpdateDrawTimeMutation>;
export type ServerConfigUpdateDrawTimeMutationOptions = Apollo.BaseMutationOptions<
  ServerConfigUpdateDrawTimeMutation,
  ServerConfigUpdateDrawTimeMutationVariables
>;
export const CreateTelegramReportDestinationDocument = gql`
  mutation CreateTelegramReportDestination($input: CreateTelegramReportDestinationInput!) {
    createTelegramReportDestination(input: $input) {
      isSuccess
    }
  }
`;
export type CreateTelegramReportDestinationMutationFn = Apollo.MutationFunction<
  CreateTelegramReportDestinationMutation,
  CreateTelegramReportDestinationMutationVariables
>;

/**
 * __useCreateTelegramReportDestinationMutation__
 *
 * To run a mutation, you first call `useCreateTelegramReportDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTelegramReportDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTelegramReportDestinationMutation, { data, loading, error }] = useCreateTelegramReportDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTelegramReportDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTelegramReportDestinationMutation, CreateTelegramReportDestinationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTelegramReportDestinationMutation, CreateTelegramReportDestinationMutationVariables>(
    CreateTelegramReportDestinationDocument,
    options,
  );
}
export type CreateTelegramReportDestinationMutationHookResult = ReturnType<typeof useCreateTelegramReportDestinationMutation>;
export type CreateTelegramReportDestinationMutationResult = Apollo.MutationResult<CreateTelegramReportDestinationMutation>;
export type CreateTelegramReportDestinationMutationOptions = Apollo.BaseMutationOptions<
  CreateTelegramReportDestinationMutation,
  CreateTelegramReportDestinationMutationVariables
>;
export const CreateTelegramReportTypeDocument = gql`
  mutation CreateTelegramReportType($input: CreateTelegramReportTypeInput!) {
    createTelegramReportType(input: $input) {
      telegramReportType {
        id
      }
    }
  }
`;
export type CreateTelegramReportTypeMutationFn = Apollo.MutationFunction<
  CreateTelegramReportTypeMutation,
  CreateTelegramReportTypeMutationVariables
>;

/**
 * __useCreateTelegramReportTypeMutation__
 *
 * To run a mutation, you first call `useCreateTelegramReportTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTelegramReportTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTelegramReportTypeMutation, { data, loading, error }] = useCreateTelegramReportTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTelegramReportTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTelegramReportTypeMutation, CreateTelegramReportTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTelegramReportTypeMutation, CreateTelegramReportTypeMutationVariables>(
    CreateTelegramReportTypeDocument,
    options,
  );
}
export type CreateTelegramReportTypeMutationHookResult = ReturnType<typeof useCreateTelegramReportTypeMutation>;
export type CreateTelegramReportTypeMutationResult = Apollo.MutationResult<CreateTelegramReportTypeMutation>;
export type CreateTelegramReportTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateTelegramReportTypeMutation,
  CreateTelegramReportTypeMutationVariables
>;
export const CreateTelegramReportDocument = gql`
  mutation CreateTelegramReport($input: CreateTelegramReportInput!) {
    createTelegramReport(input: $input) {
      telegramReport {
        id
      }
    }
  }
`;
export type CreateTelegramReportMutationFn = Apollo.MutationFunction<CreateTelegramReportMutation, CreateTelegramReportMutationVariables>;

/**
 * __useCreateTelegramReportMutation__
 *
 * To run a mutation, you first call `useCreateTelegramReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTelegramReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTelegramReportMutation, { data, loading, error }] = useCreateTelegramReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTelegramReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTelegramReportMutation, CreateTelegramReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTelegramReportMutation, CreateTelegramReportMutationVariables>(CreateTelegramReportDocument, options);
}
export type CreateTelegramReportMutationHookResult = ReturnType<typeof useCreateTelegramReportMutation>;
export type CreateTelegramReportMutationResult = Apollo.MutationResult<CreateTelegramReportMutation>;
export type CreateTelegramReportMutationOptions = Apollo.BaseMutationOptions<
  CreateTelegramReportMutation,
  CreateTelegramReportMutationVariables
>;
export const DeleteReportDestinationDocument = gql`
  mutation DeleteReportDestination($deleteReportDestinationId: String!) {
    deleteReportDestination(id: $deleteReportDestinationId)
  }
`;
export type DeleteReportDestinationMutationFn = Apollo.MutationFunction<
  DeleteReportDestinationMutation,
  DeleteReportDestinationMutationVariables
>;

/**
 * __useDeleteReportDestinationMutation__
 *
 * To run a mutation, you first call `useDeleteReportDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportDestinationMutation, { data, loading, error }] = useDeleteReportDestinationMutation({
 *   variables: {
 *      deleteReportDestinationId: // value for 'deleteReportDestinationId'
 *   },
 * });
 */
export function useDeleteReportDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteReportDestinationMutation, DeleteReportDestinationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReportDestinationMutation, DeleteReportDestinationMutationVariables>(
    DeleteReportDestinationDocument,
    options,
  );
}
export type DeleteReportDestinationMutationHookResult = ReturnType<typeof useDeleteReportDestinationMutation>;
export type DeleteReportDestinationMutationResult = Apollo.MutationResult<DeleteReportDestinationMutation>;
export type DeleteReportDestinationMutationOptions = Apollo.BaseMutationOptions<
  DeleteReportDestinationMutation,
  DeleteReportDestinationMutationVariables
>;
export const GetTelegramBotUpdatesDocument = gql`
  mutation GetTelegramBotUpdates($input: GetTelegramBotUpdatesInput!) {
    getTelegramBotUpdates(input: $input) {
      botName
      botUsername
      token
      groupChatName
      chatId
      topicId
      topicName
      type
    }
  }
`;
export type GetTelegramBotUpdatesMutationFn = Apollo.MutationFunction<
  GetTelegramBotUpdatesMutation,
  GetTelegramBotUpdatesMutationVariables
>;

/**
 * __useGetTelegramBotUpdatesMutation__
 *
 * To run a mutation, you first call `useGetTelegramBotUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTelegramBotUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTelegramBotUpdatesMutation, { data, loading, error }] = useGetTelegramBotUpdatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTelegramBotUpdatesMutation(
  baseOptions?: Apollo.MutationHookOptions<GetTelegramBotUpdatesMutation, GetTelegramBotUpdatesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetTelegramBotUpdatesMutation, GetTelegramBotUpdatesMutationVariables>(GetTelegramBotUpdatesDocument, options);
}
export type GetTelegramBotUpdatesMutationHookResult = ReturnType<typeof useGetTelegramBotUpdatesMutation>;
export type GetTelegramBotUpdatesMutationResult = Apollo.MutationResult<GetTelegramBotUpdatesMutation>;
export type GetTelegramBotUpdatesMutationOptions = Apollo.BaseMutationOptions<
  GetTelegramBotUpdatesMutation,
  GetTelegramBotUpdatesMutationVariables
>;
export const TelegramReportGameDrawTimesDocument = gql`
  query TelegramReportGameDrawTimes($first: Int, $filterBy: GameDrawTimeFilter) {
    gameDrawTimes(first: $first, filterBy: $filterBy) {
      nodes {
        id
        game {
          id
          gameType {
            id
            name
          }
        }
        drawTime {
          id
          name
          endDateTime
        }
      }
    }
  }
`;

/**
 * __useTelegramReportGameDrawTimesQuery__
 *
 * To run a query within a React component, call `useTelegramReportGameDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramReportGameDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramReportGameDrawTimesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useTelegramReportGameDrawTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<TelegramReportGameDrawTimesQuery, TelegramReportGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramReportGameDrawTimesQuery, TelegramReportGameDrawTimesQueryVariables>(
    TelegramReportGameDrawTimesDocument,
    options,
  );
}
export function useTelegramReportGameDrawTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelegramReportGameDrawTimesQuery, TelegramReportGameDrawTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramReportGameDrawTimesQuery, TelegramReportGameDrawTimesQueryVariables>(
    TelegramReportGameDrawTimesDocument,
    options,
  );
}
export type TelegramReportGameDrawTimesQueryHookResult = ReturnType<typeof useTelegramReportGameDrawTimesQuery>;
export type TelegramReportGameDrawTimesLazyQueryHookResult = ReturnType<typeof useTelegramReportGameDrawTimesLazyQuery>;
export type TelegramReportGameDrawTimesQueryResult = Apollo.QueryResult<
  TelegramReportGameDrawTimesQuery,
  TelegramReportGameDrawTimesQueryVariables
>;
export const TelegramBotsDocument = gql`
  query TelegramBots {
    telegramBots
  }
`;

/**
 * __useTelegramBotsQuery__
 *
 * To run a query within a React component, call `useTelegramBotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramBotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramBotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTelegramBotsQuery(baseOptions?: Apollo.QueryHookOptions<TelegramBotsQuery, TelegramBotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramBotsQuery, TelegramBotsQueryVariables>(TelegramBotsDocument, options);
}
export function useTelegramBotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TelegramBotsQuery, TelegramBotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramBotsQuery, TelegramBotsQueryVariables>(TelegramBotsDocument, options);
}
export type TelegramBotsQueryHookResult = ReturnType<typeof useTelegramBotsQuery>;
export type TelegramBotsLazyQueryHookResult = ReturnType<typeof useTelegramBotsLazyQuery>;
export type TelegramBotsQueryResult = Apollo.QueryResult<TelegramBotsQuery, TelegramBotsQueryVariables>;
export const TelegramGroupChatsDocument = gql`
  query TelegramGroupChats {
    telegramGroupChats
  }
`;

/**
 * __useTelegramGroupChatsQuery__
 *
 * To run a query within a React component, call `useTelegramGroupChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramGroupChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramGroupChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTelegramGroupChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<TelegramGroupChatsQuery, TelegramGroupChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramGroupChatsQuery, TelegramGroupChatsQueryVariables>(TelegramGroupChatsDocument, options);
}
export function useTelegramGroupChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelegramGroupChatsQuery, TelegramGroupChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramGroupChatsQuery, TelegramGroupChatsQueryVariables>(TelegramGroupChatsDocument, options);
}
export type TelegramGroupChatsQueryHookResult = ReturnType<typeof useTelegramGroupChatsQuery>;
export type TelegramGroupChatsLazyQueryHookResult = ReturnType<typeof useTelegramGroupChatsLazyQuery>;
export type TelegramGroupChatsQueryResult = Apollo.QueryResult<TelegramGroupChatsQuery, TelegramGroupChatsQueryVariables>;
export const TelegramReportDestinationsDocument = gql`
  query TelegramReportDestinations($first: Int!, $after: String, $before: String, $filterBy: TelegramReportDestinationsFilter) {
    telegramReportDestinations(
      first: $first
      after: $after
      before: $before
      filterBy: $filterBy
      sortBy: { direction: DESC, field: CREATED_AT }
    ) {
      nodes {
        id
        groupChatName
        topicName
        chatId
        topicId
        token
        type
        token
        botName
        telegramReport {
          id
          name
        }
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useTelegramReportDestinationsQuery__
 *
 * To run a query within a React component, call `useTelegramReportDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramReportDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramReportDestinationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useTelegramReportDestinationsQuery(
  baseOptions: Apollo.QueryHookOptions<TelegramReportDestinationsQuery, TelegramReportDestinationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramReportDestinationsQuery, TelegramReportDestinationsQueryVariables>(
    TelegramReportDestinationsDocument,
    options,
  );
}
export function useTelegramReportDestinationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelegramReportDestinationsQuery, TelegramReportDestinationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramReportDestinationsQuery, TelegramReportDestinationsQueryVariables>(
    TelegramReportDestinationsDocument,
    options,
  );
}
export type TelegramReportDestinationsQueryHookResult = ReturnType<typeof useTelegramReportDestinationsQuery>;
export type TelegramReportDestinationsLazyQueryHookResult = ReturnType<typeof useTelegramReportDestinationsLazyQuery>;
export type TelegramReportDestinationsQueryResult = Apollo.QueryResult<
  TelegramReportDestinationsQuery,
  TelegramReportDestinationsQueryVariables
>;
export const TelegramReportTypesDocument = gql`
  query TelegramReportTypes($first: Int!, $after: String, $before: String) {
    telegramReportTypes(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        name
        description
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useTelegramReportTypesQuery__
 *
 * To run a query within a React component, call `useTelegramReportTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramReportTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramReportTypesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useTelegramReportTypesQuery(
  baseOptions: Apollo.QueryHookOptions<TelegramReportTypesQuery, TelegramReportTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramReportTypesQuery, TelegramReportTypesQueryVariables>(TelegramReportTypesDocument, options);
}
export function useTelegramReportTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelegramReportTypesQuery, TelegramReportTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramReportTypesQuery, TelegramReportTypesQueryVariables>(TelegramReportTypesDocument, options);
}
export type TelegramReportTypesQueryHookResult = ReturnType<typeof useTelegramReportTypesQuery>;
export type TelegramReportTypesLazyQueryHookResult = ReturnType<typeof useTelegramReportTypesLazyQuery>;
export type TelegramReportTypesQueryResult = Apollo.QueryResult<TelegramReportTypesQuery, TelegramReportTypesQueryVariables>;
export const TelegramReportsDocument = gql`
  query TelegramReports($first: Int!, $after: String, $before: String, $filterBy: GetTelegramReportsFilter) {
    telegramReports(first: $first, after: $after, before: $before, filterBy: $filterBy, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        name
        description
        createdAt
        telegramReportType {
          id
          name
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useTelegramReportsQuery__
 *
 * To run a query within a React component, call `useTelegramReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useTelegramReportsQuery(baseOptions: Apollo.QueryHookOptions<TelegramReportsQuery, TelegramReportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramReportsQuery, TelegramReportsQueryVariables>(TelegramReportsDocument, options);
}
export function useTelegramReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelegramReportsQuery, TelegramReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramReportsQuery, TelegramReportsQueryVariables>(TelegramReportsDocument, options);
}
export type TelegramReportsQueryHookResult = ReturnType<typeof useTelegramReportsQuery>;
export type TelegramReportsLazyQueryHookResult = ReturnType<typeof useTelegramReportsLazyQuery>;
export type TelegramReportsQueryResult = Apollo.QueryResult<TelegramReportsQuery, TelegramReportsQueryVariables>;
export const TelegramTopicsDocument = gql`
  query TelegramTopics {
    telegramTopics
  }
`;

/**
 * __useTelegramTopicsQuery__
 *
 * To run a query within a React component, call `useTelegramTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelegramTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelegramTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTelegramTopicsQuery(baseOptions?: Apollo.QueryHookOptions<TelegramTopicsQuery, TelegramTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelegramTopicsQuery, TelegramTopicsQueryVariables>(TelegramTopicsDocument, options);
}
export function useTelegramTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TelegramTopicsQuery, TelegramTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelegramTopicsQuery, TelegramTopicsQueryVariables>(TelegramTopicsDocument, options);
}
export type TelegramTopicsQueryHookResult = ReturnType<typeof useTelegramTopicsQuery>;
export type TelegramTopicsLazyQueryHookResult = ReturnType<typeof useTelegramTopicsLazyQuery>;
export type TelegramTopicsQueryResult = Apollo.QueryResult<TelegramTopicsQuery, TelegramTopicsQueryVariables>;
export const TriggerManualReportDocument = gql`
  mutation TriggerManualReport($input: TriggerManualReportInput!) {
    triggerManualReport(input: $input)
  }
`;
export type TriggerManualReportMutationFn = Apollo.MutationFunction<TriggerManualReportMutation, TriggerManualReportMutationVariables>;

/**
 * __useTriggerManualReportMutation__
 *
 * To run a mutation, you first call `useTriggerManualReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerManualReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerManualReportMutation, { data, loading, error }] = useTriggerManualReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerManualReportMutation(
  baseOptions?: Apollo.MutationHookOptions<TriggerManualReportMutation, TriggerManualReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerManualReportMutation, TriggerManualReportMutationVariables>(TriggerManualReportDocument, options);
}
export type TriggerManualReportMutationHookResult = ReturnType<typeof useTriggerManualReportMutation>;
export type TriggerManualReportMutationResult = Apollo.MutationResult<TriggerManualReportMutation>;
export type TriggerManualReportMutationOptions = Apollo.BaseMutationOptions<
  TriggerManualReportMutation,
  TriggerManualReportMutationVariables
>;
export const ConfirmUsherApplicantDocument = gql`
  mutation ConfirmUsherApplicant($input: ConfirmUsherApplicantInput!) {
    confirmUsherApplicant(input: $input) {
      id
    }
  }
`;
export type ConfirmUsherApplicantMutationFn = Apollo.MutationFunction<
  ConfirmUsherApplicantMutation,
  ConfirmUsherApplicantMutationVariables
>;

/**
 * __useConfirmUsherApplicantMutation__
 *
 * To run a mutation, you first call `useConfirmUsherApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUsherApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUsherApplicantMutation, { data, loading, error }] = useConfirmUsherApplicantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUsherApplicantMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmUsherApplicantMutation, ConfirmUsherApplicantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmUsherApplicantMutation, ConfirmUsherApplicantMutationVariables>(ConfirmUsherApplicantDocument, options);
}
export type ConfirmUsherApplicantMutationHookResult = ReturnType<typeof useConfirmUsherApplicantMutation>;
export type ConfirmUsherApplicantMutationResult = Apollo.MutationResult<ConfirmUsherApplicantMutation>;
export type ConfirmUsherApplicantMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUsherApplicantMutation,
  ConfirmUsherApplicantMutationVariables
>;
export const CreateRegistrationCodeDocument = gql`
  mutation createRegistrationCode($numberOfCodes: Int!) {
    createRegistrationCode(numberOfCodes: $numberOfCodes)
  }
`;
export type CreateRegistrationCodeMutationFn = Apollo.MutationFunction<
  CreateRegistrationCodeMutation,
  CreateRegistrationCodeMutationVariables
>;

/**
 * __useCreateRegistrationCodeMutation__
 *
 * To run a mutation, you first call `useCreateRegistrationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistrationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistrationCodeMutation, { data, loading, error }] = useCreateRegistrationCodeMutation({
 *   variables: {
 *      numberOfCodes: // value for 'numberOfCodes'
 *   },
 * });
 */
export function useCreateRegistrationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRegistrationCodeMutation, CreateRegistrationCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRegistrationCodeMutation, CreateRegistrationCodeMutationVariables>(
    CreateRegistrationCodeDocument,
    options,
  );
}
export type CreateRegistrationCodeMutationHookResult = ReturnType<typeof useCreateRegistrationCodeMutation>;
export type CreateRegistrationCodeMutationResult = Apollo.MutationResult<CreateRegistrationCodeMutation>;
export type CreateRegistrationCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistrationCodeMutation,
  CreateRegistrationCodeMutationVariables
>;
export const CreateUsherDocument = gql`
  mutation CreateUsher($input: CreateUsherInput!) {
    createUsher(input: $input) {
      usher {
        id
        firstName
        lastName
        address
        contactNumber
        createdAt
        updatedAt
        coordinator {
          id
          name
          description
          createdAt
          updatedAt
        }
        usherType {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export type CreateUsherMutationFn = Apollo.MutationFunction<CreateUsherMutation, CreateUsherMutationVariables>;

/**
 * __useCreateUsherMutation__
 *
 * To run a mutation, you first call `useCreateUsherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsherMutation, { data, loading, error }] = useCreateUsherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsherMutation(baseOptions?: Apollo.MutationHookOptions<CreateUsherMutation, CreateUsherMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUsherMutation, CreateUsherMutationVariables>(CreateUsherDocument, options);
}
export type CreateUsherMutationHookResult = ReturnType<typeof useCreateUsherMutation>;
export type CreateUsherMutationResult = Apollo.MutationResult<CreateUsherMutation>;
export type CreateUsherMutationOptions = Apollo.BaseMutationOptions<CreateUsherMutation, CreateUsherMutationVariables>;
export const DeclineUsherApplicantDocument = gql`
  mutation DeclineUsherApplicant($Id: String!) {
    declineUsherApplicant(id: $Id)
  }
`;
export type DeclineUsherApplicantMutationFn = Apollo.MutationFunction<
  DeclineUsherApplicantMutation,
  DeclineUsherApplicantMutationVariables
>;

/**
 * __useDeclineUsherApplicantMutation__
 *
 * To run a mutation, you first call `useDeclineUsherApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineUsherApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineUsherApplicantMutation, { data, loading, error }] = useDeclineUsherApplicantMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeclineUsherApplicantMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineUsherApplicantMutation, DeclineUsherApplicantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineUsherApplicantMutation, DeclineUsherApplicantMutationVariables>(DeclineUsherApplicantDocument, options);
}
export type DeclineUsherApplicantMutationHookResult = ReturnType<typeof useDeclineUsherApplicantMutation>;
export type DeclineUsherApplicantMutationResult = Apollo.MutationResult<DeclineUsherApplicantMutation>;
export type DeclineUsherApplicantMutationOptions = Apollo.BaseMutationOptions<
  DeclineUsherApplicantMutation,
  DeclineUsherApplicantMutationVariables
>;
export const DisabledUshersDocument = gql`
  query DisabledUshers($first: Int!, $after: String, $before: String, $filterBy: DisabledUshersFilterField) {
    disabledUshers(filterBy: $filterBy, first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
        lastName
        uniqueCode
        address
        contactNumber
        createdAt
        DTlastTransaction
        immuneToDisable
        coordinator {
          id
          name
        }
        usherType {
          id
          name
        }
        user {
          id
          username
          email
          isDisabled
          lastDisabledDatetime
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useDisabledUshersQuery__
 *
 * To run a query within a React component, call `useDisabledUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisabledUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisabledUshersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useDisabledUshersQuery(baseOptions: Apollo.QueryHookOptions<DisabledUshersQuery, DisabledUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisabledUshersQuery, DisabledUshersQueryVariables>(DisabledUshersDocument, options);
}
export function useDisabledUshersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisabledUshersQuery, DisabledUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisabledUshersQuery, DisabledUshersQueryVariables>(DisabledUshersDocument, options);
}
export type DisabledUshersQueryHookResult = ReturnType<typeof useDisabledUshersQuery>;
export type DisabledUshersLazyQueryHookResult = ReturnType<typeof useDisabledUshersLazyQuery>;
export type DisabledUshersQueryResult = Apollo.QueryResult<DisabledUshersQuery, DisabledUshersQueryVariables>;
export const EnableDisableUsherDocument = gql`
  mutation EnableDisableUsher($input: EnableDisableUsherInput!) {
    enableDisableUsher(input: $input)
  }
`;
export type EnableDisableUsherMutationFn = Apollo.MutationFunction<EnableDisableUsherMutation, EnableDisableUsherMutationVariables>;

/**
 * __useEnableDisableUsherMutation__
 *
 * To run a mutation, you first call `useEnableDisableUsherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableDisableUsherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableDisableUsherMutation, { data, loading, error }] = useEnableDisableUsherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableDisableUsherMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableDisableUsherMutation, EnableDisableUsherMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableDisableUsherMutation, EnableDisableUsherMutationVariables>(EnableDisableUsherDocument, options);
}
export type EnableDisableUsherMutationHookResult = ReturnType<typeof useEnableDisableUsherMutation>;
export type EnableDisableUsherMutationResult = Apollo.MutationResult<EnableDisableUsherMutation>;
export type EnableDisableUsherMutationOptions = Apollo.BaseMutationOptions<EnableDisableUsherMutation, EnableDisableUsherMutationVariables>;
export const UsherRegistrationCodesDocument = gql`
  query UsherRegistrationCodes($first: Int!, $after: String, $before: String) {
    registrationCodes(first: $first, after: $after, before: $before) {
      nodes {
        id
        code
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useUsherRegistrationCodesQuery__
 *
 * To run a query within a React component, call `useUsherRegistrationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsherRegistrationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsherRegistrationCodesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUsherRegistrationCodesQuery(
  baseOptions: Apollo.QueryHookOptions<UsherRegistrationCodesQuery, UsherRegistrationCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsherRegistrationCodesQuery, UsherRegistrationCodesQueryVariables>(UsherRegistrationCodesDocument, options);
}
export function useUsherRegistrationCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsherRegistrationCodesQuery, UsherRegistrationCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsherRegistrationCodesQuery, UsherRegistrationCodesQueryVariables>(UsherRegistrationCodesDocument, options);
}
export type UsherRegistrationCodesQueryHookResult = ReturnType<typeof useUsherRegistrationCodesQuery>;
export type UsherRegistrationCodesLazyQueryHookResult = ReturnType<typeof useUsherRegistrationCodesLazyQuery>;
export type UsherRegistrationCodesQueryResult = Apollo.QueryResult<UsherRegistrationCodesQuery, UsherRegistrationCodesQueryVariables>;
export const UsherApplicantsDocument = gql`
  query UsherApplicants($first: Int!, $after: String, $before: String) {
    usherApplicants(first: $first, after: $after, before: $before) {
      nodes {
        id
        firstName
        lastName
        contactNumber
        address
        username
        email
        registrationCode
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useUsherApplicantsQuery__
 *
 * To run a query within a React component, call `useUsherApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsherApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsherApplicantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUsherApplicantsQuery(baseOptions: Apollo.QueryHookOptions<UsherApplicantsQuery, UsherApplicantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsherApplicantsQuery, UsherApplicantsQueryVariables>(UsherApplicantsDocument, options);
}
export function useUsherApplicantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsherApplicantsQuery, UsherApplicantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsherApplicantsQuery, UsherApplicantsQueryVariables>(UsherApplicantsDocument, options);
}
export type UsherApplicantsQueryHookResult = ReturnType<typeof useUsherApplicantsQuery>;
export type UsherApplicantsLazyQueryHookResult = ReturnType<typeof useUsherApplicantsLazyQuery>;
export type UsherApplicantsQueryResult = Apollo.QueryResult<UsherApplicantsQuery, UsherApplicantsQueryVariables>;
export const GetInactiveUshersDocument = gql`
  query GetInactiveUshers($filterBy: InactiveUshersFilterField!) {
    getInactiveUshers(filterBy: $filterBy) {
      id
      uniqueCode
      firstName
      lastName
      contactNumber
      DTlastTransaction
      transactionAccess
      immuneToDisable
      address
      createdAt
      usherType {
        id
        name
      }
      coordinator {
        id
        name
      }
      user {
        id
        email
        username
      }
    }
  }
`;

/**
 * __useGetInactiveUshersQuery__
 *
 * To run a query within a React component, call `useGetInactiveUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInactiveUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInactiveUshersQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGetInactiveUshersQuery(baseOptions: Apollo.QueryHookOptions<GetInactiveUshersQuery, GetInactiveUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInactiveUshersQuery, GetInactiveUshersQueryVariables>(GetInactiveUshersDocument, options);
}
export function useGetInactiveUshersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInactiveUshersQuery, GetInactiveUshersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInactiveUshersQuery, GetInactiveUshersQueryVariables>(GetInactiveUshersDocument, options);
}
export type GetInactiveUshersQueryHookResult = ReturnType<typeof useGetInactiveUshersQuery>;
export type GetInactiveUshersLazyQueryHookResult = ReturnType<typeof useGetInactiveUshersLazyQuery>;
export type GetInactiveUshersQueryResult = Apollo.QueryResult<GetInactiveUshersQuery, GetInactiveUshersQueryVariables>;
export const SelectCoordinatorsDocument = gql`
  query SelectCoordinators($first: Int!, $after: String, $before: String) {
    coordinators(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        name
        description
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useSelectCoordinatorsQuery__
 *
 * To run a query within a React component, call `useSelectCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectCoordinatorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSelectCoordinatorsQuery(
  baseOptions: Apollo.QueryHookOptions<SelectCoordinatorsQuery, SelectCoordinatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectCoordinatorsQuery, SelectCoordinatorsQueryVariables>(SelectCoordinatorsDocument, options);
}
export function useSelectCoordinatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectCoordinatorsQuery, SelectCoordinatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectCoordinatorsQuery, SelectCoordinatorsQueryVariables>(SelectCoordinatorsDocument, options);
}
export type SelectCoordinatorsQueryHookResult = ReturnType<typeof useSelectCoordinatorsQuery>;
export type SelectCoordinatorsLazyQueryHookResult = ReturnType<typeof useSelectCoordinatorsLazyQuery>;
export type SelectCoordinatorsQueryResult = Apollo.QueryResult<SelectCoordinatorsQuery, SelectCoordinatorsQueryVariables>;
export const SelectUsherTypesDocument = gql`
  query SelectUsherTypes($first: Int!, $after: String, $before: String) {
    usherTypes(first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        name
        description
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useSelectUsherTypesQuery__
 *
 * To run a query within a React component, call `useSelectUsherTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectUsherTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectUsherTypesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSelectUsherTypesQuery(baseOptions: Apollo.QueryHookOptions<SelectUsherTypesQuery, SelectUsherTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectUsherTypesQuery, SelectUsherTypesQueryVariables>(SelectUsherTypesDocument, options);
}
export function useSelectUsherTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectUsherTypesQuery, SelectUsherTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectUsherTypesQuery, SelectUsherTypesQueryVariables>(SelectUsherTypesDocument, options);
}
export type SelectUsherTypesQueryHookResult = ReturnType<typeof useSelectUsherTypesQuery>;
export type SelectUsherTypesLazyQueryHookResult = ReturnType<typeof useSelectUsherTypesLazyQuery>;
export type SelectUsherTypesQueryResult = Apollo.QueryResult<SelectUsherTypesQuery, SelectUsherTypesQueryVariables>;
export const TableUshersDocument = gql`
  query TableUshers($first: Int!, $after: String, $before: String, $filterBy: UshersFilterField) {
    ushers(filterBy: $filterBy, first: $first, after: $after, before: $before, sortBy: { direction: DESC, field: CREATED_AT }) {
      nodes {
        id
        firstName
        lastName
        uniqueCode
        address
        contactNumber
        transactionAccess
        showUsherNameOnReceipt
        createdAt
        DTlastTransaction
        immuneToDisable
        coordinator {
          id
          name
        }
        usherType {
          id
          name
        }
        user {
          id
          username
          email
          isDisabled
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useTableUshersQuery__
 *
 * To run a query within a React component, call `useTableUshersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUshersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUshersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useTableUshersQuery(baseOptions: Apollo.QueryHookOptions<TableUshersQuery, TableUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TableUshersQuery, TableUshersQueryVariables>(TableUshersDocument, options);
}
export function useTableUshersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUshersQuery, TableUshersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TableUshersQuery, TableUshersQueryVariables>(TableUshersDocument, options);
}
export type TableUshersQueryHookResult = ReturnType<typeof useTableUshersQuery>;
export type TableUshersLazyQueryHookResult = ReturnType<typeof useTableUshersLazyQuery>;
export type TableUshersQueryResult = Apollo.QueryResult<TableUshersQuery, TableUshersQueryVariables>;
export const UpdateTransactionAccessDocument = gql`
  mutation UpdateTransactionAccess($input: UpdateTransactionAccessInput!) {
    updateTransactionAccess(input: $input)
  }
`;
export type UpdateTransactionAccessMutationFn = Apollo.MutationFunction<
  UpdateTransactionAccessMutation,
  UpdateTransactionAccessMutationVariables
>;

/**
 * __useUpdateTransactionAccessMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionAccessMutation, { data, loading, error }] = useUpdateTransactionAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTransactionAccessMutation, UpdateTransactionAccessMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTransactionAccessMutation, UpdateTransactionAccessMutationVariables>(
    UpdateTransactionAccessDocument,
    options,
  );
}
export type UpdateTransactionAccessMutationHookResult = ReturnType<typeof useUpdateTransactionAccessMutation>;
export type UpdateTransactionAccessMutationResult = Apollo.MutationResult<UpdateTransactionAccessMutation>;
export type UpdateTransactionAccessMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransactionAccessMutation,
  UpdateTransactionAccessMutationVariables
>;
export const UpdateUsherDisableImmunityDocument = gql`
  mutation UpdateUsherDisableImmunity($input: UpdateUsherDisableImmunityInput!) {
    updateUsherDisableImmunity(input: $input)
  }
`;
export type UpdateUsherDisableImmunityMutationFn = Apollo.MutationFunction<
  UpdateUsherDisableImmunityMutation,
  UpdateUsherDisableImmunityMutationVariables
>;

/**
 * __useUpdateUsherDisableImmunityMutation__
 *
 * To run a mutation, you first call `useUpdateUsherDisableImmunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsherDisableImmunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsherDisableImmunityMutation, { data, loading, error }] = useUpdateUsherDisableImmunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsherDisableImmunityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsherDisableImmunityMutation, UpdateUsherDisableImmunityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsherDisableImmunityMutation, UpdateUsherDisableImmunityMutationVariables>(
    UpdateUsherDisableImmunityDocument,
    options,
  );
}
export type UpdateUsherDisableImmunityMutationHookResult = ReturnType<typeof useUpdateUsherDisableImmunityMutation>;
export type UpdateUsherDisableImmunityMutationResult = Apollo.MutationResult<UpdateUsherDisableImmunityMutation>;
export type UpdateUsherDisableImmunityMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsherDisableImmunityMutation,
  UpdateUsherDisableImmunityMutationVariables
>;
export const UpdateUsherNameReceiptVisibilityDocument = gql`
  mutation UpdateUsherNameReceiptVisibility($input: UpdateUsherNameReceiptVisibilityInput!) {
    updateUsherNameReceiptVisibility(input: $input)
  }
`;
export type UpdateUsherNameReceiptVisibilityMutationFn = Apollo.MutationFunction<
  UpdateUsherNameReceiptVisibilityMutation,
  UpdateUsherNameReceiptVisibilityMutationVariables
>;

/**
 * __useUpdateUsherNameReceiptVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateUsherNameReceiptVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsherNameReceiptVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsherNameReceiptVisibilityMutation, { data, loading, error }] = useUpdateUsherNameReceiptVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsherNameReceiptVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsherNameReceiptVisibilityMutation, UpdateUsherNameReceiptVisibilityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsherNameReceiptVisibilityMutation, UpdateUsherNameReceiptVisibilityMutationVariables>(
    UpdateUsherNameReceiptVisibilityDocument,
    options,
  );
}
export type UpdateUsherNameReceiptVisibilityMutationHookResult = ReturnType<typeof useUpdateUsherNameReceiptVisibilityMutation>;
export type UpdateUsherNameReceiptVisibilityMutationResult = Apollo.MutationResult<UpdateUsherNameReceiptVisibilityMutation>;
export type UpdateUsherNameReceiptVisibilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsherNameReceiptVisibilityMutation,
  UpdateUsherNameReceiptVisibilityMutationVariables
>;
export const UpdateUsherPasswordDocument = gql`
  mutation UpdateUsherPassword($input: UpdateUsherPasswordInput!) {
    updateUsherPassword(input: $input) {
      usher {
        id
      }
    }
  }
`;
export type UpdateUsherPasswordMutationFn = Apollo.MutationFunction<UpdateUsherPasswordMutation, UpdateUsherPasswordMutationVariables>;

/**
 * __useUpdateUsherPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUsherPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsherPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsherPasswordMutation, { data, loading, error }] = useUpdateUsherPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsherPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsherPasswordMutation, UpdateUsherPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsherPasswordMutation, UpdateUsherPasswordMutationVariables>(UpdateUsherPasswordDocument, options);
}
export type UpdateUsherPasswordMutationHookResult = ReturnType<typeof useUpdateUsherPasswordMutation>;
export type UpdateUsherPasswordMutationResult = Apollo.MutationResult<UpdateUsherPasswordMutation>;
export type UpdateUsherPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsherPasswordMutation,
  UpdateUsherPasswordMutationVariables
>;
export const UpdateUsherDocument = gql`
  mutation UpdateUsher($input: UpdateUsherInput!) {
    updateUsher(input: $input) {
      usher {
        id
      }
    }
  }
`;
export type UpdateUsherMutationFn = Apollo.MutationFunction<UpdateUsherMutation, UpdateUsherMutationVariables>;

/**
 * __useUpdateUsherMutation__
 *
 * To run a mutation, you first call `useUpdateUsherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsherMutation, { data, loading, error }] = useUpdateUsherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsherMutation, UpdateUsherMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsherMutation, UpdateUsherMutationVariables>(UpdateUsherDocument, options);
}
export type UpdateUsherMutationHookResult = ReturnType<typeof useUpdateUsherMutation>;
export type UpdateUsherMutationResult = Apollo.MutationResult<UpdateUsherMutation>;
export type UpdateUsherMutationOptions = Apollo.BaseMutationOptions<UpdateUsherMutation, UpdateUsherMutationVariables>;
export const CreateDrawTimeDocument = gql`
  mutation CreateDrawTime($input: CreateDrawTimeInput!) {
    createDrawTime(input: $input) {
      drawTime {
        id
      }
    }
  }
`;
export type CreateDrawTimeMutationFn = Apollo.MutationFunction<CreateDrawTimeMutation, CreateDrawTimeMutationVariables>;

/**
 * __useCreateDrawTimeMutation__
 *
 * To run a mutation, you first call `useCreateDrawTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrawTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDrawTimeMutation, { data, loading, error }] = useCreateDrawTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDrawTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDrawTimeMutation, CreateDrawTimeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDrawTimeMutation, CreateDrawTimeMutationVariables>(CreateDrawTimeDocument, options);
}
export type CreateDrawTimeMutationHookResult = ReturnType<typeof useCreateDrawTimeMutation>;
export type CreateDrawTimeMutationResult = Apollo.MutationResult<CreateDrawTimeMutation>;
export type CreateDrawTimeMutationOptions = Apollo.BaseMutationOptions<CreateDrawTimeMutation, CreateDrawTimeMutationVariables>;
export const DrawTimesDocument = gql`
  query DrawTimes {
    drawTimes {
      nodes {
        id
        name
        startDateTime
        endDateTime
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useDrawTimesQuery__
 *
 * To run a query within a React component, call `useDrawTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawTimesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDrawTimesQuery(baseOptions?: Apollo.QueryHookOptions<DrawTimesQuery, DrawTimesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DrawTimesQuery, DrawTimesQueryVariables>(DrawTimesDocument, options);
}
export function useDrawTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawTimesQuery, DrawTimesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DrawTimesQuery, DrawTimesQueryVariables>(DrawTimesDocument, options);
}
export type DrawTimesQueryHookResult = ReturnType<typeof useDrawTimesQuery>;
export type DrawTimesLazyQueryHookResult = ReturnType<typeof useDrawTimesLazyQuery>;
export type DrawTimesQueryResult = Apollo.QueryResult<DrawTimesQuery, DrawTimesQueryVariables>;
export const CreateGameDocument = gql`
  mutation CreateGame($input: CreateGameInput!) {
    createGame(input: $input) {
      game {
        id
        gameType {
          id
        }
      }
    }
  }
`;
export type CreateGameMutationFn = Apollo.MutationFunction<CreateGameMutation, CreateGameMutationVariables>;

/**
 * __useCreateGameMutation__
 *
 * To run a mutation, you first call `useCreateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMutation, { data, loading, error }] = useCreateGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMutation, CreateGameMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, options);
}
export type CreateGameMutationHookResult = ReturnType<typeof useCreateGameMutation>;
export type CreateGameMutationResult = Apollo.MutationResult<CreateGameMutation>;
export type CreateGameMutationOptions = Apollo.BaseMutationOptions<CreateGameMutation, CreateGameMutationVariables>;
export const GamesDocument = gql`
  query Games($first: Int, $after: String, $before: String) {
    games(first: $first, after: $after, before: $before) {
      nodes {
        id
        name
        description
        status
        createdAt
        gameType {
          id
          name
        }
        gameDrawTime {
          id
          drawTime {
            id
            name
            status
            startDateTime
            endDateTime
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGamesQuery(baseOptions?: Apollo.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, options);
}
export function useGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, options);
}
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = Apollo.QueryResult<GamesQuery, GamesQueryVariables>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        id
        role {
          name
        }
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'Admin',
      'AdvanceBet',
      'AppApk',
      'Bet',
      'BetDetail',
      'BetTransaction',
      'BetType',
      'ClaimedHit',
      'Coordinator',
      'CoordinatorCommission',
      'CoordinatorPayment',
      'Device',
      'DeviceLocation',
      'DrawAdmin',
      'DrawAdminGame',
      'DrawTime',
      'FirebaseUsherDeviceToken',
      'Game',
      'GameConfig',
      'GameDoubleRepeatingPayout',
      'GameDrawTime',
      'GameDrawTimeSchedule',
      'GameDrawtimeLimit',
      'GameHotNumber',
      'GamePayout',
      'GameResult',
      'GameResultReport',
      'GameSalesNumberLimit',
      'GameTripleRepeatingPayout',
      'GameType',
      'GreedySoldOutBet',
      'Location',
      'Notification',
      'NotificationAdminQuery',
      'NumberCombinationSales',
      'NumberCombinationSoldOut',
      'Player',
      'RegistrationCode',
      'SystemRole',
      'TelegramReport',
      'TelegramReportDestination',
      'TelegramReportType',
      'Transaction',
      'TransactionLocation',
      'User',
      'UserDisableHistory',
      'Usher',
      'UsherApplicant',
      'UsherType',
    ],
  },
};
export default result;
